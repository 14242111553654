$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.rarity-view {

    .number {
        margin: 15*$rem/10 0;
        padding: 15*$rem/10;
        background: #ffffff;
        border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
        opacity: 1;
    
        &.rarity {
          margin-bottom: 0;
          border-radius: 6*$rem/10 6*$rem/10 0 0;
        }
    
        &.bianhao {
          margin-top: 0;
          border-radius: 0 0 6*$rem/10 6*$rem/10;
        }
    
        .collection-num {
          font-size: 15*$rem/10;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
    
        .num {
          float: right;
          font-size: 13*$rem/10;
          font-family: PingFang SC-Regular, PingFang SC;
          color: #333333;
    
          &.rarity-num {
            color: #CEBF7E;
          }
        }
      }
    .rarity_list_father_view {
        margin: 15*$rem/10 0vw;
        padding-bottom: 15*$rem/10;
        background: #ffffff;
        border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
        opacity: 1;

        .collection-num_list_title {
            font-size: 15*$rem/10;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 600;
            color: #333333;
            margin-left: 15*$rem/10;
            margin-top: 15*$rem/10;
            display: inline-block;
        }

        .collection-num_list_item {
            width: 21vw;
            height: 5.5 *$rem;
            object-fit: contain;
            display: inline-block;
            //border: 6px;
            //border-radius: 6px;
            margin-top: 0.5 *$rem;
            margin-left: 4vw;
            //background-color: whitesmoke;
            overflow: hidden;


            .item_name_des {
                font-family: PingFangSC-Regular;
                font-size: 1 *$rem;
                font-weight: normal;
                //margin-top: 0.3 *$rem;
                letter-spacing: 0px;
                color: #959595;
            }

            .item_name {
                font-family: PingFangSC-Regular;
                font-size: 1.1 *$rem;
                font-weight: 600;
                margin-top: 0.3 *$rem;
                letter-spacing: 0px;
                color: #333333;

                white-space: nowrap; //规定段落中的文本不进行换行
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .item_name_rarity {
                font-family: PingFangSC-Regular;
                font-size: 1 *$rem;
                font-weight: normal;
                margin-top: 0.3 *$rem;
                letter-spacing: 0px;
                color: #959595;

                &.rarity-num_glod {
                    color: #CEBF7E;
                }

            }
        }
    }
}