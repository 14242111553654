$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.industrial-ape-home {
  position: relative;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;

  width: 100vw;
  height: 100vh;
  padding-bottom: 31*$rem;
  overflow-y: scroll;

  .industrial-ape-inner {
    width: 100vw;
    height: 70.32*$rem;
    padding-top: 21.6*$rem;
    padding-bottom: 3*$rem;
    background: url("~@/assets/img/industrialApe/bg.png") no-repeat center center / 100% 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
  }

  .back {
    position: absolute;
    top: 5.5*$rem;
    left: 1.4*$rem;
    width: 1*$rem;
    height: 1.8*$rem;
    background: url("~@/assets/img/icon/back.png") no-repeat center center / 100% 100%;
  }

  @mixin common_Btn {
    width: 2.3*$rem;
    height: 6*$rem;
    border-radius: 0.6*$rem 0px 0px 0.6*$rem;
    opacity: 1;
    background: rgba(0, 150, 191, 0.7);
    color: #FFFFFF;
    font-size: 1.1*$rem;
    font-weight: bold;
    writing-mode: vertical-lr;
    text-align: center;
    line-height: 2.3*$rem;
  }

  .activity_rules {
    position: absolute;
    top: 14.2*$rem;
    right: 0;
    @include common_Btn();
    height: 4.5*$rem;

  }

  .my_parts {
    position: absolute;
    top: 19.8*$rem;
    right: 0;
    @include common_Btn();
  }

  .activity_rules1 {
    position: absolute;
    top: 8.6*$rem;
    right: 0;
    @include common_Btn();
    height: 4.5*$rem;

  }

  .my_parts1 {
    position: absolute;
    top: 14.2*$rem;
    right: 0;
    @include common_Btn();
  }

  .real_name {
    position: absolute;
    top: 8.6*$rem;
    right: 0;
    @include common_Btn();
    height: 4.5*$rem;
  }

  .logo_box {
    margin: 0 auto;
    width: 14.8*$rem;
    height: 2.45*$rem;
    background: url("~@/assets/img/industrialApe/logo.png") no-repeat center center / 100% 100%;
  }

  .count_box {
    margin: 0 auto;
    width: 26.4*$rem;
    height: 10.32*$rem;
    // background: url("~@/assets/img/industrialApe/info.png") no-repeat top center / 100%;
    position: relative;
    z-index: 100;


    &>img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .tips_box {
      color: #D1E9F6;
      width: 24.5*$rem;
      height: 6.25*$rem;
      border-radius: 1.5*$rem;
      background-color: rgba(0, 50, 120, 0.8);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 0;
      padding-top: 3.25*$rem;
      padding-left: 1.6*$rem;
      padding-right: 1.6*$rem;
      box-sizing: border-box;
      font-size: 1*$rem;
      text-align: center;
    }

    .res_count {
      color: #3384E1;
      position: absolute;
      height: 3*$rem;
      top: 0*$rem;
      left: 50%;
      transform: translate(-50%, 0);
      border: 0.35*$rem solid;
      border-radius: 4.6*$rem;
      background-clip: border-box;
      z-index: 2;
      background-color: #00397D;

      .res_inner {
        box-sizing: border-box;
        padding: 0.3*$rem 1*$rem;
        display: flex;
        align-items: center;
        column-gap: 0.5*$rem;

        .res_bg {
          width: 6*$rem;
          height: 1.5*$rem;
          position: relative;

          img {
            width: 6*$rem;
            height: 1.5*$rem;
          }
        }

        .res {
          max-width: 15*$rem;
          display: inline-block;
          position: relative;
          color: #FDD015;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

        }
      }
    }

    .res_count::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-image: linear-gradient(270deg, #3384E1 0%, #049CFD 100%) 8;
      border-radius: inherit;
      /* 继承圆角大小 */
    }
  }


  .info_box {
    position: relative;
    top: -2*$rem;
    margin: 0 auto;
    margin-left: 8.05*$rem;
    width: 25.55*$rem;
    height: 21.3*$rem;
    background: url("~@/assets/img/industrialApe/middle.png") no-repeat center center / 100% 100%;
  }



  .bottom_box {
    z-index: 3;
    position: absolute;
    top: 49.32*$rem;
    padding-top: 3.5*$rem;
    width: 100%;
    height: 52*$rem;
    background: linear-gradient(181deg, rgba(0, 30, 74, 0) 8%, #04234F 25%);

    .buy_box {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: AlimamaShuHeiTi;
      color: #FFFFFF;
      font-size: 2*$rem;
      font-weight: bold;
      position: relative;
      margin: 0 auto;
      width: 28.45*$rem;
      height: 6.5*$rem;
      background: url("~@/assets/img/industrialApe/buy_btn.png") no-repeat center center / 100% 100%;
    }

    .setting_box {
      position: relative;
      margin: 0 auto;
      margin-top: 2*$rem;
      width: 31.5*$rem;
      height: 11.2*$rem;
      background: url("~@/assets/img/industrialApe/group.png") no-repeat center center / 100% 100%;
    }

    .opt_box {
      position: relative;
      margin: 2*$rem auto;
      margin-top: 2*$rem;
      margin-bottom: 3*$rem;

      width: 34.7*$rem;
      height: 16.15*$rem;
      background: url("~@/assets/img/industrialApe/synthesis_bg.png") no-repeat center center / 100% 100%;
      padding: 2.85*$rem 2.05*$rem;
      display: flex;
      justify-content: space-between;

      .left {
        width: 15.6*$rem;

        .title {
          margin-bottom: 1.25*$rem;
          font-family: AlimamaShuHeiTi;
          font-size: 2*$rem;
          font-weight: bold;
          color: #FFFFFF;
        }

        .subTitle {
          font-family: Source Han Sans;
          font-size: 1.3*$rem;
          font-weight: normal;
          color: #FFFFFF;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.65*$rem;
        font-family: 思源黑体;
        font-size: 1.5*$rem;
        font-weight: bold;

        text-align: center;
        color: #844000;
        width: 11.2*$rem;
        height: 3.35*$rem;
        background: url("~@/assets/img/industrialApe/synthesis_btn.png") no-repeat center center / 100% 100%;
      }
      .right_disable {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.65*$rem;
        font-family: 思源黑体;
        font-size: 1.5*$rem;
        font-weight: bold;

        text-align: center;
        color: #844000;
        width: 11.2*$rem;
        height: 3.35*$rem;
        filter: grayscale(100%);

        background: url("~@/assets/img/industrialApe/synthesis_btn.png") no-repeat center center / 100% 100%;
      }
    }
  }

  .score_count {
    width: 21.9*$rem;

    height: 4.65*$rem;
    position: absolute;
    right: 0;
    top: 3*$rem;
    z-index: 2;

    &>.Jackpot_box {
      display: flex;
      height: 3.85*$rem;
      justify-content: center;
      // justify-content: space-between;
      // padding: 0 2.3*$rem;
      width: 100%;
      background: url("~@/assets/img/industrialApe/number_bg.png") no-repeat center center / 100% 100%;
      font-size: 2.5*$rem;
      font-weight: bold;
      color: #FF5151;
      box-sizing: border-box;

      & .number_scroll_container {
        width: 100%;
      }

      & .number_scroll_container {
        width: 1*$rem;
      }

      >span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.55*$rem;
        height: 4.8*$rem;
        font-size: 2.5*$rem;

      }
    }
  }
}

.industrial-ape-rules {
  width: 33*$rem;
  height: 37.5*$rem;
  background: url("~@/assets/img/industrialApe/frame_l@1x.png") no-repeat center center / 100% 100%;

  .rules-outer {
    padding: 4.7*$rem 3*$rem;
    width: 100%;
    height: 100%;
    border-radius: 0.75*$rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .rules-title {
      font-family: AlimamaShuHeiTi;
      padding-bottom: 1.1*$rem;
      color: #FFFFFF;
      font-size: 1.8*$rem;
      font-weight: bold;
      text-align: center;
    }

    .rules-content {
      flex: 1;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow-y: auto;
      white-space: pre-line;
      line-height: 1.7*$rem;
      color: #FFFFFF;
      font-size: 1.2*$rem;
    }

    .rules-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1*$rem auto 0;
      width: 9.7*$rem;
      height: 3.35*$rem;
      background: url("~@/assets/img/industrialApe/no@1x.png") no-repeat center center / 100% 100%;
      color: #0C4390;
      font-size: 1.6*$rem;
      font-weight: 500;
    }
  }
}

.synthesis_ape_type {
  width: 33*$rem;
  height: 24.15*$rem;
  background: url("~@/assets/img/industrialApe/frame_s@1x.png") no-repeat center center / 100% 100%;

  .type_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.5*$rem 2*$rem;
    width: 100%;
    height: 100%;

    .close {
      position: absolute;
      right: 1*$rem;
      top: 2.2*$rem;
      width: 1.1*$rem;
      height: 1.1*$rem;
      background: url("~@/assets/img/collectionRecoveryLand/close_x.png") no-repeat center center / 100% 100%;
    }

    .title {
      margin-top: 2.5*$rem;
      font-size: 1.2*$rem;
      font-weight: 600;
      color: #9C003C;
    }

    .choose_tip {
      margin-top: 0.35*$rem;
      margin-bottom: 2*$rem;
      color: #FFFFFF;
      font-size: 1.6*$rem;
      font-weight: bold;
    }

    .type_box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;

      >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >img {
          width: 7.5*$rem;
          height: 7.5*$rem;
        }

        .opt {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #844000;
          font-family: 思源黑体;
          font-size: 1.5*$rem;
          font-weight: bold;
          margin-top: 1.3*$rem;
          margin-bottom: 1.5*$rem;
          width: 8.15*$rem;
          height: 3.35*$rem;
          background: url("~@/assets/img/industrialApe/yes@1x.png") no-repeat center center / 100% 100%;
        }
      }
    }
  }
}