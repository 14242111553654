$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.ape-cartoon-share {
    position: relative;
    > img {
        width: 100%;
    }
    .load-btn {
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(98deg, #FF7CFF 0%, #F821FB 105%);
      box-shadow: 0px 10px 16px 0px rgba(67, 17, 121, 0.38);
      font-size: 1.5*$rem;
      font-weight: bold;
      text-align: center;
      color: #FFFFFF;
      width: 26.5*$rem;
      height: 4*$rem;
      line-height: 4*$rem;
      border-radius: 2*$rem;

      &.load-btn-1 {
        top: 45%;
      }
    }
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: rgba(0, 0, 0, 0.5);
        > img {
            width: 100%;
        }
    }
    
  }