$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.popup-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7*$rem;
  color: #333333;
  font-weight: 500;
  span {
    flex: 1;
    margin-left: 2*$rem;
    text-align: center;
  }
  img {
    margin-top: .5*$rem;
    width: 2*$rem;
    height: 2*$rem;
  }
}
.days-box {
  margin-top: 1*$rem;
  margin-bottom: .5*$rem;
  text-align: center;
  font-size: 1.4*$rem;
  color: #333333;
  .transfer-days {
    color: #CEAD58;
    font-size: 1.8*$rem;
  }
}
.card-list {
  margin-bottom: 1.95*$rem;
  display: flex;
  flex-wrap: wrap;
  height: 30*$rem;
  overflow: hidden;
  overflow-y: auto;
  flex-grow: 0;
  align-items: flex-start;
  .card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1.5*$rem;
    margin-top: 1*$rem;
    
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
    .card-img {
      width: 10.5*$rem;
      height: 15.85*$rem;
      &.gray {
        filter: grayscale(100%); 
        opacity: .5;
      }
    }
    .check-img {
      margin-top: .45*$rem;
      width: 1.5*$rem;
      height: 1.5*$rem;
    }
    .overdue-time {
      position: absolute;
      left: 0.5*$rem;
      bottom: 2.5*$rem;
      background: url('~@/assets/img/collection/overdue_bg.png') no-repeat;
      background-size: 100% 100%;
      width: 9.45*$rem;
      height: 2.35*$rem;
      font-size: 1*$rem;
      color: #FF3E0A;
      text-align: center;
      line-height: 2.35*$rem;
    }
  }
}
.use-btn {
  padding: 1.05*$rem 11.2*$rem;
  margin: 0 2.7*$rem;
  color: #624C16;
  font-size: 1.8*$rem;
  text-align: center;
  background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
  border-radius: 3.7*$rem;
}
.point-mall {
  margin: 1.6*$rem 0 2*$rem 0;
  text-align: center;
  font-size: 1.2*$rem;
  color: #666666;
  font-weight: 500;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  img {
    margin: 6.75*$rem 0 3.35*$rem;
    width: 13.3*$rem;
    height: 17*$rem;
  }
  span {
    position: absolute;
    top: 17.75*$rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.6*$rem;
    color: #878787;
    font-weight: 500;
  }
  .exchange-btn {
    padding: 1.1*$rem 7.2*$rem;
    margin-bottom: 8*$rem;
    background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
    color: #624C16;
    font-size: 1.6*$rem;
    border-radius: 2.8*$rem;
  }
}

.adm-popup {
  z-index: 100;
}