@import '@/assets/scss/variable.scss';

.popup {
  .title {
    font-size: px2rem(32);
    color: #171717;
    text-align: center;
    padding-bottom: px2rem(40);
  }
  .content {
    width: 100%;
    max-height: 70vh;
    overflow-y: scroll;
  }
  :global {
    .adm-popup-body {
      border-top-left-radius: px2rem(30);
      border-top-right-radius: px2rem(30);
      width: 100%;
      padding: px2rem(42) 0 0;
    }
    .adm-popup-close-icon {
      top: px2rem(36);
      right: px2rem(30);
      font-size: px2rem(40);
    }
  }
}
