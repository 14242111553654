@import '@/assets/scss/variable.scss';

.recycle_epic_ape_bet_container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1 *$rem 0 0 2 *$rem;
  box-sizing: border-box;

  .left {
    //flex: 1.4;
    width: 28vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
      width: 90%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > :nth-child(1) {
        font-family: PingFangSC-Medium;
        font-size: 1.25 *$rem;
        font-weight: normal;
        color: #FFFFFF;
      }

      > :nth-child(2) {
        font-family: PingFangSC-Medium;
        font-size: 0.9 *$rem;
        color: #FFFFFF;
        //margin-left: 4 *$rem;
      }
    }

    .list {
      flex: 1;
      overflow-y: auto;
      padding-top: 5px;
      box-sizing: border-box;

      .parts_item {
        display: inline-block;
        margin-bottom: 1.1*$rem;
        //margin-left: 2%;
        //width: 4.45 *$rem;
        margin-left: 1vw;
        width: 5.6vw;
      }

      .epic_funds_item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 5.6vw;
        box-sizing: border-box;
        border-radius: 0.5*$rem;

        &.active {
          &::after {
            content: '';
            display: block;
            background: url('../../imgs/icon_sel.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }

        &.disabled {
          &::after {
            content: '';
            display: block;
            background: rgba(0, 0, 0, .7);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 0.5*$rem;
          }
        }

        img {
          border-radius: 0.5*$rem;
          width: 100%;
          height: 100%;
        }
      }

      .epic_num {
        text-align: center;
        margin-top: 0.55*$rem;
        width: 100%;
        color: #FFFFFF;
        font-size: 0.7*$rem;
        line-height: 1*$rem;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFangSC-Medium;
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;

    .placeholder {
      width: 3 *$rem;
      height: 100%;
    }

    .right_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .img0 {
        width: 9 *$rem;
      }

      .img1 {
        //width: 12 *$rem;
        width: 9 *$rem;
      }

      .huishou_btn {
        width: 10 *$rem;
        height: 2.5 *$rem;
        border-radius: 159.77px;
        opacity: 1;
        background: linear-gradient(270deg, #83FFEE 0%, #FFE97D 100%);
        font-size: 1.2 *$rem;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.6*$rem;
        &.btn_dis{
          background:grey;
        }
      }
    }
  }
}


