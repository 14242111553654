@import '@/assets/scss/variable.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .topContainer {
    width: 92%;
    padding: 1 *$rem 2 *$rem;
    margin-top: 2 *$rem;
    border-radius: 16px;
    background: #FFFFFF;
    box-sizing: border-box;

    .des {
      font-family: PingFangSC-Medium;
      font-size: 1.4 *$rem;
      font-weight: normal;
      line-height: 40px;
      color: #000000;
      border-bottom: 1px solid #D8D8D8;
    }

    .listContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 1 *$rem;

      .itemDes {
        font-family: PingFangSC-Regular;
        font-size: 1.4 *$rem;
        line-height: 30px;
        color: #1A1A1A;

      }

      > img {
        width: 1.8 *$rem;
        height: 1.8 *$rem;
        position: absolute;
        right: 0;
        top: 1.3 *$rem;
      }

      .item {
        > span {
          font-family: PingFangSC-Regular;
          font-size: 1.2 *$rem;
        }

        > :nth-child(1) {
          color: #767676;
        }

        > :nth-child(2) {
          color: #E83E5D;
        }

        > :nth-child(3) {
          color: #767676;
        }
      }
    }
  }

  .middleContainer {
    width: 92%;
    padding: 2 *$rem 2 *$rem;
    margin-top: 2 *$rem;
    border-radius: 16px;
    background: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    .proImg {
      width: 8 *$rem;
      height: 8 *$rem;
      border-radius: 10px;
      display: inline-block;
      padding: 1 *$rem;
      //background-color:red ;
    }

    .right {
      width: 100%;
      padding-left: 1 *$rem;
      position: relative;

      .title {
        font-family: PingFangSC-Semibold;
        font-size: 1.6 *$rem;
        font-weight: normal;
        color: #000000;
        display: inline-block;
      }

      .bottom {
        display: flex;
        flex-direction: row;
        position: absolute;
        align-items: center;
        bottom: 0;
        left: 0;
        width: 100%;
        .item_huo{
          margin-left: 5px;
        }
        .bottomZS {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 0.8 *$rem;

          > img {
            width: 1.6 *$rem;
          }

          > p {
            font-family: PingFangSC-Medium;
            font-size: 2 *$rem;
            color: #333333;
            margin-left: 0.5 *$rem;
          }
        }

        .addContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: absolute;
          right: 0;

          > img {
            width: 2.0 *$rem;
            padding: 3px;
          }

          > p {
            width: 2.3 *$rem;
            text-align: center;
            font-family: PingFangHK-Semibold;
            font-size: 1.2 *$rem;
            color: #333333;

          }
        }
      }
    }
  }

  .bottomBtn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10 *$rem;
    background: #FFFFFF;
    display: flex;
    justify-content: center;

    > p {
      width: 80%;
      height: 4.3 *$rem;
      border-radius: 291px;
      background: linear-gradient(90deg, #D7C289 0%, #F4E4B1 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.2 *$rem;
      font-family: PingFangSC-Medium;
      font-size: 1.9 *$rem;
      color: #695324;

    }
  }
}
