$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.shop_record_container {
  width: 100vw;
  height: 70vh;
  background: #4E52FF linear-gradient(180deg, #05512E 0%, #064124 28%, #09170A 100%);
  border-radius: 20px 20px 0px 0px;
  border: 0.1px solid #064124;
  border-bottom: none;

  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  .header_father {
    width: 100vw;
    height: 7 *$rem;
    //margin-left: 0.5%;
    margin-top: -1px;
    position: fixed;
    background: #074728;
    //background-color: red;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 1;

    .header_title_father {
      width: 100%;
      height: 3 *$rem;
      margin-top: 1.6 *$rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .shop_car_des {
        display: inline-block;
        font-family: PingFangSC-Medium;
        font-size: 1.6 *$rem;
        font-weight: normal;
        line-height: 38px;
        letter-spacing: 0px;
        color: #8EF4B1;
        margin-left: 4vw;
      }

      img {
        width: 1.5 *$rem;
        height: 1.5 *$rem;
        //margin-right: 0.8 *$rem;
        //position: absolute;
        //right: 1 *$rem;
      }

      .del_father {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4vw;

        .clear_des {
          font-family: PingFangSC-Regular;
          font-size: 1.4 *$rem;
          letter-spacing: 0px;
          margin-left: 0.5 *$rem;
          color: #FFFFFF;
        }
      }
    }
  }

  .list_father {
    //background-color: purple;
    margin-top: 7 *$rem;
    padding-bottom: 8 *$rem;
    width: 100vw;
    //height: 60vh;
  }

  .item_container {
    width: 100%;
    height: 10 *$rem;
    position: relative;
    display: flex;
    flex-direction: row;
    //background-color: purple;
    //margin-top: 2px;
    align-items: center;

    .shop_choice_btn {
      width: 3 *$rem;
      height: 3 *$rem;
      //background-color: yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 4vw;

      img {
        width: 2 *$rem;
        height: 2 *$rem;
        object-fit: cover;
      }
    }

    .shop_img_father {
      width: 7 *$rem;
      height: 7 *$rem;
      background-color: white;
      border-radius: 10px;
      margin-left: 1.5 *$rem;
      border-radius: 10px;
      border: 0.2px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    .shop_img {
      width: 90%;
      height: 100%;
      object-fit: contain;

    }

    .title_price_father {
      width: 30%;
      height: 4 *$rem;
      //background-color: yellow;
      margin-left: 1.5 *$rem;

      .pro_name {
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        font-weight: normal;
        letter-spacing: 0px;
        color: #8EF4B1;
      }

      .pro_price {
        font-family: PingFangSC-Medium;
        font-size: 1.5 *$rem;
        font-weight: normal;
        line-height: 36px;
        color: #FF5E28;
      }
    }

    .add_reduce_pro_father {
      display: flex;
      //flex: 1;
      width: 9 *$rem;
      height: 3 *$rem;
      //background-color: beige;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      right: 1.3 *$rem;

      .add_red_img {
        width: 2.2 *$rem;
        height: 2.2 *$rem;
        object-fit: cover;
      }

      .del_lajitong {
        width: 1.2 *$rem;
        height: 1.3 *$rem;
      }

      .add_red_title {
        font-family: PingFangSC-Semibold;
        font-size: 1.3 *$rem;
        font-weight: normal;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0px;
        color: #8EF4B1;
      }
    }
  }

  .bottom_father {
    width: 100%;
    height: 11 *$rem;
    background-color: #054F2D;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    //background-color: red;
    //position: relative;

    .all_sel_father {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 2*$rem;

      .shop_total_choice_img {
        width: 2 *$rem;
        height: 2 *$rem;
        object-fit: cover;
      }

      span {
        font-size: 1.6 *$rem;
        margin-left: 0.6 *$rem;
        color: #8EF4B1;
      }
    }

    .total_title {
      font-family: PingFangSC-Regular;
      font-size: 1.2 *$rem;
      font-weight: normal;
      line-height: 33.6px;
      letter-spacing: 0px;
      color: #8EF4B1;
      margin-left: 1*$rem;
    }

    .total_money {
      font-family: PingFangSC-Medium;
      font-size: 2.4 *$rem;
      font-weight: normal;
      line-height: 67.2px;
      letter-spacing: 0px;
      color: #FF5E28;
      margin-left: 1.2*$rem;
    }

    .gobuy_father_0 {
      position: absolute;
      right: 1.2 *$rem;
    }

    .gobuy_father {
      width: 18 *$rem;
      height: 5 *$rem;
      //background-color: red;
      position: relative;
      //right: 1.2 *$rem;

      .img_btn {
        width: 100%;
        height: 100%;
      }

      .pro_count_father {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        //margin-top: 0.2 *$rem;
        //background-color: yellow;

        .pro_des {
          font-family: RuiZiZhenYan;
          font-size: 1.8 *$rem;
          font-weight: normal;
          //line-height: 42px;
          text-align: center;
          letter-spacing: 0.15em;
          display: inline-block;
        }
      }
    }
  }
}
