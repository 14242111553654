$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.noteBuyCard {
    display: flex;
    flex-direction: column;
    padding-top: 2*$rem;
    padding-bottom: 2.4*$rem;
    width: 100%;
    background-color: #fff;
    border-radius: 2*$rem;
    margin-bottom: 2*$rem;
    overflow: hidden;
    .content_title {
        font-size: 1.8*$rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding: 0 2*$rem;
        padding-bottom: 0.8*$rem;
        white-space: pre-wrap;
        line-height: 3*$rem;
    }
    .content_text {
        font-size: 12px;
        font-family: PingFang SC;
        color: #777777;
        padding: 0 1rem;
        white-space: pre-wrap;
        line-height: 1.6*$rem;
    }
    
}