$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.bottomBuy {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 1.5*$rem 2*$rem 2*$rem;
    .agreement_select {
        .adm-checkbox {
            &.adm-checkbox-checked .adm-checkbox-icon {
                border-color: #d0bc7c;
                background-color: #d0bc7c;
            }
            .adm-checkbox-icon {
                color: #000;
            }
            .adm-checkbox-content {
                color: #9d9d9d;
            }
        }
        >span {
            font-size: 12px;
            color: #333;
        }
    }

    .buy-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1*$rem 0;
        .left {
            .price-detail {
                white-space: nowrap;
                margin-right: 1*$rem;
                >span {
                    color: #CBB675;
                    font-family: PingFangSC-Semibold;
                    &:nth-child(1) {
                        font-size: 1.3*$rem;
                    }
                    &:nth-child(2) {
                        font-size: 2.4*$rem;
                    }
                }
            }
        }
        .right {
            border-radius: 2.3*$rem;
            overflow: hidden;
            width: 20.1*$rem;
            .adm-button.adm-button-large {
                font-size: 1.6*$rem;
                height: 4.6*$rem;
                color: #614d1a;
                font-weight: 500;
                background: linear-gradient(90deg, #ccbd7b 0%, #f9ecb9 100%);
                border: none;
            }
        }

    }
}

@media screen and (min-height: 780px) {
    .bottomBuy {
        padding-bottom: 2*$rem+1.7*$rem;
    }
    
}