$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.unity-scratch-view {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .scratch-card-bg-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20.8*$rem;
    width: 100%;
    height: 8.9*$rem;
    background: url('../../imgs/pic_ggk_bg.png') no-repeat;
    background-size: 100% 100%;
    .s1 {
      // padding-top: 4.3*$rem;
      width: 18.4*$rem;
      height: 6.5*$rem;
      text-align: center;
      background: url('../../imgs/pic_ggk.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      .price-des {
        margin-top: 0.7*$rem;
        color: #D10073;
        font-size: 1*$rem;
        font-weight: bold;
      }
      .point-type {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        >img {
          width: 4.4*$rem;
          height: 3.2*$rem;
        }
      }
      .ape-type {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        >img {
          width: 3.5*$rem;
          height: 3.5*$rem;
        }
      }
    }
    .scratch-card-top {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18.4*$rem;
      height: 6.5*$rem;
      .inner-bg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: url('../../imgs/pic_ggk_gjq.png') no-repeat;
        background-size: 100% 100%;
        width: 18.4*$rem;
        height: 6.5*$rem;
      }
      .scratch-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8.5*$rem;
        height: 2.2*$rem;
        border-radius: 1.1*$rem;
        background: linear-gradient(180deg, #82A4FF 0%, #8C58FF 97%);
        color: #FFFFFF;
        font-size: 1.1*$rem;
        font-weight: bold;
        &.gray-bg {
          background: #CBCBCB;
        }
      }
      .scratch-count {
        margin-top: 0.5*$rem;
        color: #9E9E9E;
        font-size: 0.8*$rem;
        font-weight: bold;
        letter-spacing: 0.2*$rem;
      }
    }
  }
  .scratch-des {
    display: flex;
    justify-content: space-between;
    margin: 1.5*$rem 0 1*$rem;
    color: #1FFDE7;
    font-size: 1.5*$rem;
    font-weight: bold;
    width: 100%;
    span {
      &:nth-child(2) {
        color: #FFFFFF;
        font-size: 1.3*$rem;
      }
    }
  }
  .gifts-box {
    display: flex;
    justify-content: space-between;
    margin-top: 1*$rem;
    img {
      width: 9.7*$rem;
      height: 3.8*$rem;
      &:nth-child(1) {
        margin-right: 1.4*$rem;
      }
    }
  }
}