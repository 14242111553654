$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.collection-header {
    display: flex;
    flex-direction: column;

    .collection_top {
        width: 100vw;
        height: 100vw;
        overflow: hidden;
        position: relative;
        margin: 0 auto;

        .bg {
            width: 100vw;
            height: 100vw;
            position: absolute;
            left: 0;
            top: 0*$rem/10;
            filter: blur(5*$rem/10);
            transform: scale(1.2);
        }

        .bg_mask {
            width: 100vw;
            height: 100vw;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(rgba(202, 196, 184, 0.1) 0%, #f8f8f8 73%);
        }

        .collection_avatar {
            position: absolute;
            width: 78vw;
            //height: 33*$rem;
            border-radius: 20*$rem/10;
            left: 11vw;
            bottom: 0*$rem/10;
            overflow: hidden;
            //background: red;

            .collection_avatar_progressbar_view {
                width: 100%;
                height: 10.5 *$rem;
                //background: yellow;
                position: absolute;
                bottom: 0.1 *$rem;
                left: 0;

                .xingxiang_view {
                    width: 7.2*$rem;
                    height: 2.2*$rem;
                    border-radius: 24px;
                    //opacity: 0.6;
                    background: rgba(0, 0, 11, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0.5 *$rem;
                    //margin-top: 0.5 *$rem;

                    span {
                        font-family: PingFangSC-Medium;
                        font-size: 1.3*$rem;
                        font-weight: normal;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                    }
                }


                .xingxiang_state_view {
                    width: 8*$rem;
                    height: 2.4*$rem;
                    border-radius: 24px;
                    //background: #D8D8D8;
                    display: flex;
                    position: absolute;

                    align-items: center;
                    justify-content: center;
                    right: 0.6 *$rem;
                    top: 5.8 *$rem;

                    background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);


                    span {
                        font-family: PingFangSC-Medium;
                        font-size: 1.3*$rem;
                        font-weight: normal;
                        letter-spacing: 0px;
                        color: #614D1A;
                    }
                }

                .collection_avatar_progressbar_view_content {
                    width: 74%;
                    height: 6 *$rem;
                    //background: red;
                    margin-top: 1.8 *$rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .collection_avatar_content0 {
                        width: 57%;
                        height: 33%;
                        //background: purple;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-between;


                    }

                    .collection_avatar_content1 {
                        width: 80%;
                        height: 30%;
                        //margin-left: 5%;
                        position: relative;
                        //background: purple;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: row;

                        .collection_avatar_content1_line {
                            width: 100%;
                            height: 0.5 *$rem;
                            background: #D8D8D8;
                            position: absolute;
                            border-radius: 2px;
                            //padding-left: 2px;
                            //padding-right: 2px;
                        }

                        .collection_avatar_content1_real {
                            width: 100%;
                            height: 0.5 *$rem;
                            background: #8EF4B1;
                            position: absolute;
                            border-radius: 2px;
                            z-index: 1;
                        }

                        .span_progress_yuan {
                            width: 1 *$rem;
                            height: 1 *$rem;
                            border-radius: 0.5 *$rem;
                            z-index: 1;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #CEBF7D;
                            z-index: 2;
                            //background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);

                            .span_top {
                                position: absolute;
                                display: inline-block;
                                background: #CEBF7D;
                                width: 0.2 *$rem;
                                height: 0.4 *$rem;
                                top: -0.4*$rem;
                            }

                            .span_bottom {
                                position: absolute;
                                display: inline-block;
                                background: #CEBF7D;
                                width: 0.2 *$rem;
                                height: 0.4 *$rem;
                                bottom: -0.4*$rem;
                            }
                        }
                    }

                    .collection_avatar_content2 {
                        width: 96%;
                        height: 33%;
                        //background: #00b578;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    .xingxiang_view0 {
                        width: 4.4*$rem;
                        height: 2*$rem;
                        border-radius: 24px;
                        //opacity: 0.5;
                        background: #000000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(0, 0, 11, 0.5);

                        span {
                            font-family: PingFangSC-Medium;
                            font-size: 1.1*$rem;
                            font-weight: normal;
                            letter-spacing: 0px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }

        img {
            width: 100%;
        }
    }

    .operate {
        display: flex;
        margin: 51*$rem/10 32vw 47*$rem/10;

        img {
            margin: auto;
            width: 20*$rem/10;
            height: 20*$rem/10;
        }

        .check {
            flex: 1;
        }

        .download {
            flex: 1;
        }

        .share {
            flex: 1;
        }
    }
}