$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.order-detail-page {
  margin: 0 4vw;
  font-family: PingFang SC-Regular, PingFang SC;

  .order-status {
    margin-top: 15*$rem/10;
    padding: 15*$rem/10 15*$rem/10 12*$rem/10;
    background: #ffffff;
    border-radius: 6*$rem/10;
    display: flex;

    .detail {
      flex: 1;

      .status {
        font-size: 16*$rem/10;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }

      .bar {
        padding-top: 9*$rem/10;
        font-size: 13*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #777777;
      }

      .nbar {
        padding-top: 9*$rem/10;
        font-size: 13*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #333333;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .timer {
          width: 18 * $vw;
          height: 18 * $vw;
          display: inline-block;
          margin-right: 6*$rem/10;
        }
      }
    }

    .icon {
      margin-top: 5*$rem/10;
      flex: 0 0 36*$rem/10;

      img {
        width: 36*$rem/10;
        height: 36*$rem/10;
      }
    }
  }

  .order-content {
    background: #ffffff;
    border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    margin: 15*$rem/10 0;
    padding: 15*$rem/10;
    display: flex;
    flex-direction: column;

    .order_content__box {
      display: flex;
      margin-bottom: 17*$rem/10;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .order-img {
      flex: 0 0 74*$rem/10;
      background-color: #f5f5f5;
      border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
      img {
        object-fit: contain;
        width: 74*$rem/10;
        height: 74*$rem/10;
        
      }
    }

    .order-message {
      flex: 1;
      margin-left: 14*$rem/10;

      .title {
        height: 36*$rem/10;
        margin-left: -6*$rem/10;
        font-size: 15*$rem/10;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 18*$rem/10;
      }

      .price {
        font-size: 16*$rem/10;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 19*$rem/10;
      }

      .pay-detail {
        display: flex;

        .num {
          flex: 1;
          font-size: 12*$rem/10;
          font-family: PingFang SC-Regular, PingFang SC;
          color: #757575;
          line-height: 14*$rem/10;
        }

        .status {
          flex: 0 0 40*$rem/10;
          float: right;
          font-size: 12*$rem/10;
          font-family: PingFang SC-Regular, PingFang SC;
          color: #a89555;
          line-height: 14*$rem/10;
        }
      }
    }
  }

  .price-detail {
    padding: 16*$rem/10;
    background: #ffffff;
    border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    opacity: 1;

    .price {
      padding-bottom: 12*$rem/10;

      .text {
        font-size: 15*$rem/10;
        color: #333333;
      }

      .num {
        float: right;
        font-size: 15*$rem/10;
        font-weight: 600;
        color: #333333;
      }
    }

    .a {
      margin-top: 28*$rem/10;
    }

    .line {
      border-bottom: 1*$rem/10 solid #e6e6e6;
      width: 84vw;
      margin: auto;
      opacity: 1;
    }

    .credit {
      text-align: right;
      padding-top: 12*$rem/10;

      .total {
        font-size: 13*$rem/10;
        color: #333333;
      }

      .point {
        font-size: 11*$rem/10;
        color: #707070;
      }

      .price {
        font-size: 15*$rem/10;
        font-weight: 600;
        color: #333333;
      }
    }
  }

  .order-message-other {
    margin: 15*$rem/10 0 37*$rem/10;
    padding: 16*$rem/10 16*$rem/10 29*$rem/10;
    background: #ffffff;
    border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    white-space: nowrap;

    .title {
      white-space: nowrap;
      font-size: 15*$rem/10;
      color: #333333;
    }

    .message {
      font-size: 13*$rem/10;
      color: #707070;
      padding-top: 15*$rem/10;
      display: flex;
      justify-content: space-between;
      white-space: wrap;
      position: relative;

      .number {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
        display: block;
        width: 60%;
        line-height: 17*$rem/10;
      }

      .ordernumber {
        width: 70%;
        justify-content: space-between;

        .number {
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
          display: block;
          width: calc(100% - 15*$rem/10);
          line-height: 17*$rem/10;
          float: right;
        }

        .copy {
          line-height: 17*$rem/10;
          width: 14*$rem/10;
          height: 14*$rem/10;
          background: url(~@/assets//img/order-detail/copy.png);
          background-size: 14*$rem/10 14*$rem/10;
          float: right;
          margin-top: 2*$rem/10;
        }
      }
    }
  }

  .notPay_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .cancel_order {
      padding: 12*$rem/10 13.5vw;
      font-size: 16*$rem/10;
      color: #beae69;
      line-height: 19*$rem/10;
      border-radius: 27*$rem/10;
      opacity: 1;
      border: 1*$rem/10 solid #beae69;
    }

    .pay {
      padding: 12*$rem/10 17vw;
      font-size: 16*$rem/10;
      color: #614d1a;
      line-height: 19*$rem/10;
      background: linear-gradient(90deg, #c8b472 0%, #f8ebb7 100%);
      border-radius: 27*$rem/10;
      opacity: 1;
    }
  }
}
