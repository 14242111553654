@import '@/assets/scss/variable.scss';

#root, html, body {
  width: 100vw;
  height: 100vh;
}

.parts_recycling_home_unity {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .img_container {
    width: 70%;
    // background-color: #e0c087;
    margin-left: -5px;
    position: relative;

    > img {
      width: 100%;
      height: 100%;
    }

    .content {
      width: 70%;
      height: 55%;
      position: absolute;
      left: 10%;
      top: 27%;
    }

    .time_record_content {
      width: 36%;
      height: 30px;
      position: absolute;
      right: 5%;
      top: 9%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;


      .record {
        font-size: 1*$rem;
        color: #fff;
        padding: 0 0.2 *$rem;
      }
    }

    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 1.8*$rem;
      height: 1.8*$rem;
      padding: 0.5*$rem;
    }
  }

  .switch_container {
    display: inline-block;
    //width: 20%;
    z-index: 1;

    .item {

      &.isChoiceItem {
        background: linear-gradient(270deg, rgba(57, 72, 197, 0.00) 0%, #8742DC 48%, rgba(66, 71, 181, 0.00) 100%);
      }

      > p {
        font-family: PingFangSC-Semibold;
        font-size: 1.2 *$rem;
        color: #FFFFFF;
        line-height: 2.0 *$rem;
        width: 100%;
        text-align: center;
        opacity: 0.4;
        margin-top: 0.6 *$rem;

        &.isChoice {
          opacity: 1;
        }
      }
    }
  }

  .back {
    width: 2 *$rem;
    height: 2 *$rem;
    position: absolute;
    top: 3 *$rem;
    left: 17%;
  }
}


