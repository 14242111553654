@import '@/assets/scss/variable.scss';
.winningNumbers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .container {
        width: 32.5*$rem;
        max-height: 40*$rem;
        min-height: 20*$rem;
        display: flex;
        background: linear-gradient(144deg, #EBE6E0 6%, #D4C0AB 100%);
        border-radius: 1.2*$rem;
        overflow: hidden;
        .content {
            flex: 1;
            margin: 3.1*$rem 1.2*$rem;
            overflow-y: auto;
            -webkit-font-smoothing: subpixel-antialiased;
            -moz-osx-font-smoothing: auto;
            .group {
                padding-bottom: 1*$rem;
                margin-bottom: 2*$rem;
                border-bottom: 1px dashed #A04A2C;
                &:last-child {
                    border-bottom: none;
                }
                .group_title {
                    color: #A04A2C;
                    font-family: PangMenZhengDao;
                    font-size: 1.5*$rem;
                    margin-bottom: 0.5*$rem;
                    letter-spacing: 0
                }
                .group_numbers {
                    display: flex;
                    flex-wrap: wrap;
                    .number {
                        background: rgba(255, 255, 255, 0.8);
                        height: 1.9*$rem;
                        line-height: 1.9*$rem;
                        border-radius: 1*$rem;
                        color: #A04A2C;
                        font-weight: 500;
                        font-size: 1.2*$rem;
                        padding: 0 0.5*$rem;
                        margin: 0.75*$rem 0.5*$rem;
                    }
                }
            }
        }
    }
    .close {
        margin-top: 4.7*$rem;
        width: 2.5*$rem;
        height: 2.5*$rem;
        background: url("~@/assets/img/icon/c-close.png") no-repeat center center / 100% 100%;
    }
}