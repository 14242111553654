@function to($px) {
  @return ($px / 2558) * 100vw;
}

.exchange_records_container {
  position: relative;
  padding: to(20) to(75);
  display: flex;
  flex-direction: column;
  width: to(1850);
  height: to(960);
  border-radius: to(40);
  box-sizing: border-box;
  border: to(8) solid #FFFFFF;
  background: linear-gradient(180deg, #FEEED5 0%, #F4BFAB 100%);

  .records_title {
    margin-bottom: to(30);
    text-align: center;
    color: #3D3D3D;
    font-weight: 600;
    font-size: to(50);
    font-family: PingFang SC;
    text-align: left;
  }

  .records_list {
    padding: 0 to(20);
    height: to(740);
    overflow-y: auto;
    box-sizing: border-box;
  }

  .records_item {
    margin-bottom: to(40);
    padding: to(29) to(73) to(29) to(31);
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    border-radius: to(15);
    color: #6D6D6D;
    display: flex;
    align-items: center;
    gap: to(46);

    &>.item_left {
      width: to(162);
      height: to(162);
      opacity: 1;
      background: #D8D8D8;
      img{
        width: 100%;
        height:100%;
      }
    }

    &>.item_middle {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .item_title {
        font-family: PingFang SC;
        font-size: to(40);
        font-weight: 500;
        color: #6D6D6D;
        margin-bottom: to(36);
      }

      .item_time {
        font-family: PingFang SC;
        font-size: to(36);
        color: #6D6D6D;
      }
    }

    &>.item_right {
      color: #FFFFFF;
      font-size: to(40);
      font-weight: 600;
      border-radius: to(30);
      display: flex;
      justify-content: center;
      align-items: center;
      width: to(304);
      height: to(112);
      background: linear-gradient(180deg, #A1E3FF 1%, #61A7F3 98%);
      box-sizing: border-box;
      border: to(7) solid #FFFFFF;
      box-shadow: 0px to(4) to(10) 0px #FFE7B0, inset 0px to(4) to(10) 0px #007AFF;
    }
  }

  .close {
    position: absolute;
    top: to(20);
    right: to(20);
    width: to(62);
    height: to(62);
  }
}

.no_data {
  display: flex;
  justify-content: center;
  padding-top: to(40);
  margin: to(280) auto 0;
  background: url('~@/assets/img/landfi/img_brand.png') no-repeat;
  background-size: 100% 100%;
  width: to(268);
  height: to(215);
  box-sizing: border-box;
  font-family: YueGenYouShangYueBoHei;
  color: #792B06;
}

.gem_store_list_out {
  width: 100%;
  height: to(1000);
  overflow-y: scroll;
  box-sizing: border-box;
  margin-top: to(20);
  position: relative;
  :global {
    .list-view-body {
      padding-top: to(10);
      padding-bottom: to(200);
      padding-left: to(10);

    }
  }

  .gem_store_opt {
    position: fixed;
    bottom:to(114);
    right:to(155);
    font-family: YueGenYouShangYueBoHei;
    color: #FFFFFF;
    font-size:to(50);
    font-weight: bold;
    font-variation-settings: "opsz" auto;
    text-shadow:
    -1*to(1) -1*to(1) 0 #000,  
    to(1) -1*to(1) 0 #000,
    -1*to(1) to(1) 0 #000,
    to(1) to(1) 0 #000; /* 文字描边色 */

    &>.gem_store_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: to(379);
      height: to(140);
      background: url('~@/assets/img/landfi/landgy/btn-default.png') no-repeat;
      background-size: 100% 100%;
    }
    &>.activedBtn{
      display: flex;
      justify-content: center;
      align-items: center;
      width: to(379);
      height: to(140);
      background: url('~@/assets/img/landfi/landgy/btn-active.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .gem_store_list_item:nth-child(4n) {
    margin-right: 0;
  }

  & .gem_store_list_item {
    display: inline-block;
    margin-bottom: to(30);
    margin-right: to(30);
    width: to(400);
    height: to(486);
    border-radius: to(30);
    opacity: 1;
    background: #FFFCF8;
    box-sizing: border-box;
    border: to(1) solid #FFFFFF;
    &>.item_top {
      width: to(398);
      height: to(398);
      border-radius: to(30);
      overflow: hidden;
      opacity: 1;
      background: linear-gradient(180deg, #FFFCF8 0%, #FFEBB6 100%);
      box-sizing: border-box;
      img{
        width: 100%;
        height:100%;
      }
    }

    &>.item_bottom {
      display: flex;
      flex-shrink: 0;

      &>div {
        width: 50%;
        height: to(70);
        margin-top: to(10);
        line-height: to(70);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &>.item_gem {
        color: #9E9E9E;
        font-family: YueGenYouShangYueBoHei;
        font-size: to(29);
        font-weight: bold;
        padding-left: to(70);
        padding-right: to(10);
        background: url('~@/assets/img/landfi/landgy/icon-gem.png') no-repeat;
        background-size: to(80) to(80);
        background-position: left center;
      }

      &>.item_energy {
        color: #9E9E9E;
        font-family: YueGenYouShangYueBoHei;
        font-size: to(29);
        font-weight: bold;
        padding-left: to(60);
        padding-right: to(10);
        background: url('~@/assets/img/landfi/landgy/icon-energy.png') no-repeat;
        background-size: to(50) to(50);
        background-position: left center;
      }
    }
  }

  & .actived {
    position: relative;
    box-shadow: 0px to(4) to(34) 0px rgba(0, 0, 0, 0.2);
  }

  & .actived::after {
    content: '';
    position: absolute;
    width: calc(100% + to(3));
    height: calc(100% + to(3));
    left: -1*to(10);
    top: -1*to(10);
    box-sizing: content-box;
    border-radius: to(38);
    border: to(10) solid #FFB636;
  }
}