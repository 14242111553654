$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.rule-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  .rules-modal-common {
    .modal-container {
      padding: 1.65*$rem 2*$rem;
      width: 34*$rem;
      height: 41*$rem;
      background: #FFFFFF;
      border-radius: 1*$rem;
      display: flex;
      flex-direction: column;
      backdrop-filter: blur(11px);
      box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
      background: linear-gradient(270deg, rgba(52,51,65,0.76) 0%, rgba(52,51,65,0.69) 100%);
      box-sizing: border-box;
      .modal-title {
        padding-bottom: 1.1*$rem;
        color: #FFFFFF;
        font-size: 1.6*$rem;
        font-weight: bold;
        text-align: center;
      }
      .modal-content {
        flex: 1;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow-y: auto;
        white-space: pre-line;
        line-height: 1.7*$rem;
        color: #FFFFFF;
        font-size: 1.2*$rem;
      }
      .rules-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1*$rem auto 0;
        width: 13.1*$rem;
        height: 3.1*$rem;
        border-radius: 4.1*$rem;
        background: linear-gradient(120deg, #24FAAF -6%, #D200EE 105%);
        color: #FFFFFF;
        font-size: 1.5*$rem;
        font-weight: bold;
      }
    }
  }
}