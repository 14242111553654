@import '@/assets/scss/variable.scss';
.KangYangNFT {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background: #DCFFF2;
    .back {
        position: absolute;
        top: 2.5*$rem;
        z-index: 9;
        @media screen and (min-height: 780px) {
            top: 5.5*$rem;
        }

        left: 1.4*$rem;
        width: 1*$rem;
        height: 1.8*$rem;
        background: url("~@/assets/img/icon/back.png") no-repeat center center / 100% 100%;
    }
    .topBg {
        position: relative;
        width: 100%;
        height: 60*$rem;
        .bgTopImg {
            width: 100%;
            height: 100%;
        }
        .right_btns {
            position: absolute;
            top: 8.5*$rem;
            right: 0;
            display: flex;
            flex-direction: column;
            .rightBtn {
                width: 2.1*$rem;
                padding: 0.6*$rem 0;
                border-radius: 0.5*$rem 0px 0px 0.5*$rem;
                background: #4EDDA5;
                color: #FFFFFF;
                font-size: 1.2*$rem;
                font-weight: bold;
                text-align: center;
                line-height: 1.4*$rem;
                margin-bottom: 1.2*$rem;
                &:active {
                    opacity: 0.8;
                }
            }
            
        }
    }
    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: -5.6*$rem;
        .buy_button {
            width: 27.5*$rem;
            height: 6.1*$rem;
            margin: 0 auto;
            background: url("~@/assets/img/kangYangNFT/button_buy.png") no-repeat center center / 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 2.5*$rem;
            font-family: Alimama ShuHeiTi-Bold;
            font-weight: 700;
            &:active {
                opacity: 0.8;
            }
        }
        .card {
            width: 35.15*$rem;
            height: 10.55*$rem;
            margin: 1*$rem auto 2*$rem;
            background: url("~@/assets/img/kangYangNFT/card.png") no-repeat center center / 100% 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 2.2*$rem;
            .left {
                display: flex;
                flex-direction: column;
                color: #088756;
                >div {
                    font-weight: bold;
                    font-size: 1.8*$rem;
                }
                >span {
                    font-size: 1.5*$rem;
                    span {
                        color: #FF7B00
                    }
                }
            }
            .mergeBtn {
                width: 11.2*$rem;
                height: 3.85*$rem;
                line-height: 3.85*$rem;
                border-radius: 2*$rem;
                color: #FFFFFF;
                font-weight: 500;
                font-size: 1.8*$rem;
                background: #D8D8D8;
                text-align: center;
                &.enable {
                    background: linear-gradient(180deg, #F1B100 0%, #FF8B0F 100%);
                    &:active {
                        opacity: 0.8;
                    }
                }
            }
        }
        .configSetting {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1.5*$rem;
            &>:nth-child(1) {
                color: #088756;
                font-size: 1.5*$rem;
                font-weight: 700;
                >span {
                    color: #FF7B00;
                }
            }
            &>:nth-child(2) {
                color: #FF7B00;
                font-size: 1.3*$rem;
                font-weight: 600;
                >span {
                    color: #088756;
                    font-weight: normal;
                }
            }
        }
    }
    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2.5*$rem auto;
        span {
            color: #088756;
            font-size: 1.1*$rem;
        }
        img {
            margin-top: 0.5*$rem;
            height: 3.5*$rem;
        }
    }
}