$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.pay-type-select {
    display: flex;
    flex-direction: column;
    margin: 0 1.5*$rem 1.5*$rem;
    padding: 1.5*$rem;
    border-radius: 0.5*$rem;
    background-color: #fff;

    .pay_way {
        font-size: 1.5*$rem;
        color: #333333;
        padding-bottom: 1.2*$rem;
        border-bottom: 1px solid #f2f2f2;
    }

    .pay-type {
        display: flex;
        flex-direction: column;

        li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 15 * $vw;

            .adm-radio {
                flex: 0 0 20*$rem/10;
            }

            .apy_way_info {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    width: 24*$rem/10;
                    height: 24*$rem/10;
                    padding: 3*$rem/10;
                }

                .type {
                    font-size: 15*$rem/10;
                    margin-left: 8*$rem/10;
                    word-break: keep-all;
                    white-space: nowrap;
                    font-family: PingFang SC-Regular, PingFang SC;
                    color: #333333;
                }
            }

            .adm-radio.adm-radio-checked .adm-radio-icon {
                border-color: #c1af68;
                background-color: #c1af68;
            }
        }
    }
    .pay-type-error {
        margin: 2*$rem auto 1*$rem;
        font-size: 1.2*$rem;
        color: #999999;
    }
}