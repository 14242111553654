
@import '@/assets/scss/variable.scss';
.ExchangeHistory {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #F7F7F7;
    .historyList {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        position: relative;
        :global{
            .ant-list-view{
                height: 100%;
            }
        }
        .record_item {
            background: #FFF;
            display: flex;
            flex-direction: column;
            border-radius: 1*$rem;
            padding: 1.2*$rem 1.4*$rem;
            margin: 1.6*$rem 1.3*$rem;
            .top {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid #DFDFDF;
                margin-bottom: 1*$rem;
                padding-bottom: 1*$rem;
                >img {
                    width: 5*$rem;
                    height: 5*$rem;
                    border-radius: 0.5*$rem;
                    margin-right: 0.5*$rem;
                    // background-color: #f5f5f5;
                    border-radius: 0.5*$rem;
                }
                >div {
                    display: flex;
                    flex-direction: column;
                    >span {
                        color: #333333;
                        font-size: 1.6*$rem;
                        font-weight: 600;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    >p {
                        align-self: flex-start;
                        background: #D8D8D8;
                        line-height: 1.6*$rem;
                        border-radius: 1*$rem;
                        color: #9E9E9E;
                        font-size: 1.2*$rem;
                        font-weight: 600;
                        padding: 0 0.6*$rem;
                        margin-top: 0.5*$rem;
                    }
                }
            }
            .bottom {
                > div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    >p {
                        color: #999999;
                        font-size: 1.2*$rem;
                        padding: 0.5*$rem 0;
                    }
                    >div{
                        >span{
                            display: block;
                        }
                    }
                    span {
                        color: #333333;
                        font-weight: 600;
                        font-size: 1.2*$rem;
                    }
                }
                >.alignTop{
                    align-items: baseline;
                }
            }
        }
    }
}