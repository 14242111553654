$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.tip-modal-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  .filter-box {
    width: 29.8*$rem;
    height: 20.6*$rem;
    box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
    background: linear-gradient(270deg, rgba(52,51,65,0.76) 0%, rgba(52,51,65,0.69) 100%);
    border-radius: 0.9*$rem;
    backdrop-filter: blur(11px);
    box-sizing: border-box;
  }
  .content-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 29.8*$rem;
    height: 20.6*$rem;
    padding: 2.65*$rem 2.95*$rem 2.4*$rem;
    color: #FFFFFF;
    box-sizing: border-box;
    .title {
      font-size: 1.6*$rem;
    }
    .tip-content {
      flex: 1;
      padding-top: 1.35*$rem;
      font-size: 1.2*$rem;
      line-height: 1.7*$rem;
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      font-size: 1.6*$rem;
      width: 100%;
      .cancel-btn {
        width: 9.8*$rem;
        height: 4*$rem;
        border-radius: 2*$rem;
        text-align: center;
        line-height: 4*$rem;
        background: url('~@/assets/img/variationApe/border.png') no-repeat;
        background-size: 100% 100%;
      }
      .confirm-btn {
        width: 9.8*$rem;
        height: 4*$rem;
        border-radius: 2*$rem;
        text-align: center;
        line-height: 4*$rem;
        background: linear-gradient(120deg, #24FAAF -6%, #D200EE 105%);
      }
    }
  }
}