$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.hc_pro_container {
  height: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  background-color: white;

  .hc_pro_img {
    width: 23 *$rem;
    height: 23 *$rem;
    margin-top: 6 *$rem;
    object-fit: contain;
    //background-color: #D8D8D8;
    border-radius: 6px;
  }

  .progress_bar {
    width: 23 *$rem;
    margin-top: 2 *$rem;
  }

  .hc_pro_txt {
    width: 100%;
    opacity: 1;
    font-family: PingFangSC-Semibold;
    font-size: 2.3 *$rem;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    color: #CEBF7E;
    margin-top: 2 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hc_pro_txt_title {
    font-family: PingFangSC-Medium;
    font-size: 1.9 *$rem;
    font-weight: normal;
    letter-spacing: 0em;
    color: #3D3D3D;
    margin-top: 3 *$rem;
  }

  .hc_pro_txt_des {
    width: 24 *$rem;
    font-family: PingFangSC-Light;
    font-size: 1.4 *$rem;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #3D3D3D;
    white-space: pre-line;
    margin-top: 2 *$rem;
  }
}
