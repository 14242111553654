@import '@/assets/scss/variable.scss';

.container {
  width: 100%;
  height: 100vh;
  background-color: #F7F7F7;
  overflow-y: scroll;
}

.epic_boring_ape_container {
  width: 92%;
  margin: 0 auto;

  .list_item {
    background-color: white;
    border-radius: 10px;
    margin: 2 *$rem auto 3 *$rem;
    padding: 1 *$rem;
    min-height: 100px;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 4*$rem;
      border-bottom: 1px solid #D8D8D8;

      > :nth-child(1) {
        font-family: PingFangSC-Semibold;
        font-size: 1.4 *$rem;
        font-weight: normal;
        color: #333333;
      }

      > :nth-child(2) {
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        color: #333333;
        margin-right: 8px;
      }
    }

    .sessionContainer {
      .session {
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        margin-top: 1.8 *$rem;
        margin-bottom: 0.8 *$rem;
        color: #333333;
        font-weight: 700;
      }

      .sessionA {
        line-height: 26px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 92%;
        margin: 0 auto;

        .des {
          opacity: 0.6;
          font-family: PingFangSC-Regular;
          font-size: 1.2 *$rem;
          color: #333333;
        }

        .win {
          font-family: PingFangSC-Medium;
          font-size: 1.3 *$rem;
          font-weight: normal;
          color: #E83E5D;
        }

        .fail {
          font-family: PingFangSC-Medium;
          font-size: 1.3 *$rem;
          color: #000000;
        }

        > :nth-child(1) {
          opacity: 0.6;
          font-family: PingFangSC-Regular;
          font-size: 1.2 *$rem;
          font-weight: normal;
          color: #333333;
        }
      }
    }

    .line {
      margin-top: 2 *$rem;
      opacity: 1;
      width: 100%;
      height: 0.6px;
      background-color: #D8D8D8;
    }

    .bottom {
      margin-top: 1.5 *$rem;
      display: flex;

      > :nth-child(1) {
        font-family: PingFangSC-Regular;
        font-size: 1.3 *$rem;
        color: #333333;
      }

      .prizes {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > p {
          font-family: PingFangSC-Medium;
          font-size: 1.3 *$rem;
          font-weight: normal;
          line-height: 23px;
          color: #E7405E;

        }

      }
    }
  }
}
