$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.compose-success-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mask-content {
   
    padding: 2.5*$rem 2.75*$rem;
    width: 32.3*$rem;
    height: auto;
    box-sizing: border-box;
    border-radius: 0.9*$rem;
    backdrop-filter: blur(11px);
    box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
    background: #FFFFFF;

    .title {
      padding-bottom: 1.25*$rem;
      font-size: 2*$rem;
      color: #1A1A1A;
      font-weight: bold;
    }

    .collection-info {
      width: 100%;
      min-height: 11.8*$rem;
      max-height: 20.6*$rem;
      overflow-y: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 3*$rem;
      row-gap: 1.5*$rem;

      .img-box-out {
        width: 6.7*$rem;

        .img-box {
          width: 6.7*$rem;
          height: 6.7*$rem;
          background-color: #D8D8D8;
          border-radius: 0.50*$rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        span {
          display: inline-block;
          padding-top: 0.5*$rem;
          font-size: 1.2*$rem;
          color: #1A1A1A;
          width: calc(100% - 1px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          &:nth-child(1) {
            color: #9B9B9B;
          }
        }
      }

    }

    .btn-box {
      display: flex;
      justify-content: center;
      margin-top: 1.35*$rem;
      width: 100%;

      .common {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15.6*$rem;
        height: 4.55*$rem;
        border-radius: 2.25*$rem;
        color: #614D1A;
        font-size: 1.6*$rem;
        font-weight: 500;

      }

      .cancel-btn {

        background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
      }

      .confirm-btn {
        background: linear-gradient(120deg, #24FAAF -6%, #D200EE 105%);
      }
    }
  }
}