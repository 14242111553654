$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.paysuccess {
  background-color: #f7f0df;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .paysuccess_bg {
    width: 100%;
    height: 100vh;
    background: url(~@/assets/img/ape/purple-bg.png) no-repeat;
    background-size: 100%;
    overflow: hidden;
    &.ape_world_bg {
      background: url(~@/assets/img/ape/green_bg.png) no-repeat;
      background-size: 100%;
    }
    &.blinBox {
      background: url(~@/assets/img/ape/purple_bg.png) no-repeat;
      background-size: 100%;
    }
    &.variationApe {
      background: url('~@/assets/img/ape/bg_cg.png') no-repeat;
      background-size: 100%;
    }
    &.bg32 {
      background-image: url('./img_bg_collectionType_32.png');
    }
  }
  .stage {
    /* position:relative; */
    width: 200*$rem/10;
    height: 200*$rem/10;

    transform-style: preserve-3d;
    /* 为了方便观察让3D舞台转动角度 */
    /* transform: rotateX(330deg) rotateY(-30deg) rotateZ(180deg); */
    transform: rotateX(-30deg) rotateY(60deg);
    animation: play0 3s forwards;
    .face {
      position: absolute;
      width: 160*$rem/10;
      height: 160*$rem/10;
      /* border: 1*$rem/10 solid black; */
    }

    .front {
      /* 第一个面往前走 */
      transform: translateZ(80*$rem/10);
      animation: play2 3s forwards;
    }

    .rear {
      /* 第二个面往后走 */
      transform: translateZ(-80*$rem/10) rotateY(180deg);
      animation: play1 3s forwards;
    }

    .up {
      /* 先往上位移150*$rem/10  再绕着X轴转动90deg */

      transform: translateY(-80*$rem/10) rotateX(90deg);
      animation: play3 3s forwards;
    }

    .down {
      /* 先往下位移150*$rem/10,再绕着X轴转动90deg */
      animation: play6 3s forwards;
      transform: translateY(80*$rem/10) rotateX(-90deg);
    }

    .left {
      /* 先往左位移150*$rem/10 , 再绕着Y轴转动90deg */
      transform: translateX(-80*$rem/10) rotateY(-90deg);
      animation: play5 3s forwards;
    }

    .right {
      /* 先往右侧位移150*$rem/10,再绕着Y轴转动90deg */
      transform: translateX(80*$rem/10) rotateY(-270deg);
      animation: play4 3s forwards;
    }

    @keyframes play0 {
      0% {
        transform: rotateX(330deg) rotateY(-30deg) rotateZ(180deg);
      }
      50% {
        transform: rotateX(-30deg) rotateY(60deg);
      }

      100% {
        transform: rotateX(-30deg) rotateY(60deg);
      }
    }

    @keyframes play1 {
      0% {
        opacity: 1;
        transform: translateZ(-80*$rem/10);
        transform-origin: bottom;
      }
      50% {
        opacity: 1;
        transform: translateZ(-80*$rem/10);
        transform-origin: bottom;
      }

      100% {
        opacity: 0;
        transform-origin: bottom;
        transform: translateZ(-80*$rem/10) rotatex(180deg);
      }
    }

    @keyframes play2 {
      0% {
        opacity: 1;
        transform: translateZ(80*$rem/10);
        transform-origin: bottom;
      }
      50% {
        opacity: 1;
        transform: translateZ(80*$rem/10);
        transform-origin: bottom;
      }

      100% {
        opacity: 0;
        transform-origin: bottom;
        transform: translateZ(80*$rem/10) rotatex(-180deg);
      }
    }

    @keyframes play3 {
      0% {
        opacity: 1;
        transform: rotateX(-90deg) translateZ(-80*$rem/10);
      }
      50% {
        opacity: 1;
        transform: rotateX(-90deg) translateZ(-80*$rem/10);
      }

      100% {
        opacity: 0;

        transform: rotateX(-90deg) translateZ(-320*$rem/10);
      }
    }

    @keyframes play4 {
      0% {
        opacity: 1;
        transform-origin: bottom;
        transform: translateX(80*$rem/10) rotateY(-270deg);
      }
      50% {
        opacity: 1;
        transform-origin: bottom;
        transform: translateX(80*$rem/10) rotateY(-270deg);
      }

      100% {
        opacity: 0;
        transform-origin: bottom;
        transform: rotateZ(180deg) rotateY(90deg) translateZ(-80*$rem/10);
      }
    }

    @keyframes play5 {
      0% {
        opacity: 1;
        transform-origin: bottom;
        transform: translateX(-80*$rem/10) rotateY(-90deg);
      }

      50% {
        opacity: 1;

        transform-origin: bottom;
        transform: translateX(-80*$rem/10) rotateY(-90deg);
      }

      100% {
        opacity: 0;
        transform-origin: bottom;
        transform: rotateZ(-180deg) rotateY(-90deg) translateZ(-80*$rem/10);
      }
    }
    @keyframes play6 {
      0% {
        opacity: 1;
        transform: translateY(80*$rem/10) rotateX(-90deg);
      }
      50% {
        opacity: 1;
        transform: translateY(80*$rem/10) rotateX(-90deg);
      }
      100% {
        opacity: 0;
        transform: translateY(80*$rem/10) rotateX(-90deg);
      }
    }

    img {
      width: 100%;
    }
  }
}

.banner {
  margin: 20vh 10vw 0;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .adm-button.adm-button-large {
    width: 100%;
    padding: 12*$rem/10 0;
    background: linear-gradient(90deg, #cebf7d 0%, #f8ebb8 100%);
    border-radius: 27*$rem/10;
    color: #614d1a;
    z-index: 99;
    font-size: 16*$rem/10;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
  }
}

.swiper {
  width: 200 * $vw;
  padding: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200 * $vw;
    height: 200 * $vw;
    border-radius: 20*$rem/10;
    overflow: hidden;
    @media screen and (min-height: 736px) {
      &.img_19 {
         margin-top: -2*$rem;
       }
     }
     @media screen and (min-height: 780px) {
       &.img_19 {
         margin-top: -3*$rem;
       }
     }
  }
  .show_text {
    margin-top: 14 * $vw;
    font-size: 14*$rem/10;
    font-family: PingFang SC-Semibold, PingFang SC;
    color: #FFFFFF;
    display: inline-block;
    width: 100%;
    text-align: center;
    // word-break: keep-all;

    .blod_text {
      font-weight: 600;
      font-size: 16;
      &.variation-style {
        color: #000000;
      }
    }
    .num_style {
      color: #000000;
    }
  }
  .show_text_19 {
    margin-top: 14 * $vw;
      font-weight: normal;
      font-size: 12px;
      color: #431179;
      display: inline-block;
      width: 100%;
      text-align: center;
      // word-break: keep-all;
  
      .blod_text {
        white-space: nowrap;
        font-size: 18px;
        font-family: PingFang SC-Semibold, PingFang SC;
      }
  }
  
  .hidden_text {
    font-size: 16*$rem/10;
    color: rgba(255, 255, 255, 0);
    display: none;
  }
}

.swiper-cards .swiper-slide {
  border-radius: 20*$rem/10;
}
.animation {
  position: absolute;
  opacity: 0;
  animation: play8 3s forwards;

  .blinBox_Swiper {
    @media screen and (min-height: 736px) {
      img {
        margin-top: -2*$rem;
      }
    }
    @media screen and (min-height: 780px) {
      img {
        margin-top: -3*$rem;
      }
    }
    
    .show_text {
      margin-top: 14 * $vw;
      font-weight: normal;
      font-size: 12px;
      color: #431179;
      display: inline-block;
      width: 100%;
      text-align: center;
      // word-break: keep-all;
  
      .blod_text {
        white-space: nowrap;
        font-size: 18px;
        font-family: PingFang SC-Semibold, PingFang SC;
      }
    }
  }
  .blinbox_count {
    text-align: center;
    color: #431179;
    font-size: 1.2*$rem;
    margin-top: 5*$rem;
  }
}
@keyframes play8 {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.view_btn {
  margin-top: 150 * $vw;
  &.adm-button.adm-button-large {
    width: 29.5*$rem;
  }
  
}
