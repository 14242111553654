$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.modal-box {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
  height: 100%;
  width: 100%;
  transform: translateZ(1px);
  z-index: 100000;
  .modal-container {
    padding: 14*$rem/10 22*$rem/10 20*$rem/10 22*$rem/10;
    background: #FFFFFF;
    width: 217*$rem/10;
    border-radius: 6*$rem/10;
    .title {
      font-weight: bold;
      font-size: 16*$rem/10;
      color: #333333;
    }
    .content {
      margin-top: 13.5*$rem/10;
      padding: 13.5*$rem/10 0 30*$rem/10;
      border-top: 0.5*$rem/10 solid rgba(0,0,0,.1);
      font-size: 14*$rem/10;
      color: #333333;
    }
    .subTitle {
      font-weight: bold;
      margin-left: -10 / 20*$rem;
      margin-right: -10 / 20*$rem;
      margin-top: 14*$rem/10;
      margin-bottom: 24*$rem/10;
      line-height: 34 / 20*$rem;
      font-size: 13*$rem/10;
      color: #333333;
      text-align: center;
      display: inline-block;
      white-space: pre-wrap;
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
      .left-btn {
        margin-right: 34*$rem/10;
      }
      .btn-common {
        flex: 1;
        background: #FFFFFF;
        color: #DFCFAB;
        border-radius: 25*$rem/10;
        border: 1*$rem/10 solid #DFCFAB;
        height: 40*$rem/10;
        line-height: 40*$rem/10;
        text-align: center;
        font-size: 16*$rem/10;
        &.right-btn-bg {
          background: linear-gradient(
            121deg,
            rgba(212, 186, 119, 1) 0,
            rgba(255, 234, 180, 1) 100%);
          color: #624C16;
        }
      }
    }
  }
}
