@import '@/assets/scss/variable.scss';

.submit_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.3*$rem 2*$rem 1.1*$rem;
  width: 27.9*$rem;
  background: #FFFFFF;
  border-radius: 1*$rem;
  box-sizing: border-box;

  .modal_title {
    color: #333333;
    font-size: 1.6*$rem;
    font-weight: 600;
  }

  .modal_content {
    margin-top: 0.55*$rem;
    margin-bottom: 2.1*$rem;
    min-height: 4*$rem;
    text-align: center;
    color: #333333;
    font-size: 1.4*$rem;
  }

  .input :global(.adm-input-element) {
    width: 100%;
    height: 1.6*$rem;
    text-align: center;
    font-size: 1.75*$rem;
    color: #CF9D5E;
    font-weight: 500;

    &::placeholder {
      color: #9E9E9E;
      font-size: 1.75*$rem;
    }
  }

  .error_tip {
    margin-top: 1*$rem;
    padding-top: 1.2*$rem;
    text-align: center;
    width: 100%;
    border-top: 0.05*$rem solid #D8D8D8;
    color: #FF0000;
    font-size: 1.4*$rem;
    box-sizing: border-box;

    &.hide_error {
      display: none;
    }

    &.show_error {
      display: block;
    }
  }

  .modal_footer {
    margin-top: 1.7*$rem;
    display: flex;
    padding-top: 0.8*$rem;
    border-top: 0.05*$rem solid #D8D8D8;
    width: 100%;

    .cancel_btn {
      flex: 1;
      text-align: center;
      color: #999999;
      font-size: 1.5*$rem;
      border-right: 0.05*$rem solid #D8D8D8;
      height: 3*$rem;
      line-height: 3*$rem;
    }

    .confirm_btn {
      flex: 1;
      text-align: center;
      color: #CAA846;
      font-size: 1.5*$rem;
      height: 3*$rem;
      line-height: 3*$rem;
    }
  }
}