$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}

/* land */
.land-game-landa {
    width: to(655);
    height: to(671);
    background: url("~@/assets/img/landfi/img_land_a.png");
    background-size: 100% 100%;

    &.disabled {
        opacity: 0.6;
    }

    &.landaWork {
        width: to(202);
        height: to(207);
    }

    &.landaExp {
        width: to(140);
        height: to(142);
    }

    &-workApe {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: to(153);
        height: to(153);

        &.active {
            background: url('~@/assets/img/landfi/select-ape.png') no-repeat;
            background-size: 100% 100%;
        }

        &-time {
            position: absolute;
            top: 8%;
            left: -5%;
            width: to(246);
            height: to(63);
            transform: translate(-15%, -80%);
        }

        >img {
            width: to(105);
            height: to(105);

            &.apeImg {
                width: to(78);
                height: to(78);
            }
        }

        &.ape_0 {
            top: -6%;
            left: 40%
        }

        &.ape_1 {
            top: 20%;
            left: 23%
        }

        &.ape_2 {
            top: 21%;
            left: 61%
        }

        &.ape_3 {
            top: 48%;
            left: 24%
        }

        &.ape_4 {
            top: 47%;
            left: 64%
        }
    }
}

.land-game-landa2 {
    width: to(655);
    height: to(671);
    background: url("~@/assets/img/landfi/img_land_a.png");
    background-size: 100% 100%;

    &.disabled {
        opacity: 0.6;
    }

    &.landaWork {
        width: to(202);
        height: to(207);
    }

    &.landaExp {
        width: to(140);
        height: to(142);
    }

    &-workApe {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: to(153);
        height: to(153);

        &.active {
            background: url('~@/assets/img/landfi/select-ape.png') no-repeat;
            background-size: 100% 100%;
        }

        &-time {
            position: absolute;
            top: 8%;
            left: -5%;
            width: to(246);
            height: to(63);
            transform: translate(-15%, -80%);
        }

        >img {
            width: to(105);
            height: to(105);

            &.apeImg {
                width: to(78);
                height: to(78);
            }
        }

        &.ape_0 {
            top: -5%;
            left: 51%;
        }

        &.ape_1 {
            top: 28%;
            left: 23%;
        }

        &.ape_2 {
            top: 22%;
            left: 61%;
        }

        &.ape_3 {
            top: 53%;
            left: 24%;
        }

        &.ape_4 {
            top: 51%;
            left: 62%;
        }
    }
}

.land-game-landb {
    width: to(570);
    height: to(514);
    background: url("~@/assets/img/landfi/img_land_b.png");
    background-size: 100% 100%;

    &.disabled {
        opacity: 0.6;
    }

    &.landbWork {
        width: to(197);
        height: to(178);
    }

    &.landbExp {
        width: to(140);
        height: to(125);
    }

    &-workApe {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: to(153);
        height: to(153);

        &.active {
            background: url('~@/assets/img/landfi/select-ape.png') no-repeat;
            background-size: 100% 100%;
        }

        >img {
            width: to(105);
            height: to(105);

            &.apeImg {
                width: to(78);
                height: to(78);
            }
        }

        &-time {
            position: absolute;
            top: 8%;
            left: -5%;
            width: to(246);
            height: to(63);
            transform: translate(-15%, -80%);
        }

        &.ape_0 {
            top: -8%;
            left: 35%
        }

        &.ape_1 {
            top: 28%;
            left: 16%
        }

        &.ape_2 {
            top: 40%;
            left: 48%
        }
    }
}

.land-game-landc {
    position: relative;
    width: to(570);
    height: to(474);
    background-image: url("~@/assets/img/landfi/img_land_c3.png");
    background-size: 100% 100%;

    &__a {
        background-image: url("~@/assets/img/landfi/img_land_c1.png");
    }

    &__b {
        background-image: url("~@/assets/img/landfi/img_land_c2.png");
    }

    &__c {
        background-image: url("~@/assets/img/landfi/img_land_c4.png");
    }

    &.disabled {
        opacity: 0.6;
    }

    &.landcWork {
        width: to(200);
        height: to(158);
    }

    &.landcExp {
        width: to(140);
        height: to(110);
    }

    &-workApe {
        position: absolute;
        top: 23%;
        left: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: to(153);
        height: to(153);
        background: url('~@/assets/img/landfi/select-ape.png') no-repeat;
        background-size: 100% 100%;

        >img {
            width: to(105);
            height: to(105);

            &.apeImg {
                width: to(78);
                height: to(78);
            }
        }

        &-time {
            position: absolute;
            top: 8%;
            left: -5%;
            width: to(246);
            height: to(63);
            transform: translate(-15%, -80%);
        }
    }
}

.mode2 {
    & .land-game-landa {
        width: to(655);
        height: to(671);
        background: url("~@/assets/img/landfi/landgy/img_land_a.png");
        background-size: 100% 100%;

        &.disabled {
            opacity: 0.6;
        }

        &.landaWork {
            width: to(202);
            height: to(207);
        }

        &.landaExp {
            width: to(140);
            height: to(142);
        }

        &-workApe {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: to(153);
            height: to(153);

            &.active {
                background: url('~@/assets/img/landfi/select-ape.png') no-repeat;
                background-size: 100% 100%;
            }

            &-time {
                position: absolute;
                top: 8%;
                left: -5%;
                width: to(246);
                height: to(63);
                transform: translate(-15%, -80%);
            }

            >img {
                width: to(105);
                height: to(105);

                &.apeImg {
                    width: to(78);
                    height: to(78);
                }
            }

            &.ape_0 {
                top: -6%;
                left: 40%
            }

            &.ape_1 {
                top: 20%;
                left: 23%
            }

            &.ape_2 {
                top: 21%;
                left: 61%
            }

            &.ape_3 {
                top: 48%;
                left: 24%
            }

            &.ape_4 {
                top: 47%;
                left: 64%
            }
        }
    }

    & .land-game-landb {
        width: to(570);
        height: to(514);
        background: url("~@/assets/img/landfi/landgy/img_land_b.png");
        background-size: 100% 100%;

        &.disabled {
            opacity: 0.6;
        }

        &.landbWork {
            width: to(197);
            height: to(178);
        }

        &.landbExp {
            width: to(140);
            height: to(125);
        }

        &-workApe {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: to(153);
            height: to(153);

            &.active {
                background: url('~@/assets/img/landfi/select-ape.png') no-repeat;
                background-size: 100% 100%;
            }

            >img {
                width: to(105);
                height: to(105);

                &.apeImg {
                    width: to(78);
                    height: to(78);
                }
            }

            &-time {
                position: absolute;
                top: 8%;
                left: -5%;
                width: to(246);
                height: to(63);
                transform: translate(-15%, -80%);
            }

            &.ape_0 {
                top: -8%;
                left: 35%
            }

            &.ape_1 {
                top: 28%;
                left: 16%
            }

            &.ape_2 {
                top: 40%;
                left: 48%
            }
        }
    }

    & .land-game-landc {
        position: relative;
        width: to(570);
        height: to(474);
        background-image: url("~@/assets/img/landfi/landgy/img_land_c1.png");
        background-size: 100% 100%;

        &__a {
            background-image: url("~@/assets/img/landfi/landgy/img_land_c1.png");
        }

        &__b {
            background-image: url("~@/assets/img/landfi/landgy/img_land_c1.png");
        }

        &__c {
            background-image: url("~@/assets/img/landfi/landgy/img_land_c1.png");
        }

        &.disabled {
            opacity: 0.6;
        }

        &.landcWork {
            width: to(200);
            height: to(158);
        }

        &.landcExp {
            width: to(140);
            height: to(110);
        }

        &-workApe {
            position: absolute;
            top: 23%;
            left: 45%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: to(153);
            height: to(153);
            background: url('~@/assets/img/landfi/select-ape.png') no-repeat;
            background-size: 100% 100%;

            >img {
                width: to(105);
                height: to(105);

                &.apeImg {
                    width: to(78);
                    height: to(78);
                }
            }

            &-time {
                position: absolute;
                top: 8%;
                left: -5%;
                width: to(246);
                height: to(63);
                transform: translate(-15%, -80%);
            }
        }
    }
}