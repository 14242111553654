$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.collectionlist_page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #F8F8F8;
    .nft-header .container {
        .right {
            font-size: 1.5*$rem;
            color: #3D3D3D;
        }
        
    }
    .listpage_body {
        flex: 1;
        .adm-list {
            --border-inner: initial;
            --border-top: initial;
            --border-bottom: initial;
            .adm-list-body {
                background-color: transparent;
                margin-top: 1.5*$rem;
                .adm-list-item {
                    background-color: transparent;
                    padding-left: 1.5*$rem;
                    .adm-list-item-content {
                        padding-right: 1.5*$rem;
                        .adm-list-item-content-main {
                            padding: 0;
                        }
                    }
                }
            }
            
        }
        .list-item-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: #FFFFFF;
            padding: 1.5 * $rem 1.3 * $rem;
            margin-bottom: 1 * $rem;
            border-radius: 0.6 * $rem;
            font-size: 1.5*$rem;
        }

        .emty-view {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 15*$rem;
            > img {
                width: 18.5 * $rem;
                height: 18.5 * $rem;
            }
            >span {
                margin: 2.57*$rem;
                width: 26.8*$rem;
                text-align: center;
                font-size: 1.5*$rem;
                color: #959595;
            }
        }

    }
}