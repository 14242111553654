@import '@/assets/scss/variable.scss';

.space_beautiful_account {
  position: relative;
  padding-top: 49.6*$rem;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  background: url("~@/assets/img/spaceBeautifulAccount/home-bg.png") no-repeat;
  background-size: cover;

  .back_box {
    position: fixed;
    top: 4*$rem;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5*$rem;
    height: 5*$rem;
    > img {
      width: 1*$rem;
      height: 1.8*$rem;
    }
  }

  @mixin common_btn {
    width: 2*$rem;
    height: 4*$rem;
    border-radius: 0.5*$rem 0px 0px 0.5*$rem;
    opacity: 0.8;
    background: rgba(0, 38, 191, 0.6);
    color: #FFFFFF;
    font-size: 1.2*$rem;
    font-weight: bold;
    writing-mode: vertical-lr;
    text-align: center;
    line-height: 2*$rem;
  }

  .rules {
    @include common_btn();
    position: absolute;
    top: 7*$rem;
    right: 0;
  }

  .real_name {
    @include common_btn();
    position: absolute;
    top: 12.5*$rem;
    right: 0;
  }

  .purchase_btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18.5*$rem;
    height: 4*$rem;
    border: 0.15*$rem solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 2*$rem;
    background: linear-gradient(270deg, #EA67EA 0%, #7E8AFF 100%);
    font-size: 1.5*$rem;
    font-weight: bold;
    color: #FFFFFF;
  }

  .id_blindbox {
    margin: 2*$rem 4.5*$rem 0;
    display: flex;

    .id_blindbox_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 12.5*$rem;
      height: 18.9*$rem;
      background: #4E6FFF;
      border: 0.3*$rem solid #FFFFFF;
      border-radius: 0.65*$rem;
      box-shadow: inset -0.35*$rem 0px 0.5*$rem 0px #FFFFFF,inset 0.35*$rem 0px 0.5*$rem 0px #FFFFFF,inset 0px -0.35*$rem 0.5*$rem 0px #FFFFFF,inset 0px 0.35*$rem 0.5*$rem 0px #FFFFFF;
      color: #FFFFFF;
      font-size: 1*$rem;
      box-sizing: border-box;

      &:first-child {
        margin-right: 3.5*$rem;
      }

      > img {
        margin: 0.85*$rem 0 1*$rem;
        width: 10.35*$rem;
        height: 10.35*$rem;
      }

      > p {
        margin: 0 1.1*$rem;
      }
    }
  }
}

.rules_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5*$rem 2.5*$rem;
  width: 30*$rem;
  height: 36.3*$rem;
  border-radius: 1.25*$rem;
  background: linear-gradient(140deg, #FFFFFF 0%, #FFFFFF 100%);
  box-sizing: border-box;

  .title {
    color: #590076;
    font-size: 1.75*$rem;
    font-weight: 500;
    text-align: center;
  }

  .content {
    padding-top: 1*$rem;
    box-sizing: border-box;
    flex: 1;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow-y: auto;
    white-space: pre-line;
    line-height: 1.7*$rem;
    color: #5B0A53;
    font-size: 1.05*$rem;
  }

  .close {
    display: flex;
    justify-content: center;
    padding-top: 1.5*$rem;
    box-sizing: border-box;
    width: 100%;
    > div {
      width: 9.8*$rem;
      height: 3*$rem;
      text-align: center;
      line-height: 3*$rem;
      color: #FFFFFF;
      font-size: 1.6*$rem;
      font-weight: 500;
      background: linear-gradient(270deg, #EA67EA 0%, #7E8AFF 100%);
      border-radius: 1.5*$rem;
      border: 0.15*$rem solid #FFFFFF;
    }
  }
}