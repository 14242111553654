$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.egg_2_container {
  width: 100%;
  height: 100%;
  background-color: #092010;
  //padding-bottom: 3*$rem;
  position: relative;
  //background-color: purple;
  overflow: hidden;
}

.egg_2_content_switch_container {
  width: 11 *$rem;
  height: 3 *$rem;
  position: absolute;
  left: 2 *$rem;
  top: 40vh;
  border-radius: 2 *$rem;
  opacity: 1;
  background: rgba(13, 80, 55, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-around;

  .egg_2_shop_txt {
    font-family: PingFangSC-Medium;
    font-size: 1.5 *$rem;
    font-weight: normal;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0px;
    color: #8EF4B1;
  }

  .egg_2_my_txt {
    font-family: PingFangSC-Medium;
    font-size: 1.5 *$rem;
    font-weight: normal;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0px;
    color: #8EF4B1;
  }

  .egg_2_content_switch_btn_container {
    position: absolute;
    width: 100%;
    height: 100%;
    //background-color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .egg_2_shop_btn_shop {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 6 *$rem;
      height: 3.4 *$rem;
      border-radius: 2 *$rem;
      opacity: 1;
      font-weight: normal;
      font-size: 1.5 *$rem;
      /* 绿字 */
      background: #8EF4B1;

      img {
        width: 1.6 *$rem;
        height: 1.65 *$rem;
        object-fit: cover;
      }

      span {
        margin-left: 2px;
      }
    }
  }
}

.hc_container {
  width: 11 *$rem;
  height: 3 *$rem;
  position: absolute;
  right: 2 *$rem;
  top: 40vh;
  border-radius: 2 *$rem;
  opacity: 1;
  background: #93F5AD;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: RuiZiZhenYan;
  font-size: 1.6 *$rem;
  font-weight: normal;
  text-align: center;
  letter-spacing: 4px;
  color: #081C0D;
}

.hc_container_dis {
  background: #C9E1D1;
  color: #55745F;
}

.egg_header_2_component {
  width: 100vw;
  height: 45vh;
  background-color: #092010;
  position: relative;
  overflow: hidden;

  .shop_back_btn {
    position: fixed;
    width: 1.75*$rem;
    height: 1.75*$rem;
    left: 2.4*$rem;
    top: 4.5*$rem;
    background: url(~@/assets/img/icon/go_back.png) no-repeat;
    background-size: 100%;
    z-index: 10;
  }

  .shop_pre_big_img{
    position: absolute;
    width: 2*$rem;
    height: 2*$rem;
    right: 2.4*$rem;
    top: 4.5*$rem;
    background: url(~@/assets//img/icon_yulan.png) no-repeat;
    background-size: 100%;
    z-index: 10;
  }


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.shop_stage_2_content_container {
  //width: 100vw;
  //height: 40 *$rem;
  height: 53vh;
  margin-top: 2vh;
  width: 94vw;
  margin-left: 3vw;
  overflow-x: hidden;
  position: relative;
  //background-color: yellow;
  display: flex;
  flex-direction: column;
}

.shop_my_btn_bottom_father {
  width: 100%;
  height: 10vh;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #054F2D;
  display: flex;
  justify-content: center;
  z-index: 1;

  .icon_btn_hc_father {
    width: 29 *$rem;
    height: 4 *$rem;
    position: relative;
    margin-top: 1 *$rem;
  }

  .icon_btn_hc {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hc_txt {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: RuiZiZhenYan;
    font-size: 1.8 *$rem;
    font-weight: normal;
    text-align: center;
    letter-spacing: 10px;

    color: #081C0D;

  }
}

.shop_stage_2_header_btn_second_father0 {
  width: 98%;
  margin-left: 1%;
  height: 4*$rem;
  //margin-top: 1*$rem;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  align-items: center;
}

.header_btn_2_second_father {
  width: 6.25*$rem;
  height: 2.25*$rem;
  position: relative;
  display: inline-block;
  //margin-left: 2vw;
  flex-shrink: 0;

  .header_btn_second_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header_btn_second_title {
    width: 100%;
    height: 100%;
    font-size: 1.4*$rem;
    font-weight: normal;
    text-align: center;
    color: #092010;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
  }
}



.shop_stage_2_list {
  width: 100%;
  height: 35 *$rem;
  overflow-x: hidden;
  //background-color: red;
  flex: 1;

  .adm-swiper-item {
    height: 37 *$rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  //scrollbar-width: 0;
  //
  //&::-webkit-scrollbar {
  //  display: none;
  //}
  //
  //&::-webkit-scrollbar {
  //  width: 0;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  width: 0;
  //}


  .adm-pull-to-refresh-content {
    display: block;
  }

  .adm-infinite-scroll {
    width: initial;
  }

  .adm-list-body {
    background-color: transparent;
    border-top: transparent;
    border-bottom: transparent;
  }

  .shop_stage_1_item_container1 {
    width: 27.3vw;
    height: 14.2*$rem;
    margin-left: 3vw;
    margin-top: 1.5*$rem;
    position: relative;
    display: inline-block;

    &.no_money_buy {
      height: 12*$rem;
    }
  }

  .item_img_father_1 {
    height: 10*$rem;
    background-color: white;
    border: 0.2px solid #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    //&.isSel {
    //  border: 2px solid #CEBF7E;
    //}

    .item_img_father_1_padding {
      width: 98%;
      height: 99%;
      //background-color: red;
      position: absolute;
      border: 3px solid #CEBF7E;
      border-radius: 6px;
    }


    .item_img {
      width: 90%;
      height: 10*$rem;
      object-fit: contain;
    }

    .item_choice_img {
      width: 1.5 *$rem;
      height: 1.5 *$rem;
      object-fit: cover;
      position: absolute;
      right: 0.2 *$rem;
      bottom: 0.2 *$rem;
    }

    .item_has_father {
      position: absolute;
      left: 0px;
      bottom: 5px;
      width: 100%;
      height: 1.6 *$rem;
      opacity: 1;
      background: linear-gradient(270deg, rgba(142, 244, 177, 0.20) 0%, #8DF4B1 49%, rgba(141, 244, 177, 0.20) 100%);
      font-size: 1 *$rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .item_has_count_span {
        color: red;
      }
    }
  }

  .item_name_2 {
    width: 100%;
    font-family: PingFangSC-Regular;
    font-size: 1.2*$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #8EF4B1;
    margin-top: 0.2*$rem;
    margin-left: 0.1*$rem;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item_price_add_father_2 {
    width: 100%;
    height: 1.5*$rem;
    //background-color: yellow;
    position: absolute;
    left: 0.1*$rem;;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price_span {
      font-family: PingFangSC-Medium;
      font-size: 1.5*$rem;
      font-weight: normal;
      color: #FF5E28;
    }

    .add_buy_father {
      width: 4 *$rem;
      height: 1.6 *$rem;
      border-radius: 0.7 *$rem;
      //opacity: 1;
      background: linear-gradient(90deg, #B1F995 0%, #8DF4B2 100%);
      display: flex;
      align-items: center;
      justify-content: center;


      .buy_span {
        font-size: 11px;
        font-weight: normal;
        margin-top: 0.08 *$rem;
        color: #092010;
      }
    }
  }

  .song_pkp_logo {
    position: absolute;
    width: 7 *$rem;
    height: 1.6 *$rem;

    left: 0px;
    top: 1.3 *$rem;
    border-radius: 0px 138px 138px 0px;
    opacity: 1;
    background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Regular;
    font-size: 0.9 *$rem;
    font-weight: normal;
    color: #3D3D3D;

  }


  .item_choice_img {

  }
}



