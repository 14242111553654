$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.recycling {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .recycling-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        background: linear-gradient(123deg, #FF9A7A 0%, #F86738 83%);
        .select-preview {
            display: flex;
            flex-direction: column;
            margin-top: 2.8*$rem;
            .preview-list {
                display: flex;
                flex-direction: row;
                justify-content: center;
                .preview-item {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 0.75*$rem;
                    .preview-img {
                        border-radius: 0.5*$rem;
                        width: 9*$rem;
                        height: 9*$rem;
                        background: #FFFFFF;
                    }
                    .close-icon {
                        position: absolute;
                        right: 0.4*$rem;
                        top: 0.5*$rem;
                        width: 1.5*$rem;
                        height: 1.5*$rem;
                    }
                    
                    span {
                        width: 9*$rem;
                        color: #FFFFFF; 
                        font-size: 1.2*$rem;
                        font-weight: 500;
                        line-height: 2*$rem;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .preview-emty {
                    border-radius: 0.5*$rem;
                    margin: 0 0.75*$rem 2*$rem;
                    width: 9*$rem;
                    height: 9*$rem;
                    background: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 3*$rem;
                        object-fit: contain;
                    }
                }
            }
            .recycling-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 3.6*$rem;
                border-radius: 1.8*$rem;
                background: #FFDE9C;
                font-size: 1.8*$rem;
                font-weight: 500;
                color: #D43C36;
                margin: 1.2*$rem 5*$rem;
                &:active {
                    opacity: 0.8;
                }
                &.disable {
                    background: #D8D8D8;
                    color: #767586;
                    &:active {
                        opacity: 1.0;
                    }
                }
            }
        }
        .collection-list {
            display: flex;
            flex-direction: column;
            flex: 1;
            border-radius: 2*$rem 2*$rem 0 0;
            background: linear-gradient(144deg, #FFEADF 0%, #FF8251 55%, #FFF8B9 100%);
            padding-top: 1*$rem;
            overflow: hidden;
            .list-tip {
                color: #000000;
                font-weight: 500;
                font-size: 1.6*$rem;
                padding: 1*$rem;
            }
            .ant-list-view {
                padding: 0 1.5*$rem 1*$rem;
            }
            .collection-item {
                display: inline-block;
                margin: 0 0.45*$rem 1*$rem;
                .collection-item-content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    .item-img {
                        position: relative;
                        background: transparent;
                        padding: 0.2*$rem;
                        border-radius: 0.5*$rem;
                        overflow: hidden;
                        width: 7.7*$rem;
                        height: 7.7*$rem;
                        box-sizing: border-box;
                        &.active {
                            background: url(~@/assets/img/collectionRecoveryLand/kuang.png);
                            background-size: 7.7*$rem 7.7*$rem;
                        }
                        &.disabled {
                            &::after {
                                content: '';
                                display: block;
                                background: rgba(0, 0, 0, .4);
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                border-radius: 0.5*$rem;
                                margin: 0.2*$rem;
                            }
                        }
                        img {
                            background: #FFFFFF;
                            border-radius: 0.5*$rem;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .select-icon {
                        position: absolute;
                        width: 1.25*$rem;
                        height: 1.25*$rem;
                        top: 0.5*$rem;
                        left: 0.75*$rem;
                    }
                    span {
                        width: 7.7*$rem;
                        line-height: 2*$rem;
                        color: #FFFFFF;
                        font-size: 1.2*$rem;
                        font-weight: 500;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .adm-modal {
        .adm-center-popup-wrap,
        .adm-modal-wrap {
            width: auto;
            max-width: initial;
            >div {
                .adm-modal-body {
                    padding: 0;
                    .adm-modal-content {
                        padding: 0;
                        .alert-body {
                            .alert-content {
                                margin: 0;
                                line-height: 0;
                            }
                        }
                    }
                    .adm-modal-footer-empty {
                        height: 0;
                    }
                }
            }
        }
    }


}