$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
  @return ($px / 2558) * 100vw;
}

.st-table-container {
  border-width: to(2);
  border-color: white;
  border-style: solid;
  border-right: 0;
  border-bottom: 0;
  font-family: PingFang SC;
  font-size: to(40);
  color: #3D3D3D;
  height: 100%;
  overflow-y: scroll;
  position: relative;

  .adm-pull-to-refresh-content {
    width: 100%;
  }

  .list-view-content {
    width: 100%;
  }

  .st-table-header {
    border-right-style: solid;
    border-right-width: to(2);
    border-right-color: white;
    overflow: hidden;

    table {
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;
      width: 100%;

      thead.st-table-thead {
        tr {
          height: to(100);

          th.st-table-cell {
            height: to(100);
            line-height: to(100);
            border-right-style: solid;
            border-right-width: to(2);
            border-right-color: white;
            border-bottom-style: solid;
            border-bottom-width: to(2);
            border-bottom-color: white;
            font-size: to(40);
            color: #3D3D3D;
            text-align: left;
            font-weight: normal;

            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }

  .st-table-body {
    overflow: scroll;
    max-height: to(660);
    border-right-width: to(2);
    border-right-color: white;
    // border-bottom-style: solid;
    // border-bottom-width: to(2);
    // border-bottom-color: white;

    table {
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;
      font-size: to(36);

      tbody.st-table-tbody {
        tr {
          height: to(100);
        }

        tr.st-table-row {
          height: to(100);

          td.st-table-cell {
            height: to(100);
            line-height: to(100);
            border-right-style: solid;
            border-right-width: to(2);
            border-right-color: white;
            border-bottom-style: solid;
            border-bottom-width: to(2);
            border-bottom-color: white;
            color: rgba(0, 0, 0, .65);
            text-align: left;

            &:last-child {
              border-right: 0;
            }
          }

          &:last-child {
            td.st-table-cell {
              // border-bottom: 0;
            }
          }
        }
      }
    }
  }

  table {
    .result {
      box-sizing: border-box;
      position: relative;
    }

    .winNum {
      width: 40%;
      position: absolute;
      display: inline-block;
      font-family: PingFang SC;
      font-size: to(36);
      font-weight: 500;
      font-variation-settings: "opsz"auto;
      color: #E84040;
      margin-left:to(10);
      left:60%;
      top:50%;
      transform: translate(0,-50%);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left !important;
    }

    .st-table-cell {
      &.st-table-cell-fix-left {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 2;

      }

      &.st-table-cell-fix-right {
        position: -webkit-sticky;
        position: sticky;
        right: 0;
        z-index: 2;
        // background-color: rgba(255,255,255,1);
        // background: rgba(255, 255, 255, 0.47);
        // backdrop-filter: blur(to(200));
      }
    }
  }
}