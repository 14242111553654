@import '@/assets/scss/variable.scss';

.get_coupon_container {
  width: 100%;
  height: 100vh;
  position: relative;
  //background-color: white;
  overflow: hidden;

  .cartoon_record_com {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .invite_record_header_container {
      width: 100%;
      position: relative;
      height: 6 *$rem;


      .invite_record_header {
        width: 66%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        //margin-left: 5%;
        //background-color: red;
        margin: 0 auto;
      }

      .heder_title_com {
        font-family: PingFangSC-Semibold;
        font-size: 1.8 *$rem;
        font-weight: normal;
        text-align: center;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .header_title_sel {
          font-size: 1.4 *$rem;
          font-weight: normal;
          text-align: center;
          color: #000;
          opacity: 1;
          font-family: PingFangSC-Semibold;
        }

        .header_title_dis {
          font-family: PingFangSC-Semibold;
          font-size: 1.4 *$rem;
          font-weight: normal;
          text-align: center;
          color: #9E9E9E;
        }
      }
    }

    .cartoon_record_com_ph {
      width: 100%;
      //height: 7 *$rem;
      height: 10vh;
    }
  }
}

