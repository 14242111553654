$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.share-mask-modal {
  .adm-mask {
      overflow: auto;
      background: linear-gradient(rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 1)) !important;
      // z-index: 9999;
  }
}

.adm-mask {
  .adm-mask-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .share-body {
      width: 80vw;
      border-radius: 20px;
      margin-top: 8vh;
      overflow: hidden;
      position: relative;

      .share-content {
        width: 80vw;
        // position: relative;

        .share-avatar {
          overflow: hidden;
          img {
            display: block;
            width: 80vw;
          }
        }

        .share_box {
          position: absolute;
          top: 150 * $vw;
          left: 55 * $vw;
          width: 55vw;
          display: block;
        }

        .share-detail {
          position: absolute;
          right: 3%;
          bottom: 7%;
          svg {
            background-color: #ffffff;
            border: 6px solid #fff;
            border-radius: 8px;
            float: right;
            width: 60 * $vw;
            height: 60 * $vw;
          }
          &.type-10 {
            bottom: 1.5*$rem;
            svg {
              width: 5.5 * $rem;
              height: 5.5 * $rem;
            }
          }

        }

        
      }
    }

    .opt {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 80vw;
      margin-top: 5vh;
      margin-bottom: 4vh;

      img {
        width: 46px;
        height: 46px;
      }
    }
  }

  .wechat,
  .share,
  .copy,
  .download {
    color: #fff;
    font-size: 11px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
    text-align: center;
  }

  .cancel_btn {
    width: 80vw;
    height: 12vw;
    overflow: hidden;
    margin-top: 2vh;
    border-radius: 23px;
    margin-bottom: 5vh;

    .adm-button.adm-button-large {
      width: 80vw;
      height: 12vw;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #c1c1c1;
      background-color: #262626;
      border: none;
    }
  }
}