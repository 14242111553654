$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.collectionvote_suggest_page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .suggest_text_area_father {
    margin: 2 *$rem;
    padding: 1 *$rem;
    background-color: #EBEBEB;
    border-radius: 8px;
  }

  .suggest_submit_father {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 5%;
    bottom: 15 *$rem;
    width: 90%;
    height: 5 *$rem;
    border-radius: 2.5 *$rem;
    opacity: 1;
    /* 图标 */
    background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
    font-family: PingFangSC-Semibold;
    font-size: 2 *$rem;
    font-weight: normal;
    //line-height: 38px;
    letter-spacing: 10px;
    color: #49200A;

  }
}
