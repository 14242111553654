@function to($px) {
  @return ($px / 2558) * 100vw;
}

.confirm_cancel_lease {
  width: to(1070);
  height: to(724);
  background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
  background-size: 100% 100%;
  font-family: YueGenYouShangYueBoHei;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: to(52) to(103) 0;

    > span {
      &:nth-child(1) {
        width: to(79);
      }
      &:nth-child(2) {
        font-size: to(57);
        font-weight: bold;
        color: #602401;
      }
    }

    > img {
      width: to(79);
      height: to(79);
    }
  }

  &_content {
    margin-top: to(130);
    color: #A25A2B;
    text-align: center;
    font-size: to(44);
  }

  &_footer {
    display: flex;
    justify-content: space-between;
    padding: 0 to(150);
    margin-top: to(160);

    .cancel {
      width: to(328);
      height: to(134);
      background: url('~@/assets/img/landfi/cancel-4.png') no-repeat;
      background-size: 100% 100%;
    }

    .confirm {
      width: to(328);
      height: to(134);
      background: url('~@/assets/img/landfi/confirm-4.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}