$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.guessing-rule-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  .rules-modal-common {
    .modal-container {
      padding: 1.4*$rem 2.4*$rem 1.9*$rem;
      width: 33*$rem;
      height: 41*$rem;
      background: #FFFFFF;
      border-radius: 1*$rem;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      box-sizing: border-box;
      .modal-title {
        padding-bottom: 1.1*$rem;
        color: #000;
        font-size: 1.8*$rem;
        font-weight: bold;
        text-align: center;
      }
      .modal-content {
        flex: 1;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow-y: auto;
        white-space: pre-line;
        line-height: 1.7*$rem;
        color: #000;
        font-size: 1.2*$rem;
      }
      .rules-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1*$rem auto 0;
        width: 15.6*$rem;
        height: 4.1*$rem;
        border-radius: 4.1*$rem;
        background: linear-gradient(180deg, #C9EEFF 0%, #86D9FF 100%);
        color: #2E63F6;
        font-size: 1.8*$rem;
      }
    }
  }
}
