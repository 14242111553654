@function to($px) {
  @return ($px / 2558) * 100vw;
}

.real_name_mask {
  display: flex;
  justify-content: center;
  align-items: center;
}

.real_name_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: to(115) to(110) 0;
  width: to(900);
  height: to(680);
  background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
  background-size: 100% 100%;
  font-family: YueGenYouShangYueBoHei;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .title {
    color: #371F00;
    font-size: to(60);
  }

  .confirm_tip {
    margin-top: to(60);
    margin-bottom: to(136);
    font-size: to(35);
    font-weight: 500;
    text-align: center;
    color: #000000;
  }

  .btn_box {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .cancel_btn {
      background: url('~@/assets/img/landfi/icon_zh.png') no-repeat;
      background-size: 100% 100%;
    }

    .confirm_btn {
      background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .common_btn {
    width: to(298);
    height:  to(102);
    border-radius: to(40);
    text-align: center;
    line-height: to(102);
    color: #FFFFFF;
    font-size: to(40);
    font-family: YueGenYouShangYueBoHei;
  }
}