$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.all-session-container {
  background: linear-gradient(180deg, #DFFAFD 0%, #A9D2FF 100%);
  //min-height: 100%;
  height: 100vh;
  display: flex;
  //padding-bottom: 2 *$rem;

  .all-session-item-title {
    font-family: PingFangSC-Medium;
    font-size: 1.2 *$rem;
    //line-height: 27px;
    color: #3597FC;
    margin-left:4.6vw;
    margin-top: 1.5 *$rem;
  }

  .all-session-item-container {
    width: 100%;
    //display: flex;
    //flex-direction: row;
    //align-items: center;
    //justify-content: space-between;

    .all-session-item {
      width: 43vw;
      height: 8 *$rem;
      margin-left: 4.6vw;
      margin-top: 1 *$rem;
      border-radius: 8px 20px 8px 20px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      display: inline-block;

      //&.bg_gray{
      //  background: #EEEEEE;
      //}
      //
      //&.bg_blue{
      //  background: linear-gradient(119deg, #38A7FF -18%, #2B61F5 81%);
      //}

      > :nth-child(1) {
        font-family: 思源黑体;
        font-size: 1.6 *$rem;
        font-weight: bold;
        text-align: center;
        color: #FFFFFF;
        margin-top: 0.5 *$rem;
      }

      > :nth-child(2) {
        font-family: 思源黑体;
        font-size: 1.2 *$rem;
        text-align: center;
        color: #FFFFFF;
        &.color_gray{
          color: #767676;
        }
      }

      > :nth-child(3) {
        font-family: 思源黑体;
        font-size: 1.2 *$rem;
        text-align: center;
        color: #FFFFFF;
        margin-top: 1 *$rem;
        &.color_gray{
          color: #767676;
        }
      }
    }
  }
}
