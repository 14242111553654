@function to($px) {
  @return ($px / 2558) * 100vw;
}

.gem_detail_out_mask {
  width: 100vw;
  height: 100vh;
  position: absolute;

  :global {
    .adm-mask-content {
      width: 100vw;
      height: 100vh;
      display: block;
      position: relative;
    }
  }

  .gem_detail_out {
    position: absolute;
    left: to(35);
    right: to(35);
    top: to(26);
    bottom: to(28);
    background-image: url("~@/assets/img/landfi/order_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    box-sizing: border-box;
    padding: to(100) to(114);

    .close {
      position: absolute;
      top: to(33);
      right: to(64);
      width: to(88);
      height: to(88);
    }

    .box {
      width: 100%;
      height: 100%;
      border-radius: to(17);
      background: #F1D7AE;
      padding: 0 to(103);
      box-sizing: border-box;
      font-family: PingFang SC;
      font-size: to(44);
      color: #A05D20;
      overflow-y: scroll;
      .box_item {
        width: 100%;
        height: to(165);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info {
          width: 30%;
          font-size: to(56.64);
          font-weight: 500;
          color: #C8450B;
          text-align: right;
        }
        .date{
          width:30%;
          text-align: center;

        }
        .title{
          width:30%;
        }

      }

      .box_item:not(:last-child) {
        border-bottom-width: to(1.56);
        border-bottom-style: solid;
        border-bottom-color: rgba(170, 103, 39, 0.41);
      }
    }
  }
}