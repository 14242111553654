$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.secondCreationAgreement {
  margin: 30 / 20*$rem;
  padding-bottom: 12*$rem;

  .cell {
    background: #ffffff;
    border-radius: 30 / 20*$rem;
    padding: 30 / 20*$rem;
    opacity: 1;

    .title {
      font-size: 30 / 20*$rem;
      line-height: 42 / 20*$rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 8 / 20*$rem;
    }

    .part {
      display: flex;
      flex-direction: row;
      margin-top: 4 / 20*$rem;
      font-size: 26 / 20*$rem;
      line-height: 42 / 20*$rem;
      color: #777777;
      .subTitle {
        flex: 1;
        font-size: 26 / 20*$rem;
        line-height: 42 / 20*$rem;
        color: #777777;
        display: inline-block;
        white-space: pre-wrap;
      }
      .tipTitle{
        flex: 1;
        font-size: 22 / 20*$rem;
        line-height: 32 / 20*$rem;
        color: #999999;
        display: inline-block;
        white-space: pre-wrap;
      }
    }
  }
}