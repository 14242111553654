$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.winner-swiper1 {
    border-radius: setRem(16);
    opacity: 1;
    background: linear-gradient(114deg, rgba(229,152,111,0.47) 0%, #E5986F 100%);
    margin-top: setRem(40);
    margin-right: setRem(30);
    margin-left: setRem(30);
    padding-top: setRem(51);
    height: setRem(261);

    .winner-list {
        position: relative;
        .winner-swiper-item {
            overflow: hidden;
            text-overflow:ellipsis;
            font-family: PingFangSC-Regular;
            font-size: setRem(26);
            font-weight: normal;
            line-height: setRem(31);
            text-align: center;
            letter-spacing: 0px;
            white-space: nowrap;
            color: #FFFFFF;
            margin-left: setRem(30);
            margin-right: setRem(30);
        }
    }
}
