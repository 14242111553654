@import '@/assets/scss/variable.scss';

.upgrade_record {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  &_content {
    margin: 2*$rem auto 0;
    padding: 1.4*$rem 1.4*$rem 0;
    width: 92%;
    height: 15.2*$rem;
    background: #FFFFFF;
    border-radius: 1*$rem;
    box-sizing: border-box;

    .s_info {
      display: flex;
      align-items: center;

      > img {
        width: 5*$rem;
        height: 5*$rem;
        border-radius: 0.5*$rem;
        border: 0.05*$rem solid #979797;
        box-sizing: border-box;
      }

      > div {
        margin-left: 1*$rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > div {
          &:nth-child(1) {
            width: 25*$rem;
            color: #333333;
            font-size: 1.6*$rem;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            box-sizing: border-box;
            white-space: nowrap;
          }
          &:nth-child(2) {
            margin-top: 1.1*$rem;
            width: 4.2*$rem;
            height: 1.6*$rem;
            border-radius: 0.8*$rem;
            background: #D8D8D8;
            text-align: center;
            line-height: 1.6*$rem;
          }
        }
      }
    }

    .upgrade_info {
      margin-top: 1*$rem;
      padding-top: 1.4*$rem;
      border-top: 0.05*$rem solid #DFDFDF;
      width: 100%;

      &_item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-child(2) {
          margin-top: 2*$rem;
        }

        > span {
          &:nth-child(1) {
            color: #999999;
            font-size: 1.2*$rem;
          }
          &:nth-child(2) {
            color: #333333;
            font-size: 1.2*$rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  .record_list {
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
}