@import '@/assets/scss/variable.scss';

.goods_detail {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #F1F0F5;

    .goods_container {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .swiper_item {
            width: 100vw;
            height: 100vw;

            >img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .goods_price {
            padding: 1.5*$rem;
            box-sizing: border-box;
            width: 100%;
            background: #FFFFFF;

            >div {
                &:nth-child(1) {
                    margin-bottom: 0.3*$rem;
                    color: #FF5203;
                    >span {
                        &:nth-child(1) {
                            font-size: 1.0*$rem;
                            font-weight: 500;
                        }

                        &:nth-child(2) {
                            padding-right: 0.5*$rem;
                            font-size: 2.0*$rem;
                            font-weight: 600;
                            
                        }
                    }
                }

                &:nth-child(2) {
                    font-size: 1.6*$rem;
                    font-weight: 500;
                    color: #262727;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
            }
        }
        .goods_spec {
            padding: 1.2*$rem;
            margin-top: 1.5*$rem;
            margin-bottom: 1*$rem;
            box-sizing: border-box;
            width: 100%;
            background: #FFFFFF;
            > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.5*$rem 0;
                font-size: 1.6*$rem;
                >span:nth-child(1) {
                    font-weight: 500;
                }
            }
        }
    }

    .goods_bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        background: #FFFFFF;
        padding: 2*$rem;

        @media screen and (min-height: 780px) {
            padding-bottom: 3.5 * $rem;
        }

        .goods_btn {
            background: linear-gradient(90deg, #FE7F02 0%, #FF5203 100%);
            height: 4.4*$rem;
            width: 12*$rem;
            border-radius: 2.3*$rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.6*$rem;
            color: #FFFFFF;
            font-weight: bold;
            &:active {
                opacity: 0.8;
            }
        }
    }
}