$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.get_coupon_container {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: white;
  overflow: hidden;

  .cartoon_record_com {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .invite_record_header_container {
      width: 100%;
      position: relative;
      height: 11vh;

      .current_juan {
        display: inline-block;
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        font-weight: normal;
        line-height: 60px;
        color: #000000;
        margin-left: 1.5 *$rem;
      }

      .current_juan_count {
        display: inline-block;
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        font-weight: normal;
        line-height: 60px;
        color: #F26881;
      }

      .integral_bg {
        width: 10*$rem;
        height: 2.8*$rem;
        background: url(../imgs/msg_yh.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 4 *$rem;
        top: 2.0 *$rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 1.2 *$rem;
        padding-bottom: 1 *$rem;
        padding-left: 3px;
        font-family: PingFangSC-Medium;
      }

      .invite_record_header {
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        z-index: 1;
        margin-left: 5%;

      }

      .header_line {
        width: 1.5px;
        height: 20px;
        opacity: 1;
        background-color: #000000;
      }

      .heder_title_com {
        font-family: PingFangSC-Semibold;
        font-size: 1.8 *$rem;
        font-weight: normal;
        text-align: center;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .header_title_sel {
          font-size: 1.4 *$rem;
          font-weight: normal;
          text-align: center;
          color: #2E63F6;
          opacity: 1;
          font-family: PingFangSC-Semibold;
        }

        .header_title_dis {
          font-family: PingFangSC-Semibold;
          font-size: 1.4 *$rem;
          font-weight: normal;
          text-align: center;
          color: #000000;
        }
      }
    }

    .cartoon_record_com_ph {
      width: 100%;
      //height: 7 *$rem;
      height: 10vh;

    }
  }
}

