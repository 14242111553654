@function to($px) {
  @return ($px / 2558) * 100vw;
}

.rent_out_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .land_rent_out {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;

    .close {
      position: absolute;
      top: to(30);
      right: to(146);
      width: to(88);
      height: to(88);
    }

    .box {
      position: absolute;
      top: to(147);
      right: to(568);
      left: to(568);
      bottom: to(278);
      font-size: to(56);

      .title {
        width: 100%;
        font-family: YueGenYouShangYueBoHei;
        color: white;
        text-align: center;
      }

      .info {
        width: 100%;
        display: flex;
        min-height: to(500);
        justify-content: space-between;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .item {
          width: to(500);
          position: relative;
          display: inline-block;

          img {
            width: to(500);
            height: to(500);
          }
        }

        .item_info {
          font-family: Source Han Sans;
          font-weight: bold;
          font-feature-settings: "kern"on;
          color: #FAF4B1;
        }
      }


    }


  }
}

.land_rent_detail_out {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: to(188);
  padding-left: to(168);
  background-color: #FCD6A3;
  background-image: url("~@/assets/img/landfi/land-detail-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding-top: to(144);
  padding-bottom: to(78);
  box-sizing: border-box;
  .close {
    position: absolute;
    top: to(30);
    right: to(146);
    width: to(88);
    height: to(88);
  }
  .left {
    position: relative;
    width: to(1200);
    height: 100%;
    box-sizing: border-box;

    .select_box {
      position: relative;
      padding-left:to(20);
      display: flex;
      z-index: 999;
    }
    .list_box{
     position: absolute;
     top:to(70);
     bottom:to(36);
     right:0;
     left:0;
     overflow: hidden;
    }
  }

  .left::after {
    content: '';
    position: absolute;
    right:0;
    top:to(9);
    width: to(3);
    height: 100%;
    background-color: rgba(168, 102, 72, 0.34);
  }

  .right {
    flex: 1;
    height: 100%;
    padding-left:to(84);
    padding-bottom: to(77);
    box-sizing: border-box;
  }

}