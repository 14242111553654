$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.bottom-pay-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2.5*$rem 2*$rem;
    background-color: #fff;

    .bottom-left {
        display: flex;
        flex-direction: row;
        align-items: center;

        >span {
            padding-top: 0.5*$rem;
            font-size: 1.2*$rem;
            color: #333;
        }

        .price-text {
            display: flex;
            flex-direction: column;

            >:nth-child(1) {
                color: #CBB675;
                font-family: PingFangSC-Medium;

                >span {
                    font-size: 2.4*$rem;
                    font-weight: bold;
                    &:nth-child(1) {
                      font-size: 1.3*$rem;
                    }
                }
            }
            >:nth-child(2) {
                color: #333;
                font-size: 1*$rem;
                opacity: 0.5;
                >span {
                    font-size: 1.3*$rem;
                    text-decoration: line-through;
                }
            }
        }
    }
    .bottom-right {
        .adm-button {
            font-size: 1.6*$rem;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #614d1a;
            background: linear-gradient(90deg, #ccbd7b 0%, #f9ecb9 100%);
            padding: 0 4.6*$rem;
            height: 4.5*$rem;
            line-height: 4.5*$rem;
            border-radius: 2.25*$rem;
          }
    }
}

@media screen and (min-height: 780px) {
    .bottom-pay-btn {
        padding-bottom: 4 * $rem;
    }
}