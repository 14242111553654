@function to($px) {
  @return ($px / 2558) * 100vw;
}

.lease_out_detail {
  .lease_land_top {
    display: flex;
    padding-bottom: to(20);
    border-bottom: to(1.57) solid rgba(168, 102, 72, 0.34);

    .land_img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: to(362);
      height: to(362);
      background: url('~@/assets/img/landfi/lease-land-bg.png') no-repeat;
      background-size: 100% 100%;

      > img {
        width: to(280);
        height: to(286);
        object-fit: contain;
        border-radius: to(20);
      }
    }

    .common_item {
      margin-top: to(12);
      color: #763900;
      font-size: to(38);
      font-weight: 500;
    }
    .time_forever {
      display: flex;
      align-items: center;
      padding: 0 to(23);
      font-size: to(38);
      min-width: to(274);
      height: to(63);
      border-radius: to(13);
      border: to(1.57) solid #FFFFFF;
      background: #FEFAF2;
      box-sizing: border-box;
      color: #c4c0c0;
    }

    .land_info {
      flex: 1;

      .info_title {
        display: flex;
        justify-content: space-between;
        color: #8D3218;
        font-size: to(57);
        font-weight: 600;
      }
      
      .lease_no {
        margin-top: to(6);
      }   
      
      .lease_term {
        margin-top: to(37);
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
          &:nth-child(2) {
            display: flex;
            color: #3AA700;
            font-size: to(38);
            font-weight: 500;
          }
        }
      }

      .lease_price {
        display: flex;
        align-items: center;
        margin-left: to(-30);
        font-size: to(63);
        color: #FFFFFF;
        font-weight: bold;
        font-family: YueGenYouShangYueBoHei;
        text-shadow: to(1.5) to(2) to(1) #000;

        > img {
          width: to(136);
          height: to(136);
        }
      }
    }
  }

  .lease_land_bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: to(90);
    font-family: YueGenYouShangYueBoHei;
    color: #FFFFFF;
    font-size: to(50);
    font-weight: bold;

    .btn_box {
      display: flex;
      margin-top: to(18);

      .cancel {
        margin-right: to(86);
        box-sizing: border-box;
        position: relative;
        width: to(326);
        height: to(92);
        background: linear-gradient(134deg, #A7A7A7 12%, #9A9A9A 65%);
        box-shadow: inset 0px 6.29px 6.29px 0px #FFFFFF;
        border: to(1.57) solid #878787;
        border-radius: to(46);
        .inner {
          position: absolute;
          top: to(0);
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          width: to(321);
          height: to(87);
          background: linear-gradient(137deg, #CDCDCD 29%, #B6B6B6 65%);
          box-shadow: 0px 6.29px 15.73px 0px rgba(0, 0, 0, 0.3),inset 0px 0px 12.59px 0px rgba(255, 255, 255, 0.3),inset 0px -4.72px 4.72px 0px #C8C8C8;
          border-radius: to(46);
        }
      }

      .confirm {
        position: relative;
        min-width: to(468);
        height: to(92);
        border-radius: to(128);
        border: to(1.57) solid #68AE2E;
        box-shadow: inset 0px 6.29px 6.29px 0px #CDFF5E;
        background: linear-gradient(113deg, #2BAE47 12%, #68AE2E 53%);
        box-sizing: border-box;

        .inner {
          position: absolute;
          top: to(0);
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 0 to(20);
          box-sizing: border-box;
          min-width: to(462);
          height: to(87);
          border-radius: to(128);
          background: linear-gradient(0deg, #6FBF19 5%, #AEF172 110%);
          box-shadow: 0px 6.29px 15.73px 0px rgba(0, 0, 0, 0.3),inset 0px 6.29px 12.59px 0px #BCFF26,inset 0px -4.72px 22.03px 0px #FFFFFF;

          > img {
            margin-top: to(10);
            margin-left: to(-15);
            width: to(146);
            height: to(146);
          }
        }

      }
    }

  }
  
  .lease_info {
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: to(25);
      color: #9C766B;
      font-size: to(38);
    }
  }
  .lease_term_edit {
    display: flex;
    align-items: center;
    margin-top: to(29);
  }

  .lease_price_edit {
    display: flex;
    align-items: center;

    :global {
      .adm-input {
        min-height: 0;
      }
      .adm-input-element {
        color: #763900;
        font-size: to(38);
      }
    }

    > img {
      margin-left: to(-35);
      width: to(146);
      height: to(146);
    }

    .input_price {
      padding: 0 to(42);
      width: to(274);
      border-radius: to(13);
      border: to(1.57) solid #FFFFFF;
      background: #FEFAF2;
      box-sizing: border-box;
    }
  }

  .dropdown_select {
    color: #763900;
    width: fit-content;
  
    .dropdown_title {
      display: flex;
      align-items: center;
  
      .title {
        margin-right: to(12);
        font-size: to(44);
      }
  
      .content_box {
        position: relative;
      }
  
      .content_top {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 to(23);
        font-size: to(38);
        min-width: to(274);
        height: to(63);
        border-radius: to(13);
        border: to(1.57) solid #FFFFFF;
        background: #FEFAF2;
        box-sizing: border-box;

        > span {
          flex: 1;
          text-align: left;
          padding-left: to(15);
        }
  
        > img {
          margin-left: to(23);
          width: to(26);
          height: to(14);
          transition: 0.3s;
          
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
  
    .dropdown_content {
      position: absolute;
      left: 50%;
      top: to(80);
      z-index: 999;
      transform: translateX(-50%);
      padding-top: to(20);
      font-size: to(38);
      border: to(1.57) solid #FFFFFF;
      background: #FEFAF2;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      border-radius: to(20);
      display: none;
      transition: 0.3s;
  
      &.show {
        display: block;
      }
  
      &.hidden {
        display: none;
      }
  
      &_item {
        margin-bottom: to(20);
        text-align: center;
        opacity: 1;
        transition: transform 0.3s;
        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}