@function to($px) {
  @return ($px / 2558) * 100vw;
}

.modal_box {
  width: to(1070);
  min-height: to(724);
  background-image: url("~@/assets/img/landfi/pop_2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: to(61) to(60) to(61) to(60);

  .modal_header {
    height: to(78);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    gap: to(10);

    &>.top_btns {
      flex: 1;
      display: flex;
      gap: to(24);
    }

    .close {
      width: to(78);
      height: to(78);
      position: absolute;
      top: 50%;
      right: to(10);
      transform: translate(0, -50%);
    }

    .modal_title {
      width: to(160);
      font-family: YueGenYouShangYueBoHei;
      font-size: to(56);
      font-weight: bold;
      line-height: normal;
      text-align: center;
      color: #602401;
    }
  }


  .modal_content {
    flex: 1;
    font-family: YueGenYouShangYueBoHei;
    font-size: to(44);
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: #A25A2B;

    box-sizing: border-box;
    position: relative;

    .info {
      width: 100%;
      padding: to(0) to(40);
      &>.address {
        font-family: PingFang SC;
        margin-top: to(34);
        padding: to(27) to(38);
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: to(20);

        .add_address {
          display: flex;

          >img {
            width: to(50);
            height: to(55);
          }

          .tip_text {
            flex: 1;
            padding: 0 to(26);
            box-sizing: border-box;
            color: #9E9E9E;
            font-size: to(36);
            text-align: left;
          }

          .line {
            margin-right: to(21);
            width: to(1.92);
            height: to(50);
            background: #D8D8D8;
          }

          .add_btn {
            color: #000000;
            font-size: to(36);
            font-weight: 600;
          }
        }

        .address_detail {
          display: flex;

          >img {
            margin-right: to(34);
            width: to(50);
            height: to(55);
          }

          .address_text {
            max-width: to(480);

            >span {
              display: inline-block;
              color: #262727;
              font-size: to(32);
              font-weight: 500;
            }

            .user_info {
              margin-top: to(30);
              color: #696969;
              font-size: to(36);

              span {
                padding-right: to(17);
              }
            }
          }
        }
      }

      &>.goods_info {
        color: #A25A2B;
        display: flex;
        column-gap: to(37);
        margin-top: to(47);
        text-align: left;
        flex-wrap: wrap;
        align-items: center;

        &>.goods_info_img {
          width: to(187);
          height: to(187);
          opacity: 1;
          background: #D8D8D8;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &>.goods_info_box {
          flex: 1;
          height: to(187);
          display: flex;
          flex-direction: column;
          gap: to(62);
          .goods_info_title{
            width:to(640);
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
          &>.goods_info_number {
            font-family: PingFang SC;
            min-width: to(253);
            height: to(63);
            display: flex;
            color: #FFFFFF;
            align-items: center;

            .goods_info_number_sub {
              width: to(70);
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #BF6B1F;
              border-radius: to(152) to(0) to(0) to(152);

            }

            .goods_info_number_dig {
              min-width: to(113);
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #D57B2B;
            }

            .goods_info_number_add {
              width: to(70);
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #BF6B1F;
              border-radius: to(0) to(152) to(152) to(0);

            }
          }

        }

        &>.goods_info_numAmount {
          width: 100%;
          font-size: to(36);
          padding-left: to(224);
          margin-top: to(25);
        }
      }
    }
  }

  .modal_opt {
    height: to(130);
    display: flex;
    justify-content: space-between;
    width: to(680);
    margin: 0 auto;

    .modal_opt_cancel {
      width: to(320);
      height: to(130);
      background-image: url("~@/assets/img/landfi/cancel.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }

    .modal_opt_confirm {
      width: to(320);
      height: to(130);
      background-image: url("~@/assets/img/landfi/confirm-6.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }


}

.modal_box_2 {
  width: to(1112);
  min-height: to(724);
  background-image: url("~@/assets/img/landfi/landgy/modal-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  .modal_header {
    .close {
      width: to(78);
      height: to(78);
      position: absolute;
      top: 50%;
      right: to(0);
      transform: translate(0, -50%);
    }

    .modal_title {
      width: to(160);
      font-family: YueGenYouShangYueBoHei;
      font-size: to(56);
      font-weight: bold;
      line-height: normal;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .modal_content {
    flex: 1;
    font-family: YueGenYouShangYueBoHei;
    font-size: to(44);
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: #A25A2B;

    box-sizing: border-box;
    position: relative;

    .info {
      width: 100%;
      padding: to(0) to(40);

      &>.goods_info {
        color: #ffffff;
        display: flex;
        column-gap: to(37);
        margin-top: to(47);
        text-align: left;
        flex-wrap: wrap;
        align-items: center;

        &>.goods_info_img {
          width: to(187);
          height: to(187);
          opacity: 1;
          background: #D8D8D8;
        }

        &>.goods_info_box {
          flex: 1;
          height: to(187);
          display: flex;
          flex-direction: column;
          gap: to(62);

          &>.goods_info_number {
            font-family: YueGenYouShangYueBoHei;
            height: to(63);
            color: #FFFFFF;
            width: 100%;
            &>.goods_info_numAmount {
              width: 100%;
              font-size: to(36);
              margin-top: to(25);
            }
            .goods_info_number_sub {
              width: to(70);
              height: 100%;
              text-align: center;
              line-height: to(50);
              background: #D1ADF6;
              border-radius: to(152) to(0) to(0) to(152);

            }

            .goods_info_number_dig {
              min-width: to(113);
              color: #000000;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #FFFFFF;
            }

            .goods_info_number_add {
              width: to(70);
              height: 100%;
              text-align: center;
              line-height: to(50);
              background: #D1ADF6;
              border-radius: to(0) to(152) to(152) to(0);

            }
          }

        }

      }
      &>.goods_info_2{
        margin-top: to(120);
      }
    }
  }
}