$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.detail_b {
  width: 100vw;
  height: 100vh;
  // height: 260vh;
  position: relative;
  background: linear-gradient(180deg, #ecd9bc 30%, #f8f8f8 40%);

  .right_btn {
    width: 20 * $vw;
    height: 20 * $vw;
    background: url(~@/assets//img/ape-world/egg_bt_fenxiang.png);
    background-size: 20 * $vw 20 * $vw;
    top: 42 * $vw;
    right: 4vw;
    position: fixed;
    z-index: 999;
  }

  .back_btn {
    width: 15 * $vw;
    height: 15 * $vw;
    background: url(~@/assets/img/icon/go_back.png);
    background-size: 15 * $vw 15 * $vw;
    top: 43 * $vw;
    left: 4vw;
    position: fixed;
    z-index: 999;
  }

  .detail_bottom {
    width: 100vw;
    height: 110 * $vw;
    padding: 0 4vw;
    box-sizing: border-box;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;

    .detail_bottom_top {
      padding-top: 4vw;
      padding-bottom: 3vw;
      font-size: 12*$rem/10;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #9d9d9d;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .adm-checkbox {
        padding: 4*$rem/10 10*$rem/10 4*$rem/10 0 !important;
      }
      .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
        border-color: #d0bc7c;
        background-color: #d0bc7c;
      }
      .adm-checkbox .adm-checkbox-icon {
        color: #000;
      }

      span {
        font-size: 12*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .detail_bottom_bt {
      display: flex;
      flex-direction: row;
      position: relative;

      .shop_detail_price{
        font-family: PingFangSC-Medium;
        font-size: 2.4 *$rem;
        font-weight: normal;
        color: #333333;
      }
      .shop_detail_add_shop{
        width: 10 *$rem;
        height: 4 *$rem;
        border-radius: 45px;
        opacity: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #CEBF7E;
        font-family: PingFangSC-Medium;
        font-size: 1.6 *$rem;
        font-weight: normal;
        color: #614D1A;
        position: absolute;
        right: 12 *$rem;
        //display: inline-block;
      }
      .shop_detail_buy_shop{
        width: 11 *$rem;
        height: 4 *$rem;
        border-radius: 45px;
        opacity: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Medium;
        font-size: 1.6 *$rem;
        font-weight: normal;
        color: #614D1A;
        background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
      }

      .shop_detail_buy_shop_dis{
        width: 11 *$rem;
        height: 4 *$rem;
        border-radius: 45px;
        opacity: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Medium;
        font-size: 1.6 *$rem;
        font-weight: normal;
        color: #747474;
        background: #D7D7D7;
      }
    }

    .unsale {
      width: 201*$rem/10;
      height: 46*$rem/10;
      border-radius: 27*$rem/10 27*$rem/10 27*$rem/10 27*$rem/10;
      overflow: hidden;
      font-size: 16*$rem/10;

      .adm-button.adm-button-large {
        font-size: 16*$rem/10;
        height: 46*$rem/10;
        background-color: #868686;
        border: none;
      }

      .adm-button-disabled {
        opacity: 1;
        border-radius: 27*$rem/10;
      }
    }
    .sale_nocheck {
      width: 201*$rem/10;
      height: 46*$rem/10;
      border-radius: 27*$rem/10;
      overflow: hidden;
      font-size: 16*$rem/10;
      opacity: 0.5;

      .adm-button.adm-button-large {
        font-size: 16*$rem/10;
        height: 46*$rem/10;
        color: #614d1a;
        background: linear-gradient(90deg, #ccbd7b 0%, #f9ecb9 100%);
        border: none;
      }

      .adm-button-disabled {
        opacity: 1;
      }
    }

    .sale {
      width: 201*$rem/10;
      height: 46*$rem/10;
      border-radius: 27*$rem/10;
      overflow: hidden;
      font-size: 16*$rem/10;

      .adm-button.adm-button-large {
        font-size: 16*$rem/10;
        height: 46*$rem/10;
        color: #614d1a;
        background: linear-gradient(90deg, #ccbd7b 0%, #f9ecb9 100%);
        border: none;
      }

      .adm-button-disabled {
        opacity: 1;
      }
    }
  }

  .detail_bc {
    width: 100%;
    height: 45.75*$rem;
    background: no-repeat;
    background: url(~@/assets//img/ape-world/egg_bg_zhukv.png) no-repeat;
    background-size: 100% 100%;
  }

  .detail_banner {
    width: 92vw;
    position: absolute;
    top: 22*$rem;
    left: 50%;
    transform: translateX(-50%);

    .detail_main {
      width: 100%;
    }
  }
}

.detail_main {
  .content {
    padding-top: 20*$rem/10;
    width: 100%;
    background-color: #fff;
    border-radius: 20*$rem/10;
    margin-bottom: 20*$rem/10;
    overflow: hidden;
    &:nth-child(3) {
      padding-bottom: 2*$rem;
      .desc-img {
        padding: 0 .9*$rem;
        box-sizing: border-box;
      }
    }
    &:nth-child(4) {
      border-radius: 20*$rem/10 20*$rem/10 2*$rem 2*$rem;
      margin-bottom: 2*$rem;
    }
    &:nth-child(5) {
      border-radius: 2*$rem 2*$rem 20*$rem/10 20*$rem/10;
    }

    .content_title {
      font-size: 25*$rem/10;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding-left: 20*$rem/10;
      padding-bottom: 8*$rem/10;
      white-space: pre-wrap;
      line-height: 30*$rem/10;

      span {
        font-size: 25*$rem/10;
        font-family: PingFang SC-Light, PingFang SC;
        font-weight: 300;
        padding-bottom: 8*$rem/10;
        color: #333;
        padding: 0;
      }
    }

    p {
      font-size: 13*$rem/10;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #777777;
      padding: 0 20*$rem/10;
      // padding-bottom: 8*$rem/10;
    }
    .desc-img {
      margin-top: 3.5*$rem;
    }
  }

  img {
    width: 100%;
  }

  .content_opcity {
    background: #FFFFFF;
  }

  .content_img_center {
    width: calc(100% - 40*$rem/10);
    padding: 0 20*$rem/10 20*$rem/10;
    display: flex;
    justify-content: space-between;

    li {
      width: 18%;

      p {
        font-size: 12*$rem/10;
        text-align: center;
        margin-top: 8*$rem/10;
        padding: 0;
        color: #333;
      }
    }
  }
}

.content_lunbo {
  width: 100%;
  height: 178*$rem/10;
  overflow: hidden;
  padding-top: 12*$rem/10;
  padding-bottom: 33*$rem/10;

  .luobo_conter {
    width: 1200*$rem/10;
    height: 84*$rem/10;

    li {
      width: 84*$rem/10;
      height: 84*$rem/10;
      margin-right: 10*$rem/10;
      float: left;
      border-radius: 4*$rem/10;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .luobo_conter:nth-of-type(1) {
    margin-bottom: 10*$rem/10;
    animation: myfirst 25s infinite linear;
  }

  .luobo_conter:nth-of-type(2) {
    margin-bottom: 10*$rem/10;
    animation: mymiddle 25s infinite linear;
  }

  @keyframes myfirst {
    0% {
      margin-left: 0*$rem/10;
    }

    100% {
      margin-left: -564*$rem/10;
    }
  }

  @keyframes mymiddle {
    0% {
      margin-left: -47*$rem/10;
    }

    100% {
      margin-left: -611*$rem/10;
    }
  }
}

.content {
  .content_banner {
    padding: 0 20*$rem/10 1.5*$rem;
    width: calc(100% - 40*$rem/10);

    .content_display {
      width: 100%;
      height: 0;
      padding-top: 100%;
      border-radius: 10*$rem/10;
      margin-top: 7*$rem/10;
      overflow: hidden;

      //.stage {
      //  margin: auto;
      //  width: 180*$rem/10;
      //  height: 180*$rem/10;
      //  transform-style: preserve-3d;
      //  transform-origin: center center;
      //  /* 为了方便观察让3D舞台转动角度 */
      //  /* transform: rotateX(330deg) rotateY(-30deg) rotateZ(180deg); */
      //  transform: rotateX(-30deg) rotateY(60deg);
      //  animation: playaaa 20s infinite linear;
      //  margin-top: -79%;
      //
      //  .face {
      //    position: absolute;
      //    width: 100%;
      //  }
      //
      //  .front {
      //    /* 第一个面往前走 */
      //    transform: translateZ(90*$rem/10);
      //  }
      //
      //  .rear {
      //    /* 第二个面往后走 */
      //    transform: translateZ(-90*$rem/10) rotateY(180deg);
      //  }
      //
      //  .up {
      //    /* 先往上位移150*$rem/10  再绕着X轴转动90deg */
      //
      //    transform: translateY(-90*$rem/10) rotateX(90deg);
      //  }
      //
      //  .down {
      //    /* 先往下位移150*$rem/10,再绕着X轴转动90deg */
      //
      //    transform: translateY(90*$rem/10) rotateX(-90deg);
      //  }
      //
      //  .left {
      //    /* 先往左位移150*$rem/10 , 再绕着Y轴转动90deg */
      //    transform: translateX(-90*$rem/10) rotateY(-90deg);
      //  }
      //
      //  .rightface {
      //    /* 先往右侧位移150*$rem/10,再绕着Y轴转动90deg */
      //    transform: translateX(90*$rem/10) rotateY(90deg);
      //  }
      //}

      @keyframes playaaa {
        0% {
          transform: rotateX(-25deg) rotateY(60deg);
        }

        100% {
          transform: rotateX(-25deg) rotateY(420deg);
        }
      }
    }

    .display_mh {
      background-color: #e0c087;
    }

    .display_dc {
      background-color: #ede9e7;
    }

    .display_ss {
      background-color: #eeebda;
    }

    .display_cs {
      background-color: #9aacc6;
    }

    //.display_ape_world {
    //  //background: url('~@/assets/img/ape-world/egg_bg_xiangqingye.png') no-repeat;
    //  background-size: cover;
    //  background-color: yellow;
    //  img{
    //    width: 100px;
    //    height: 100px;
    //    background-color: red;
    //  }
    //}
    .ape_world_image{
        margin: auto;
        width: 100%;
        //height: 100%;
        height: 26 *$rem;
      object-fit: contain;
      //padding-left: 2 *$rem;
      //padding-right: 2 *$rem;
      //background-color: red;
    }

    .content_series {
      height: 15*$rem/10;
    }

    .mh_text {
      font-size: 13*$rem/10;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding: 0;
      padding-bottom: 15*$rem/10;
    }

    .content_text {
      text-align: left;
      font-size: 13*$rem/10;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      line-height: 18*$rem/10;

      span {
        color: #777;
        font-family: PingFang SC-Medium, PingFang SC;
        font-size: 12*$rem/10;
        display: inline-block;
        white-space: pre-wrap;
      }

      .label {
        font-family: PingFang SC-Medium, PingFang SC;
        font-size: 13*$rem/10;
        word-break: keep-all;
        word-wrap: nowrap;
        margin-right: 6*$rem/10;
        color: #333;
      }
    }

    .content_textlast {
      padding-bottom: 20*$rem/10;
    }
  }
}

.content {
  .content_spacebt {
    display: flex;
    width: calc(100% - 40*$rem/10);
    justify-content: space-between;
    margin: 0 20*$rem/10;
    border-top: 1*$rem/10 #f2f2f2 solid;
    padding-top: 15*$rem/10;
    padding-bottom: 15*$rem/10;

    span:nth-of-type(1) {
      font-family: PingFang SC-Regular, PingFang SC;
      font-size: 14*$rem/10;
      color: #333;
      .receive_tip {
        color: #A1A1A1;
        font-size: 1.2*$rem;
      }
    }

    span:nth-of-type(2) {
      font-family: PingFang SC-Midum, PingFang SC;
      font-size: 14*$rem/10;
      color: #333;
      font-weight: 500;
      text-align: right;

      p {
        font-size: 12*$rem/10;
        color: #333;
        font-weight: 500;
        padding: 0;
      }
    }

    // .with_background {
    //   padding: 3*$rem/10 10*$rem/10;
    //   background: linear-gradient(90deg, #c7b270 0%, #f8ebb7 100%);
    //   border-radius: 13*$rem/10;
    // }

    .content_home {
      font-family: PingFang SC-Regular, PingFang SC;
      font-size: 14*$rem/10;
      color: #333;
      font-weight: 500;
      display: flex;

      div {
        width: 20*$rem/10;
        height: 20*$rem/10;
        background: url(~@/assets//img/lvdi.png);
        background-size: 20*$rem/10 20*$rem/10;
      }
    }
  }

  .content_spacebt:nth-child(2) {
    border: none;
  }

  .content_radio {
    display: block;
    margin-bottom: 10*$rem/10;
  }
}

.content_space {
  width: 100%;
  height: 100*$rem/10;
}

.ship {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #312729;
}
