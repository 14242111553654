$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.home-page {
  background: #f8f8f8;
  .home_bg {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .tab_sticky {
    width: 100%;
  }

  .adm-tabs-header {
    border: none;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    background-color: #f8f8f8;
  }

  .adm-tabs-tab-list {
    padding: 2vh 4vw 0;
  }

  .adm-tabs-tab {
    font-size: 20px;
    font-weight: 400;
    color: #969696;
    line-height: 23px;
    font-family: PingFang SC-Regular, PingFang SC;
  }

  .adm-tabs-tab-active {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    font-family: PingFang SC-Semibold, PingFang SC;
  }

  .adm-tabs-tab-line {
    height: 0;
  }

  .adm-swiper-slide {
    // height: calc(98vh - 44px);
    height: auto;
    // overflow-y: scroll;
    scrollbar-width: 0;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      width: 0;
    }
  }
  .adm-pull-to-refresh-content {
    padding: 0;
  }
}
