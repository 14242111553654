$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.nft_setting_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .item_container {
    width: 86%;
    padding: 1.4 *$rem 1 *$rem;
    background: white;
    margin-top: 2 *$rem;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .item_title {
    font-family: PingFang SC;
    font-size: 1.4 *$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #000000;
  }

  .item_right {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    .item_right_type_0 {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      p {
        opacity: 0.5;
        font-family: PingFang SC;
        font-size: 1.4 *$rem;
        font-weight: normal;
        color: #000000;
        padding-right: 0.5 *$rem;
      }

      img {
        width: 13px;
        height: 13px;
      }
    }

    .switch_item {
      width: 4.4 *$rem;
      height: 2.4 *$rem;
    }
  }
}

.dialog_container {
  padding: 0 *$rem 1.5 *$rem 0.6 *$rem;

  .dialog_title {
    font-family: PingFangSC-Medium;
    font-size: 1.6 *$rem;
    font-weight: normal;
    color: #333333;
  }

  .dialog_line {
    width: 100%;
    height: 1px;
    margin-top: 1.5 *$rem;
    opacity: 0.1;
    background: #000000;
  }

  .dialog_des {
    font-family: PingFangSC-Regular;
    font-size: 1.5 *$rem;
    font-weight: normal;
    color: #333333;
    margin-top: 1 *$rem;
  }

  .dialog_btn_container {
    width: 100%;
    height: 4*$rem;
    margin-top: 3 *$rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .dialog_cancel_btn {
      width: 9.8 *$rem;
      height: 100%;
      border-radius: 40px;
      box-sizing: border-box;
      border: 1.6px solid #DFCFAB;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #CDAC5B;
      font-size: 1.6 *$rem;

      &.btnBg{
        background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
        color: #624C16;
        border: 0.1px solid #DFCFAB;
      }
    }
  }
}
