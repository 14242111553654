@function to($px) {
  @return ($px / 2558) * 100vw;
}

.module_view {
  width: to(1595);
  height: to(940);
  background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
  background-size: 100% 100%;
  font-family: YueGenYouShangYueBoHei;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: to(52) to(103) 0;

    > span {
      &:nth-child(1) {
        width: to(102);
      }
      &:nth-child(2) {
        font-size: to(57);
        font-weight: bold;
        color: #602401;
      }
    }

    > img {
      width: to(102);
      height: to(102);
    }
  }

  &_content {
    padding: to(45) to(170) 0 to(145);
  }
}