$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.synthesis-record {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    .synthesis-record-warper {
        background: rgb(18, 48, 125);
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: scroll;
        .synthesis-record-item {
            background: #FFFFFF;
            border-radius: 1*$rem;
            margin: 0 1.65*$rem 1*$rem 1.65*$rem;
            padding: 1.4*$rem 1.4*$rem;

            &:first-child {
                margin-top: 2.15*$rem;
            }

            .card-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 0.5px solid #D8D8D8;
                padding: 0.5*$rem 0 1*$rem 0;
                margin-bottom: 1*$rem;
                &>.left {
                    display: flex;
                    flex-direction: row;
                    column-gap: 1*$rem;
                    font-family: PingFang SC;
                    font-size: 1.6*$rem;
                    font-weight: 600;
                    color: #333333;
                    width: 25.5*$rem;
                    &>.img {
                        width: 5*$rem;
                        height: 5*$rem;
                        border-radius: 0.5*$rem;
                        opacity: 1;
                        background: #979797;
                        box-sizing: border-box;
                        border: 1px solid #979797;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &>.info {
                        width: calc(100% - 6*$rem);
                        display: block;
                        &>.name{
                            position: relative;
                            width: calc(100% - 1px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        &>.label {
                            width: 4.65*$rem;
                            height: 1.6*$rem;
                            border-radius: 10*$rem;
                            opacity: 1;
                            color: #844000;
                            background: #FDD015;
                            font-family: PingFang SC;
                            font-size: 1.2*$rem;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 1.15*$rem;
                        }
                    }
                }

                &>.right {
                    width: 5.5*$rem;
                    font-family: PingFang SC;
                    font-size: 3*$rem;
                    font-weight: 600;
                    color: #2D74EE;
                    text-align: right;
                }
            }

            .card-body {
                color: #999999;
                font-weight: normal;
                font-size: 1.2*$rem;
                padding:1.4*$rem 0 0 0;
                &>.info {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 1.6*$rem;
                    &>.item_box{
                        flex:1;
                        display: flex;
                        flex-direction: column;
                        row-gap: 0.95*$rem;
                        text-align: right;
                        &>.item {
                            display: flex;
                            align-items: center;
                            font-family: PingFang SC;
                            font-size: 1.2*$rem;
                            font-weight: 600;
                            justify-content: flex-end;
                            gap:0.4*$rem;
                            /* 正文色/正文色 */
                            color: #1A1A1A;
                            .name{
                                width: 80%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    
                }
            }
        }
    }
}