$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.refresh {
  height: 100%;
  .adm-pull-to-refresh-content {
    width: 100vw;
    box-sizing: border-box;
    padding: 2vw 4vw;
    height: 100%;
  }
  .adm-pull-to-refresh {
    height: 100%;
  }
}
