@import '@/assets/scss/variable.scss';
.home {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 99.35*$rem;
    background: url("~@/assets/img/partsBlindbox/bg_main.png") no-repeat center center / 100% 100%;
    overflow-x: hidden;
    .back {
        position: absolute;
        top: 2.5*$rem;

        @media screen and (min-height: 780px) {
            top: 5.5*$rem;
        }

        left: 1.4*$rem;
        width: 1*$rem;
        height: 1.8*$rem;
        background: url("~@/assets/img/icon/back.png") no-repeat center center / 100% 100%;
    }
    .right_btns {
        position: absolute;
        top: 8.5*$rem;
        right: 0;
        display: flex;
        flex-direction: column;
        @mixin common_Btn {
            width: 2.1*$rem;
            height: 4.1*$rem;
            border-radius: 0.5*$rem 0px 0px 0.5*$rem;
            background: rgba(0, 38, 191, 0.6);
            color: #FFFFFF;
            font-size: 1.2*$rem;
            font-weight: bold;
            writing-mode: vertical-lr;
            text-align: center;
            line-height: 2.2*$rem;
    
            &:active {
                background: rgba(0, 38, 191, 0.8);
            }
        }
        .activity_rules,
        .real_name {
            margin-bottom: 1.2*$rem;
            @include common_Btn();
        }
    }
    
    .wraper {
        display: flex;
        flex-direction: column;
        padding-top: 7*$rem;
        .titleImg {
            width: 25.3*$rem;
            height: 14.1*$rem;
            margin: 0 auto;
            background: url("~@/assets/img/partsBlindbox/titleImg.png") no-repeat center center / 100% 100%
        }
        
        .apeWorld {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            .apeWorldImg {
                position: relative;
                margin-top: -2*$rem;
                .apeimg {
                    width: 23.6*$rem;
                    height: 23*$rem;
                }
                .apetag {
                    position: absolute;
                    top: 4*$rem;
                    right: -4.5*$rem;
                    width: 9.65*$rem;
                    height: 8.5*$rem;
                }
            }
            .buy_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 13.9*$rem;
                height: 4.8*$rem;
                margin-top: 6*$rem;
                margin-bottom: 1.5*$rem;
                background: url("~@/assets/img/partsBlindbox/buy_btn.png") no-repeat center center / 100% 100%;
                &:active {
                    opacity: 0.8;
                }
                > div {
                    color: #3146BD;
                    font-size: 2.2*$rem;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }
        .cardObjcs {
            display: flex;
            flex-direction: row;
            padding: 1.5*$rem 2.5*$rem;
            .itemCard {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 14.5*$rem;
                background: rgba(156, 220, 255, 0.6);
                border: 1px solid #FFFFFF;
                border-radius: 0.7*$rem;
                padding: 0.9*$rem 1.0*$rem;
                &:first-child {
                    margin-right: 3*$rem;
                }
                >img {
                    width: 10.1*$rem;
                    height: 10.1*$rem;
                    object-fit: contain;
                }
                >div {
                    font-size: 1.55*$rem;
                    font-weight: 600;
                    color: #2D44B6;
                    line-height: 2.2*$rem;
                    margin:0.3*$rem 0;
                }
                >span {
                    font-size: 1.1*$rem;
                    line-height: 1.5*$rem;
                    color: #2D44B6;
                    white-space: wrap;
                }
            }
            
        }
        .actions {
            display: flex;
            flex-direction: column;
            .card_h {
                display: flex;
                flex-direction: row;
                align-items: center;
                background: rgba(156, 220, 255, 0.6);
                border: 1px solid #FFFFFF;
                border-radius: 0.7*$rem;
                margin: 0.9*$rem 2.5*$rem;
                padding: 0.8*$rem 1*$rem 0.8*$rem 1.3*$rem;
                .leftBox {
                    flex: 1;
                    flex-direction: column;
                    >span {
                        color: #1003FF;
                        font-size: 1.7*$rem;
                        font-weight: 600;
                    }
                    >div {
                        color: #2D44B6;
                        font-size: 1.2*$rem;
                        padding-top: 0.5*$rem;
                    }
                }
                .rightBox {
                    >img {
                        width: 5.53*$rem;
                        height: 5.53*$rem;
                        object-fit: contain;
                        &.enable {
                            animation: scaleLoop 2s ease-in-out infinite;
                            &.enable1 {
                                animation-delay: 0.5s;
                            }
                            @keyframes scaleLoop {
                                0%, 100% {  transform: scale(0.9); }
                                50% { transform: scale(1.1); }
                              }

                            &:active {
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
        
    }

}