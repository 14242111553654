$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}
.land-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__header {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: to(200) 0 to(160);
        .star-box{
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 0 to(34);
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                transform: scale(1.0);
                .item__icon {
                    width: to(470);
                    height: to(470);
                    border-radius: 50%;
                    margin: 0 to(128);
                    background: #D8D8D8;
                }
                .item__title {
                    font-size: to(40);
                    margin-top: to(58);
                    color: #9E9E9E;
                }
                &--active {
                    transition: transform 0.3s;
                    transform: scale(1.1);
                    .item__title {
                        color: #50FFF9;
                        font-size: to(48);
                    }
                }
                
            }
        }
        .enter-btn {
            width: to(540);
            height: to(110);
            border-radius: to(55);
            line-height: to(110);
            text-align: center;
            background: linear-gradient(0deg, #6FBF19 5%, #AEF172 110%);
            color: #FFFFFF;
            font-weight: bold;
            font-size: to(50);
            &:active {
                opacity: 0.8;
            }
            &.disabled {
                background: linear-gradient(128deg, #CDCDCD 28%, #B6B6B6 59%);
                &:active {
                    opacity: 1;
                }
            }
        }
        
    }
}