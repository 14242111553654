$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.sessioncpt-not-start-component {
  width: 100%;

  .session_container {
    width: 96%;
    min-height: 80px;
    margin: 2 *$rem auto;
    position: relative;

    .pk_msg_container {
      background: url(../../imgs/pic_time.png) no-repeat;
      background-size: 100% 100%;
      width: 16.9 *$rem;
      height: 6 *$rem;
      object-fit: cover;
      position: relative;
      z-index: 1;

      &.long {
        width: 20 *$rem;
      }

      .session_count {
        display: inline-block;
        font-size: 2.5 *$rem;
        color: white;
        margin-left: 1 *$rem;
        margin-top: 0.3 *$rem;
        font-family: PangMenZhengDao;
      }

      .session_count_0 {
        display: inline-block;
        font-family: 思源黑体;
        font-size: 1.2 *$rem;
        font-weight: bold;
        color: #FFFFFF;
        margin-left: 1.0 *$rem;
        font-weight: bold;
      }

      .countDown {
        width: 82%;
        margin-top: 0.3 *$rem;
      }
    }


    .session_info {
      width: 100%;
      //min-height: 15 *$rem;
      border-radius: 20px;
      border: 0.1px solid transparent;
      margin-top: -3 *$rem;
      background: linear-gradient(121deg, #38A7FF -18%, #2B61F5 81%);

      .session_info_container {
        margin: 5px;
        //padding-bottom: 1 *$rem;
        border-radius: 20px;
        border: 2px solid #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .not_start_title {
          width: 90%;
          font-family: PingFangSC-Medium;
          font-size: 1.8 *$rem;
          font-weight: normal;
          //line-height: 44.44px;
          text-align: center;
          color: #FFFFFF;
          margin-top: 4 *$rem;
          margin-bottom: 4 *$rem;
        }

        .proportion_container {
          width: 85%;
          margin-top: 1 *$rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          img {
            width: 2.5 *$rem;
            height: 2.5 *$rem;
          }
        }
      }
    }
  }
}
