@import '@/assets/scss/variable.scss';

.activity_order_page {
  height: 100vh;
  background: #EEECF3;
  display: flex;
  flex-direction: column;

  .body {
    display: flex;
    flex: 1;
    flex-direction: column;

    .address_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #ffffff;
      border-radius: 0.6*$rem;
      margin: 1.5*$rem;

      > img {
        width: 3.3*$rem;
        margin-left: 1.5*$rem;
      }

      .address_center {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 2*$rem 1*$rem 2*$rem 0.8*$rem;

        .user_info {
          display: flex;
          flex-direction: row;
          align-items: flex-end;

          :nth-child(1) {
            max-width: 115px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #333;
            font-size: 1.6*$rem;
            font-family: PingFangSC-Medium;
          }

          :nth-child(2) {
            color: #333;
            font-size: 1.2*$rem;
            padding: 0 6px;
          }

          :nth-child(3) {
            background: #323232;
            color: #DFC197;
            font-size: 12px;
            line-height: 15px;
            height: 15px;
            padding: 0 5px;
            font-family: PingFang-SC-Medium;
            border-radius: 1px;
          }
        }

        .address_info {
          color: #333;
          font-size: 1.3*$rem;
          line-height: 18px;
          padding: 0.6*$rem 0;
          white-space: pre-wrap;
        }
      }

      .none_address {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 2*$rem 1*$rem 2*$rem 0.8*$rem;
        color: #333;
        font-size: 1.3*$rem;
      }

      .right_arrow {
        display: flex;
        align-items: center;
        height: 80px;
        margin-right: 1*$rem;

        > img {
          height: 1.3*$rem;
        }
      }

    }

    .shop_item {
      padding: 2*$rem 1.5*$rem;
      background-color: #ffffff;
      border-radius: 0.6*$rem;
      margin: 0 1.5*$rem;

      .item_top {
        display: flex;
        flex-direction: row;

        .left {
          padding-bottom: 2*$rem;

          > img {
            background-color: #f8f8f8;
            width: 8*$rem;
            height: 8*$rem;
          }
        }

        .right {
          flex: 1;
          margin-left: 1.5*$rem;

          > span {
            font-size: 1.6*$rem;
            font-weight: bold;
            overflow: hidden;
            line-height: 2.2*$rem;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }

        .suipian_container {
          width: 100%;
          height: 3 *$rem;
          margin-top: 2 *$rem;
          .bottomZS {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 0.8 *$rem;

            > img {
              width: 1.6 *$rem;
            }

            > p {
              font-family: PingFangSC-Medium;
              font-size: 2 *$rem;
              color: #333333;
              margin-left: 0.5 *$rem;
            }
          }
        }

      }

      .item_bottom {
        font-size: 1.4*$rem;
        color: #666666;
        display: flex;
        justify-content: space-between;
        padding-top: 2*$rem;
        border-top: 1px solid #E9E9E9;

        > span {
          color: #333333;
        }
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;

    .button {
      margin: 1.5*$rem 2.5*$rem;
      background-image: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
      border: none 0px;
      color: #624C16;
      font-size: 1.8*$rem;
      font-weight: 400;
      height: 4.4*$rem;
      border-radius: 2.2*$rem;
      line-height: 4.4*$rem;
      text-align: center;

      &:active {
        opacity: 0.8;
      }

      &.disable {
        opacity: 0.6;
      }
    }

    @media screen and (min-height: 780px) {
      padding-bottom: 1.5*$rem;
    }

  }
}

.confirm_modal_mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 1000;

  .modal_body {
    position: relative;
    background-color: #ffffff;
    padding: 2*$rem;
    //width: 32*$rem;
    width: 80%;
    border-radius: 0.6*$rem;
    color: #333333;

    .modal_header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-bottom: 1*$rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .modal_header_title {
        font-size: 1.6*$rem;
        font-weight: 500;
        text-align: center;
      }

      > img {
        position: absolute;
        right: 1.5*$rem;
        top: 1.5*$rem;
        object-fit: contain;
        width: 4*$rem;
        height: 2.5*$rem;

      }
    }

    .modal_content {
      display: flex;
      flex-direction: column;

      .notice_info {
        margin-top: 1.3*$rem;
        font-size: 1.4*$rem;
        font-weight: bold;
        line-height: 2.1*$rem;
      }

      .confirm_info {
        margin-top: 2*$rem;
        font-size: 1.4*$rem;

        > div {
          margin-bottom: 1*$rem;
          display: flex;
          flex-direction: row;

          > span {
            line-height: 2*$rem;

            &:nth-child(1) {
              color: #999999;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .modal_footer {
      display: flex;
      flex-direction: row;
      margin-top: 2*$rem;

      > div {
        height: 4*$rem;
        line-height: 4*$rem;
        border-radius: 2*$rem;
        flex: 1;
        text-align: center;
        font-size: 1.7*$rem;

        &:nth-child(1) {
          border: 1px solid #DFCFAB;
          background-color: #ffffff;
          color: #CDAC5B;
          margin-right: 1.5*$rem;
        }

        &:nth-child(2) {
          margin-left: 1.5*$rem;
          border: none 0px;
          background-image: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
        }
      }

    }
  }
}
