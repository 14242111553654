@import '@/assets/scss/variable.scss';
.box_to_stone {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background: linear-gradient(180deg, rgba(156, 157, 232, 0) 0%, #9A9BEE 33%);
    .back {
        position: absolute;
        top: 2.5*$rem;
        z-index: 9;
        @media screen and (min-height: 780px) {
            top: 5.5*$rem;
        }

        left: 1.5*$rem;
        width: 1*$rem;
        height: 1.8*$rem;
        background: url("~@/assets/img/icon/icon_back_black.png") no-repeat center center / 100% 100%;
    }
    .top_bg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-bottom: 14.7*$rem;
        width: 100vw;
        height: 82.1*$rem;
        background: url("~@/assets/img/boxToStone/bg.png") no-repeat center center / 100% 100%;
        .right_btns {
            position: absolute;
            top: 8.5*$rem;
            right: 0;
            display: flex;
            flex-direction: column;
            .rightBtn {
                width: 2.1*$rem;
                padding: 0.6*$rem 0;
                border-radius: 0.5*$rem 0px 0px 0.5*$rem;
                background: rgba(54, 0, 191, 0.6);
                color: #FFFFFF;
                font-size: 1.2*$rem;
                font-weight: bold;
                text-align: center;
                line-height: 1.4*$rem;
                margin-bottom: 1.2*$rem;
                &:active {
                    opacity: 0.8;
                }
            }
            
        }
        .price {
            color: #FF516B;
            font-weight: bold;
            font-size: 1.5*$rem;
            margin-bottom: 20*$rem;
            padding-right: 3.4*$rem;
            align-self: flex-end;
            width: 7*$rem;
            text-align: center;
            box-sizing: content-box;
            span {
                font-size: 3*$rem;
            }
        }
        .buy_button {
            width: 29.4*$rem;
            height: 5.5*$rem;
            margin: 0 auto 12.6*$rem;
            background: url("~@/assets/img/boxToStone/button_buy.png") no-repeat center center / 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 2.5*$rem;
            font-weight: 700;
            &:active {
                opacity: 0.8;
            }
        }
    }
    .grids {
        display: flex;
        flex-wrap: wrap;
        margin-top: -18*$rem;
        width: 100vw;
        padding: 0 1.5*$rem;
        .grid_item {
            position: relative;
            width: 10.5*$rem;
            height: 10*$rem;
            // background: linear-gradient(226deg, #9E7CE9 -1%, #6159DB 100%);
            margin: 0.5*$rem;
            img {
                width: 100%;
                height: 100%;
            }
            div {
                position: absolute;
                left: 0;
                bottom: 0.5*$rem;
                right: 0;
                text-align: center;
                color: #FFFFFF;
                font-weight: 600;
                font-size: 1.4*$rem;
            }
        }
    }
    .buttomDesc {
        height: 5*$rem;
        width: 100vw;
        object-fit: contain;
        margin: 2*$rem auto;
    }
    .bottom_logo {
        margin-top: 5*$rem;
        margin-bottom: 3.5*$rem;
        img {
            height: 3.5*$rem;
            margin: 0 auto;
        }
    }
}