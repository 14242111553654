$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@mixin commonBtnStyle {
  //width: setRem(243);
  //height: setRem(90);
  font-family: PingFang SC;
  font-size: setRem(30);
  font-weight: 500;
  line-height: setRem(36);
  text-align: center;
  letter-spacing: 0;
  color: #614D1A;
  flex: 1;
}
.modalContainer-compound {
  display: flex;
  align-items: center;
  justify-content: center;
  .box-compound {
    width: setRem(558);
    min-height: setRem(433);
    border-radius: setRem(20);
    background: #FFFFFF;
    padding: setRem(44) setRem(32) 0 setRem(44);
    .title-compound {
      font-family: PingFang SC;
      font-size: setRem(32);
      font-weight: 600;
      line-height: setRem(38);
      text-align: center;
      letter-spacing: 0;
      color: #333333;
    }
    .content-compound {
      margin-top: setRem(30);
      margin-bottom: setRem(40);
      word-break: break-word;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .one-text1-box {
        margin-top: setRem(70);
        margin-bottom: setRem(94);
      }
      .text1-compound {
        font-family: PingFang SC;
        font-size: setRem(28);
        font-weight: normal;
        line-height: setRem(34);
        letter-spacing: 0;
        color: #333333;
      }
      .text2-compound {
        font-family: PingFang SC;
        font-size: setRem(24);
        font-weight: normal;
        line-height: setRem(34);
        text-align: center;
        letter-spacing: 0;
        color: #737373;
        margin-bottom: setRem(70);
      }
      .text3-compound {
        font-family: PingFang SC;
        font-size: setRem(32);
        font-weight: 600;
        line-height: setRem(38);
        text-align: center;
        letter-spacing: 0;
        color: #333333;
      }
      .centerImg-compound {
        width: setRem(288);
        height: setRem(288);
        margin-bottom: setRem(35);
      }
    }
    .btnBox-compound {
      height: setRem(108);
      border-top: 1px solid #D8D8D8;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .btnFlex {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cancelBtn-compound {
        @include commonBtnStyle;
        color: #999999;
      }
      .confirmBtn-compound {
        @include commonBtnStyle;
        color: #CAA846;
      }
      .divider-compound {
        background: #D8D8D8;
        width: setRem(1);
        height: setRem(60);
      }
    }
  }
}