@import '@/assets/scss/variable.scss';



.popup {
  --border-radius: 1rem; // 40px
  color: #000;
  :global {
    .adm-popup-body {
      border-top-left-radius: px2rem(40);
      border-top-right-radius: px2rem(40);
      width: 100%;
      box-sizing: border-box;
      padding: px2rem(43) px2rem(35) px2rem(90);
    }
  }

  .descText {
    font-size: px2rem(32);
  }
  .content {
    display: flex;
    padding: px2rem(40) 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D8D8D8;
    > img {
      width: px2rem(88);
      height: px2rem(88);
    }
    .center {
      margin-left: px2rem(28);
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: px2rem(90);
      justify-content: space-between;
    }
    .labelList {
      > span {
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
        padding: px2rem(9) px2rem(12);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: px2rem(80);
        transform: scale(0.9);
        font-size: 1.1 *$rem;
        &:not(:first-of-type) {
          margin-left: px2rem(27);
        }
      }
    }
    .strong {
      font-size: px2rem(24);
      margin-bottom: px2rem(12);
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px2rem(40) 0;
    .btn {
      border: none;
      outline: none;
      color: #624C16;
      font-size: px2rem(28);
      padding: px2rem(14) px2rem(46);
      border-radius: px2rem(40);
      background: linear-gradient(104deg, #D4BA77 4%, #FFEAB4 97%);
    }
  }
}
