$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.technology-info {
    background: #ffffff;
    border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    opacity: 1;
    padding: 15*$rem/10 15*$rem/10 17*$rem/10;
    margin-bottom: 15*$rem/10;

    .head {
        display: flex;

        .bar {
            flex: 1;
            font-size: 15*$rem/10;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 600;
            color: #333333;
        }

        .icon {
            flex: 0 0 10*$rem/10;
            float: right;
            margin-top: 5*$rem/10;

            img {
                width: 10*$rem/10;
                height: 10*$rem/10;
            }
        }
    }

    .detail-message {
        padding-top: 16*$rem/10;

        word-wrap: break-word;
        word-break: normal;

        .title {
            font-size: 13*$rem/10;
            font-family: PingFang SC-Regular, PingFang SC;
            color: #333333;
        }

        .detail {
            word-break: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 169*$rem/10;
            float: right;
            font-size: 13*$rem/10;
            font-family: PingFang SC-Regular, PingFang SC;
            color: #333333;
        }
    }

    .adm-popup-body-position-bottom {
        padding: 15*$rem/10 4vw 31*$rem/10;
        .technical-information {
            text-align: center;
            display: flex;

            .title {
                flex: 1;
                font-size: 15*$rem/10;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
                padding-bottom: 24*$rem/10;
            }

            .icon {
                float: right;

                img {
                    flex: 0 0 30*$rem/10;
                    width: 24*$rem/10;
                    height: 24*$rem/10;
                }
            }
        }

        .piece {
            padding-bottom: 30*$rem/10;

            .id {
                font-size: 15*$rem/10;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
            }

            .intro {
                padding: 10*$rem/10 0;
                font-size: 13*$rem/10;
                font-family: PingFang SC-Regular, PingFang SC;
                color: #909090;
            }

            .detail {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                word-wrap: break-word;
                word-break: break-all;
                font-size: 13*$rem/10;
                font-family: PingFang SC-Regular, PingFang SC;
                color: #909090;
                line-height: 15*$rem/10;
                >span {
                    display: contents;
                }
                .copy_icon {
                    width: 14*$rem/10;
                    height: 14*$rem/10;
                    margin-left: 4*$rem/10;
                    display: inline-block;
                }
            }
        }
    }

}