$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.unity-acratch-success-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .mask-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50*$rem;
    height: 24.2*$rem;
    background: rgba(255, 255, 255, .5);
    border-radius: 0.75*$rem;
    .mask-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 47.9*$rem;
      height: 23.2*$rem;
      box-sizing: border-box;
      background: linear-gradient(116deg, #D89FFF 0%, #89B6FF 51%, #C299FF 100%);
      background-size: 100% 100%;
      border-radius: 0.75*$rem;
      .title {
        margin-top: 2*$rem;
        font-size: 1.4*$rem;
        color: #FFFFFF;
        font-weight: bold;
      }
      .points-content {
        text-align: center;
        .ape-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          > span {
            margin: 1.2*$rem 0;
            color: #FFFFFF;
            font-size: 1*$rem;
          }
          > img {
            margin-bottom: 1.5*$rem;
            width: 8*$rem;
            height: 8*$rem;
          }
        }
        .points {
          margin-top: 1.2*$rem;
          font-size: 1*$rem;
          color: #FFFFFF;
        }
        .points-prize {
          margin: 1.8*$rem 0 2.1*$rem;
          width: 8.8*$rem;
          height: 6.6*$rem;
        }
        .ape-prize {
          margin: 1.1*$rem 0 1.5*$rem;
          width: 8*$rem;
          height: 8*$rem;
          border-radius: 0.5*$rem;
        }
      }
      .btn-box {
        display: flex;
      }
      .confirm-btn {
        margin-right: 4.1*$rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8.2*$rem;
        height: 3.35*$rem;
        border-radius: 2*$rem;
        color: #004FC4;
        font-size: 1.35*$rem;
        background: #D3F1FF;
        font-weight: 500;
      }
      .address-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8.2*$rem;
        height: 3.35*$rem;
        border-radius: 2*$rem;
        color: #4F0037;
        font-size: 1.35*$rem;
        font-weight: 500;
        background: linear-gradient(270deg, #83FFEE 0%, #FFE97D 100%);
      }
    }
  }
}