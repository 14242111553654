$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}

.land-game-work {
    display: flex;
    height: 100%;
    background: #f1d7ae;
    
    &>div {
        box-sizing: border-box;

        &:nth-child(1) {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            height: 100%;
            padding: to(30) to(45);
            box-sizing: border-box;

            span {
                position: absolute;
                top: to(30);
                left: to(45);
                font-size: to(28);
                font-weight: 500;
                color: #9e7b36;
            }
        }

        &:nth-child(3) {
            width: to(700);
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: to(60) to(30);
            // margin-right: to(50);
            box-sizing: border-box;

            h1 {
                font-family: YueGenYouShangYueBoHei;
                font-size: to(43);
                font-weight: bold;
                text-align: center;
                color: #27140d;
                margin-bottom: to(63);
            }

            .text-h6 {
                font-size: to(32);
                font-weight: 600;
                color: #674110;
                margin-left: to(20);
                &.mode2 {
                    color: #FFFFFF;
                }
            }
        }
    }
    &.mode2 {
        background: #B0A3C4;
        &>div:nth-child(1) {
            span {
                color: #FFFFFF;
            }
        }
    }

    .land-game-divide {
        position: absolute;
        right: to(720);
        width: to(2);
        height: calc(100% - #{to(56)});
        margin: to(28) 0;

        &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: white;
        }

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            transform: translateX(200%);
            background-color: #e1c292;
        }
    }

    .land-game-tabx {
        width: to(600);
        height: to(88);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 to(20);
        font-size: to(24);
        font-weight: 500;
        background: rgba(255, 240, 211, 0.4);
        border-radius: to(10);
        overflow-x: auto;
        box-sizing: border-box;
        margin-bottom: to(40);
        &::-webkit-scrollbar {
            height: 0;
        }
        &.mode2 {
            background: rgba(240, 211, 255, 0.4);
        }
       
        &>span {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 to(22);
            // width: to(128);
            height: to(60);
            border-radius: to(10);
            font-size: to(28);
            &.active {
                color: #dc7c00;
                background: #fff0d3;
                &.mode2 {
                    background: #F0D3FF;
                }
            }
        }
    }

    .land-flex-vmiddle {
        display: flex;
        align-items: center;

        &>span {
            min-width: to(100);
            text-align: left;
        }
    }

    .land-flex-hmiddle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .exp-num {
            margin-bottom: to(22);
            color: #BB6410;
            font-size: to(31);
        }
    }
}