@import '@/assets/scss/variable.scss';
.up_exchange {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(153deg, #EEF7FF 0%, #94BEF9 100%);
    :global {
        .nft-header .container {
            background: #FFFFFF;
        }
    }
    .description {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        font-size: 1.6*$rem;
        padding: 1.7*$rem 2*$rem;
        > div {
            &:last-child {
                color: #FF0000;
                font-weight: 600;
                font-size: 1.2*$rem;
                padding: 0 1.6*$rem;
            }
        }
    }
    .tabs {
        display: flex;
        flex-direction: row;
        padding: 0 1.8*$rem;
        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.75*$rem;
            border-radius: 0.5*$rem;
            background: #FFFFFF;
            color: #000000;
            font-size: 1.2*$rem;
            font-weight: 500;
            margin-right: 2.6*$rem;
            padding: 0 1.5*$rem;
            &.tab_active {
                background: #B9DCFF;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
            }
            
        }
    }
    
    .up_exchange_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-top: 2*$rem;
        overflow: hidden;
       :global(.ant-list-view) {
            padding: 0 1.5*$rem 1*$rem;
        }
        .collection_item {
            display: inline-block;
            margin: 0 0.45*$rem 1*$rem;
            .item_content {
                position: relative;
                display: flex;
                flex-direction: column;
                .item_img {
                    position: relative;
                    background: transparent;
                    padding: 0.2*$rem;
                    border-radius: 0.5*$rem;
                    overflow: hidden;
                    width: 7.7*$rem;
                    height: 7.7*$rem;
                    box-sizing: border-box;
                    &.active {
                        background: url(~@/assets/img/collectionRecoveryLand/kuang.png);
                        background-size: 7.7*$rem 7.7*$rem;
                    }
                    
                    img {
                        background: #FFFFFF;
                        border-radius: 0.5*$rem;
                        width: 100%;
                        height: 100%;
                    }
                    
                }
                .select_icon {
                    position: absolute;
                    width: 1.25*$rem;
                    height: 1.25*$rem;
                    top: 0.5*$rem;
                    left: 0.75*$rem;
                }
                span {
                    width: 7.7*$rem;
                    line-height: 2*$rem;
                    color: #FFFFFF;
                    font-size: 1.2*$rem;
                    font-weight: 500;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            
        }
    }
    .bottom_view {
        display: flex;
        padding: 3*$rem 0;
        @mixin out_mx {
            height: 4*$rem;
            width: 25.9*$rem;
            border-radius: 2*$rem;
            font-size: 1.5*$rem;
            font-weight: 700;
            color: #FFFFFF;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        @mixin in_mx {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            height: 3.5*$rem;
            width: 23.5*$rem;
            border-radius: 2*$rem;
            >span {
                font-size: 1.0*$rem;
                margin-top: -0.1*$rem;
            }
        }
        .outBtn {
            background: linear-gradient(270deg, #1150FF 0%, #47FFF9 100%);
            @include out_mx();
            &:active {
                opacity: 0.8;
            }
            .inBtn {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 99%);
                @include in_mx();
            }
        }
        .disOutBtn {
            background: #B8BBC2;
            @include out_mx();
            .disInBtn {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 99%);
                @include in_mx();
            }
        }
    }
    :global {
        .adm-modal-body {
            .adm-modal-content {
                .alert-body {
                    .alert-content {
                        text-align: center;
                        color: #9E9E9E;
                    }
                }
            }
            
        }
        
    }
}