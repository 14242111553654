$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.nft-search-page {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;

  .top {
    background: url('~@/assets/img/collection/search_collection_bg.png') no-repeat;
    background-size: 100% 100%;
    height: 27.1*$rem;
    //background-color: #4E52FF;
    width: 100%;
    box-sizing: border-box;
    }
  .ListContainer {
    flex: 1;
    display: flex;
    width: 100%;
    margin-top: setRem(-350);
    overflow: hidden;
    flex-direction: row;
    @media screen and (min-height: 780px) {
      &{
        margin-top: setRem(-360);
      }
    }

    .leftList {
      overflow: auto;
      background: #FFFFFF;
      .list-view-body {
        //margin: auto;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        background: #b40d0d;
      }
      .leftItemContainerSelected {
        flex: 1;
        flex-direction: row;
        display: flex;
        align-items: center;
        height: setRem(100);
        width: setRem(236);
        background: #EDFFF9;
        .leftIndicator {
          > img {
            width: setRem(10);
            height: setRem(101);
          }
        }
        .leftTitleSelected {
          display: flex;
          font-family: PingFangSC-Semibold;
          font-size: setRem(28);
          font-weight: 600;
          line-height: setRem(38);
          letter-spacing: setRem(0);
          justify-content: center;
          align-items: center;
          color: #000000;
          >span {
            flex: 1;
            text-align: left;
            padding-left: setRem(10);
            padding-right: setRem(20);
          }
        }
      }
      .leftItemContainerUnselected {
        display: flex;
        justify-content: center;
        align-items: center;
        height: setRem(100);
        width: setRem(236);
        font-family: PingFangSC-Regular;
        font-size: setRem(22);
        text-align: center;
        font-weight: normal;
        line-height: setRem(38);
        letter-spacing: setRem(0);
        color: #000000;
        >span {
          flex: 1;
          text-align: left;
          padding-left: setRem(20);
          padding-right: setRem(20);
        }
      }

    }
    .rightList {
      //box-shadow: 0px 4px 10px 0px rgba(228, 228, 228, 0.3);
      box-shadow: 0px 4px 10px 0px rgba(228, 228, 228, 0.3);
      //background: #4F0037;
      flex: 1;
      overflow: auto;
      background: #FFFFFF;
      .list-view-body {
        //margin: auto;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 1*$rem;
      }
    }
    .rightItemContainer {
      flex: 1;
      padding-top: setRem(32);
      padding-left: setRem(32);
      padding-right: setRem(32);

      .subTitleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        .right-title {
          font-family: PingFangSC-Regular;
          font-size: setRem(28);
          font-weight: normal;
          line-height: setRem(38);
          letter-spacing: 0px;
          color: #000000;
          margin-right: setRem(12);
        }
        > img {
          width: setRem(8);
          height: setRem(15);
        }
      }

      .right-imag-container {
        margin-top: setRem(50);
        position: relative;
        width: setRem(271);
        height: setRem(274);
        .icon {
          position: absolute;
          width: setRem(271);
          height: setRem(274);
          left: setRem(-10);
          top: setRem(-15);
          bottom: setRem(0);
          right: 0;
          border-radius: setRem(20);
          opacity: 1;
        }
        .img_bg {
          background: #FFFFFF;
          position: absolute;
          top: setRem(-13);
          left: setRem(0);
          width: setRem(240);
          height: setRem(240);
          border-radius: setRem(16);
          opacity: 1;
          box-sizing: border-box;
          border: 1px solid #FFFFFF;
        }
        .img-btn {
          display: flex;
          position: absolute;
          top: setRem(190);
          left: setRem(130);
          opacity: 1;
          width: setRem(95);
          height: setRem(30);
          justify-content: center;
          align-items: center;
          border-radius: setRem(19);
          background: rgba(255, 255, 255, 0.5);
          .img-btn-text {
            font-family: PingFangSC-Medium;
            font-size: setRem(16);
            font-weight: normal;
            align-items: center;
            text-align: center;
            line-height: setRem(24);
            letter-spacing: 0px;
            color: #000000;
          }
        }

      }
      .right-imag-container-single {
        height: setRem(300);
        display: flex;
        align-items: center;
        .icon_dd {
          width: setRem(240);
          height: setRem(240);
          box-sizing: border-box;
          border-radius: setRem(16);
          box-shadow: 0px 4px 20px 0px rgba(98, 98, 108, 0.2);
          opacity: 1;
          border: 1px solid #FFFFFF;
          background: #FFFFFF;
        }
      }

    }
  }
}


