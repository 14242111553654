$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.put-guess-bond-mask {
  display: flex;
  align-items: center;
  justify-content: center;

  .put-modal-common {
    background: url(../../imgs/pop_1.png) no-repeat;
    background-size: 100% 100%;
    width: 88vw;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .put-modal-container {
      //background-color: yellow;
      width: 75%;
      height: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;

      .put-guess-title {
        font-family: PangMenZhengDao;
        font-size: 2.5 *$rem;
        font-weight: normal;
        line-height: 45px;
        text-align: center;
        margin-top: 1 *$rem;
        background: linear-gradient(108deg, #FF9BAD -14%, #FF4567 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .juan_container {
        width: 90%;
        height: 4 *$rem;
        border-bottom: 0.5px solid #535353;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        > :nth-child(1) {
          color: #1A1A1A;
          font-size: 1.2 *$rem;
        }

        > :nth-child(2) {
          color: #E13F3F;
          font-size: 1.2 *$rem;
        }

        > :nth-child(3) {
          position: absolute;
          right: 1px;
          opacity: 1;
          font-family: PingFangSC-Regular;
          font-size: 1.2 *$rem;
          color: #1975FF;
          border: 1px solid #1975FF;
          border-radius: 20px;
          padding: 2px 6px;

        }
      }

      .option_a {
        width: 90%;
        //background-color: red;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1.5 *$rem;

        > p {
          width: 40%;
          font-family: PingFangSC-Medium;
          font-size: 1.5 *$rem;
          font-weight: normal;
          color: #1A1A1A;
          text-align: center;
        }

        .option_a_a {
          //background-color: purple;
          display: flex;
          flex-direction: row;
          flex: 1;
          align-items: center;
          justify-content: space-around;

          > img {
            width: 2 *$rem;
            height: 2.1 *$rem;
          }

          > :nth-child(1) {
            color: #1A1A1A;
            font-size: 1.2 *$rem;
            opacity: 0.5;
          }

          > :nth-child(3) {
            font-family: PingFangHK-Semibold;
            font-size: 1.9 *$rem;
            font-weight: normal;
            line-height: 33.6px;
            color: #E13F3F;
          }

          > :nth-child(5) {
            color: #1A1A1A;
            font-size: 1.2 *$rem;
            opacity: 0.5;
          }
        }
      }

      .guess_btn_container {
        width: 90%;
        height: 3.6 *$rem;
        margin-top: 1.2 *$rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > p {
          width: 9 *$rem;
          height: 3 *$rem;
          display: flex;
          font-size: 1.6 *$rem;
          align-items: center;
          justify-content: center;
        }
        > :nth-child(1) {
          color: #2E63F6;
          border-radius: 119px;
          background: linear-gradient(180deg, #C9EEFF 0%, #86D9FF 100%);
        }
        > :nth-child(2) {
          border-radius: 119px;
          color: #FFFFFF;
          background: linear-gradient(180deg, #FF9CAE 0%, #F26881 100%);
        }
      }
    }
  }
}
