@import '@/assets/scss/variable.scss';

.compound_dialog {

  display: flex;
  align-items: center;
  justify-content: center;

  .put_modal_container {
    background-color: #FFFFFF;
    width: 80vw;
    min-height: 200px;
    padding-bottom: 3 *$rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    .put_guess_title {
      line-height: 45px;
      text-align: center;
      margin-top: 1 *$rem;
      font-family: PingFang SC;
      font-size: 1.6 *$rem;
      font-weight: 500;
      color: #000000;
    }

    .des {
      width: 90%;
      font-family: PingFang SC;
      font-size: 1.4 *$rem;
      color: #000000;
    }

    .images {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 80%;
      flex-wrap: wrap;
      padding: 0 3vw 2 *$rem;
      box-sizing: border-box;

      > img {
        width: 22vw;
        height: 22vw;
        background-color: lightgray;
        border-radius: 6px;
        margin-top: 2 *$rem;

      }
    }

    .success_div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .nft_name {
        font-family: PingFang SC;
        font-size: 1.6 *$rem;
        font-weight: 500;
        line-height: 44.8px;
        letter-spacing: 0px;
        color: #000000;
      }

      .nft_no {
        font-family: PingFang SC;
        font-size: 1.6 *$rem;
        font-weight: normal;
        line-height: 30px;
        color: #838383;
      }
    }

    .success_img {
      width: 70vw;
      height: 75vw;
      background-color: lightgray;
      border-radius: 6px;
      margin-top: 2 *$rem;
    }

    .success_btn {
      width: 60%;
      height: 4 *$rem;
      border-radius: 118px;
      opacity: 1;
      background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
      font-size: 1.5 *$rem;
      font-weight: bold;
      color: #624C16;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3 *$rem;
    }

    .guess_btn_container {
      width: 80%;
      height: 3.6 *$rem;
      margin-top: 1.2 *$rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        width: 10 *$rem;
        height: 4 *$rem;
        display: flex;
        font-size: 1.5 *$rem;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        color: #624C16;
        border-radius: 119px;
      }

      > :nth-child(1) {
        border: 1px solid #624C16;
      }

      > :nth-child(2) {
        background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
      }
    }
  }

}
