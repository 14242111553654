$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.block_chain_col_info_container {
  width: 100%;
  min-height: 100%;
  //border-radius: 5px;
  border: 0.1px solid white;
  background: #F1F0F5;
  //background: purple;


  .col_msg_container {
    width: 92%;
    margin-left: 4%;
    margin-top: 2 *$rem;
    min-height: 100px;
    background: white;
    border-radius: setRem(10);
    padding-bottom: 20px;
    border: 0.1px solid transparent;
    .head-image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: setRem(282);
      >img {
        border-radius: setRem(10);
        width: setRem(204);
        height: setRem(204);
      }
    }
  }

  .col_title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4vw;
    margin-top: 1.5 *$rem;
    width: 8 *$rem;
    //background: red;

    > p {
      font-family: PingFangSC-Regular;
      font-size: 1.4 *$rem;
      font-weight: bold;
      line-height: 38px;
      letter-spacing: 0px;
      color: #000000;
    }

    .rightImg {
      width: 6px;
      height: 12px;
      margin-left: 5px;
    }

    .bottomImg {
      width: 12px;
      height: 6px;
      margin-left: 5px;
    }
  }


  .col_msg_container_top {
    width: 84vw;
    margin-left: 4vw;
    min-height: 100px;
    border-radius: 8px;
    border: 0.1px solid transparent;
    background: #EEF3FF;
    padding-bottom: 20px;
    margin-top: 1 *$rem;
    //  :nth-child(1){}
    .title {
      color: #000000;
      font-size: 1.2 *$rem;
      margin-left: 1.5 *$rem;
      margin-top: 1 *$rem;
      opacity: 0.6;

      &.marTop {
        margin-top: 30px;
      }
    }
  }

  .content {
    color: #000000;
    font-size: 1.4 *$rem;
    margin-left: 1.5 *$rem;
    margin-top: 1 *$rem;
    padding-right: 10px;
  }

  .del_hash_container {
    margin-top: 1 *$rem;
    margin-left: 1.5 *$rem;
    padding-right: 10px;

    .account_address {
      color: #000;
      font-size: 1.4 *$rem;
      width: 80%;
      word-break: break-all;
      word-wrap: break-word;
    }

    > img {
      width: 16px;
      height: 18px;
      margin-left: 5px;
      display: inline-block;
    }
  }

  .rarity_container {
    width: 100%;
    min-height: 100px;
    margin-top: 10px;
    //background: purple;

    .block_chain-num_list_item {
      width: 20vw;
      height: 5.5 *$rem;
      object-fit: contain;
      display: inline-block;
      margin-top: 0.5 *$rem;
      margin-left: 4vw;
      overflow: hidden;
      //background: orange;
      //border: 0.001px solid red;


      .item_name_des {
        font-family: PingFangSC-Regular;
        font-size: 1 *$rem;
        font-weight: normal;
        //margin-top: 0.3 *$rem;
        letter-spacing: 0px;

        color: #959595;
      }

      .item_name {
        font-family: PingFangSC-Regular;
        font-size: 1.1 *$rem;
        font-weight: 600;
        margin-top: 0.3 *$rem;
        letter-spacing: 0px;
        color: #333333;

        white-space: nowrap; //规定段落中的文本不进行换行
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .item_name_rarity {
        font-family: PingFangSC-Regular;
        font-size: 1 *$rem;
        font-weight: normal;
        margin-top: 0.3 *$rem;
        letter-spacing: 0px;
        color: #959595;

        &.rarity-num_glod {
          color: #CEBF7E;
        }

      }
    }
  }


  .del_msg_container {
    width: 92%;
    margin-left: 4%;
    margin-top: 2 *$rem;
    min-height: 100px;
    background: white;
    padding-bottom: 20px;
    border: 0.1px solid transparent;
    border-radius: setRem(10);
  }

  .del_msg_content {
    margin-top: 2 *$rem;
    width: 100%;
    min-height: 100px;

    //background: red;
    position: relative;

    .line {
      width: 1.6px;
      height: 100%;
      background: #D1C281;
      position: absolute;
      left: 2.8 *$rem;
      top: 1.1 *$rem;
    }

    .del_msg_content_item {
      width: 80%;
      margin-left: 15%;
      margin-top: 2 *$rem;
      min-height: 100px;
      background: #EEF3FF;
      border-radius: 10px;
      border: 1px solid transparent;
      padding-bottom: 1 *$rem;
      position: relative;

      .title0 {
        color: #000000;
        font-size: 1.2 *$rem;
        margin-left: 1.5 *$rem;
        margin-top: 1 *$rem;
        opacity: 0.6;

        &.marTop0 {
          margin-top: 30px;
        }
      }

      .yuan_quan {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 0.1px solid transparent;
        background: #CFC07F;
        position: absolute;
        left: -3 *$rem;
        top: 1 *$rem;
      }

      .line_item {
        width: 4px;
        height: 100%;
        background: white;
        position: absolute;
        left: -2.6 *$rem;
        top: 2.2 *$rem;
        z-index: 1;
      }
    }
  }
}
