$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.recovery-mask {
  padding-top: 10*$rem;
  .mask-out-view {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31.2*$rem;
    height: 32.8*$rem;
    border-radius: 0.75*$rem;
    background: rgba(255, 255, 255, .5);
  }
  .mask-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.3*$rem 2.8*$rem 2.8*$rem;
    width: 29.8*$rem;
    height: 31.4*$rem;
    box-sizing: border-box;
    border-radius: 0.75*$rem;
    background: linear-gradient(136deg, #E6C2FF 0%, #5194FF 37%, #B9FFFF 100%);
    .title {
      font-size: 1.6*$rem;
      color: #FFFFFF;
      font-weight: bold;
    }
    .tip {
      padding-top: 1.85*$rem;
      color: #FFFFFF;
      font-size: 1.2*$rem;
    }
    .recovery-collection {
      display: flex;
      justify-content: space-around;
      margin-top: 1.8*$rem;
      width: 100%;
      .material-list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 6.4*$rem;
        height: 8.74*$rem;
        img {
          width: 6.4*$rem;
          height: 6.4*$rem;
          border-radius: 0.5*$rem;
        }
        span {
          color: #FFFFFF;
          font-size: 1.2*$rem;
          line-height: 2.24*$rem;
          font-weight: bold;
          width: 6.4*$rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
      margin-top: 3.91*$rem;
      width: 100%;
      .common {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.8*$rem;
        height: 4*$rem;
        border-radius: 2*$rem;
        color: #FFFFFF;
        font-size: 1.6*$rem;
      }
      .cancel-btn {
        background: #D3F1FF;
        color: #004FC4;
      }
      .confirm-btn {
        background: linear-gradient(90deg, #FFAFD6 1%, #D4209E 102%);
        color: #4F0037;
      }
    }
  }
}