$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url(~@/assets//font/YouSheBiaoTiHei-2.ttf);
}

.blindbox {
  width: 100vw;

  background-color: #f9efcd;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .back_btn {
    width: 35 * $vw;
    height: 35 * $vw;
    background: url(~@/assets//img/homedetail/goback.png);
    background-size: 35 * $vw 35 * $vw;
    top: 35 * $vw;
    left: 4vw;
    position: fixed;
    z-index: 999;
  }

  h1 {
    margin-top: 70 * $vw;
    font-size: 54px;
    font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
    color: #030103;
  }

  .mhbg {
    margin-top: 20px;
    width: 335px;
    height: 934px;
    background: url(~@/assets//img/mh/mhbg.png);
    background-size: 335px 934px;
    margin-bottom: 50px;
    position: relative;
    text-align: left;

    .drawbg {
      position: absolute;
      width: 301px;
      height: 37.5px;
      background: url(~@/assets//img/mh/drawbg.png);
      background-size: 301px 37.5px;
      top: 23px;
      left: 17px;

      .mydraw {
        position: absolute;
        width: 280px;
        height: 18px;
        font-size: 13px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
        top: 10px;
        left: 12px;

        .right {
          float: right;
          font-size: 13px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #404040;

          span {
            float: right;
            display: block;
            // margin-top: 1px;
            width: 16px;
            height: 16px;
            background: url(~@/assets//img/mh/drawpic.png);
            background-size: 16px 16px;
          }
        }
      }
    }

    .tip {
      font-size: 25px;
      font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
      color: #333333;
      text-align: center;
      margin-top: 31px;
    }

    .toppic {
      position: absolute;
      top: 70px;
      left: 72px;
      width: 209px;
      height: 209px;
      background: url(~@/assets//img/mh/mhpic.png);
      background-size: 209px 209px;
    }

    .toppic1 {
      position: absolute;
      top: 78px;
      left: 95px;
      width: 144px;
      height: 144px;
      background: url(~@/assets//img/mh/mhpic.png);
      background-size: 144px 144px;
    }

    .gopurchase {
      position: absolute;
      width: 142px;
      height: 34px;

      border-radius: 6px;
      top: 228px;
      left: 96px;
      border: 1px solid #000000;

      .adm-button {
        background-color: #fcb84d;
        border: none;
        width: 142px;
        height: 34px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #000000;
      }
    }

    .award {
      position: absolute;
      font-size: 18px;
      font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
      color: #333333;
      top: 316px;
      left: 29px;
    }

    .commonpic {
      position: absolute;
      width: 100px;
      top: 362px;
      text-align: center;

      img {
        width: 100%;
      }

      div {
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
        padding-top: 5px;
      }
    }

    .ycpic {
      left: 48px;
    }

    .dcpic {
      right: 48px;
    }

    .process {
      position: absolute;
      font-size: 18px;
      font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
      color: #333333;
      top: 554px;
      left: 29px;
    }

    .processstrip {
      width: 208px;
      height: 20px;
      position: absolute;
      top: 603px;
      left: 63px;

      img {
        width: 100%;
      }
    }

    .pro_text {
      position: absolute;
      width: 84px;
      height: 45px;
      top: 634px;
      text-align: center;

      p {
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #404040;
      }

      div {
        font-size: 10px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #717171;
      }
    }

    .pro_left {
      left: 30px;
    }

    .pro_middle {
      left: 127px;
    }

    .pro_right {
      right: 30px;
    }

    .regular {
      position: absolute;
      font-size: 18px;
      font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
      color: #333333;
      top: 739px;
      left: 29px;
    }

    .regulartext {
      position: absolute;
      left: 29px;
      top: 773px;
      width: 278px;
      font-size: 11px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 14px;
    }
  }
}
