@import '@/assets/scss/variable.scss';
.mergeHistory {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #F1F0F5;
    .historyList {
        display: flex;
        flex-direction: column;
        flex: 1;
        .record_item {
            background: #FFF;
            display: flex;
            flex-direction: column;
            border-radius: 1*$rem;
            padding: 1.2*$rem 1.3*$rem;
            margin: 1.3*$rem;
            .title {
                display: flex;
                flex-direction: column;
                color: #333333;
                font-weight: 600;
                border-bottom: 0.5px solid #DFDFDF;
                padding-bottom: 1*$rem;
                &>:nth-child(1) {
                    font-size: 1.6*$rem;
                }
                &>:nth-child(2) {
                    margin-top: 1*$rem;
                    font-size: 1.2*$rem;
                }
            }
            .bottom {
                margin: 1.2*$rem 0;
                display: flex;
                flex-direction: column;
                >div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-size: 1.2*$rem;
                    &:not(:last-child) {
                        margin-bottom: 1*$rem;
                    }
                    p {
                        color: #999999;
                    }
                    >div {
                        color: #333333;
                        font-weight: 600;
                        display: flex;
                        flex-direction: column;
                        text-align: end;
                        >div {
                            margin-top: 0.5*$rem;
                        }
                    }
                }
            }
        }
    }
}