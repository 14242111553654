$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.shop_stage_container {
  width: 100%;
  min-height: 100%;
  background-color: #092010;
  padding-bottom: 3*$rem;
  position: relative;
}

.shop_stage_header_component {
  //background-color: #FFF2C5;
  position: relative;
  //background-color: red;

  .shop_stage_img_bg {
    width: 100%;
    height: 100%;
  }

  .shop_stage_back_share_father {
    position: fixed;
    top: 3.8*$rem;
    width: 100%;
    height: 3*$rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .back_btn {
      width: 1.75*$rem;
      height: 1.75*$rem;
      background: url(~@/assets/img/icon/go_back.png) no-repeat;
      background-size: 100%;
      margin-left: 1.5*$rem;
    }

    .share {
      width: 2.2*$rem;
      height: 2.2*$rem;
      background: url(~@/assets/img/egg_bt_fenxiang.png) no-repeat;
      background-size: 100%;
      margin-right: 1.8*$rem;
    }
  }

  .shop_stage_rule_shiming_father {
    position: absolute;
    top: 7.3*$rem;
    width: 100%;
    height: 4*$rem;
    //background-color: purple;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .huodong_father {
      //background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7*$rem;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 3*$rem;
      }

      .title {
        position: absolute;
        width: 100%;
        height: 3*$rem;
        line-height: 3*$rem;
        font-size: 1.2*$rem;
        text-align: center;
        margin-left: -0.3*$rem;
        margin-top: 0.1*$rem;
        vertical-align: center;

      }

      .title_shiming {
        position: absolute;
        width: 100%;
        height: 3*$rem;
        line-height: 3*$rem;
        font-size: 1.2*$rem;
        text-align: center;
        margin-left: 0.3*$rem;
        margin-top: 0.1*$rem;
        vertical-align: center;
      }
    }
  }

  .jifen_qd_yx_father {
    width: 100%;
    height: 13*$rem;
    position: absolute;
    bottom: 2.2 *$rem;
    left: 0;
    //background-color: purple;

    .jifen_father {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 3vw;
      width: 94vw;
      height: 5.5 *$rem;
      border-radius: 8px;
      opacity: 0.7;
      background: #0D5037;
      box-sizing: border-box;
      border: 1px solid #8EF4B1;

      .jifen_des0 {
        font-family: PingFangSC-Semibold;
        font-weight: normal;
        //margin-left: 0.5*$rem;
        font-size: 1.2 *$rem;
        line-height: 1.5 *$rem;
        text-align: center;
        letter-spacing: 0px;
        color: #8EF4B1;
        white-space: pre-line;
      }
    }

    .qd_yx_father {
      width: 100%;
      height: 13*$rem;
      //background-color: yellow;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1.3*$rem;

      .qd_father {
        width: 45.5vw;
        height: 12*$rem;
        background: url(~@/assets/img/shop_pic_shouye.png) no-repeat;
        background-size: 100%;
        margin-left: 3vw;
        position: relative;
      }

      .yx_father {
        width: 45.5vw;
        height: 12*$rem;
        background: url(~@/assets/img/shop_pic_shouye2.png) no-repeat;
        background-size: 100%;
        margin-left: 3vw;
        position: relative;
      }

      .qd_yx_title {
        font-family: RuiZiZhenYan;
        font-size: 2*$rem;
        font-weight: normal;
        letter-spacing: 0px;
        color: #8EF4B1;
        margin-left: 3vw;
        margin-top: 1.2*$rem;
      }

      .qd_yx_second_title {
        font-family: PingFangSC-Regular;
        font-size: 1.1*$rem;
        font-weight: normal;
        letter-spacing: 0px;
        color: #8EF4B1;
        white-space: pre-line;
        margin-left: 3vw;
        line-height: 15px;
        display: inline-block;
        margin-top: 0.5*$rem;
      }

      .qd_btn_father {
        width: 10*$rem;
        height: 4*$rem;
        position: absolute;
        right: 1*$rem;
        bottom: 1*$rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .qd_btn_title {
          width: 100%;
          height: 100%;
          font-family: RuiZiZhenYan;
          font-size: 1.7*$rem;
          font-weight: normal;
          text-align: center;
          color: #092010;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: -0.1*$rem;
        }

        .qd_btn_title_hui {
          width: 100%;
          height: 100%;
          font-family: RuiZiZhenYan;
          font-size: 1.7*$rem;
          font-weight: normal;
          text-align: center;
          color: #55745F;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: -0.1*$rem;
        }
      }


    }
  }
}

.shop_stage_content_container {
  width: 100vw;
  margin-top: 5*$rem;
  //background-color: yellow;
  overflow-y: scroll;
  overflow-x: hidden;
}

.shop_stage_header_btn_father0 {
  //width: 100vw;
  width: 96vw;
  margin-left: 2vw;
  height: 4*$rem;
  word-break: keep-all;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
  //overflow-x: scroll;
  //background-color: red;
  //padding-right: 3vw;

  //margin-right: 3vw;
  scrollbar-width: 0;
  position: -webkit-sticky;
  position: sticky;
  //background-color: red;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 0;
  }

  //align-items: center;

  .header_btn_father {
    //width: 9.5*$rem;
    height: 3.6*$rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-left: 3vw;
    //background-color: yellow;

    .header_btn_img {
      width: 90%;
      height: 90%;
      //object-fit: cover;
    }

    .header_btn_title {
      width: 100%;
      height: 100%;
      font-family: RuiZiZhenYan;
      font-size: 1.7*$rem;
      font-weight: normal;
      text-align: center;
      color: #092010;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: -0.1*$rem;
    }

    .header_btn_title_sel {
      width: 100%;
      height: 100%;
      font-family: RuiZiZhenYan;
      font-size: 1.7*$rem;
      font-weight: normal;
      text-align: center;
      color: #8EF4B1;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: -0.1*$rem;
    }
  }
}


.shop_stage_header_content_father {
  width: 94vw;
  min-height: 30vh;
  margin-left: 3vw;
  margin-top: 1*$rem;
  border-radius: 10px;
  border: 0.01px solid #0D5037;
  background: #0D5037;

  .shop_stage_header_btn_second_father0 {
    width: 96%;
    margin-left: 2%;
    height: 3*$rem;
    margin-top: 1.9*$rem;
    //word-break: keep-all;
    //white-space: nowrap;
    //display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
    align-items: center;
    justify-content: space-around;
    //background-color: purple;
  }

  .header_btn_second_father {
    width: 6.25*$rem;
    height: 2.25*$rem;
    position: relative;
    display: inline-block;
    //margin-left: 2vw;
    flex-shrink: 0;
    //background-color: red;

    .header_btn_second_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .header_btn_second_title {
      width: 100%;
      height: 100%;
      font-size: 1.4*$rem;
      font-weight: normal;
      text-align: center;
      color: #092010;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
    }
  }
}

.shop_stage_list {
  width: 100%;
  //background-color: red;
  //background-color: purple;
  //min-height: 16*$rem;
  //display: flex;
  //flex-direction: row;
  //flex-wrap: wrap;
  .adm-pull-to-refresh-content {
    display: block;
  }

  .shop_stage_item_container1 {
    width: 27.3vw;
    //width: 30%;
    height: 15*$rem;
    margin-left: 3vw;
    margin-top: 2*$rem;
    position: relative;
    display: inline-block;
    //background-color: yellow;

    .item_img_father {
      //width: 100%;
      height: 10.5*$rem;
      background-color: white;
      border: 0.2px solid #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .item_img {
        width: 90%;
        height: 10.5*$rem;
        object-fit: contain;
      }
    }

    .item_name {
      width: 100%;
      font-family: PingFangSC-Regular;
      font-size: 1.2*$rem;
      font-weight: normal;
      letter-spacing: 0px;
      color: #8EF4B1;
      margin-top: 0.4*$rem;
      margin-left: 0.1*$rem;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item_price_add_father {
      width: 100%;
      height: 2*$rem;
      //background-color: yellow;
      position: absolute;
      left: 0.1*$rem;;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price_span {
        font-family: PingFangSC-Medium;
        font-size: 1.5*$rem;
        font-weight: normal;
        color: #FF5E28;
      }

      .img_add {
        width: 1.6*$rem;
        height: 1.6*$rem;
        object-fit: cover;
        margin-right: 0.2*$rem;
      }
    }

    .song_pkp_logo {
      position: absolute;
      width: 7 *$rem;
      height: 1.4 *$rem;

      left: 0px;
      top: 0.9 *$rem;
      border-radius: 0px 138px 138px 0px;
      opacity: 1;
      background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Regular;
      font-size: 0.9 *$rem;
      font-weight: normal;
      color: #3D3D3D;

    }
  }
}

.shop_logo_container {
  position: fixed;
  right: 0px;
  top: 45*$rem;
  //background-color: purple;

  .shop_logo {
    width: 6*$rem;
    height: 6*$rem;
    object-fit: cover;
  }

  .shop_count {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 20px;
    height: 20px;
    font-family: PingFangSC-Regular;
    font-size: 1.2*$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
    background-color: #FF5E28;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
}

.logo_father {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6.7*$rem;
}

.egg_logo {
  width: 15*$rem;
  height: 2.5*$rem;
}
