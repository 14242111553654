$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.compose-success-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .mask-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.55*$rem 2.75*$rem 2.85*$rem;
    width: 26.9*$rem;
    height: 36.4*$rem;
    box-sizing: border-box;
    border-radius: 0.9*$rem;
    backdrop-filter: blur(11px);
    box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
    background: linear-gradient(270deg, rgba(52,51,65,0.76) 0%, rgba(52,51,65,0.69) 100%);
    .title {
      padding-bottom: 1.25*$rem;
      font-size: 1.6*$rem;
      color: #FFFFFF;
      font-weight: bold;
    }
    .collection-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img-box {
        width: 15.8*$rem;
        height: 15.8*$rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      span {
        padding-top: 0.5*$rem;
        font-size: 1.6;
        color: #FFFFFF;
        &:nth-child(1) {
          color: #9B9B9B;
        }
      }
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
      margin-top: 3.1*$rem;
      width: 100%;
      .common {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.8*$rem;
        height: 4*$rem;
        border-radius: 2*$rem;
        color: #FFFFFF;
        font-size: 1.6*$rem;
      }
      .cancel-btn {
        background: url('~@/assets/img/variationApe/border.png') no-repeat;
        background-size: 100% 100%;
      }
      .confirm-btn {
        background: linear-gradient(120deg, #24FAAF -6%, #D200EE 105%);
      }
    }
  }
}