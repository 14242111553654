$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.detailCard {
	display: flex;
	flex-direction: column;
	padding-top: 2*$rem;
	background-color: #fff;
	border-radius: 2*$rem;
	margin-bottom: 2*$rem;
	overflow: hidden;

	.content_title {
		font-size: 25*$rem/10;
		font-family: PingFang SC-Semibold, PingFang SC;
		font-weight: 500;
		color: #333333;
		padding-left: 20*$rem/10;
		padding-bottom: 8*$rem/10;
		white-space: pre-wrap;
		line-height: 30*$rem/10;
	}

	.content_spacebt {
		display: flex;
		justify-content: space-between;
		margin: 0 20*$rem/10;
		border-top: 1*$rem/10 #f2f2f2 solid;
		padding-top: 15*$rem/10;
		padding-bottom: 15*$rem/10;

		span:nth-of-type(1) {
			font-family: PingFang SC-Regular, PingFang SC;
			font-size: 14*$rem/10;
			color: #333;

			.receive_tip {
				color: #A1A1A1;
				font-size: 1.2*$rem;
			}
		}

		span:nth-of-type(2) {
			font-family: PingFang SC-Midum, PingFang SC;
			font-size: 14*$rem/10;
			color: #333;
			font-weight: 500;
			text-align: right;
		}

		.content_home {
			font-family: PingFang SC-Regular, PingFang SC;
			font-size: 14*$rem/10;
			color: #333;
			font-weight: 500;
			display: flex;

			div {
				width: 20*$rem/10;
				height: 20*$rem/10;
				background: url('~@/assets/img/lvdi.png');
				background-size: 20*$rem/10 20*$rem/10;
			}
		}
	}

	.content_radio {
		display: block;
		margin-bottom: 1*$rem;
		margin-left: 2*$rem;
		font-size: 1.2*$rem;
		color: #A1A1A1;
	}
}