@import "@/assets/scss/variable.scss";
.purchaseRebateHome {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(255, 232, 138, 0) 1%, #FFCA61 13%);
  .back {
    position: absolute;
    top: 2.5*$rem;
    z-index: 9;
    @media screen and (min-height: 780px) {
      top: 5.5*$rem;
    }
    left: 1.5*$rem;
    width: 1*$rem;
    height: 1.8*$rem;
    background: url("~@/assets/img/icon/icon_back_black.png") no-repeat center center / 100% 100%;
  }
  .top_bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100vw;
    height: 57.75*$rem;
    //background: url("~@/assets/img/purchaseRebate/home.png") no-repeat center center / 100% 100%;;
    background-image: url("~@/assets/img/purchaseRebate/home.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    .right_btns {
      position: absolute;
      top: 8.5*$rem;
      right: 0;
      display: flex;
      flex-direction: column;
      z-index: 5;
      .rightBtn {
        width: 2.1*$rem;
        padding: 0.6*$rem 0;
        border-radius: 0.5*$rem 0px 0px 0.5*$rem;
        background: #AB5300;
        color: #FFFFFF;
        font-size: 1.2*$rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.4*$rem;
        margin-bottom: 1.2*$rem;
        &:active {
          opacity: 0.8;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      background-image: url("~@/assets/img/purchaseRebate/sunshine.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 43.5*$rem;
      width: 100vw;
      top: 3*$rem;
      @media screen and (min-height: 780px) {
        top: 7*$rem;
      }
      mix-blend-mode: color-dodge;
    }
  }
    .headIcon {
      position: absolute;
      background-image: url("~@/assets/img/purchaseRebate/head.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 39*$rem;
      width: 100vw;
      top: 10*$rem;
  }
  .headText {
    position: absolute;
    background-image: url("~@/assets/img/purchaseRebate/text.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 8.1*$rem;
    width: 29*$rem;
    top: 8*$rem;
  }
  .amountCard {
    position: absolute;
    background-image: url("~@/assets/img/purchaseRebate/amountCard.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 5.4*$rem;
    width: 9.2*$rem;
    top: 19*$rem;
    left: 25*$rem;
  }
  .contentBox {
    margin-top: -14*$rem;
    background: linear-gradient(180deg, rgba(255, 232, 138, 0) 1%, #FFCA61 13%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .counts {
      width: 14.5*$rem;
      height: 3*$rem;
      border-radius: 196px;
      background: #FFFFFF;
      box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFang SC;
      font-size: 1.5*$rem;
      font-weight: 600;
      line-height: 1.45*$rem;
      letter-spacing: 0;
      margin-bottom: 2.65*$rem;
      margin-top: 2.15*$rem;
      .label {
        color: #AB5300;
        margin-right: 0.4*$rem;
      }
      .value {
        color: #EA0000;
      }
    }
    .buyButton {
      width: 29.4*$rem;
      height: 5.45*$rem;
      background: url("~@/assets/img/purchaseRebate/buyButtBack.png") no-repeat center center / 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFang SC;
      font-size: setRem(40);
      font-weight: 600;
      line-height: setRem(29);
      text-align: center;
      letter-spacing: 0;
      color: #FFFFFF;
      margin-bottom: setRem(39);
    }
    .backRecordText {
      font-family: 思源黑体;
      font-size: setRem(24);
      font-weight: bold;
      line-height: setRem(29);
      text-align: center;
      letter-spacing: 0;
      color: #AB5300;
      margin-bottom: setRem(39);
    }
    .box {
      padding: 0 setRem(42);
      width: 100vw;
    }
    .detailCard {
      padding: setRem(59) setRem(42) setRem(96) setRem(42);
      background: linear-gradient(180deg, #FFF7EF 0%, #FFEAD9 100%);
      border-radius: setRem(56);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .text1 {
        font-family: PingFang SC;
        font-size: setRem(36);
        font-weight: 600;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0;
        color: #AB5300;
        margin-bottom: setRem(52);
      }
      .box2 {
        border-radius: setRem(154);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1.5*$rem 2.25*$rem;
        background: #FFFFFF;
        box-shadow: inset 0 0.2*$rem 0.5*$rem 0 rgba(0, 0, 0, 0.3);
        font-family: PingFang SC;
        font-weight: 400;
        font-size: setRem(32);
        color: #AB5300;
        line-height: setRem(68);
        .text3 {
          color: #EA0000
        }
        .text4 {
          font-size: setRem(24);
        }
        .text5 {
          margin-top: 0.6*$rem;
          font-weight: 600;
          line-height: 125%;
          letter-spacing: 0;
          color: #EA0000;
        }
      }
      .addIcon {
        width: setRem(46);
        height: setRem(42);
        margin: setRem(30) 0;
        background: url("~@/assets/img/purchaseRebate/addIcon.png") no-repeat center center / 100% 100%;
      }
      .box3 {
        border-radius: setRem(154);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: setRem(15) 2.25*$rem 1.5*$rem 2.25*$rem;
        background: #FFFFFF;
        box-shadow: inset 0 0.2*$rem 0.5*$rem 0 rgba(0, 0, 0, 0.3);
        font-family: PingFang SC;
        font-size: setRem(32);
        font-weight: normal;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0;
        color: #AB5300;
        .text6 {
          color: #EA0000
        }
        .text7 {
          margin-top: setRem(30);
          margin-left: setRem(20);
          font-size: setRem(28);
        }
        ul, li {
          list-style: disc;
        }
      }
    }
  }
  .bottomLogo {
    margin-top: 5*$rem;
    margin-bottom: 3.5*$rem;
    img {
      height: 3.5*$rem;
      margin: 0 auto;
    }
  }
}