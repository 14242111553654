$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.variation_ape_compose {
  display: flex;
  flex-direction: column;
  background: #14141F;
  height: 100vh;
  overflow: hidden;
  .compose_ape_container {
    padding: 5.45*$rem 1.5*$rem 2.7*$rem 2.2*$rem;
    box-sizing: border-box;
    .compose_material_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .top-img-box {
        display: flex;
        align-items: center;
      }
      .bottom-text-box {
        display: flex;
        align-items: center;
        .material_img_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: none;
          height: 3.5*$rem;
          .delete-choose {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          span {
            color: #FFFFFF;
            font-size: 1.2*$rem;
            line-height: 2.24*$rem;
          }
          img {
            width: 1.75*$rem;
            height: 1.75*$rem;
          }
        }
        .compose_result_img {
          span {
            color: #24FAAF;
            font-size: 1.2*$rem;
            font-weight: bold;
          }
        }
      }
      .material_img_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7*$rem;
        height: 7.3*$rem;
        border: 0.05*$rem solid #24FAAF;
        box-sizing: border-box;
        .image-bg {
          height: 100%;
          width: 100%;
        }
        .add-sign {
          width: 1.6*$rem;
          height: 1.6*$rem;
        }
      }
      .white_add_sign {
        margin: 0 1.2*$rem;
        width: 3.2*$rem;
        img {
          height: 3.7*$rem;
          width: 100%;
        }
      }
      .equal_sign_box {
        margin: 0 1.4*$rem 0 1.25*$rem;
        width: 2.55*$rem;
        img {
          height: 1.65*$rem;
          width: 100%;
        }
      }
      .compose_result_img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10*$rem;
        img {
          height: 8.85*$rem;
          width: 8.5*$rem;
        }
      }
    }
    .compose-ape-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2.9*$rem auto 0;
      color: #767586;
      font-size: 1.5*$rem;
      width: 17.6*$rem;
      height: 4*$rem;
      border-radius: 2*$rem;
      background: #D8D8D8;
      font-weight: bold;
      &.available {
        background: linear-gradient(108deg, #24FAAF -4%, #D200EE 101%);
        color: #FFFFFF;
      }
    }
  }
  .material-list-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1.3*$rem 1.95*$rem 0;
    border-radius: 1.5*$rem 1.5*$rem 0 0;
    background: linear-gradient(270deg, rgba(52,51,65,0.88) 0%, rgba(52,51,65,0.83) 100%);
    backdrop-filter: blur(61px);
    box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
    overflow: hidden;
    .list-box {
      .title {
        color: #FFFFFF;
        font-size: 1.4*$rem;
        line-height: 2.24*$rem;
        font-weight: bold;
      }
      .tab-box {
        display: flex;
        align-items: center;
        margin: 1.36*$rem  0 1.85*$rem;
        height: 1.75*$rem;
        font-size: 1.4*$rem;
        line-height: 2.24*$rem;
        color: #FFFFFF;
        .tab-left {
          flex: 1;
          text-align: center;
          font-weight: bold;
        }
        .active {
          color: #24FAAF;
        }
        .tab-middle {
          width: 0.05*$rem;
          height: 1.75*$rem;
          background: #FFFFFF;
        }
        .tab-right {
          flex: 1;
          text-align: center;
          font-weight: bold;
        }
      }
    }
    .list-content {
      flex: 1;
    }
  }
  .medicine-list-container, .legend-ape-list-container {
    height: 100%;
    overflow-y: auto;
  }
  .container-item {
    display: inline-block;
    position: relative;
    margin-bottom: 1.1*$rem;
    margin-left: 1.75*$rem;
    width: 10*$rem;
    height: 10*$rem;
    border: 0.4*$rem solid transparent;
    box-sizing: border-box;
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
    &.active {
      border-color: #FA6124;
    }
    &.disabled {
      &::after {
        content: '';
        display: block;
        background: rgba(0, 0, 0, .7);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    .collection-num {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1.5*$rem;
      background: rgba(0, 0, 0, .5);
      color: #FFFFFF;
      font-size: 1.2*$rem;
      line-height: 1.5*$rem;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-align: center;
    }
    img {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
    }
  }
  .adm-modal {
    .adm-modal-wrap {
      width: 100vw;
      max-width: initial;
      >div {
        .adm-modal-body {
          padding: 0;
          .adm-modal-content {
            padding: 0;
            .alert-body {
              .alert-content {
                margin: 0;
                line-height: 0;
              }
            }
          }
          .adm-modal-footer-empty {
            height: 0;
          }
        }
      }
    }
  }
}