$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.block_chain_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title_container {
    width: 100%;
    margin: 2 *$rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2 *$rem;

    > span {
      display: inline-block;
      color: #4E52FF;
    }
  }

  .textArea_container {
    width: 80%;
    background: white;
    padding: 10px 15px;
    margin-top: 2 *$rem;
    border-radius: 10px;
  }

  .next_btn {
    width: 90%;
    height: 4 *$rem;
    margin-top: 4 *$rem;
    border-radius: 100px;
    background: linear-gradient(94deg, #D4BA77 4%, #FFEAB4 99%);
    color: #624C16;
    font-size: 1.8 *$rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
  }
}
