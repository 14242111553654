$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.acratch-success-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .mask-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30.7*$rem;
    height: 31.2*$rem;
    box-sizing: border-box;
    background: url('../../imgs/pop_11.png') no-repeat;
    background-size: 100% 100%;
    .text-box {
      position: absolute;
      top: 15*$rem;
      left: 6.6*$rem;
      display: flex;
      flex-direction: column;
      width: 21*$rem;
      &.points-type {
        top: 16*$rem;
      }
      &-item {
        color: #D10073;
        font-size: 1.75*$rem;
        font-weight: bold;
        &:nth-child(1) {
          text-align: center;
        }
        &:nth-child(2) {
          padding: 0.2*$rem 0 0.4*$rem;
          font-size: 1.6*$rem;
          &.text-center {
            text-align: center;
          }
        }
        &:nth-child(3) {
          color: #1960ca;
          font-size: 1*$rem;
          i {
            color: #D10073;
          }
        }
      }
    }
    .btn-box {
      position: absolute;
      bottom: 1.2*$rem;
      left: 4.2*$rem;
      display: flex;
      &.left-btn {
        left: 36.5%;
      }
    }
    .confirm-btn {
      margin-right: 1.7*$rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10.1*$rem;
      height: 3.65*$rem;
      border-radius: 2*$rem;
      color: #004FC4;
      font-size: 1.6*$rem;
      background: #D3F1FF;
    }
    .address-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12.8*$rem;
      height: 3.65*$rem;
      border-radius: 2*$rem;
      color: #4F0037;
      font-size: 1.6*$rem;
      background: linear-gradient(90deg, #FFAFD6 1%, #D4209E 102%);
    }
  }
}