@import '@/assets/scss/variable.scss';


.recycling_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;

    .title {
      width: 70%;
      font-family: PingFang SC;
      font-size: 1.3 *$rem;
      color: #FFFFFF;
    }

    .list {
      width: 70%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 3 *$rem;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .img {
          width: 5.5 *$rem;
          height: 5.5 *$rem;
          margin: 0 10px;
          border-radius: 6px;
          background: #fff;
        }

        .nftNo {
          width: 5.0 *$rem;
          font-family: PingFang SC;
          font-size: 0.9 *$rem;
          font-weight: 500;
          line-height: 34.8px;
          color: #FFFFFF;

          white-space: nowrap; /* 禁止换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 使用省略号代替溢出的文本 */
        }
      }
      .img1 {
        width: 5.5 *$rem;
        height: 5.5 *$rem;
        margin: 0 10px;
        border-radius: 6px;
        background: #fff;
      }
    }

    .btns {
      width: 55%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-top:1 *$rem;

      > p {
        width: 10 *$rem;
        height: 2.8 *$rem;
        border-radius: 1.4 *$rem;
        box-sizing: border-box;
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(1) {
          border: 1px solid #FCD888;
          color: #FCD888;
        }

        &:nth-child(2) {
          background: linear-gradient(286deg, #FCD786 0%, #FFE9A0 99%);
          color: #BF0808;
        }
      }
    }

    .btn {
      margin-top: 4 *$rem;
      width: 10 *$rem;
      height: 2.8 *$rem;
      border-radius: 1.4 *$rem;
      box-sizing: border-box;
      font-family: PingFang SC;
      font-size: 1.2 *$rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(286deg, #FCD786 0%, #FFE9A0 99%);
      color: #BF0808;
    }

  }
}

