$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.moviegoer-ticket-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
  align-items: center;

  .moviegoer-warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2 *$rem;
    font-family: PingFangSC-Regular;
    font-size: 1.1 *$rem;
    font-weight: normal;
    letter-spacing: 1px;
    color: #E20000;
    opacity: 0.6;
    width: 90%;
    padding: 1 *$rem 0;
    border-radius: 10px;
    background: rgba(226, 0, 0, 0.08);
    box-sizing: border-box;
    border: 1px solid rgba(226, 0, 0, 0.46);

    > img {
      width: 2 *$rem;
      height: 2 *$rem;
      margin: 0 10px;
    }
  }

  .moviegoer-content {
    margin-top: 1.5 *$rem;
    width: 90%;
    min-height: 100px;
    border-radius: 10px;
    background: #fff;
    box-sizing: border-box;
    padding: 2 *$rem 2 *$rem 2 *$rem 1.5 *$rem;

    .moviegoer-title {
      font-family: PingFangSC-Semibold;
      font-size: 1.6 *$rem;
      font-weight: normal;
      line-height: 28px;
      color: #333333;
    }

    .moviegoer-item {
      width: 100%;
      height: 3 *$rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      margin-top: 2 *$rem;

      > span {
        font-family: PingFangSC-Regular;
        font-size: 1.4 *$rem;
        font-weight: normal;
        line-height: 22px;
        color: #333333;
        word-spacing: 30px;
      }

      > input {
        border: none;
        outline: none;
        height: 100%;
        flex: 1;
        margin-left: 2 *$rem;
        font-family: PingFangSC-Regular;
        font-size: 1.4 *$rem;
        line-height: 22px;
        opacity: 1;
        color: #000;


        &::placeholder {
          color: #999999;
        }
      }

      .moviegoer-line {
        width: 77%;
        height: 0.5px;
        opacity: 0.7;
        background-color: #D8D8D8;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .moviegoer-pro {
      margin-top: 3 *$rem;
      font-family: PingFangSC-Regular;
      font-size: 1.1 *$rem;
      font-weight: normal;
      color: #9E9E9E;
      display: flex;
      flex-direction: row;
      align-items: center;

      > img {
        width: 1.3 *$rem;
        height: 1.3 *$rem;
        padding: 5px;
      }

      > span {
        color: #B89969;
      }
    }
  }

  .moviegoer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 4 *$rem;
    border-radius: 40px;
    font-family: PingFangSC-Regular;
    font-size: 1.6 *$rem;
    font-weight: normal;
    letter-spacing: 1px;
    color: #624C16;
    margin-top: 3 *$rem;
    background: linear-gradient(94deg, #D4BA77 4%, #FFEAB4 99%);
  }
}
