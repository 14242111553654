$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.WordWhatCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 1*$rem;
    padding: 0 2*$rem;
    margin-bottom: 1.5*$rem;

    >span {
        font-size: 21*$rem/10;
        font-family: PingFangSC-Semibold;
        font-weight: 500;
        color: #333333;
        padding: 1.8*$rem 0*$rem 0.8*$rem;
        text-align: center;
        white-space: pre-wrap;
    }

    .ape_world_content {
        display: flex;
        flex-direction: column;
        padding-bottom: 2.3*$rem;

        .content_display {
            position: relative;
            display: flex;
            flex-direction: column;
            border-radius: 1*$rem;
            margin-top: 0.7*$rem;
            overflow: hidden;
            height: 306px;
            img{
                height: 100%;
            }
        }

    }

}
