@import '@/assets/scss/variable.scss';

.recycle_col {
  width: 100%;
  height: 100%;
  //background-color: red;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2 *$rem;
  box-sizing: border-box;

  .left {
    flex: 0.4;
    //background-color: #e0c087;
    height: 90%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .left_top{
      > p {
        font-family: PingFangSC-Semibold;
        font-size: 1.1 *$rem;
        color: #FFFFFF;
        margin-top: 0.3 *$rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }



    .items {
      flex: 0.9;
      //background-color: red;
      display: flex;
      flex-direction: row;
      margin-top: 7%;

      > img {
        width: 40%;
        //width: 5.75 *$rem;
        //height: 7 *$rem;
      }

      > :nth-child(2) {
        margin-left: 1 *$rem;
      }
    }
  }

  .right {
    flex: 0.6;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .right_top{
      width: 100%;
      height: 87%;
      background: url('../../imgs/img_machine.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .machine_container {
      width: 85%;
      margin-left: 1.1%;
      margin-top: 6.0%;
      height: 43%;
      background: url('../../imgs/img_machine1.png') no-repeat;
      background-size: 100% 100%;

      .swiper_container {
        width: 86%;
        height: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        //background-color: rgba(0,0,0,0.6);

        .point_item {
          display: flex;
          justify-content: center;
          align-items: center;
          //width: 10.5vw;
          height: 6 *$rem;
          color: #F714A4;
          font-size: 2 *$rem;
          font-family: PingFangSC-Semibold;
        }

        :global(.adm-swiper-track) {
          height: 6 *$rem;
        }
      }
    }

    .btn_container {
      width: 75%;
      height: 20%;
      position: absolute;
      top: 57%;
      left: 11%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      //background-color: red;

      .btn_des{
        width: 100%;
        font-family: PingFangSC-Semibold;
        font-size: 1.5 *$rem;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        color: #FFE269;
      }

      .btn {
        width: 40%;
        height: 90%;
        background: url('../../imgs/btn_blue.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -1%;
        margin-left: -2%;
        &.btn_dis{
          background: url('../../imgs/btn_dis_lq.png') no-repeat;
          background-size: 100% 100%;
        }

        > p {
          font-family: PingFangSC-Semibold;
          font-size: 1 *$rem;
          text-align: center;
          color: #FFFFFF;
          margin-top: - 0.5 *$rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .btn1 {
        width: 48%;
        height: 90%;
        background: url('../../imgs/btn_yellow.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -1%;
        margin-right: 5%;
        &.btn1_dis{
          background: url('../../imgs/btn_dis_yz.png') no-repeat;
          background-size: 100% 100%;
        }

        > p {
          font-family: PingFangSC-Semibold;
          font-size: 1 *$rem;
          color: #814545;
          margin-top: - 0.8 *$rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .bottom {
      width: 96%;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .bottom_left {
        > :nth-child(odd) {
          font-family: PingFangSC-Semibold;
          font-weight: 400;
          font-size: 1 *$rem;
          color: #FFFFFF
        }

        > :nth-child(2) {
          font-family: PingFangSC-Semibold;
          font-weight: bold;
          font-size: 1.2 *$rem;
          /* 可回收藏品： */
          color: #FDD015
        }
      }
    }
  }
}





