// @import 'scss/variables.scss';
$dpr: 1;
$rem: 0.5rem/$dpr;
$gray:#666;
$orange:#FA6400;
$typeColor:#CAA846;
$deepGray:#333;
$lightGray:#999;
$white:#FFF;
$background:rgba(0, 0, 0, 0.8);
$assetUrl:"https://icon.gkewang.com/gu/weapp";
$assetVersion : "20200916144304";

$device_width: 375;
$vw: (100vw / $device_width);

@mixin backgroundImage($url) {
    background-image: url($assetUrl+$url+'?version='+$assetVersion);
}

@mixin button($width, $height, $fontSize) {
    border-radius: $height/2;
    border-radius: $height/2;
    width: $width;
    height: $height;
    line-height: $height;
    font-size: $fontSize;
    box-sizing: content-box;
    text-align: center;
}

@mixin lightButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background-image: #FFF;
    border: 1px solid #DFCFAB;
    color: #CDAC5B;
    box-sizing: border-box;
}

@mixin deepButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background-image: linear-gradient(120deg, #D4BA77 0%, #FFEAB4 100%);
    border: none 0px;
    color: #624C16;
}

@mixin disableButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background: #ccc;
    border: none 0px;
    color: $lightGray;
}

.share_container {
  // height: 100vh;
  min-height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wechat,
  .share,
  .copy,
  .download {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2 *$rem;
    color: #fff;
    span {
      margin-top: 0.8 *$rem;
    }
  }

  :global {
    .share-body {
      position: relative;
    }
    .footer {
      width: 31 * $rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 1.2 * $rem;
      img {
        width: 4.6 * $rem;
        height: 4.6 * $rem;
      }
    }

    .btn-cancel {
      width: 31 * $rem;
      height: 4.5 * $rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3 *$rem;
      border-radius: 2.5 *$rem;
      background-color: #262626;;
      font-size: 1.6 *$rem;
      font-weight: bold;
      color: #C1C1C1;
    }

    // 二维码 默认样式
    .qrcode {
      position: absolute;
      border: 2px solid #FFF;
      right: 1.5 * $rem;
      bottom: 1 * $rem;
    }
  }
}