@import '@/assets/scss/variable.scss';
@mixin common-button {
  width: 100%;
  margin-top: setRem(17);
  height: setRem(90);
  margin-bottom: setRem(50);
  border-radius: setRem(50);
  font-family: PingFang SC;
  font-size: setRem(32);
  font-weight: 500;
  line-height: setRem(45);
  letter-spacing: 0;
}
.container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #F1F0F5;
  :global {
    .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
      border-color: #EA0000;
      background-color: #EA0000;
    }
    .adm-checkbox.adm-checkbox-indeterminate .adm-checkbox-icon {
      color: #EA0000;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2*$rem 1.5*$rem 0 1.5*$rem;
    background: linear-gradient(180deg, #FF9961 0%, rgba(216, 216, 216, 0) 100%);
    .baojiBox {
      display: flex;
      align-items: center;
      & > img {
        width: setRem(86);
        height: setRem(86);
      }
      .baojiText {
        font-family: PingFang SC;
        font-size: setRem(32);
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0;
        color: #FFFFFF;
        flex: 1 1 auto;
        word-break: break-word;
      }
    }
    .checkCard {
      width: 100%;
      border-radius: setRem(17);
      display: flex;
      background: #FFFFFF;
      margin-bottom: setRem(27);
      padding: setRem(25) setRem(33);
      .itemBox {
        display: flex;
      }
      .imgBox {
        width: setRem(170);
        height: setRem(170);
        border-radius: setRem(17);
        background: #D8D8D8;
        margin-right: setRem(46);
      }
      .itemName {
        font-family: PingFang SC;
        font-size: setRem(32);
        font-weight: 600;
        line-height: setRem(54);
        letter-spacing: 0;
        color: #000000;
      }
      .itemNo {
        font-family: PingFang SC;
        font-size: setRem(24);
        font-weight: 500;
        line-height: setRem(45);
        letter-spacing: 0;
        color: #9E9E9E;
        margin-bottom: setRem(22);
      }
      .itemJifenText {
        font-family: PingFang SC;
        font-size: setRem(22);
        font-weight: 600;
        line-height: setRem(29);
        letter-spacing: 0;
        color: #9E9E9E;
        .jifenCount {
          color: #C1AF68
        }
      }
    }
  }
  .footerBox {
    position: sticky;
    bottom: 0;
    padding-left: setRem(33);
    padding-right: setRem(33);
    background: white;
    display: flex;
    gap: setRem(64);
    .checkAll {
      //margin-bottom: setRem(134);
    }
    .buttonBox {
      margin-top: setRem(24);
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        font-family: PingFang SC;
        font-size: setRem(32);
        font-weight: 500;
        line-height: setRem(45);
        text-align: center;
        letter-spacing: 0;
        color: #1A1A1A
      }
      .colorText {
        color: #C1AF68
      }
      .button {
        @include common-button;
        background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
        color: #614D1A;
      }
      .disabledButton {
        @include common-button;
        background: #AAAAAA;
        color: #FFFFFF;
      }
    }
  }
}