$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.donation-info {
  height: 100%;

  .img-box {
    padding: 44 / 20*$rem  44 / 20*$rem  0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;

    .swiper {
      width: 260 / 20*$rem;
      height: 265 / 20*$rem;
      //padding: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 260 / 20*$rem;
        height: 260 / 20*$rem;
        border-radius: 20*$rem/10;
        overflow: hidden;
      }
    }
      .img_des {
        width: 100%;
        height: 20px;
        margin-top: 0.7 *$rem;
        //position: absolute;
        //bottom: 0;
        text-align: center;
        font-family: PingFangSC-Semibold;
        font-size: 1.2 *$rem;
        font-weight: normal;
        color: #999;
      }
  }

  .textArea_container {
    width: 80%;
    margin: 0 auto 0;
    background: white;
    padding: 10px 15px;
    margin-top: 2 *$rem;
    border-radius: 10px;
  }
  .msgInfo {
    margin-top: 4.8*$rem;
    text-align: center;
    font-family: PingFang SC;
    font-size: 1.1*$rem;
    font-weight: normal;
    line-height: 1.3*$rem;
    letter-spacing: 0;
    color: #9E9E9E;
    .need {
      color: #FF0000
    }
    .total {
      color: #1A1A1A
    }
  }

  .confirm-btn {
    margin: 28*$rem/10 auto 0;
    width: 335*$rem/10;
    height: 45*$rem/10;
    text-align: center;
    line-height: 45*$rem/10;
    font-size: 18*$rem/10;
    background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
    border-radius: 23*$rem/10;
    opacity: 0.6;
    color: #624C16;

    &.available {
      opacity: 1;
    }
  }

  .protocal-box {
    display: flex;
    align-items: center;
    padding: 0 22*$rem/10;
    margin-top: 20*$rem/10;
    font-size: 11*$rem/10;
    color: #CDAC5B;

    .check {
      margin-top: -13*$rem/10;
      margin-right: 5*$rem/10;

      img {
        width: 13*$rem/10;
        height: 13*$rem/10;
      }
    }

    .protocol-name {
      flex: 1 1 auto;
      word-break: break-word;
      span {
        &:nth-child(1) {
          color: #999999;
        }
      }
    }
  }
}
