$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.___scratch{
  position: relative;
  display: inline-block;
  user-select: none;
  font-size: 0;
  border-radius: 0.85*$rem;
  overflow: hidden;
  &.frozen{
    pointer-events: none;
  }
  .___content{
    opacity: 0;
    &.visible{
      opacity: 1;
    }
  }
  canvas{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}