@import '@/assets/scss/variable.scss';

.container {
  width: px2rem(750);
  margin: 0 auto;
  position: relative;
  :global(.nft-header) {
    height: 0 !important;
  }

  .rule_btn_container {
    width: 100%;
    position: absolute;
    top: 19 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .rule_btn {
    width: 14.6 *$rem;
    height: 4 *$rem;
  }

  img {
    width: 100%;
  }
}
