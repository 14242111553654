@import '@/assets/scss/variable.scss';

.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #F7F7F7;

  display: flex;
  flex-direction: column;

  .container1 {
    flex: 1;
    //background-color: red;
    overflow-y: scroll;
    position: relative;
  }

  .bg_img {
    width: 100%;
    object-fit: cover;
  }

  .content {
    width: 80%;
    min-height: 100px;
    position: absolute;
    left: 10%;
    top: 15 *$rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      width: 18 *$rem;
      height: 18 *$rem;
      background-color: white;
    }

    > p {
      font-family: PingFang SC;
      font-size: 1.6 *$rem;
      font-weight: normal;
      color: #5A5A5A;
      margin-top: 1.5 *$rem;
    }
  }
}

