@import '@/assets/scss/variable.scss';


.recycling_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .container {
    width: 90vw;
    height: 90vh;
    background: #F5F7FA;
    margin: 0 auto;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    padding-top: 2 *$rem;
  }

  .view_header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5 *$rem 1 *$rem;

    > h3 {
      font-family: PingFang SC;
      font-size: 1.2 *$rem;
      font-weight: 500;
      color: #262727;
    }
  }

  .stage {
    /* position:relative; */
    width: 200*$rem/10;
    height: 200*$rem/10;

    transform-style: preserve-3d;
    /* 为了方便观察让3D舞台转动角度 */
    /* transform: rotateX(330deg) rotateY(-30deg) rotateZ(180deg); */
    transform: rotateX(-30deg) rotateY(60deg);
    animation: play0 3s forwards;

    .face {
      position: absolute;
      width: 160*$rem/10;
      height: 160*$rem/10;

      > img {
        width: 100%;
      }
    }

    .front {
      /* 第一个面往前走 */
      transform: translateZ(80*$rem/10);
      animation: play2 3s forwards;
    }

    .rear {
      /* 第二个面往后走 */
      transform: translateZ(-80*$rem/10) rotateY(180deg);
      animation: play1 3s forwards;
    }

    .up {
      /* 先往上位移150*$rem/10  再绕着X轴转动90deg */

      transform: translateY(-80*$rem/10) rotateX(90deg);
      animation: play3 3s forwards;
    }

    .down {
      /* 先往下位移150*$rem/10,再绕着X轴转动90deg */
      animation: play6 3s forwards;
      transform: translateY(80*$rem/10) rotateX(-90deg);
    }

    .left {
      /* 先往左位移150*$rem/10 , 再绕着Y轴转动90deg */
      transform: translateX(-80*$rem/10) rotateY(-90deg);
      animation: play5 3s forwards;
    }

    .right {
      /* 先往右侧位移150*$rem/10,再绕着Y轴转动90deg */
      transform: translateX(80*$rem/10) rotateY(-270deg);
      animation: play4 3s forwards;
    }

    @keyframes play0 {
      0% {
        transform: rotateX(330deg) rotateY(-30deg) rotateZ(180deg);
      }
      50% {
        transform: rotateX(-30deg) rotateY(60deg);
      }

      100% {
        transform: rotateX(-30deg) rotateY(60deg);
      }
    }

    @keyframes play1 {
      0% {
        opacity: 1;
        transform: translateZ(-80*$rem/10);
        transform-origin: bottom;
      }
      50% {
        opacity: 1;
        transform: translateZ(-80*$rem/10);
        transform-origin: bottom;
      }

      100% {
        opacity: 0;
        transform-origin: bottom;
        transform: translateZ(-80*$rem/10) rotatex(180deg);
      }
    }

    @keyframes play2 {
      0% {
        opacity: 1;
        transform: translateZ(80*$rem/10);
        transform-origin: bottom;
      }
      50% {
        opacity: 1;
        transform: translateZ(80*$rem/10);
        transform-origin: bottom;
      }

      100% {
        opacity: 0;
        transform-origin: bottom;
        transform: translateZ(80*$rem/10) rotatex(-180deg);
      }
    }

    @keyframes play3 {
      0% {
        opacity: 1;
        transform: rotateX(-90deg) translateZ(-80*$rem/10);
      }
      50% {
        opacity: 1;
        transform: rotateX(-90deg) translateZ(-80*$rem/10);
      }

      100% {
        opacity: 0;

        transform: rotateX(-90deg) translateZ(-320*$rem/10);
      }
    }

    @keyframes play4 {
      0% {
        opacity: 1;
        transform-origin: bottom;
        transform: translateX(80*$rem/10) rotateY(-270deg);
      }
      50% {
        opacity: 1;
        transform-origin: bottom;
        transform: translateX(80*$rem/10) rotateY(-270deg);
      }

      100% {
        opacity: 0;
        transform-origin: bottom;
        transform: rotateZ(180deg) rotateY(90deg) translateZ(-80*$rem/10);
      }
    }

    @keyframes play5 {
      0% {
        opacity: 1;
        transform-origin: bottom;
        transform: translateX(-80*$rem/10) rotateY(-90deg);
      }

      50% {
        opacity: 1;

        transform-origin: bottom;
        transform: translateX(-80*$rem/10) rotateY(-90deg);
      }

      100% {
        opacity: 0;
        transform-origin: bottom;
        transform: rotateZ(-180deg) rotateY(-90deg) translateZ(-80*$rem/10);
      }
    }
    @keyframes play6 {
      0% {
        opacity: 1;
        transform: translateY(80*$rem/10) rotateX(-90deg);
      }
      50% {
        opacity: 1;
        transform: translateY(80*$rem/10) rotateX(-90deg);
      }
      100% {
        opacity: 0;
        transform: translateY(80*$rem/10) rotateX(-90deg);
      }
    }

  }

  .animation {
    position: absolute;
    opacity: 0;
    animation: play8 3s forwards;


    .imgs {
      width: 80vw;
      height: 150px;

      overflow: hidden;
      margin: 0 auto;
      //padding: 0 20px;
      box-sizing: border-box;

      .images1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-y: auto;

        &.isCenter {
          justify-content: center;
        }

        > img {
          width: 8 *$rem;
          margin: 10px;
          box-sizing: border-box;
          border-radius: 6px;
          background: lightgray;
        }
      }
    }
  }

  @keyframes play8 {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .img {
    width: 17 *$rem;
    border-radius: 8px;
    margin: 20px auto;
  }

  .btn {
    width: 25 *$rem;
    height: 3.8 *$rem;
    border-radius: 356px;
    background: linear-gradient(279deg, #FCD786 0%, #FFE9A0 99%);
    font-family: PingFang SC;
    font-size: 1.4 *$rem;
    font-weight: 500;
    color: #BF0808;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
  }
}

