$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.nft-search-page-navigation {
  height: 100vh;
  // background: linear-gradient(180deg, #FFFFFF 0%, #F1F0F5 100%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  .top {
    background: url('~@/assets/img/collection/search_collection_bg.png') no-repeat;
    background-size: 100% 100%;
    height: 27.1*$rem;
    //background-color: #4E52FF;
    width: 100%;
    box-sizing: border-box;
    }
  .list {
    flex: 1;
    // 小于780走这个
    margin-top: setRem(-65);
    overflow: auto;
    box-shadow: 0px 0px 60px 0px rgba(198, 203, 210, 0.5);
    @media screen and (min-height: 780px) {
      &{
        // 大于780走这个
        margin-top: setRem(-55);
      }
    }

  }
  .list-view-body {
    //margin: auto;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1*$rem;
  }
  .no-data-container {
    display: flex;
    justify-content: center;
    margin-top: setRem(20);
    .no-data{
      width: setRem(266);
      height: setRem(342);
      background: url('~@/assets/img/collection/icon_no_data.png') no-repeat;
      background-size: 100% 100%;
    }
  }

}
