$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.purchase-rank-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .purchase-rank-container {
    position: relative;
    width: 34.5*$rem;
    min-height: 67.6*$rem;
    padding-bottom: 2.15*$rem;
    box-sizing: border-box;
    border-radius: 0.9*$rem;
    backdrop-filter: blur(11px);
    box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
    background: linear-gradient(270deg, rgba(52,51,65,0.76) 0%, rgba(52,51,65,0.69) 100%);
    .purchase-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.8*$rem 2.25*$rem 1.35*$rem;
      box-sizing: border-box;
      font-size: 1.8*$rem;
      color: #FFFFFF;
      width: 100%;
      span {
        &:nth-child(1) {
          width: 1.5*$rem;
        }
      }
      img {
        width: 1.5*$rem;
        height: 1.5*$rem;
      }
    }
    .purchase-tip {
      text-align: center;
      color: #24FAAF;
      font-size: 1.7*$rem;
      font-weight: bold;
    }
    .purchase-rank-list {
      margin-top: 2*$rem;
      .rank-list-title {
        display: flex;
        justify-content: space-between;
        padding: 0 2.5*$rem 0 5.1*$rem;
        color: #FFFFFF;
        font-size: 1.45*$rem;
      }
      .rank-list-content {
        padding: 0 2.5*$rem;
        .rank-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 1.25*$rem;
          color: #FFFFFF;
          font-size: 1.6*$rem;
          &-left {
            flex: 1;
            display: flex;
            align-items: center;
            span {
              &:nth-child(1) {
                width: 2*$rem;
                font-size: 1.85*$rem;
              }
              &:nth-child(2) {
                width: 10.35*$rem;
              }
            }
            .rank-avatar {
              margin: 0 1.5*$rem 0 0.5*$rem;
              width: 3.25*$rem;
              height: 3.25*$rem;
              img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
              }
            }
          }
          &-right {
            text-align: center;
            width: 6*$rem;
          }
        }
      }
      .congratulate-text {
        position: absolute;
        bottom: 2.15*$rem;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 2*$rem;
        text-align: center;
        color: #24FAAF;
        font-size: 1.4*$rem;
        width: 100%;
      }
    }
  }
}