$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.parts-recycling-shop {
  display: flex;
  flex-direction: column;
  margin: 2.85*$rem 0 1.25*$rem;
  padding: 0 0.9*$rem 2*$rem;
  background: linear-gradient(129deg, #6C8DFF -2%, #5DC6FF 106%);
  border-radius: 0.85*$rem;
  .shop-title {
    margin-top: 2*$rem;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    img {
      width: 23*$rem;
      height: 5.1*$rem;
    }
  }
  .shop-goods-list-view {
    margin-top: 0.3*$rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .shop-goods-item {
      margin-top: 2*$rem;
      border-radius: 0.75*$rem;
      overflow: hidden;
      width: 16.5*$rem;
      min-height: 22*$rem;
      background: #FFFFFF;
      .goods-img {
        border-radius: 0.75*$rem 0.75*$rem 0 0;
        width: 16.5*$rem;
        height: 16.5*$rem;
      }
      .goods-info {
        padding: 0.6*$rem 0.6*$rem 0.7*$rem;
        background: #FFFFFF;
        border-radius: 0 0 0.75*$rem 0.75*$rem;
        border-top: 0.05*$rem solid #e9f1fc;
        .goods-name {
          display: inline-block;
          width: 100%;
          font-size: 1.4*$rem;
          color: #000000;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .goods-price-info {
          margin-top: 0.5*$rem;
          font-weight: bold;
          span {
            &:nth-child(1) {
              color: #FA6400;
              font-size: 1*$rem;
            }
            &:nth-child(2) {
              padding-right: 0.5*$rem;
              color: #FA6400;
              font-size: 1.5*$rem;
            }
            &:nth-child(3) {
              color: #BCBCBC;
              font-size: 1*$rem;
            }
            &:nth-child(4) {
              color: #BCBCBC;
              font-size: 1.5*$rem;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}