$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.ape-sale-detail {
    height: 100vh;
    background: #F1F0F5;

    .nft-header .container {
        .left img {
           width: 15 * $vw;
        }
        .right {
            display: flex;
            justify-content: flex-end;
        }
    } 
    
    .page-body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        &--content {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: auto;
            .top-bg {
                width: 100%;
                >img {
                    width: 100%;
                }
            }
            .body-content {
                padding: 0 2*$rem;
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-top: -25*$rem;
                &.healthPreservation {
                    margin-top: -23*$rem;
                }
            }
        }
    }
}