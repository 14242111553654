@function to($px) {
  @return ($px / 2558) * 100vw;
}

.modal_box {
  width: to(1070);
  height: to(724);
  background-image: url("~@/assets/img/landfi/pop_2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  padding: to(61) to(100) to(125) to(100);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .modal_header {
    height: to(78);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .close {
      width: to(78);
      height: to(78);
      position: absolute;
      top:50%;
      right: 0;
      transform: translate(0,-50%);
    }

    .modal_title {
      flex: 1;
      font-family: YueGenYouShangYueBoHei;
      font-size: to(56);
      font-weight: bold;
      line-height: normal;
      text-align: center;
      color: #602401;
    }
  }


  .modal_content {
    flex:1;
    font-family: YueGenYouShangYueBoHei;
    font-size: to(44);
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: #A25A2B;
   
    box-sizing: border-box;
    position: relative;
    .info{
      width: 100%;
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
      word-spacing: normal;
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  .modal_opt {
    height: to(130);
    display: flex;
    justify-content: space-between;
    width: to(680);
    margin: 0 auto;
    .modal_opt_cancel {
      width: to(320);
      height: to(130);
      background-image: url("~@/assets/img/landfi/cancel.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }

    .modal_opt_confirm {
      width: to(320);
      height: to(130);
      background-image: url("~@/assets/img/landfi/confirm.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }


}