$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.eurocup-home {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background: #2AAA19;
    .back {
      position: absolute;
      top: 2.5*$rem;
      @media screen and (min-height: 780px){
        top: 5.5*$rem;
      }
      left: 1.4*$rem;
      width: 1*$rem;
      height: 1.8*$rem;
      background: url("~@/assets/img/icon/back.png") no-repeat center center / 100% 100%;
    }
    .top_bg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-bottom: 14.7*$rem;
      width: 100vw;
      height: 55.9*$rem;
      background: url("~@/assets/img/eurocup/top_bg.png") no-repeat center center / 100% 100%;
      
      .ball_desc {
        position: relative;
        display: flex;
        height: 18.8*$rem;
        img:nth-child(1) {
          z-index: 1;
          height: 100%;
        }
        img:nth-child(2) {
          position: absolute;
          bottom: 2.4*$rem;
          right: -4.5*$rem;
          height: 5.8*$rem;
        }
      }
      
    }
    
    @mixin common_Btn {
        width: 2.1*$rem;
        height: 4.1*$rem;
        border-radius: 0.5*$rem 0px 0px 0.5*$rem;
        background: rgba(0, 38, 191, 0.6);
        color: #FFFFFF;
        font-size: 1.2*$rem;
        font-weight: bold;
        writing-mode: vertical-lr;
        text-align: center;
        line-height: 2.2*$rem;
        &:active {
          background: rgba(0, 38, 191, 0.8);
        }
      }
    
      .activity_rules {
        position: absolute;
        top: 8.6*$rem;
        right: 0;
        @include common_Btn();
      }
      .real_name {
        position: absolute;
        top: 13.8*$rem;
        right: 0;
        @include common_Btn();
      }
    .body_content {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: -17*$rem;
      .nums_bg {
        margin: 0 auto;
        height: 12.35*$rem;
        width: 35.5*$rem;
        background: url("~@/assets/img/eurocup/nums_bg.png") no-repeat center center / 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        >.number_scroll_container {
          >div {
            .turn_box_container .turn_box .turn_box_number {
              background: linear-gradient(180deg, #F7A414 2%, #D10073 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              color: unset;
              font-size: 35px;
              font-weight: 600;
            }
          }
        }
      }
      .gap_bg {
        margin-top: -0.3*$rem;
        height: 4.85*$rem;
        width: 100vw;
        background: url("~@/assets/img/eurocup/gap_img.png") no-repeat center center / 100% 100%;
        >img {
          height: 2.55*$rem;
          margin: auto;
          transform: translate(0,40%);
        }
      }
      .team_list {
        width: 100vw;
        background: #103CA5;
        border-radius: 0px 0px 30px 30px;
        margin-top: -1px;
        margin-bottom: 2.4*$rem;
        padding: 0 10px 4.5*$rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        .team_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 1.7*$rem;
          width: 8.3*$rem;
          height: 16.35*$rem;
          background: url("~@/assets/img/eurocup/card_bg_0.png") no-repeat center center / 100% 100%;
          &.team_item_1 {
            background: url("~@/assets/img/eurocup/card_bg_1.png") no-repeat center center / 100% 100%;
          }
          &.team_item_2 {
            background: url("~@/assets/img/eurocup/card_bg_2.png") no-repeat center center / 100% 100%;
          }
          &.team_item_3 {
            background: url("~@/assets/img/eurocup/card_bg_3.png") no-repeat center center / 100% 100%;
          }
          &.team_item_4 {
            background: url("~@/assets/img/eurocup/card_bg_4.png") no-repeat center center / 100% 100%;
          }
          &.team_item_5 {
            background: url("~@/assets/img/eurocup/card_bg_5.png") no-repeat center center / 100% 100%;
          }
          &.team_item_6 {
            background: url("~@/assets/img/eurocup/card_bg_6.png") no-repeat center center / 100% 100%;
          }
          &.team_item_7 {
            background: url("~@/assets/img/eurocup/card_bg_7.png") no-repeat center center / 100% 100%;
          }
          .team_name {
            font-size: 1.75*$rem;
            font-weight: bold;
            color: #FFFFFF;
            margin-top: 0.75*$rem;
          }
          .team_logo {
            position: relative;
            margin-top: 0.5*$rem;
            background: #D8D8D8;
            border-radius: 4px;
            width: 7.3*$rem;
            height: 7.3*$rem;
            overflow: hidden;
            >span {
              position: absolute;
              top:0;
              border-radius: 4px 0px 4px 0px;
              background: rgba(237, 118, 47, 0.8);
              line-height: 1*$rem;
              color: #FFFFFF;
              font-size: 0.7*$rem;
              padding: 0 0.2*$rem;
            }
            >img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .team_sale {
            margin: 0.6*$rem 0;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 1*$rem;
          }
          .buy_btn {
            width: 6.2*$rem;
            height: 2*$rem;
            background: url("~@/assets/img/eurocup/buy_btn.png") no-repeat center center / 100% 100%;
            &:active {
              opacity: 0.8;
            }
          }
        }
      }
    }
}