@function to($px) {
    @return ($px / 2558) * 100vw;
}

.my_lands_mask {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .my_lands_container {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: to(90) to(153) to(100) to(200);
        width: to(2318);
        height: to(1092);
        background: url('~@/assets/img/landfi/landgy/land-detail-bg.png') no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;

        .close {
            position: absolute;
            top: to(38);
            right: to(38);
            width: to(88);
            height: to(88);
        }

        .my_lands_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: to(98);
            margin-left: to(-50);
            height: to(110);
            font-family: YueGenYouShangYueBoHei;
            box-sizing: border-box;

            .header_left {
                color: #FFFFFF;
                font-weight: bold;
                font-size: to(60);
            }

            .header_right {
                display: flex;
                flex-direction: row;
                align-items: center;

                .gy_lands_edu {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: #E4B478;
                    font-size: to(35);
                    font-weight: bold;
                    margin-top: to(-40);
                    position: relative;
                    &_title {
                        margin-left: to(10);
                        height: to(115);
                        width: to(250);
                        position: relative;
                        text-align: right;
                        &>span{
                            display: block;
                            position: absolute;
                            width: 100%;
                            top:70%;
                            left:50%;
                            transform: translate(-50%,-50%);
                        }
                    }
                }

                .buyLandBtn {
                    width: to(253);
                    height: to(85);
                    transform: scale(1);
                    transition: transform 0.3s;
                    margin-left: to(160);
                    &:active {
                        transform: scale(0.88);
                    }
                }
            }
        }

        .my_lands_content {
            flex: 1;
            display: flex;
            margin-top: to(20);

            .lands_list_box {
                flex: 1;
                height: to(800);
                background: rgba(234, 229, 255, 0.5);
                border-radius: to(60);
                margin-left: to(-2);
                overflow: hidden;
                .lands_tabs {
                    display: flex;
                    flex-direction: row;
                    padding: to(38) to(50) to(28) to(50);
                    &_item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0 to(50);
                        color: #200334;
                        .item_text {
                            font-size: to(40);
                            &.active {
                                font-size: to(48);
                                font-weight: 600;
                            }
                        }
                        .unLine {
                            background: #D8D8D8;
                            height: to(15);
                            border-radius: to(8);
                        }
                    }
                }
                .land_list {
                    padding: 0 to(25);
                    width: 100%;
                    height: calc(100% - to(100));
                    box-sizing: border-box;
                    overflow-y: auto;
                    overflow-x: hidden;

                    .no_data {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: to(255);
                        box-sizing: border-box;

                        >img {
                            width: to(268);
                            height: to(215);
                        }
                    }
                }

                .list_item {
                    display: inline-block;
                    width: fit-content;
                    margin-top: to(68);

                    .list_item_inner {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: to(430);

                        .lease_tag {
                            position: absolute;
                            top: to(40);
                            left: to(18);
                            width: to(61);
                            height: to(72);
                            background: url('~@/assets/img/landfi/lease-tag.png') no-repeat;
                            background-size: 100% 100%;
                        }

                        .img_bg {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: url('~@/assets/img/landfi/item-bg.png') no-repeat;
                            background-size: 100% 100%;
                            width: to(378);
                            height: to(237);
                            &.mode2 {
                                background: url('~@/assets/img/landfi/landgy/item-bg.png') no-repeat;
                                background-size: 100% 100%;
                            }
                            .land_img {
                                margin-bottom: to(20);
                                width: to(244);
                                height: to(193);
                            }

                        }

                        .used_mark {
                            position: absolute;
                            top: to(25);
                            right: 0;
                            width: to(130);
                            width: to(104);
                        }

                        >p {
                            margin-top: to(9);
                            color: #2C2C2C;
                            font-size: to(24);
                            font-weight: 500;
                            width: to(400);
                            text-align: center;
                            word-break: break-all;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }

                        .land_item_btns {
                            display: flex;
                            flex-direction: row;
                            margin-top: to(9);
                            .up_land_btn {
                                width: to(132);
                                height: to(48);
    
                                &.up_active {
                                    background: url('~@/assets/img/landfi/up-active.png') no-repeat;
                                    background-size: 100% 100%;
                                }
    
                                &.up_disabled {
                                    background: url('~@/assets/img/landfi/up-disabled.png') no-repeat;
                                    background-size: 100% 100%;
                                }
                            }
                            .gy_yd {
                                width: to(132);
                                height: to(48);
                                margin-left: to(48);
                                background: url('~@/assets/img/landfi/landgy/gy_yd.png') no-repeat;
                                background-size: 100% 100%;
                                &.trans_disabled {
                                    width: to(132);
                                    height: to(48);
                                    background: url('~@/assets/img/landfi/landgy/gy_yd_disabled.png') no-repeat;
                                    background-size: 100% 100%;

                                }

                                
                            }
                            .gy_yd_detail {
                                width: to(132);
                                height: to(48);
                                background: url('~@/assets/img/landfi/landgy/gy_yd_detail.png') no-repeat;
                                background-size: 100% 100%;
                                &.trans_disabled {
                                    width: to(132);
                                    height: to(48);
                                    background: url('~@/assets/img/landfi/landgy/gy_yd_disabled.png') no-repeat;
                                    background-size: 100% 100%;

                                }

                                
                            }
                        }
                        
                    }
                }
            }
        }

        .tabs {
            :global {
                --content-padding: 0;

                .adm-tabs-header {
                    border-bottom: none;
                    box-sizing: content-box;
                }

                .adm-tabs-tab-list {
                    display: flex;
                    flex-direction: column;
                    margin-top: to(30);
                }

                .adm-tabs-tab-wrapper {
                    padding: 0;
                    width: fit-content;
                }

                .adm-tabs-tab {
                    width: fit-content;
                    padding-bottom: 0;
                    margin: 0;
                }

                .adm-tabs-tab-line {
                    display: none;
                }

                .adm-tabs-tab-wrapper-stretch {
                    flex: none;
                }
            }

            .tab_title {
                color: #DDC1F9;
                font-family: YueGenYouShangYueBoHei;
                font-size: to(60);
                width: to(182);
                height: to(135);
                text-align: center;
                line-height: to(135);
                border-radius: to(30) 0 0 to(30);

                &.tab_select {
                    background: rgba(234, 229, 255, 0.5);
                    color: #200334;
                }
            }
        }
    }
}