@import '@/assets/scss/variable.scss';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  background-color: #F8F8F8;

  .icon {
    width: 8 *$rem;
    height: 8 *$rem;
    margin-top: 20vh;
  }

  .title {
    font-family: PingFang SC;
    font-size: 1.6  *$rem;
    font-weight: 500;
    line-height: 33.6px;
    color: #3D3D3D;
    margin-top: 1 *$rem;
  }

  .des {
    font-family: PingFang SC;
    font-size: 1.3 *$rem;
    font-weight: normal;
    line-height: 33.6px;
    color: #000000;
    opacity: 0.5;
  }

  .btn {
    width: 13 *$rem;
    height: 3.8 *$rem;
    border-radius: 1.9 *$rem;
    background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
    font-family: PingFang SC;
    font-size:1.6 *$rem;
    font-weight: 500;
    letter-spacing: 11px;
    color: #614D1A;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15vh;
  }
}
