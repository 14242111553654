@function to($px) {
  @return ($px / 1624) * 100vw;
}

.purchase_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.list_container {
  margin: to(15) to(96);
  overflow: auto;
}

.list_item {
  margin-bottom: to(25);
  min-height: to(156);
  border-radius: to(7);
  background: linear-gradient(279deg, #FCD786 0%, #FFE9A0 99%);
  box-sizing: border-box;

  .time {
    display: flex;
    justify-content: space-between;
    padding: to(18) to(10) to(14);
    margin: 0 to(47);
    color: #000000;
    font-size: to(20);
    border-bottom: to(1) solid #000000;
    box-sizing: border-box;
  }

  .recyc_ele {
    padding: to(20) to(10);
    margin: 0 to(47) to(16);
    color: #000000;
    font-weight: 600;
    font-size: to(21);
    box-sizing: border-box;
    border-bottom: to(1) solid #000000;

    > span {
      &:nth-child(1) {
        margin-right: to(29);
      }
      &:nth-child(2) {
        display: inline-block;
        margin-right: to(15);
        width: to(107);
      }
      &:nth-child(3) {
        margin-right: to(13);
      }
    }
  }

  .order_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 to(57) to(13);
    box-sizing: border-box;

    > span {
      &:nth-child(1) {
        color: #000000;
        font-size: to(20);
      }
      &:nth-child(2) {
        color: #BF0808;
        font-weight: 600;
        font-size: to(24);
      }
    }
  }
}