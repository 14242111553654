@import '@/assets/scss/variable.scss';


.recycling_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;

    .title {
      width: 70%;
      font-family: PingFang SC;
      font-size: 1.3 *$rem;
      color: #FFFFFF;
    }

    .list {
      width: 70%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 3 *$rem;

      > img {
        width: 5.5 *$rem;
        height: 5.5 *$rem;
        background: #fff;
        margin: 0 10px;
        border-radius: 6px;
      }
    }

    .list1 {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > img {
        width: 14 *$rem;
        height: 14 *$rem;
        margin: 0 10px;
        border-radius: 6px;
      }

      > p {
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        line-height: 3 *$rem;
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .btns {
      width: 55%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-top: 4 *$rem;

      > p {
        width: 10 *$rem;
        height: 2.8 *$rem;
        border-radius: 1.4 *$rem;
        box-sizing: border-box;
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(1) {
          border: 1px solid #FCD888;
          color: #FCD888;
        }

        &:nth-child(2) {
          background: linear-gradient(286deg, #FCD786 0%, #FFE9A0 99%);
          color: #BF0808;
        }
      }
    }

    .btn {
      width: 10 *$rem;
      height: 2.8 *$rem;
      border-radius: 1.4 *$rem;
      box-sizing: border-box;
      font-family: PingFang SC;
      font-size: 1.2 *$rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(286deg, #FCD786 0%, #FFE9A0 99%);
      color: #BF0808;
    }

  }
}

