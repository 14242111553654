@function to($px) {
  @return ($px / 2558) * 100vw;
}

.space_land {
  position: relative;
  padding: to(98) to(120) to(95) to(212);
  width: to(1109);
  height: to(1015);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  background: url('~@/assets/img/landfi/pop_left.png') no-repeat;
  background-size: 100% 100%;
  &.mode2 {
    width: to(1053);
    height: to(1133);
    padding: to(140) to(130) to(100) to(130);
    background: url('~@/assets/img/landfi/landgy/pop_left.png') no-repeat;
    background-size: 100% 100%;
  }

  .close_popup {
    position: absolute;
    top: to(60);
    right: to(10);
    background: url('~@/assets/img/landfi/icon_del.png') no-repeat;
    background-size: 100% 100%;
    width: to(88);
    height: to(88);
    &.mode2 {
      right: to(40);
      background: url('~@/assets/img/landfi/landgy/icon_del.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .tabs {
    :global {
      --content-padding: 0;
      .adm-tabs-header {
        border-bottom: none;
        box-sizing: content-box;
      }
      .adm-tabs-tab-wrapper {
        margin-right: to(50);
        padding: 0;
        width: fit-content;
      }
      .adm-tabs-tab {
        width: fit-content;
        padding-bottom: 0;
        margin: 0;
      }
      .adm-tabs-tab-line {
        display: none;
      }
      .adm-tabs-tab-wrapper-stretch {
        flex: none;
      }
    }
    .tab_title {
      color: #DC7C00;
      font-family: YueGenYouShangYueBoHei;
      font-size: to(50);
      width: to(184);
      height: to(107);
      text-align: center;
      line-height: to(107);
      border-radius: to(30) to(30) 0 0;
      &.tab_select {
        background: #E3C49C;
        color: #371F00;
      }
      &.mode2 {
        color: #FFFFFF;
        &.tab_select {
          background: #B79ECE;
          color: #371F00;
        }
      }
    }
  }

  .tabs_content {
    flex: 1;
    display: flex;
    width: 100%;
    overflow: hidden;
    margin-top: to(-2);
    background: #E3C49C;
    &.mode2 {
      background: #B79ECE;
    }
  }

  .confirm_btn_box {
    display: flex;
    justify-content: flex-end;

    .confirm_btn {
      margin-top: to(33);
      width: to(298);
      height:  to(102);
      border-radius: to(40);
      text-align: center;
      line-height: to(102);
      color: #FFFFFF;
      font-size: to(40);
      font-family: YueGenYouShangYueBoHei;
      &.active {
        background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
        background-size: 100% 100%;
      }
      &.disabled {
        background: url('~@/assets/img/landfi/icon_zh.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}


.land_list {
  flex: 1;
  padding: 0 to(25);
  width: 100%;
  overflow: auto;

  .no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: to(120);
    > img {
      width: to(268);
      height: to(215);
    }
  }
}

.list_item {
  display: inline-block;
  width: fit-content;
  margin-top: to(52);
  margin-right: to(25);
  box-sizing: border-box;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .list_item_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    .select_img {
      position: absolute;
      top: 0;
      right: 0;
      width: to(49);
      height: to(49);
    }
    .land_img {
      margin-bottom: to(10);
      width: to(225);
      height: to(219);
      object-fit: contain;
    }
    > p {
      color: #2C2C2C;
      font-size: to(26);
      font-weight: 500;
      width: to(225);
      text-align: center;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .lease_tag {
      position: absolute;
      top: 0;
      left: 0;
      width: to(61);
      height: to(72);
      background: url('~@/assets/img/landfi/lease-tag.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}

.confirm_mask {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: to(60) to(120);
  width: to(1266);
  height: to(944);
  background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
  background-size: 100% 100%;
  font-family: YueGenYouShangYueBoHei;
  box-sizing: border-box;

  .close_confirm_mask {
    width: to(98);
    height: to(95);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .title {
    color: #371F00;
    font-size: to(60);
    font-weight: bold;
  }

  .confirm_tip {
    margin-top: to(49);
    margin-bottom: to(32);
    color: #000000;
    font-size: to(35);
    font-weight: 500;
    text-align: center;
    >p {
      &:nth-child(1) {
        margin-bottom: to(10);
      }
    }
  }

  .place_land_img {
    margin-bottom: to(39);
    width: to(391);
    height: to(400);
  }

  .confirm_btn {
    background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
    background-size: 100% 100%;
    width: to(298);
    height:  to(102);
    border-radius: to(40);
    text-align: center;
    line-height: to(102);
    color: #FFFFFF;
    font-size: to(40);
    font-family: YueGenYouShangYueBoHei;
  }
}