@import '@/assets/scss/variable.scss';

.switch_container {
  display: flex;
  width: 18%;
  height: 100%;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  .item {
    width: 11 *$rem;
    height: 4.3 *$rem;
    background: url("../../imgs/btn_home_click.png");
    background-size: 100%;
    margin-top: 1.2 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;

    &.isChoiceItem {
      opacity: 1;
    }

    > p {
      font-family: PingFangSC-Semibold;
      font-size: 1.5 *$rem;
      font-weight: 600;
      color: #F1BB7B;
      text-align: center;
      letter-spacing: 5.45px;
    }
  }
}
