.nft_modal {
  :global {
    .adm-modal-body {
      padding: 0;
    }
    
    .adm-modal-content {
      padding: 0;
      max-height: 100vh;
    }
    
    .adm-modal-footer-empty {
      height: 0;
    }
  }
}