@import '@/assets/scss/variable.scss';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .5);
  height: 100%;
  width: 100%;

  .modal_container {
    padding: 14*$rem/10 25*$rem/10 15*$rem/10;
    background: #FFFFFF;
    width: 280*$rem/10;
    border-radius: 10*$rem/10;

    .title {
      //font-weight: bold;
      font-size: 16*$rem/10;
      color: #333333;
      width: 100%;
      height: 6 *$rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0.3px solid grey;
    }

    .btn_box {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      align-items: center;

      .line {
        width: 1px;
        height: 40*$rem/10;;
        background-color: #F0F0F0;
      }

      .left_btn {
        width: 40%;
        color: grey;
        height: 40*$rem/10;
        line-height: 40*$rem/10;
        text-align: center;
        font-size: 16*$rem/10;
      }

      .right_btn {
        //flex: 1;
        width: 40%;
        color: #CEBB76;
        height: 40*$rem/10;
        line-height: 40*$rem/10;
        text-align: center;
        font-size: 16*$rem/10;

        &.right_btn_activity {
          opacity: 1;
        }
      }
    }
  }
}
