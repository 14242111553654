$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.collection_title {
    background: #ffffff;
    border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    padding: 20*$rem/10 15*$rem/10 15*$rem/10;
    opacity: 1;

    .title {
      font-size: 18*$rem/10;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }

    .message {
      margin-top: 17*$rem/10;
      display: flex;

      img {
        flex: 0 0 20*$rem/10;
        width: 20*$rem/10;
        height: 20*$rem/10;
      }

      .company {
        flex: 1;
        padding-top: 2*$rem/10;
        font-size: 15*$rem/10;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20*$rem/10;
      }

      .quantity {
        padding: 3*$rem/10 0*$rem/10;
        float: right;
        display: flex;
        font-size: 12*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20*$rem/10;

        img {
          display: block;
          margin-right: 2*$rem/10;
        }
      }
    }
}

.collection_no {
    margin: 15*$rem/10 0;
    padding:  8 / 20*$rem 30 / 20*$rem;
    background: #ffffff;
    border-radius: 6*$rem/10;
    opacity: 1;
    display: flex;
    flex-direction: column;

    .cell {
      padding: 22 / 20*$rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .collection-num {
        font-size: 15*$rem/10;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
      }

      .num {
        float: right;
        font-size: 13*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #333333;
      }
    }
}