@function to($px) {
  @return ($px / 2558) * 100vw;
}

.portal_mask {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: to(60) to(120);
  width: to(1266);
  height: to(944);
  background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
  background-size: 100% 100%;
  font-family: YueGenYouShangYueBoHei;
  box-sizing: border-box;

  .close_portal_mask {
    width: to(98);
    height: to(95);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .title {
    color: #371F00;
    font-size: to(60);
    font-weight: bold;
  }

  .portal_input {
    margin: to(223) 0 to(170);
    width: to(700);
    height: to(100);
    border-radius: to(50);
    background: #FFFFFF;
    box-sizing: border-box;

    .input_id :global(.adm-input-element) {
      padding: 0 to(30);
      width: to(700);
      height: to(100);
      text-align: center;
      font-size: to(46);
      color: #000000;

      &::placeholder {
        color: #9E9E9E;
        font-size: to(46);
      }
    }
  }

  .confirm_btn {
    background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
    background-size: 100% 100%;
    width: to(298);
    height:  to(102);
    border-radius: to(40);
    text-align: center;
    line-height: to(102);
    color: #FFFFFF;
    font-size: to(40);
    font-family: YueGenYouShangYueBoHei;
  }
}