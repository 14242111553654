$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.number_scroll_container {
  .number_test {
    display:none;
    position: absolute;
    z-index: 1;
  }
  .turn_box_container {
    position: relative;
    display: inline-block;
    // float: left;
    overflow: hidden;
  }

  .turn_box {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
    transform-origin: 0 0;
    transition: top 0.8s;
  }

  .turn_box_number {
    line-height: 90px;
    font-size: 40px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    color: #4898F1;
    text-align: center;
  }
}