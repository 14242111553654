@import '@/assets/scss/variable.scss';

@function to($px) {
  @return ($px / 1624) * 100vw;
}

.huiContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    //background: red;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-family: PingFang SC;
      font-size: 1.2 *$rem;
      font-weight: normal;
      line-height: 27.33px;
      color: #FFF;
    }

    .left {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      .imgContainer {
        width: 9.5 *$rem;
        height: 9.5 *$rem;
        position: relative;
        > img {
          width: 100%;
          height: 100%;
        }

        .pre {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;

        }
      }

      .btn {
        height: 2.2 *$rem;
        border-radius: 356px;
        padding: 0 5px;
        background: linear-gradient(283deg, #FCD786 0%, #FFE9A0 99%);
        font-family: PingFang SC;
        font-size: 1 *$rem;
        font-weight: 600;
        text-align: center;
        color: #BF0808;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > span {
        font-family: PingFang SC;
        font-weight: 600;
        line-height: 25.44px;
        color: #FEE69C;
        font-size: 2.2 *$rem;

        &::before {
          content: '¥';
          font-size: 1.5 *$rem;
        }
      }
    }

    .right {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      //background: orange;
      margin-left: -3%;

      .listCon {
        flex: 1;
        width: 95%;
        //background: purple;
        overflow: hidden;
        display: flex;
        box-sizing: border-box;

        .ele_list {
          flex: 1;
          width: 90%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: 0 10px;
          box-sizing: border-box;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.1);


          .tabs {
            :global {
              --content-padding: 0;

              .adm-tabs-header {
                border-bottom: none;
                box-sizing: content-box;
              }

              .adm-tabs-tab-wrapper {
                margin-right: to(30);
                padding: 0;
              }

              .adm-tabs-tab {
                width: fit-content;
                padding-bottom: 0;
                margin: 0;
              }

              .adm-tabs-tab-line {
                display: none;
              }

              .adm-tabs-tab-wrapper-stretch {
                flex: none;
              }

              .adm-tabs-header-mask-right {
                background: transparent;
              }
            }

            .tab_title {
              padding: 0 to(17);
              height: to(40);
              line-height: to(40);
              color: #3D3D3D;
              font-size: to(20);
              border-radius: to(8);
              background: linear-gradient(298deg, #FCD786 0%, #FFE9A0 99%);
              opacity: 0.7;

              &.tab_select {
                background: linear-gradient(287deg, #FCD786 0%, #FFE9A0 99%);
                opacity: 1;
              }
            }
          }
        }

        .list_container {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          margin-top: to(10);
          overflow-y: auto;

          .ele_list_item {
            position: relative;
            margin-right: to(26);
            margin-bottom: to(26);
            width: 4 *$rem;
            height: 4 *$rem;
            border-radius: 4px;
            background: #FFFFFF;
            box-sizing: border-box;

            &.isChoice {
              border: 2px solid #FEE093;
            }


            > img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }

            > span {
              position: absolute;
              top: to(1);
              left: to(1);
              width: to(26);
              height: to(26);
              border-radius: to(6);
              //border: to(1) solid #E75F4D;
              background: #933225;
              color: #FFFFFF;
              font-size: 1 *$rem;
              font-weight: 400;
              text-align: center;
              box-sizing: border-box;
            }
          }
        }

      }
    }
  }
}
