$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.adm-nav-bar-back-arrow {
  font-size: 17px;

}

.adm-nav-bar-title {
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
}

.adm-nav-bar {
  padding-left: 0;
  position: sticky;
  height: 6.8vh;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #f8f8f8;
}

.adm-toast-mask {
  background: rgba(255, 255, 255, 0) !important;
}

.adm-empty {
  height: 60vh;
}
.adm-empty-description {
  margin-top: -20%;
}

.adm-tabs-content {
  padding: 0;
}
.adm-tabs-tab-list {
  align-items: baseline;
}

.adm-pull-to-refresh-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.adm-infinite-scroll {
  width: 100%;
}
