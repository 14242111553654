$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}

.land-game-levelinfo {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: to(120);
    padding-left: to(295);
    margin: to(120) 0 to(40) 0;
    box-sizing: border-box;

    .land-game-levelbar {
        position: relative;
        width: to(1124);
        height: to(50);
        border-radius: to(25);
        margin-left: to(60);
        background: rgba(12, 12, 12, 0.5);
        box-sizing: border-box;
        border: to(6) solid rgba(216, 216, 216, 0.5);
        box-shadow: inset 0px 4px 39px 0px rgba(0, 0, 0, 0.3);

        .land-game-levelbarinner {
            position: relative;
            display: flex;
            align-items: center;
            height: to(40);
            border-radius: to(20);
            background: #5ec6f7;
            box-sizing: border-box;

            .exp_num_box {
                padding-left: to(70);
                font-size: to(28);
                font-weight: bold;
                color: white;
                z-index: 2;

                &.exp_num {
                    padding-left: to(420);
                }
            }

            &-rocket {
                position: absolute;
                top: to(-37);
                right: 0;
                transform: translateX(16%);

                .exp_0 {
                    width: to(144);
                    height: to(125);
                }

                &.exp_100 {
                    z-index: 1;
                }

                >img {
                    width: to(144);
                    height: to(125);
                }

                >span {
                    position: absolute;
                    top: 80%;
                    left: 35%;
                    display: block;
                    min-width: to(450);
                    color: #792D16;
                    font-size: to(28);
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: to(8);
                left: 0;
                width: 100%;
                height: to(10);
                border-radius: 0px 5px 0px 0px;
                opacity: 1;
                background: #92dcff;
                z-index: 0;
            }
        }
        .land-game-levelbg {
            position: absolute;
            top: to(-25);
            left: to(-72);
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: #EABC5E;
            width: to(90);
            height: to(90);
            background: url("~@/assets/img/landfi/level-bg.png") no-repeat;
            background-size: 100% 100%;
            font-size: to(35);
            box-shadow: to(7) to(6) to(7) rgba(0, 0, 0, 0.3);
            border-radius: 100%;
            font-family: YueGenYouShangYueBoHei;
          }
    }

    .land-btn-speedbtn {
        position: relative;
        margin-left: to(-30);
        top: unset;
        right: unset;
    }

    .land-btn-scorexbtn {
        top: unset;
        bottom: unset;
    }
}