$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.collectioin_detail {
  width: 100vw;
  position: relative;

}

.collection-detail-content {
  margin: 0 4vw 90*$rem/10;
  padding-bottom: 12*$rem;

  .technology-message {
    background: #ffffff;
    border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    opacity: 1;
    padding: 15*$rem/10 15*$rem/10 17*$rem/10;
    margin-bottom: 15*$rem/10;

    .head {
      display: flex;

      .bar {
        flex: 1;
        font-size: 15*$rem/10;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
      }

      .icon {
        flex: 0 0 10*$rem/10;
        float: right;
        margin-top: 5*$rem/10;

        img {
          width: 10*$rem/10;
          height: 10*$rem/10;
        }
      }
    }
  }
}