@import '@/assets/scss/variable.scss';

.container {
  width: px2rem(750);
  margin: 0 auto;
  .status {
    font-size: px2rem(32);
    color: #000000;
    margin: px2rem(50) 0 px2rem(12) px2rem(36);
  }
  .item {
    box-sizing: border-box;
    width: px2rem(690);
    height: px2rem(246);
    margin: 0 auto px2rem(30);
    border-radius: px2rem(17);
    padding: 0 px2rem(25);
    background: #FFFFFF;	
    display: flex;
    align-items: center;
    > img {
      width: px2rem(198);
      height: px2rem(198);
      border-radius: px2rem(10);
      margin-right: px2rem(21);;
    }
  }
  .righter {
    display: flex;
    height: px2rem(198);
    flex-direction: column;
    justify-content: space-between;
    .top {
      padding-left: px2rem(8);
      padding-top: px2rem(8);
      font-size: px2rem(26);
      color: #767676;
      .title {
        font-size: px2rem(32);
        color: #000000;
      }
    }
    .desc {
      color: #141414;
      font-size: px2rem(24);
      padding-bottom: px2rem(10);
      strong {
        color: #E6742D;
        font-size: px2rem(30);
      }
    }
  }
}

.info {
  border-radius: px2rem(17);
  width: px2rem(690);
  margin: 0 auto;
  box-sizing: border-box;
  background: #FFFFFF;	
  padding: px2rem(27) px2rem(26);
  font-size: px2rem(26);
  .row {
    display: flex;
    align-items: center;
    &:not(:first-of-type) {
      margin-top: px2rem(12);
    }
    > :first-child {
      margin-right: px2rem(8);
      color: #9E9E9E;
    }
  }
}

.btn {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  width: px2rem(210);
  height: px2rem(77);
  margin-top: px2rem(43);
  margin-right: px2rem(34);
  border-radius: px2rem(96);
  border: 1px solid #959595;
  font-size: px2rem(28);
  color: #9E9E9E;
}
