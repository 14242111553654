@import '@/assets/scss/variable.scss';

.confirm_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.3*$rem 1.55*$rem 1.1*$rem;
  width: 27.9*$rem;
  background: #FFFFFF;
  border-radius: 1*$rem;
  box-sizing: border-box;

  .modal_title {
    color: #333333;
    font-size: 1.6*$rem;
    font-weight: 600;
  }

  .modal_content {
    margin-top: 2.8*$rem;
    margin-bottom: 2.8*$rem;
    min-height: 4*$rem;
    text-align: center;
    color: #333333;
    font-size: 1.4*$rem;
  }

  .modal_footer {
    display: flex;
    padding-top: 0.8*$rem;
    border-top: 0.05*$rem solid #D8D8D8;
    width: 100%;

    .cancel_btn {
      flex: 1;
      text-align: center;
      color: #999999;
      font-size: 1.5*$rem;
      border-right: 0.05*$rem solid #D8D8D8;
      height: 3*$rem;
      line-height: 3*$rem;
    }

    .confirm_btn {
      flex: 1;
      text-align: center;
      color: #CAA846;
      font-size: 1.5*$rem;
      height: 3*$rem;
      line-height: 3*$rem;
    }
  }
}