$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.scratch-view {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4.5*$rem;
  padding: 4.2*$rem 1.4*$rem 1.95*$rem;
  background: linear-gradient(129deg, #6C8DFF -2%, #5DC6FF 106%);
  border-radius: 0.85*$rem;
  .scratch-view-title {
    position: absolute;
    top: -2.7*$rem;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 16.45*$rem;
      height: 4.05;
    }
  }
  .scratch-card-bg-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32.7*$rem;
    height: 12.5*$rem;
    background: url('../../imgs/pic_ggk_bg.png') no-repeat;
    background-size: 100% 100%;
    .s1 {
      width: 28.2*$rem;
      height: 9*$rem;
      text-align: center;
      line-height: 14*$rem;
      .price-des {
        color: #D10073;
        font-size: 1.6*$rem;
        font-weight: bold;
      }
      &.price-01 {
        background: url('../../imgs/pic_ggk_1.png') no-repeat;
        background-size: 100% 100%;
      }
      &.price-02 {
        background: url('../../imgs/pic_ggk_2.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .scratch-card-top {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 29*$rem;
      height: 13.5*$rem;
      .inner-bg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: url('../../imgs/pic_ggk_gjq.png') no-repeat;
        background-size: 100% 100%;
        width: 29*$rem;
        height: 9.5*$rem;
      }
      .scratch-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12.2*$rem;
        height: 3.25*$rem;
        border-radius: 50*$rem;
        background: linear-gradient(180deg, #FF59C2 0%, #D10073 100%);
        color: #FFFFFF;
        font-size: 1.6*$rem;
        font-weight: bold;
        &.gray-bg {
          background: #CBCBCB;
        }
      }
      .scratch-count {
        margin-top: 0.75*$rem;
        color: #9E9E9E;
        font-size: 1.2*$rem;
        font-weight: bold;
        letter-spacing: 0.2*$rem;
      }
    }
  }
  .scratch-des {
    display: flex;
    justify-content: space-between;
    margin: 1.5*$rem 0 1*$rem;
    color: #1FFDE7;
    font-size: 1.5*$rem;
    font-weight: bold;
    width: 100%;
    span {
      &:nth-child(2) {
        color: #FFFFFF;
        font-size: 1.3*$rem;
      }
    }
  }
  .gifts-box {
    display: flex;
    justify-content: space-between;
    img {
      width: 16*$rem;
      height: 6.25*$rem;
      &:nth-child(1) {
        margin-right: 0.9*$rem;
      }
    }
  }
}