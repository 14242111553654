$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.order-page {
  .tab_sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #f8f8f8;
    padding-bottom: 1vh;
  }
  .adm-tabs-header {
    border: none;
  }
  .adm-tabs-tab {
    font-size: 15*$rem/10;
    font-family: PingFang SC-Regular, PingFang SC;
  }

  .adm-tabs-tab-wrapper-stretch {
    color: #777777;
  }

  .adm-tabs-tab-active {
    color: #333333;
  }

  .adm-tabs-tab-line {
    background-color: #dece8f;
  }

  .adm-nav-bar {
    padding-left: 4vw;
  }

  .adm-swiper-slide {
    height: calc(99vh - 38*$rem/10);
    overflow-y: scroll;
    scrollbar-width: 0;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      width: 0;
    }
  }
}
