$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.detail_footer {
    width: 100%;
    padding: 2.5*$rem 3*$rem;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;

    .reduce-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 1.5 *$rem;

        img {
            width: 3.2*$rem;
            height: 3.2*$rem;
        }

        span {
            font-size: 1*$rem;
            color: #333333;
        }
    }

    .hecheng_btn {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 40*$rem/10;
        font-size: 16*$rem/10;
        text-align: center;
        font-weight: 500;
        color: #624C16;
        border-radius: 20*$rem/10;
        margin-right: 2*$rem;
        background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
        opacity: 0.5;
        &.enable {
            opacity: 1.0;
        }
        >span {
            font-size: 1.2*$rem;
        }
    }


    .give_away {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 165*$rem/10;
        height: 40*$rem/10;
        font-size: 16*$rem/10;
        font-weight: 500;
        color: #614D1A;
        border-radius: 20*$rem/10;

        &.land-ape {
            display: none;
        }

        &.can-donation-bg {
            // background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
            background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
        }

        &.donation-bg {
            background: #CCCCCC;
            color: #FFFFFF;
        }

        &.notdonation-bg {
            background: #D7D7D7;
            color: #747474;
            font-weight: 500;
        }
    }

    .use_beautiful_num {
        margin-right: 2*$rem;
        flex: 1;
        height: 4*$rem;
        border-radius: 2*$rem;
        background: linear-gradient(90deg, #F2CC53 0%, #F8EBB8 100%);
        text-align: center;
        line-height: 4*$rem;
        color: #6D390C;
        font-size: 1.6*$rem;
        font-weight: 500;

        &.re_submit {
            font-size: 1.3*$rem;
        }
    }
}