@import '@/assets/scss/variable.scss';

.container {
  width: px2rem(750);
  margin: 0 auto;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  .item {
    //margin-top: px2rem(40);
    margin: px2rem(40) auto 0;
    border-radius: px2rem(30);
    width: px2rem(682);
    background: #FFFFFF;
    .main {
      height: px2rem(210);
      padding: 0 px2rem(28);
      display: flex;
      align-items: center;
      > img {
        width: px2rem(160);
        height: px2rem(160);
        border-radius: px2rem(10);
        margin-right: px2rem(29);;
      }
    }
  }
  .righter {
    display: flex;
    height: px2rem(160);
    flex-direction: column;
    justify-content: space-between;
    .top {
      padding-left: px2rem(8);
      padding-top: px2rem(8);
      h2 {
        font-size: px2rem(32);
        color: #000000;
      }
      p {
        font-size: px2rem(24);
        color: #767676;
      }
    }
    .desc {
      font-size: px2rem(26);
      color: #1A1A1A;
    }
  }

  .footer {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: px2rem(630);
    height: px2rem(70);
    align-items: center;
    border-top: 1px solid #D8D8D8;
    color: #767676;
    font-size: px2rem(22);
    > a {
      color: #fff;
      padding: px2rem(8) px2rem(26);
      border-radius: px2rem(105);
      /* 蓝色渐变 */
      background: linear-gradient(112deg, #38A7FF -17%, #2B61F5 81%);
    }
  }
}
