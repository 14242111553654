@function to($px) {
  @return ($px / 2558) * 100vw;
}

.detail_out_mask {
  width: 100vw;
  height: 100vh;

  :global {
    .adm-mask-content {
      width: 100vw;
      height: 100vh;
      display: block;
      position: relative;
    }
  }

  .detail_out {
    position: absolute;
    left: to(391);
    right: to(317);
    top: to(114);
    bottom: to(90);
    border-radius: to(60);
    opacity: 1;
    background: linear-gradient(126deg, #FDE4FF 15%, #DCFFF5 96%);
    border: to(4) solid #FFFFFF;
    font-family: PingFang SC;
    padding: to(41) to(75);

    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close_box {
        width: to(52);

        .close {
          position: absolute;
          top: to(28);
          right: to(24);
          width: to(52);
          height: to(52);
        }
      }

      .title {
        flex: 1;
        font-size: to(56);
        font-weight: 500;
        text-align: center;
        font-variation-settings: "opsz"auto;
        color: #000000;
      }
    }



    .box {
      position: absolute;
      top: to(153);
      bottom: to(41);
      left: to(75);
      right: to(75);
      overflow-y: scroll;
      word-wrap: break-word;
      word-break: break-all;
      font-size: to(45);
      color: #3D3D3D;

    }
  }
}