$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.collection_detail {
  // margin-top: 15px;
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;

  margin: 0.5*$rem;

  .content {
    position: relative;
    .status-tag {
      position: absolute;
      top: 1.2*$rem;
      left: 1.2*$rem;
      padding: .45*$rem .95*$rem;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2.4*$rem;
      color: #FFFFFF;
      z-index: 3;
      &.receive-tag {
        color: #5A3600;
        background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
      }
    }
  }

  .btn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8.5px;
    border-radius: 6px;
    height: 41px;
    color: #FFFFFF;
    font-size: 13px;
    margin-top: 8px;
    background: #FFFFFF;
    .donation_btn {
      background: #CCCCCC;
      height: 27px;
      width: 100%;
      line-height: 27px;
      text-align: center;
      border-radius: 14px;
      &.can-donation {
        background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
        color: #624C16;
      }
    }
  }

  .avatar {
    background: #F8F8F8;
    img {
      width: 44.5vw;
      height: 44.5vw;
      &.don-status {
        opacity: .4;
      }
    }
  }

  .avatar_reopen {
    position: relative;
    img {
      width: 44.5vw;
      height: 44.5vw;
      filter: blur(2px);
    }
    .img_mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("~@/assets/img/lvdi.png") no-repeat top 50% left 50%/40px 40px, rgba(61, 53, 25, 0.88);
    }
  }
  .reopen {
    width: 100%;
    height: 25px;
    margin-top: 14px;
    background: linear-gradient(90deg, #c9b472 0%, #f6e8b4 100%);
    border-radius: 27px;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #614d1a;
    padding: 0px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 11px;
      color: #747474;
      font-family: PingFang SC-Medium, PingFang SC;
    }
  }

  .text {
    // height: 58px;
    width: 44.5vw;
    padding: 8px 0px 8px 6px;
    box-sizing: border-box;
    border-radius: 0 0 6px 6px;

    .collection-name {
      //max-width: 38vw;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;

      &.flexWrap{
        max-width: 38vw;
        display: flex;
        flex-direction: row;
        align-items: center;
      }


      .collection-name-tag {
        font-size: 18 / 20*$rem;
        margin-right: 6 / 20*$rem;
        padding: 2 / 20*$rem 10 / 20*$rem;
        border-radius: 2.4*$rem;
        color: #614D1A;
        z-index: 3;
        font-weight:500;
        background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
      }
    }
    .collection_no {
      font-size: 11px;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #333333;
      margin-top: 5px;
      font-weight: 400;
    }

    .collection-date {
      margin-top: 2px;
      font-size: 11px;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #777777;
      font-weight: 400;
    }
    .give_away {
      width: 100%;
      height: 25px;
      box-sizing: border-box;
      border: none;
      margin-top: 14px;
      background: #d7d7d7;
      border-radius: 27px;
      padding: 0 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .give_away_icon {
        width: 14 * $vw;
        height: 14 * $vw;
        margin-right: 4px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 11px;
        color: #747474;
        font-family: PingFang SC-Medium, PingFang SC;
      }
    }
  }
}
