$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

//注意： ant-list-view的父容器需设置overflow: hidden;
.ant-list-view {
    overflow-y: scroll;
    .adm-pull-to-refresh-content {
        width: 100%;
      }
    .list-view-content {
        width: 100%;
    }
}