@import '@/assets/scss/variable.scss';

.huiContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: PingFang SC;
    font-size: 1.2 *$rem;
    font-weight: 500;
    line-height: 27.33px;
    color: #FFFFFF;
    margin-left: 2%;
  }

  .content {
    flex: 1;
    //background: red;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;

    .left {
      width: 60%;
      height: 100%;
      overflow-y: scroll;
      //overflow: hidden;
      //background: yellow;

      .item {
        display: inline-block;
        width: 20%;
        height: 20%;
        margin-left: 4%;
        margin-top: 1 *$rem;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        box-sizing: border-box;
        opacity: 1;
        background: #f8f8f8;

        &.addBoder {
          border: 1px solid yellow;
        }

        .toast {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); /* 设置透明度为0.5的黑色背景 */
          z-index: 1;
        }

        > img {
          width: 100%;
        }
      }
    }

    .right {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      > img {
        width: 88%;
      }

      .des {
        font-family: PingFang SC;
        font-size: 1.1 *$rem;
        font-weight: 500;
        line-height: 27.33px;
        text-align: center;
        color: #FFFFFF;
      }

      .btn {
        width: 88%;
        height: 2.7 *$rem;
        border-radius: 356px;
        background: linear-gradient(279deg, #FCD786 0%, #FFE9A0 99%);
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        font-weight: 600;
        color: #BF0808;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
