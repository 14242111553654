$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}
.land-game-landwrapper {
    position: absolute;
    left: 50%;
    top: 56%;
    transform: translate(-50%, -50%) rotate(45deg) skew(-15deg, -15deg);
    width: 35vw;
    height: 35vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.land-game-landitalics {
    display: flex;
    justify-content: center;
    align-items: center;
    width: to(260);
    height: to(260);
    background: url("~@/assets/img/landfi/img_space_s.png");
    background-size: cover;
    // background: blue;
    &.mode2 {
      background: url("~@/assets/img/landfi/landgy/img_space_s.png");
      background-size: cover;
    }

    .land-game-space-gif {
        position: relative;
        transform: translate(-142%, -35%) rotate(-45deg) skew(0deg, 0deg);
        width: to(305);
        height: to(458);
        & > img {
          width: to(690);
          height: to(1100);
        }
    }
    .land-game-space {
        position: relative;
        // background: pink;
        // width: to(305);
        // height: to(458);
    
        .lease-tag {
          position: absolute;
          right: 41.5%;
          bottom: 5%;
          width: to(61);
          height: to(100);
          background: url('~@/assets/img/landfi/lease-tag.png') no-repeat;
          background-size: 100% 100%;
        }
    
        &.space-a {
          position: relative;
          transform: translate(-16.5%, -11.5%) rotate(-45deg) skew(0deg, 0deg);
          clip-path: polygon(39% 8%, 68% 4%, 99% 66%, 51% 98%, -12% 57%, -12% 57%,-9% 62%, -12% 79%);
          .exchange-land {
            position: absolute;
            right: 40%;
            bottom: 40%;
            width: to(62);
            height: to(95);
          }
          .work-monkey {
            position: absolute;
            width: to(70);
            height: to(90);
    
            &._0 {
              top: 5%;
              left: 47%;
            }
    
            &._1 {
              top: 37%;
              left: 30%;
            }
    
            &._2 {
              top: 36%;
              left: 61%;
            }
    
            &._3 {
              top: 64%;
              left: 30%;
            }
    
            &._4 {
              top: 57%;
              left: 67%;
            }
      
            & > img {
              width: to(60);
              height: to(90);
            }
          }
          & > img {
            width: to(385);
            height: to(545);
          }
        }
        &.space-a2 {
          position: relative;
          transform: translate(-16.5%, -11.5%) rotate(-45deg) skew(0deg, 0deg);
          clip-path: polygon(13% 13%, 82% -10%, 37% -39%, 118% 55%, 51% 102%, -12% 57%, -12% 57%, -9% 62%, -12% 79%);
          .exchange-land {
            position: absolute;
            right: 40%;
            bottom: 40%;
            width: to(62);
            height: to(95);
          }
          .work-monkey {
            position: absolute;
            width: to(70);
            height: to(90);
    
            &._0 {
              top: 1%;
              left: 56%;
            }
    
            &._1 {
              top: 37%;
              left: 30%;
            }
    
            &._2 {
              top: 29%;
              left: 64%;
            }
    
            &._3 {
              top: 67%;
              left: 28%;
            }
    
            &._4 {
              top: 60%;
              left: 67%;
            }
      
            & > img {
              width: to(60);
              height: to(90);
            }
          }
          & > img {
            width: to(385);
            height: to(545);
          }
        }
        &.space-b {
          position: relative;
          transform: translate(-9%, -7.5%) rotate(-45deg) skew(0deg, 0deg);
          clip-path: polygon(23% 11%, 59% -3%, 112% 53%, 51% 102%, -1% 62%, 4% 42%);
          .exchange-land {
            position: absolute;
            right: 40%;
            bottom: 40%;
            width: to(62);
            height: to(95);
          }
          .work-monkey {
            position: absolute;
            width: to(60);
            height: to(90);
    
            &._0 {
              top: 0;
              left: 40%;
            }
    
            &._1 {
              top: 45%;
              left: 20%;
            }
    
            &._2 {
              top: 55%;
              left: 53%;
            }
      
            & > img {
              width: to(60);
              height: to(90);
            }
          }
          & > img {
            width: to(355);
            height: to(430);
          }
        }
        &.space-c5 {
          position: relative;
          transform: translate(-5%, -5%) rotate(-45deg) skew(0deg, 0deg);
          clip-path: polygon(48% -2%, 57% 0%, 121% 47%, 50% 107%, -4% 55%, -7% 37%);
          .exchange-land {
            position: absolute;
            right: 40%;
            bottom: 40%;
            width: to(62);
            height: to(95);
          }
          .work-monkey {
            position: absolute;
            top: 40%;
            left: 50%;
            width: to(60);
            height: to(90);
      
            & > img {
              width: to(60);
              height: to(80);
            }
          }
          & > img {
            width: to(400);
            height: to(420);
          }
        }
        &.space-c {
          position: relative;
          transform: translate(-4%, -4%) rotate(-45deg) skew(0deg, 0deg);
          clip-path: polygon(48% -2%, 57% 0%, 117% 51%, 50% 107%, -4% 55%, -7% 37%, -9% 81%, -11% 52%);
          .exchange-land {
            position: absolute;
            right: 40%;
            bottom: 40%;
            width: to(62);
            height: to(95);
          }
          .work-monkey {
            position: absolute;
            top: 35%;
            left: 50%;
            width: to(60);
            height: to(90);
      
            & > img {
              width: to(60);
              height: to(90);
            }
          }
          & > img {
            width: to(355);
            height: to(400);
          }
        }
        &-click-box {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 999;
          width: 11vw;
          height: 11vw;
          transform: translate(11%, -17%) rotate(45deg) skew(0deg, 0deg);
          // background: red;
    
          &.click-box-a {
            transform: translate(20%, -20%) rotate(45deg) skew(0deg, 0deg);
          }
    
          &.click-box-b {
            transform: translate(10%, -15%) rotate(45deg) skew(0deg, 0deg);
          }
    
          &.click-box-c5 {
            transform: translate(20%, -20%) rotate(45deg) skew(0deg, 0deg);
          }
        }
    }
}