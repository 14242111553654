$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.profile {
  padding: 2vw 4vw;

  .mine {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20 * $vw;
    padding: 18 * $vw 20 * $vw;
    background: #ffffff;
    border-radius: 6px;

    .my {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex: 0 0 46%;

      .logo {
        flex: none;
        width: 40 * $vw;
        height: 40 * $vw;
      }

      .detail {
        margin-left: 15 * $vw;

        .detail-text {
          font-size: 15px;
          font-family: PingFang SC-Regular, PingFang SC;
          color: #aaaaaa;
        }

        .num {
          font-size: 16px;
          font-family: PingFang HK-Medium, PingFang HK;
          font-weight: 500;
          color: #333333;
          display: inline-block;
          max-width: 80 * $vw;
          word-break: break-all;
        }
      }
    }

    .split {
      width: 2px;
      height: 60 * $vw;
      margin-right: 2%;
      background-color: #dfdfdf;
    }
  }

  .mystery_box {
    width: 100%;
    position: relative;
    margin-top: 4vh;

    img {
      width: 100%;
    }

    .start_time {
      display: flex;
      height: 20 * $vw;
      border-radius: 13px;
      border: 1px solid #030103;
      background-color: #fff;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #030103;
      line-height: 20 * $vw;
      position: absolute;
      left: 16 * $vw;
      bottom: 30 * $vw;
      box-sizing: content-box;

      .time_label {
        height: 20 * $vw;
        background: #030103;
        border-radius: 13px;
        padding: 0px 2vw;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #ffffff;
        line-height: 20 * $vw;
        // text-align: center;
      }

      .time {
        display: inline-block;
        margin: auto 6 * $vw;
        min-width: 80 * $vw;
      }
    }
  }

  .mine_bottom {
    position: fixed;
    width: 100vw;
    bottom: 5vh;
    text-align: center;
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #aaaaaa;

    span {
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
}
