$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.raffle-share {
  position: relative;
  .bg-img {
      width: 100%;
  }
  .przie-title {
    position: absolute;
    top: 19*$rem;
    left: 51%;
    transform: translateX(-50%);
    font-family: PangMenZhengDao;
    font-size: 2.6*$rem;
    color: rgba(216,40,40,0.77);
  }
  .prize-img {
    position: absolute;
    top: setRem(644);
    left: 51.5%;
    transform: translateX(-50%);
    width: setRem(307);
    height: setRem(240);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .load-btn {
    position: absolute;
    top: 76%;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(102deg, #F9A86D -3%, #DD2817 100%);
    box-shadow: 0px 10px 16px 0px #CE905E;
    font-size: 1.5*$rem;
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
    width: 26.5*$rem;
    height: 4*$rem;
    line-height: 4*$rem;
    border-radius: 2*$rem;
  }
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
      > img {
          width: 100%;
      }
  }

}
