$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.epic_boring_ape_container {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  //align-items: center;
  position: relative;

  .list_item {
    width: 26.5vw;
    padding-bottom: 1 *$rem;
    position: relative;
    display: inline-block;
    margin-left: 5vw;
    margin-top: 1 *$rem;


    .monkey_img_zhezhao{
      width: 100%;
      height: 10 *$rem;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 5px;
      margin: 0.5 *$rem auto;
      background-color: rgba(0,0,0,0.5);
      //z-index: 10;
    }


    .monkey_img {
      width: 98%;
      height: 10 *$rem;
      margin: 0.5 *$rem auto;
      border-radius: 5px;
      //position: relative;
    }

    .monkey_img_sel {
      position: absolute;
      width: 98%;
      height: 10 *$rem;
      margin: 0.5 *$rem auto;
      overflow: hidden;
      left: 0;
      top: 0;
    }

    > p {
      width: 100%;
      text-align: center;
      margin-top: 0.5 *$rem;
    }
  }

  .duihuan_btn_container {
    position: fixed;
    bottom: 20 *$rem;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .duihuan_btn {
    width: 19*$rem;
    height: 3.5*$rem;
    background: url(../../../imgs/btn_da.png) no-repeat;
    background-size: 100% 100%;
    font-family: 思源黑体;
    font-size: 1.5 *$rem;
    font-weight: bold;
    text-align: center;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
