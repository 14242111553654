@function to($px) {
  @return ($px / 2558) * 100vw;
}

.receive_mask {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: to(115) to(120) to(0);
  width: to(1266);
  height: to(944);
  background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
  background-size: 100% 100%;
  font-family: YueGenYouShangYueBoHei;
  box-sizing: border-box;

  .close_confirm_mask {
    width: to(98);
    height: to(95);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > span {
      &:nth-child(1) {
        width: to(90);
        height: to(90);
      }
    }

    > img {
      width: to(90);
      height: to(90);
    }
  }

  .title {
    color: #371F00;
    font-size: to(60);
    font-weight: bold;
  }

  .confirm_tip {
    margin-bottom: to(80);
    color: #79A744;
    font-size: to(35);
    font-weight: 600;
    text-align: center;
    >span {
      &:nth-child(1) {
        padding-right: to(10);
        box-sizing: border-box;
        color: #3D3D3D 
      }
    }
  }

  .place_land_img {
    margin-top: to(83);
    margin-bottom: to(37);
    width: to(385);
    height: to(305);
  }

  .confirm_btn {
    background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
    background-size: 100% 100%;
    width: to(298);
    height:  to(102);
    border-radius: to(40);
    text-align: center;
    line-height: to(102);
    color: #FFFFFF;
    font-size: to(40);
    font-family: YueGenYouShangYueBoHei;
  }
}