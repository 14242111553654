$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.collectionvote_page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;

  .vote_body {
    flex: 1;
    overflow-y: auto;

    .top-info {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      margin: 1.8*$rem 1.5*$rem;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 1.45*$rem;
          height: 1.45*$rem;
          margin-right: 0.6*$rem;
        }

        span {
          font-size: 1.5*$rem;
          color: #3d3d3d;
        }

        & > :nth-child(3) {
          color: #A7A7A7;
        }
      }
    }

    .question-box {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  .bottom-area {
    display: flex;
    justify-content: center;
    padding: 1.2 *$rem 0 5*$rem;
    //background-color: red;

    .commit-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4.2*$rem;
      width: 26*$rem;
      border-radius: 2.1*$rem;
      background: #C7C7C7;

      span {
        font-size: 2*$rem;
        font-family: PingFangSC-Semibold;
        color: #676767;
        letter-spacing: 6px;
      }

      &.enable {
        &:active {
          opacity: 0.8;
        }

        background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);

        span {
          color: #49200A;
        }
      }


    }
  }

  @media screen and (min-height: 780px) {
    .bottom-area {
      margin-bottom: 34px;
    }
  }

}

.question-item {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 0.6 * $rem;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, .2);
  margin: 0 1.5*$rem 1.5*$rem;
  padding: 0 1.5*$rem;

  .question-title {
    font-size: 1.5*$rem;
    color: #3d3d3d;
    padding-top: 1 * $rem;
    padding-bottom: 1.2*$rem;

    &.require::before {
      content: "*";
      color: #FF0000;
    }
  }

  .question-options-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .option {
      display: flex;
      flex-direction: column;
      width: 15*$rem;

      &.left {
        margin-right: 1.3*$rem;
      }

      > img {
        width: 15*$rem;
        height: 15*$rem;
        border-radius: 0.8*$rem;
        margin-bottom: 0.8 * $rem;
      }
    }
  }

  .question-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #EBEBEB;
    height: 2.8*$rem;
    border-radius: 1.4 * $rem;
    padding: 0 1.4 * $rem;
    margin-bottom: 1.5 * $rem;

    span {
      font-size: 1.3*$rem;

      &:nth-child(1) {
        color: #000;
      }

      &:nth-child(2) {
        color: #B89969;
        font-family: PingFangSC-Semibold;
      }
    }

    &.select {
      background-color: rgba(2, 86, 255, 0.3);

      span {
        color: #000;
      }
    }

  }

  .input-content {
    display: flex;
    background-color: #EBEBEB;
    margin-bottom: 1.4*$rem;
    height: 2.8*$rem;
    border-radius: 1.4*$rem;
    padding: 0 1.4*$rem;

    .adm-text-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      --placeholder-color: #8D8D8D;
      --font-size: 1.3 *$rem;

      .adm-text-area-element {
        caret-color: #CEBF7D;
      }

      .adm-text-area-count {
        font-size: 0.9 *$rem;
        padding-top: 0;
      }
    }
  }

  .textArea-content {
    background-color: #EBEBEB;
    margin-bottom: 1.4*$rem;
    border-radius: 1.1*$rem;
    padding-left: 1.3*$rem;
    padding-right: 1 *$rem;
    padding-top: 0.5*$rem;
    padding-bottom: 0.5*$rem;

    .adm-text-area {
      --placeholder-color: #8D8D8D;
      --font-size: 1.3 *$rem;

      .adm-text-area-element {
        caret-color: #CEBF7D;
      }

      .adm-text-area-count {
        font-size: 0.9 *$rem;
      }
    }
  }
}


.rule_know_father {
  width: 100%;
  margin-bottom: 2 *$rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .rule_know_content {
    width: 60%;
    height: 4 *$rem;
    border-radius: 2 *$rem;
    background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Medium;
    font-size: 1.8 *$rem;
    font-weight: normal;
    letter-spacing: 0em;
    color: #494444;
  }
}
