$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.boring_club_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  position: relative;

  .bg_img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: 100%;
  }

  .qr_bg{
    margin: 10*$rem auto 0;
    width: 23.3 *$rem;
    height: 29.25 *$rem;
    z-index: 100;
  }


  .boring_club_des {
    font-family: "PingFang SC";
    font-size: 1.5 *$rem;
    line-height: 3.45 *$rem;
    text-align: center;
    letter-spacing: 0px;
    color: #555555;
    white-space: pre-line;
    margin-top: 1.1 *$rem;
    z-index: 101;
  }
}
