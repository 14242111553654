@import '@/assets/scss/variable.scss';
.default_pay {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    .container {
        flex: 1;
        .remind {
            width: 100%;
            height: 4*$rem;
            background: #fdf6f0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .remindIcon {
                width: 1.6*$rem;
                height: 1.6*$rem
            }
            >span {
                font-size: 1.2*$rem;
                margin-left: 0.5*$rem;
                color: #f88b34
            }
        }
        .priceStyle {
            margin: 4*$rem auto 3.5*$rem;
            text-align: center;
            font-size: 4.4*$rem;
            color: #FF5E5E;
            >span {
                font-size: 1.9*$rem;
                
            }
        }
    }
    .absButtom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        padding-top: 1.5*$rem;
        padding-bottom: 1.5*$rem;
        @media screen and (min-height: 780px) {
            padding-bottom: 4.5 * $rem;
        }
        .payBtn {
            width: 32.5*$rem;
            height: 4*$rem;
            border-radius: 2*$rem;
            background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.6*$rem;
            color: #624C16;
            overflow: hidden;
        }
    }
}