$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.markingCard {
    padding-top: 2*$rem;
    width: 100%;
    background-color: #fff;
    border-radius: 2*$rem;
    margin-bottom: 2*$rem;
    overflow: hidden;

    .content_img_center {
        width: calc(100% - 40*$rem/10);
        padding: 0 20*$rem/10 20*$rem/10;
        display: flex;
        justify-content: space-between;
    
        li {
          width: 18%;
            img {
                width: 100%;
            }
            p {
                font-size: 12*$rem/10;
                text-align: center;
                margin-top: 8*$rem/10;
                padding: 0;
                color: #333;
            }
        }
    }
}