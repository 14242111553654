$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.create_community_page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .item_container {
    margin-top: setRem(30);
    margin-left: setRem(30);
    margin-right: setRem(30);
    display: flex;
    flex-direction: row;
    height: setRem(100);
    border-radius: setRem(12);
    opacity: 1;
    background: #FFFFFF;
    align-items: center;
    justify-content: space-between;

    .icon_title {
      display: flex;
      flex-direction: row;
      margin-left: setRem(32);
      align-items: center;

      .img_icon {
        width: setRem(41);
        height: setRem(41);
      }
      .title {
        margin-left: setRem(20);
        font-family: PingFangSC-Regular;
        font-size: setRem(30);
        font-weight: 500;
        line-height: setRem(38);
        letter-spacing: 0px;
        color: #000000;
      }
    }
    .img_arrow {
      width: setRem(30);
      height: setRem(30);
      margin-right: setRem(40);
    }
  }


}
