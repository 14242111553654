$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.parts-recycling-home {
  position: relative;
  height: 100vh;
  overflow-y: auto;
  .back-share {
    position: fixed;
    left: 0;
    right: 0;
    top: 5.5*$rem;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    padding: 0 1.4*$rem;

    .back-img {
      width: 0.95*$rem;
      height: 1.75*$rem;
    }

    .share-img {
      width: 2.05*$rem;
      height: 2.05*$rem;
    }
  }

  .rules-authen {
    position: absolute;
    left: 0;
    right: 0;
    top: 8.5*$rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2*$rem;
    color: #FFFFFF;
    font-weight: bold;
    width: 100%;

    .activity-rules {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.95*$rem;
      height: 2.5*$rem;
      border-radius: 1.25*$rem 0 0 1.25*$rem;
      background: rgba(0, 38, 191, 0.6);
    }

    .name-authen-no {
      width: 7.95*$rem;
      height: 2.5*$rem;
      border-radius: 0 1.25*$rem 1.25*$rem 0;
    }
    
    .name-authen {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.95*$rem;
      height: 2.5*$rem;
      background: rgba(0, 38, 191, 0.6);
      border-radius: 0 1.25*$rem 1.25*$rem 0;
    }
  }
  .main-img {
    img {
      height: 58*$rem;
      width: 100%;
    }
  }

  .game-container {
    position: absolute;
    top: 22*$rem;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40.5*$rem;
    width: 100%;
    background: url('../imgs/huishou_yxj.png') no-repeat;
    background-size: 100% 100%;

    .count-down-record {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10.2*$rem 2.3*$rem 0 3.2*$rem;

      .recycling-record {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7.95*$rem;
        height: 2.5*$rem;
        background: url('../imgs/record-bg.png') no-repeat;
        background-size: 100% 100%;
        color: #03009F;
        font-size: 1.3*$rem;
        font-weight: bold;
      }
    }

    .jackpot-point {
      margin: 0.5*$rem auto 0;
      //width: 32*$rem;
      width: 86vw;
      height: 9.8*$rem;
      background: url('../imgs/pic_kuang.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: row;
      //background: purple;
      align-items: center;
      justify-content: center;

      .swiper-container {
        width: 87.5%;
        height: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .point-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10.5vw;
        height: 8*$rem;
        color: #F714A4;
        font-size: 3.5 *$rem;
        font-family: PingFangSC-Semibold;

      }

      .adm-swiper {
        width: 100%;
      }

      .adm-swiper-track {
        height: 8*$rem;
      }
    }

    .points-allocation-rules {
      display: flex;
      margin: 0.5*$rem 4.35*$rem 0 3.85*$rem;

      span {
        &:nth-child(1) {
          background: url('../imgs/80_bg.png') no-repeat;
          background-size: 100% 100%;
          width: 17.95*$rem;
          height: 2.7*$rem;
          color: #8D0000;
          font-size: 1.2*$rem;
          text-align: center;
          line-height: 2.7*$rem;
          font-weight: bold;
        }

        &:nth-child(2) {
          background: url('../imgs/20_bg.png') no-repeat;
          background-size: 100% 100%;
          width: 11.95*$rem;
          height: 2.7*$rem;
          color: #000000;
          font-size: 1.2*$rem;
          text-align: center;
          line-height: 2.7*$rem;
          font-weight: bold;
        }
      }
    }

    .user-parts-info {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 0.2*$rem 4.35*$rem 0 3.85*$rem;
      font-weight: bold;
      .parts-info {
        font-size: 1.2*$rem;
        color: #FFFFFF;
        
        span {
          &:nth-child(2) {
            font-weight: bold;
            padding-left: 0.5*$rem;
            color: #fdd015;
            font-size: 1.5*$rem;
          }
        }
      }

      .add-beer {
        position: absolute;
        right: 0;
        top: -3.7*$rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 9.4*$rem;
        height: 1.3*$rem;
        border-radius: 5*$rem;
        background: rgba(255, 0, 0, 0.6);
        color: #FFFFFF;
        font-size: 1*$rem;
      }
    }

    .recycling-end-btn {
      margin: 1.8*$rem auto 6.45*$rem;
      width: 22*$rem;
      height: 6.6*$rem;
      background: url('../imgs/tab_dis.png') no-repeat;
      background-size: 100% 100%;
    }

    .recycling-btn {
      display: flex;
      margin: 1.8*$rem 4.95*$rem 0 6.45*$rem;

      .recycling-points-btn {
        margin-right: 0.85*$rem;
        width: 9.05*$rem;
        height: 6.2*$rem;
        background: url('../imgs/recycling-points.png') no-repeat;
        background-size: 100% 100%;
        &.unavailable {
          background: url('../imgs/btn_500_dis.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .recycling-stake-btn {
        position: relative;
        width: 15.95*$rem;
        height: 6.2*$rem;
        background: url('../imgs/stake-bg.png') no-repeat;
        background-size: 100% 100%;
        &.unavailable {
          background: url('../imgs/btn_2000_dis.png') no-repeat;
          background-size: 100% 100%;
        }
        .tip-animate {
          position: absolute;
          top: -2.2*$rem;
          left: 3.2*$rem;
          width: 9.2*$rem;
          height: 3.3*$rem;
        }
      }
    }
  }

  .scratch-goods-view {
    padding: 2.7*$rem 1*$rem 0.5*$rem;
    background: linear-gradient(180deg, #1C68FF 0%, #2C4DD1 78%);
    .parts-logo {
      margin: 3.5*$rem auto;
      width: 14.85*$rem;
      height: 2.45*$rem;
      img {
        width: 14.85*$rem;
        height: 2.45*$rem;
      }
    }
  }
  .s1 {
    width: 28.2*$rem;
    height: 9.35*$rem;
    // border-radius: 0.85*$rem;
    img {
      width: 28*$rem;
      height: 9*$rem;
    }
  }
}
