@function to($px) {
  @return ($px / 2558) * 100vw;
}

.fill_in_address {
  .main_container {
    padding: to(30);
    width: to(1850);
    height: to(960);
    background: #F5F7FA;
    border-radius: to(29.6);
    box-sizing: border-box;
    .top_nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: to(53.8);
      .back {
        width: to(57);
        height: to(57);
      }
      .nav_title {
        color: #262727;
        font-size: to(46);
        font-weight: 500;
      }
    }
    .mid_content {
      display: flex;
      justify-content: space-between;
      .content_left {
        padding: to(40);
        margin-right: to(32);
        width: to(965);
        height: to(616);
        background: #FFFFFF;
        border-radius: to(20);
        box-sizing: border-box;
        .goods_info {
          display: flex;
          padding-bottom: to(15);
          .goods_img {
            margin-right: to(28);
            width: to(345);
            height: to(345);
            border-radius: to(20);
            >img {
              border-radius: to(20);
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .goods_name {
            display: flex;
            flex-direction: column;
            padding: to(20) 0;
            span {
              &:nth-child(1) {
                margin-bottom: to(21);
                font-size: to(56);
                color: #262727;
                font-weight: 600;
                overflow: hidden;
                text-overflow:ellipsis;
                text-align: center;
                white-space: nowrap;
                width: 100%;
              }
              &:nth-child(2) {
                color: #FF5203;
                font-weight: 600;
                font-size: to(40);
              }
            }
          }
        }
        .order_info {
          .order_info_item {
            display: flex;
            justify-content: space-between;
            margin-top: to(39);
            color: #767676;
            font-size: to(36);
          }
        }
      }
      .content_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: to(616);
        .pay_type {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 to(30);
          height: to(140);
          width: 100%;
          border-radius: to(20);
          background: #FFFFFF;
          color: #262727;
          font-size: to(36);
          box-sizing: border-box;
        }
        .address {
          flex: 1;
          margin-top: to(34);
          padding: to(48) to(38);
          background: #FFFFFF;
          height: 100%;
          box-sizing: border-box;
          border-radius: to(20);
          .add_address {
            display: flex;
            >img {
              width: to(50);
              height: to(55);
            }
            .tip_text {
              flex: 1;
              padding: 0 to(26);
              box-sizing: border-box;
              color: #9E9E9E;
              font-size: to(36);
            }
            .line {
              margin-right: to(21);
              width: to(1.92);
              height: to(50);
              background: #D8D8D8;
            }
            .add_btn {
              color: #000000;
              font-size: to(36);
              font-weight: 600;
            }
          }
          .address_detail {
            display: flex;
            >img {
              margin-right: to(34);
              width: to(50);
              height: to(55);
            }
            .address_text {
              max-width: to(480);
              >span {
                display: inline-block;
                color: #262727;
                font-size: to(32);
                font-weight: 500;
              }
              .user_info {
                margin-top: to(30);
                color: #696969;
                font-size: to(36);
                span {
                  padding-right: to(17);
                }
              }
            }
          }
        }
      }
    }
    .bottom_btn {
      display: flex;
      justify-content: flex-end;
      margin-top: to(62);
      width: 100%;
      .sub_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: to(380);
        height: to(90);
        border-radius: to(45);
        background: linear-gradient(90deg, #FE7F02 0%, #FF5203 100%);
        font-size: to(36);
        color: #FFFFFF;
      }
    }
  }
}