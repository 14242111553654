@function to($px) {
  @return ($px / 2558) * 100vw;
}

.my_modal {
  :global {
    .adm-modal-body {
      padding: 0;
    }
    
    .adm-modal-content {
      padding: 0;
      max-height: 100vh;
    }
    
    .adm-modal-footer-empty {
      height: 0;
    }
  }
}

.gem_transfer {
  position: relative;
  padding: to(62) to(144) to(0) to(92);
  width: to(1850);
  height: to(960);
  border-radius: to(40);
  box-sizing: border-box;
  border: to(8) solid #FFFFFF;
  background: linear-gradient(180deg, #FEEED5 0%, #F4BFAB 100%);

  .close_img {
    position: absolute;
    right: to(18);
    top: to(18);
    width: to(62);
    height: to(62);
  }

  .gem_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      &:nth-child(1) {
        color: #3D3D3D;
        font-size: to(50);
        font-weight: 600;
      }
      &:nth-child(2) {
        color: #9E9E9E;
        font-size: to(36);
        font-weight: 500;
      }
    }
  }

  .gemstore_counts {
    margin-top: to(23);
    color: #3D3D3D;
    font-size: to(45);
  }

  .input_box {
    display: flex;
    align-items: center;
    margin-top: to(77);

    &.second_input {
      margin-top: to(30);
    }

    .label {
      margin-right: to(73);
      color: #3D3D3D;
      font-size: to(36);
      width: to(200);
      text-align: right;
    }

    .input {
      padding: 0 to(80);
      width: to(1327);
      height: to(95);
      border-radius: to(15);
      background: #FFFFFF;
      box-sizing: border-box;

      :global {
        .adm-input-element {
          color: #989898;
          font-size: to(36);
        }
      }
    }
  }

  .commission_tip {
    margin-top: to(10);
    padding-left: to(350);
    color: #3D3D3D;
    font-size: to(32);
  }

  .tip_empty {
    margin-top: to(86);
  }

  .transfer_btn {
    margin: to(200) auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: to(755);
    height: to(110);
    border-radius: to(55);
    background: linear-gradient(270deg, #D6F38C 0%, #9EDCC2 100%);
    color: #000000;
    font-size: to(40);
    font-weight: 600;
    letter-spacing: to(10);
  }
}

.set_pwd_tip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: to(720);
  width: to(1170);
  border-radius: to(32);
  box-sizing: border-box;
  background: #FFFFFF;

  > img {
    width: to(167);
    height: to(157);
  }

  > p {
    text-align: center;
    color: #262727;
    font-size: to(36);
  }

  .footer_btn {
    display: flex;
    margin-top: to(113);

    .btn_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: to(400);
      height: to(120);
      font-size: to(48);
      border-radius: to(60);

      &.cancel {
        margin-right: to(130);
        background: #E7E7E7;
        color: #696969;
      }

      &.goset {
        background: linear-gradient(90deg, #FE7F02 0%, #FF5203 100%);
        color: #FFFFFF;
      }
    }
  }
}

.input_pwd {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: to(48);
  height: to(720);
  width: to(1170);
  border-radius: to(32);
  box-sizing: border-box;
  background: #FFFFFF;

  > h1 {
    color: #262727;
    font-size: to(40);
    font-weight: 500;
  }

  .close {
    position: absolute;
    right: to(20);
    top: to(20);
    width: to(62);
    height: to(62);
  }

  .pwd_box {
    position: relative;
    margin-top: to(92);
    .pwd_list {
      display: flex;

      .pwd_item {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F0F0F0;
        width: to(120);
        height: to(120);
        border-radius: to(8);
        margin-right: to(46);
        color: #3D3D3D;
        font-size: to(64);

        &:nth-child(6) {
          margin-right: 0;
        }
      }
    }

    .pwd_input {
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: to(120);
      opacity: 0;

      :global {
        .adm-input-element {
          height: to(120);
          color: transparent;
          max-width: 100vw;
        }
      }
    }
  }

  .forget_pwd {
    margin-top: to(38);
    padding: 0 to(110);
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    color: #9E9E9E;
    font-size: to(24);
    text-decoration: underline;
  }

  .footer_btn {
    display: flex;
    margin-top: to(112);

    .btn_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: to(400);
      height: to(120);
      font-size: to(48);
      border-radius: to(60);

      &.cancel {
        margin-right: to(130);
        background: #E7E7E7;
        color: #696969;
      }

      &.goset {
        background: linear-gradient(90deg, #FE7F02 0%, #FF5203 100%);
        color: #FFFFFF;
      }
    }
  }
}

.transfer_record {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: to(62);
  width: to(1850);
  height: to(960);
  border-radius: to(40);
  box-sizing: border-box;
  border: to(8) solid #FFFFFF;
  background: linear-gradient(180deg, #FEEED5 0%, #F4BFAB 100%);

  > h1 {
    padding-left: to(92);
    font-size: to(50);
    color: #3D3D3D;
    font-weight: 600;
  }

  .close {
    position: absolute;
    right: to(35);
    top: to(35);
    width: to(62);
    height: to(62);
  }

  .tabs {
    :global {
      --content-padding: 0;
      .adm-tabs-tab-line {
        --active-line-color: #346AFF;
        height: to(8);
      }
      .adm-tabs-tab {
        color: #3D3D3D;
        font-size: to(45);
      }
      .adm-tabs-header {
        border-bottom: none;
      }
      .adm-tabs-tab-list {
        margin: 0 to(400);
      }
    }

    .tab_title {
      font-size: to(45);
      color: #3D3D3D;

      &.tab_select {
        background: linear-gradient(102deg, #6BBDFE 1%, #AA9FFE 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-weight: 500;
      }
    }
  }

  .tabs_content {
    margin: to(70) to(109) 0 to(141);
    flex: 1;
    overflow: auto;

    .list_item {
      display: flex;
      flex-direction: column;
      padding: to(36) to(111) 0 to(60);
      margin-bottom: to(30);
      box-sizing: border-box;
      width: 100%;
      height: to(220);
      background: rgba(255, 255, 255, 0.8);
      border-radius: to(15);
      color: #6D6D6D;
      font-size: to(36);

      .item_top {
        display: flex;
        justify-content: space-between;
        > div {
          &:nth-child(1) {
            margin-bottom: to(47);
          }
        }
      }

      .time_span {
        display: inline-block;
        width: to(200);
        text-align: right;
      }
    }
  }
}
