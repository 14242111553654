$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}

.land-up-order {
    position: relative;
    // margin: to(43) to(124) 0;
    padding: to(75) to(120);
    width: calc(100% - #{to(248)});
    height: calc(100% - #{to(43)});
    background: url("~@/assets/img/landfi/order_bg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &>div {

        /* content */
        &:nth-child(2) {
            flex: 1;
            display: flex;
            justify-content: space-between;

            &>div {
                color: #a05d20;

                /* left */
                &:nth-child(1) {
                    width: to(1180);
                    background: #f1d7ae;
                    border-radius: to(17);
                    padding: to(64) to(64) to(64) to(46);
                    box-sizing: border-box;

                    &>div {

                        /* top */
                        &:nth-child(1) {
                            display: flex;

                            h1 {
                                font-size: to(40);
                                font-weight: 600;

                                color: #a05d20;
                            }

                            h2 {
                                font-size: to(64);
                                font-weight: 600;
                            }

                            &>div {
                                margin-bottom: to(48);

                                &:nth-child(1) {
                                    width: to(300);
                                    height: to(300);
                                    margin-right: to(52);

                                    background: url("~@/assets/img/landfi/img_thu_bg.png");
                                    background-size: 100% 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }

                                &:nth-child(2) {
                                    padding: to(26) 0;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    box-sizing: border-box;
                                }
                            }
                        }

                        /* bottom */
                        &:nth-child(2) {
                            .adm-input {
                                --placeholder-color: #fff;
                                --font-size: #{to(30)};
                                --color: #fff;
                                padding: 0 to(20);
                                min-height: to(60);
                            }

                            &>div {
                                display: flex;
                                justify-content: space-between;

                                &:not(:last-child) {
                                    margin-bottom: to(40);
                                }

                                span {
                                    font-weight: 500;
                                    font-size: to(41);

                                    >span {
                                        font-size: to(30);
                                    }
                                }

                                &:nth-child(2) {
                                    font-size: to(36);
                                }

                                &:nth-child(3) {
                                    width: to(260);
                                    height: to(60);
                                    background: #d48741;
                                    border-radius: to(7);
                                }
                            }
                        }
                    }
                }

                /* right */
                &:nth-child(2) {
                    width: to(858);
                    background: #f1d7ae;
                    border-radius: to(17);
                    padding: to(32) to(50);
                    box-sizing: border-box;

                    &>div {

                        /* info */
                        &:nth-child(1) {
                            margin-bottom: to(70);

                            &>div {
                                display: flex;
                                justify-content: space-between;
                                font-size: to(41);
                                font-weight: 500;
                                margin-bottom: to(32);
                                color: #a05d20;

                                span {
                                    font-size: to(30);
                                }
                            }
                        }

                        /* select */
                        &:nth-child(2) {
                            h1 {
                                font-size: to(48);
                                font-weight: 500;
                                color: #a05d20;
                                padding-bottom: to(20);
                                margin-bottom: to(70);
                                border-bottom: to(1) solid #a05d20;
                                box-sizing: border-box;
                            }

                            .land-up-order__selectpay {
                                &>div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin-bottom: to(40);

                                    &>label {
                                        &:nth-child(1) {
                                            display: flex;
                                            align-items: center;

                                            span {
                                                margin-left: to(20);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
                margin: to(40);

                span {
                    &:nth-child(1) {
                        font-size: to(41);
                        font-weight: 500;
                    }

                    &:nth-child(2) {
                        margin-left: to(10);
                        font-size: to(30);
                        font-weight: 500;
                        color: #ff7800;
                    }

                    &:nth-child(3) {
                        font-size: to(58);
                        font-weight: 600;
                        color: #ff7800;
                    }
                }
            }
        }
    }

    &__title {
        font-family: YueGenYouShangYueBoHei;
        font-size: to(60);
        font-weight: bold;
        text-align: center;
        color: #371f00;
        margin-bottom: to(40);
    }
}