$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
  @return ($px / 2558) * 100vw;
}

.land-game {
  overflow: hidden;
  font-size: to(40) !important;

  .adm-input {
    min-height: 0;
  }

  .adm-radio-icon {
    border-color: #d57b2b !important;
  }
  .adm-radio-checked {
    .adm-radio-icon {
      background-color: #d57b2b;
    }
  }

  .adm-switch {
    --width: #{to(120)};
    --height: #{to(60)};
    --checked-color: #d57b2b;
    --adm-color-background: #a05d20;
  }
  .adm-switch-checkbox {
    background: #874D2B;
    &::before {
      background: #874D2B;
    }
  }

  &-icon {
    width: to(76);
    height: to(76);
    background-size: 100% 100%;
    &__alipay {
      background-image: url("~@/assets/img/landfi/icon_pay_zfb.png");
    }
    &__wxpay {
      background-image: url("~@/assets/img/landfi/icon_pay_wx.png");
    }
    &__exp {
      width: to(90);
      height: to(72);
      background-image: url("~@/assets/img/landfi/icon_exp.png");
    }
    &__diamond {
      width: to(100);
      height: to(100);
      background-image: url("~@/assets/img/landfi/icon-gem.png");
    }
    &__treasure {
      width: to(345);
      height: to(210);
      background-image: url("~@/assets/img/landfi/icon_k.png");
      background-size: 100% 100%;
    }
  }

  &-scenes {
    background: url("~@/assets/img/landfi/bg.png");
    background-size: 100% 100%;
    &.mode2 {
      background: url("~@/assets/img/landfi/landgy/bg-2.png");
      background-size: 100% 100%;
    }
    
  }

  &-title {
    position: absolute;
    top: to(-39);
    left: to(100);
    padding-top: to(124);
    padding-left: to(74);
    width: to(415);
    height: to(234);
    background: url('~@/assets/img/landfi/title-bg.png') no-repeat;
    background-size: 100% 100%;
    color: #FFE9D3;
    font-size: to(62);
    font-weight: bold;
    text-shadow: 0px 1px 2px #000000;
    box-sizing: border-box;
    font-family: YueGenYouShangYueBoHei;
  }

  &-leftarrow {
    position: absolute;
    top: 50%;
    left: to(120);
    transform: translateY(-50%);
    width: to(105);
    height: to(196);
    background: url("~@/assets/img/landfi/left-arrow.png");
    background-size: 100% 100%;

    &.activity {
      background: url("~@/assets/img/landfi/right-arrow.png");
      background-size: 100% 100%;
      transform: rotate(180deg) translateY(50%);
      transform-origin: center center;
    }
  }

  &-rightarrow {
    position: absolute;
    top: 50%;
    right: to(120);
    transform: translateY(-50%);
    width: to(105);
    height: to(196);
    background: url("~@/assets/img/landfi/right-arrow.png");
    background-size: 100% 100%;

    &.disabled {
      background: url("~@/assets/img/landfi/left-arrow.png");
      background-size: 100% 100%;
      transform: rotate(180deg) translateY(50%);
      transform-origin: center center;
    }
  }

  &-noitalics {
    transform: translateY(-44%) rotate(-45deg) skew(12deg, 12deg);
  }

  &-receive-tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    padding-top: to(40);
    top: 37%;
    left: 44.8%;
    width: to(268);
    height: to(215);
    background: url("~@/assets/img/landfi/img_brand.png") no-repeat;
    background-size: 100% 100%;
    z-index: 10;
    box-sizing: border-box;
    font-family: YueGenYouShangYueBoHei;

    & > span {
      color: #792b06;
      font-size: to(48);
      font-weight: bold;
      line-height: to(36);
    }
  }

  &-receive-tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    padding-top: to(40);
    top: 37%;
    left: 44.8%;
    width: to(268);
    height: to(215);
    background: url("~@/assets/img/landfi/img_brand.png") no-repeat;
    background-size: 100% 100%;
    z-index: 10;
    box-sizing: border-box;
    font-family: YueGenYouShangYueBoHei;

    & > span {
      color: #792b06;
      font-size: to(48);
      font-weight: bold;
      line-height: to(36);
    }
  }

  &-receive-tip-mode2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    padding-top: to(21);
    top: 37%;
    left: 44.8%;
    width: to(268);
    height: to(215);
    background: url("~@/assets/img/landfi/landgy/img_brand.png") no-repeat;
    background-size: 100% 100%;
    z-index: 10;
    box-sizing: border-box;
    font-family: YueGenYouShangYueBoHei;

    & > span:first-child {
      color: #000000;
      font-size: to(38);
      font-weight: bold;
      line-height: to(36);
    }
    & > span:last-child {
      color: #000000;
      font-size: to(26);
      font-weight: bold;
      line-height: to(36);
    }
  }
  &-brand {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: to(25);
    width: to(215);
    height: to(268);
    // background: url("~@/assets/img/landfi/img_brand.png") no-repeat;
    // background-size: 100% 100%;
    transform: translate(-41%, -33%) rotate(-45deg);
    font-family: YueGenYouShangYueBoHei;
    &-click-box {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 999;
      width: 10vw;
      height: 10vw;
      transform: translate(-10%, 35%) rotate(45deg) skew(0deg, 0deg);
    }
    & > span {
      color: #792b06;
      font-size: to(35);
      font-weight: 700;
      line-height: to(36);
      &:nth-child(2) {
        margin-top: to(8);
        font-size: to(24);
      }
    }
  }

  &-modal {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: to(60) to(120);
    background: #000000b3;
    box-sizing: border-box;
    z-index: 2;

    & > div {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-right: to(200);
      padding-left: to(161);
      background: url("~@/assets/img/landfi/land-detail-bg.png") no-repeat;
      background-size: 98% 100%;
      background-position: center;
      padding-top: to(120);
      box-sizing: border-box;
    }

    h1 {
      font-family: YueGenYouShangYueBoHei;
      font-size: to(60);
      font-weight: bold;
      text-align: center;
      color: #371f00;
    }
    h6 {
      font-size: to(32);
      font-weight: 500;
      color: #a05d20;
      margin-bottom: to(120);
    }

    .land-game-icon__treasure {
      width: to(470);
      height: to(404);
      margin-top: to(20);
      margin-bottom: to(40);
    }
  }
}

.land-text {
  color: white;
  font-size: to(40);
  font-weight: bold;
  text-align: center;
}

.land-text-black-32 {
  color: #3d3d3d;
  font-size: to(40);
  font-weight: bold;
  text-align: center;
}

