$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.land-collection-recovery-home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 22*$rem;
  padding-bottom: 3*$rem;
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/img/collectionRecoveryLand/landape-recycling-bg.png") no-repeat center center / 100% 100%;
  box-sizing: border-box;

  .back {
    position: absolute;
    top: 5.5*$rem;
    left: 1.4*$rem;
    width: 1*$rem;
    height: 1.8*$rem;
    background: url("~@/assets/img/icon/back.png") no-repeat center center / 100% 100%;
  }

  @mixin common_Btn {
    width: 2.3*$rem;
    height: 6*$rem;
    border-radius: 0.6*$rem 0px 0px 0.6*$rem;
    opacity: 0.7;
    background: #FFFFFF;
    color: #CD2608;
    font-size: 1.1*$rem;
    font-weight: bold;
    writing-mode: vertical-lr;
    text-align: center;
    line-height: 2.3*$rem;
  }

  .activity_rules {
    position: absolute;
    top: 8.6*$rem;
    right: 0;
    @include common_Btn();
  }

  .real_name {
    position: absolute;
    top: 16.4*$rem;
    right: 0;
    @include common_Btn();
  }

  .game_container {
    display: inline-block;
    width: 100%;
    height: 40.5*$rem;
    background: url("~@/assets/img/collectionRecoveryLand/game-bg.png") no-repeat center center / 100% 100%;

    .time_record {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10.9*$rem auto 0;
      width: 80%;

      .lottery_record {
        font-size: 1.3*$rem;
        font-weight: 500;
        color: #9F2800;
      }
    }

    .Jackpot_box {
      display: flex;
      margin: 1.2*$rem auto 0;
      justify-content: center;
      // justify-content: space-between;
      // padding: 0 2.3*$rem;
      width: 32*$rem;
      height: 9.8*$rem;
      background: url("~@/assets/img/collectionRecoveryLand/Jackpot -total-bg.png") no-repeat center center / 100% 100%;
      font-size: 3.5*$rem;
      font-weight: 600;
      color: #D10073;
      box-sizing: border-box;

      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5*$rem;
        height: 8.8*$rem;
      }
    }

    .recycling_times {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2.2*$rem auto 0;
      color: #FFFFFF;
      font-size: 1.2*$rem;
      font-weight: 600;
      width: 80%;

      span {
        font-size: 1.5*$rem;
        color: #FD1515;
      }
    }

    .game_btn_box {
      display: flex;
      margin: 2.4*$rem auto 0;
      width: fit-content;

      .left_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0.7*$rem;
        margin-left: 1.3*$rem;
        padding-top: 0.5*$rem;
        width: 11.45*$rem;
        height: 6.2*$rem;
        background: url("~@/assets/img/collectionRecoveryLand/game-left-btn-bg.png") no-repeat center center / 100% 100%;
        color: #890076;
        font-weight: 600;
        box-sizing: border-box;
        transition: transform 0.3s;
        transform: scale(1);
        &:active {
          transform: scale(0.88);
        }

        &.disabled {
          background: url("~@/assets/img/collectionRecoveryLand/left-btn-dis-bg.png") no-repeat center center / 100% 100%;
          color: #676767;
          pointer-events: none;
        }

        >span {
          &:nth-child(1) {
            font-size: 1*$rem;
          }
          &:nth-child(2) {
            font-size: 1.6*$rem;
          }
        }

        >span {
          &:nth-child(1) {
            font-size: 1*$rem;
          }
          &:nth-child(2) {
            font-size: 1.6*$rem;
          }
        }
      }

      .right_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.5*$rem;
        width: 14.1*$rem;
        height: 6.2*$rem;
        background: url("~@/assets/img/collectionRecoveryLand/game-right-btn-bg.png") no-repeat center center / 100% 100%;
        color: #643904;
        font-weight: 600;
        box-sizing: border-box;
        transition: transform 0.3s;
        transform: scale(1);
        &:active {
          transform: scale(0.88);
        }

        &.disabled {
          background: url("~@/assets/img/collectionRecoveryLand/right-btn-dis-bg.png") no-repeat center center / 100% 100%;
          color: #676767;
          pointer-events: none;
        }

        >span {
          &:nth-child(1) {
            font-size: 1*$rem;
          }
          &:nth-child(2) {
            font-size: 1.6*$rem;
          }
        }
      }
    }
  }

  .game_bottom {
    height: 2.2*$rem;
    background: linear-gradient(93deg, #FF8C45 0%, #E57B23 100%);
  }

  .logo_box {
    margin: 0 auto;
    width: 14.8*$rem;
    height: 2.5*$rem;
    background: url("~@/assets/img/collectionRecoveryLand/logo.png") no-repeat center center / 100% 100%;
  }
}

.landape-recycling-rules {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31.1*$rem;
  height: 40.8*$rem;
  border-radius: 0.75*$rem;
  background: rgba(255, 255, 255, .5);

  .rules-outer {
    padding: 1.4*$rem 2.4*$rem 1.9*$rem;
    width: 29.8*$rem;
    height: 39*$rem;
    background: #FFFFFF;
    border-radius: 0.75*$rem;
    display: flex;
    flex-direction: column;
    background: linear-gradient(143deg, #FFE6D9 -4%, #FF8352 37%, #FFDFA3 99%);
    box-sizing: border-box;
    .rules-title {
      padding-bottom: 1.1*$rem;
      color: #FFFFFF;
      font-size: 1.8*$rem;
      font-weight: bold;
      text-align: center;
    }
    .rules-content {
      flex: 1;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow-y: auto;
      white-space: pre-line;
      line-height: 1.7*$rem;
      color: #FFFFFF;
      font-size: 1.2*$rem;
    }
    .rules-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1*$rem auto 0;
      width: 9.8*$rem;
      height: 3*$rem;
      border-radius: 1.5*$rem;
      background: #FF8655;
      color: #FFFFFF;
      font-size: 1.6*$rem;
      font-weight: 500;
    }
  }
}

.landape_recycling_type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31.4*$rem;
  height: 33.9*$rem;
  border-radius: 0.75*$rem;
  background: rgba(255, 255, 255, .5);

  .type_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 29.8*$rem;
    height: 32.2*$rem;
    border-radius: 0.75*$rem;
    background: linear-gradient(137deg, #FFE6D9 0%, #FF8352 37%, #FFDFA3 100%);

    .close {
      position: absolute;
      right: 1*$rem;
      top: 0.8*$rem;
      width: 1.1*$rem;
      height: 1.1*$rem;
      background: url("~@/assets/img/collectionRecoveryLand/close_x.png") no-repeat center center / 100% 100%;
    }

    .title {
      margin-top: 2.5*$rem;
      font-size: 1.2*$rem;
      font-weight: 600;
      color: #9C003C;
    }

    .choose_tip {
      margin-top: 0.35*$rem;
      margin-bottom: 3*$rem;
      color: #FFFFFF;
      font-size: 1.6*$rem;
      font-weight: bold;
    }

    .type_box {
      display: flex;
      justify-content: space-between;
      padding: 0 0.9*$rem;
      width: 100%;
      box-sizing: border-box;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        > img {
          width: 7.5*$rem;
          height: 7.5*$rem;
        }

        .ape_type {
          margin-top: 1.3*$rem;
          margin-bottom: 1.5*$rem;
          color: #FFFFFF;
          font-size: 1.4*$rem;
          font-weight: 600;
        }

        .deuce_points {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 7.7*$rem;
          height: 5.8*$rem;
          border-radius: 0.75*$rem;
          background: linear-gradient(180deg, #FFDAB7 0%, #FFEED0 100%);
          color: #643904;
          font-size: 1.2*$rem;
          font-weight: 600;
        }

        .deuce_times {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 0.4*$rem;
          color: #643904;
          font-size: 1*$rem;
          font-weight: 600;
        }
      }
    }
  }
}