@import '@/assets/scss/variable.scss';

.container {
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;

  .content {
    width: 75%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    .middle {
      //background-color: #00b578;
      position: relative;

      .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 2.5 *$rem;
        height: 2.5 *$rem;
        padding: 5px;
      }
      .back{
        position: absolute;
        left: 5%;
        top: 0;
        width: 2.5 *$rem;
        height: 2.5 *$rem;
        padding: 5px;
      }
    }
  }

  .bgImg {
    width: 100%;
  }


    .yshc{//元素合成
      position: absolute;
      top: 15%;
      left: 43%;
      width: 16%;
    }

    .hsdmh { //回收得盲盒
      position: absolute;
      top: 15.5%;
      left: 37.5%;
      width: 26%;
    }
    .buymh { //购买盲盒
      position: absolute;
      top: 15.5%;
      left: 39%;
      width: 23%;
    }

  .otherCom {
    width: 76%;
    height: 55%;
    //background-color: purple;
    position: absolute;
    top: 28%;
    left: 13%;
    display: flex;
    flex-direction: column;
  }


}
