$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.pay_page {
    height: 100vh;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    &--body {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding-top: 2.5*$rem;
        .body-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: scroll;
        }
        .bottom {
            display: flex;
            flex-direction: column;
        }
    }
}