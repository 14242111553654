@function to($px) {
  @return ($px / 2558) * 100vw;
}

.land_lease_item {
  display: inline-block;
  margin-bottom: to(63);
  margin-right: to(63);
  &:nth-child(3n) {
    margin-right: 0;
  }
  
  &_box {
    .item_out {
      position: relative;
      border: to(1.57) dashed #B5641E;
      box-shadow: 0px to(6.29) 0px 0px rgba(181, 100, 30, 0.8),0px to(6.29) to(15.73) 0px #FFE8D3,inset 0px to(6.29) to(15.73) 0px #FFC9A9;
      background: linear-gradient(180deg, #F3CEAC 0%, #E7B17F 100%);
      box-sizing: border-box;
      border-radius: to(28);
      width: to(579);
      height: to(252);

      &.item_big {
        border-bottom: none;
        box-shadow: none;
        border-radius: to(28) to(28) 0 0;
      }

      .work_tag {
        position: absolute;
        left: to(-1.57);
        top: to(-1.57);
        width: to(158);
        height: to(62);
        background: url('~@/assets/img/landfi/work-tag-bg.png') no-repeat;
        background-size: 100% 100%;
        line-height: to(62);
        text-align: center;
        color: #FFFFFF;
        font-size: to(32);
        font-weight: 600;
      }
    }

    .item_top {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .item_left {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: to(28);
      width: to(186);
      height: to(186);
      border-radius: to(22);
      border: to(1) solid #FFFFFF;
      box-sizing: border-box;

      &.land_img {
        padding: 0 to(10);
      }
      
      > img {
        width: 100%;
        height: 100%;
        border-radius: to(22);
        object-fit: contain;
      }
    }
  
    .item_right {
      display: flex;
      flex-direction: column;
      .goods_name {
        color: #8D3218;
        font-size: to(44);
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display:-webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        width: to(300);
      }
  
      .goods_lease_days {
        margin-bottom: to(10);
        color: #C8450B;
        font-size: to(38);
        font-weight: 500;
      }
  
      .goods_price {
        display: flex;
        align-items: center;
        padding-left: to(10);
        width: to(333.5);
        height: to(72.4);
        box-shadow: inset 0px -1.57px 7.87px 0px #FFFFFF,inset 0px 6.29px 31.47px 0px #FFFFFF;
        background: #FFD87E;
        border-radius: to(137);
        color: #FFFFFF;
        font-size: to(44);
        font-weight: bold;
        font-family: YueGenYouShangYueBoHei;
        text-shadow: to(1.5) to(2) to(1) #000;
        box-sizing: border-box;
  
        > img {
          margin-left: to(-38);
          margin-top: to(10);
          width: to(146);
          height: to(146);
        }
  
        > span {
          margin-left: to(-10);
          flex: 1;
          overflow: hidden;
        }
      }
    }
    .radio {
      position: absolute;
      right: to(13);
      top: to(13);
      width: to(63);
      height: to(63);
      border-radius: 50%;
      background: #FFFFFF;
      border: to(1.57) solid #B6B6B6;

      &.active {
        background: url('~@/assets/img/landfi/radio-icon.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .lease_term_time {
      display: flex;
      align-items: center;
      justify-content: center;
      width: to(579);
      height: to(82);
      border-radius: 0 0 to(28) to(28);
      background: rgba(255, 255, 255, 0.9);
      font-size: to(35);
      font-weight: 500;
      box-shadow: 0px 6.29px 15.73px 0px #E3B796;
      border-top: to(1.57) dashed #B5641E;
      box-sizing: border-box;

      > span {
        &:nth-child(1) {
          margin-right: to(20);
          color: #A25A2B;
        }
        &:nth-child(2) {
          color: #3AA700;
        }
      }
    }
  }
}