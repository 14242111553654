$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.guessing_acty_container {
  width: 100vw;
  min-height: 100%;
  //height: 100vh;
  //display: flex;
  background-color: #C0E7FF;
  padding-bottom: 3*$rem;
  position: relative;
}

.guessing_acty_img_bg {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.guessing_acty_back_share_father {
  position: fixed;
  top: 3.8*$rem;
  width: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;

  .back_father {
    margin-left: 0.5*$rem;
    padding: 0 10px;

    .back_btn {
      width: 0.95*$rem;
      height: 1.75*$rem;
      background: url(~@/assets/img/icon/icon_back_black.png) no-repeat;
      background-size: 100%;
    }
  }

  .share_father {
    margin-right: 0.8*$rem;
    padding: 0 10px;

    .share {
      width: 1.7*$rem;
      height: 1.85*$rem;
      background: url(~@/assets//img/icon_link_black.png) no-repeat;
      background-size: 100%;
    }
  }
}

.rule_shiming_father {
  position: absolute;
  right: 0;
  top: 9 *$rem;
  width: 5 *$rem;
  //height: 9.6 *$rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  > p {
    white-space: pre-line;
    width: 1.9 *$rem;
    height: 4.2 *$rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 6px 0px 0px 6px;
    opacity: 0.8;
    background: rgba(0, 38, 191, 0.6);
    font-size: 1.2 *$rem;
    font-weight: bold;
    color: #FFFFFF;
    padding-right: 1.5px;

    &.martop{
      margin-top: 1.2 *$rem;
    }
    &.paihangbang{
      height: 6 *$rem;
    }
  }
}

.rule_scanner_father {
  position: absolute;
  left: 0;
  top: 21 *$rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  > p {
    white-space: pre-line;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 6px 6px 0;
    opacity: 0.8;
    background: rgba(240, 158, 29, 1);
    font-size: 1.2 *$rem;
    font-weight: bold;
    color: #FFFFFF;
    box-sizing: border-box;
    padding: 6px;
  }
}

.guessing_buycoll_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: -6 *$rem;
  align-items: flex-end;
  justify-content: center;
  z-index: 2;
  position: relative;

  > :nth-child(1) {
    width: 44vw;
    height: 7 *$rem;
    display: inline-block;
    position: absolute;
    left: 1.0 *$rem;
  }

  > :nth-child(2) {
    width: 4.1 *$rem;
    height: 8.1 *$rem;
    display: inline-block;
  }

  > :nth-child(3) {
    width: 44vw;
    height: 7 *$rem;
    display: inline-block;
    position: absolute;
    right: 1.0 *$rem;
  }
}

.guessing_middle_container {
  width: 94%;
  min-height: 200px;
  margin: -4.5 *$rem auto;
  border: 2px solid #FFFFFF;
  border-radius: 50px 50px 30px 30px;
  background-color: rgba(160, 203, 255, 0.76);
  padding-top: 5.2 *$rem;
  padding-bottom: 3 *$rem;
  position: relative;

  .guessing_middle_top_container {
    position: relative;
    width: 100%;
    height: 32 *$rem;
    border: 0.1px solid transparent;

    .guessing_middle_img {
      width: 97%;
      height: 100%;
      position: absolute;
      left: 1%;
      top: 0;
    }

    .yx_des_container {
      position: absolute;
      width: 100%;
      height: 100%;

      .yx_btn_record {
        position: absolute;
        top: 3 *$rem;
        right: 3 *$rem;
        display: inline-block;
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        font-weight: normal;
        line-height: 30px;
        color: #2E63F6;
      }

      .yx_des {
        width: 83%;
        margin: 6 *$rem auto 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.yx_des1{
          margin: 0 *$rem auto;
        }

        > span {
          color: #2E63F6;
          font-size: 1.3 *$rem;
          font-weight: 600;
          text-align: center;
          white-space: pre-line;

          &.span1{
            color: #2E63F6;
            font-size: 1.0 *$rem;
            text-align: center;
            white-space: pre-line;
            margin: 0.5 *$rem 0 ;
          }

          > :nth-child(1) {
            color: #E83E5D;
            font-size: 2.3 *$rem;
            font-weight: bold;
          }
        }
      }

      .yx_btn {
        //background-color: red;
        width: 50%;
        height: 7 *$rem;
        margin: 0.0 *$rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.6 *$rem;

        > img {
          width: 100%;
          height: 100%;

          &.img_dis {
            width: 17.6 *$rem;
            height: 4.3 *$rem;
          }
        }
      }

      .fuli_container {
        width: 90%;
        min-height: 45px;
        margin: 2 *$rem auto 0;

        .fuli_container_0 {
          display: flex;
          flex-direction: row;

          .fuli_container_left {
            width: 7.6 *$rem;
            height: 3 *$rem;
            position: relative;


            .txt_bg {
              display: inline-block;
              width: 7.6 *$rem;
              height: 1 *$rem;
              background-color: #D4ECFF;
              border-radius: 5px;
              margin-top: 1.7 *$rem;

            }

            .txt {
              display: inline-block;
              width: 7.6 *$rem;
              text-align: center;
              z-index: 1;
              color: #2E63F6;
              font-size: 1.3 *$rem;

              position: absolute;
              bottom: 0.5 *$rem;
              left: 0;
            }
          }

          .des {
            display: inline-block;
            margin-left: 1 *$rem;
            margin-top: 1 *$rem;
            color: #1A1A1A;
            font-size: 1.2 *$rem;
          }
        }
      }
    }
  }

  .start_time {
    width: 97%;
    margin-top: 1.5 *$rem;
    margin-left: 1%;

  }

  .lianji_container {
    width: 78%;
    margin: -4.2 *$rem auto 0;
    height: 4*$rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    position: relative;

    > img {
      width: 1.2 *$rem;
      height: 100%;
    }
  }

  .guessing_middle_middle_container {
    width: 96%;
    min-height: 200px;
    margin: -1.2 *$rem auto;
    border-radius: 17px;
    background: rgba(255, 255, 255, 0.5);
    border: 0.1px solid transparent;

    .guess_middle_top {
      width: 100%;
      margin-top: 2 *$rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > img {
        width: 15 *$rem;
        margin-left: 10px;
      }

      > p {
        width: 8 *$rem;
        height: 2.6 *$rem;
        opacity: 1;
        background: #669BF3;
        border-radius: 13px 0px 0px 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
    }

    .award_list {
      width: 94%;
      min-height: 11 *$rem;
      margin: 1 *$rem auto;
      //background-color: whitesmoke;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 0.1px solid transparent;

      > img {
        width: 100%;
        //margin-top: 1 *$rem;
      }

      .des_container {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5 *$rem;

        > p {
          white-space: pre-line;
          text-align: center;
          font-size: 1.1 *$rem;
          text-align: center;
          color: #1A1A1A;
        }
      }
    }

    .pic_fs {
      width: 18 *$rem;
      margin-left: 10px;
    }

    .guess_get_container {
      width: 94%;
      min-height: 100px;
      //background-color: red;
      margin: 1 *$rem auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .item {
        width: 48.5%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1.0 *$rem;
        border-radius: 10px;

        .item_bg {
          width: 90%;
          margin-top: 2 *$rem;
        }

        .item_title {
          width: 90%;
          margin-top: 1 *$rem;

          > span {
            font-family: PingFangSC-Regular;
            font-size: 1.2 *$rem;
          }

          > :nth-child(1) {
            color: #1A1A1A;
          }

          > :nth-child(2) {
            color: #E83E5D;
          }

          > :nth-child(3) {
            color: #1A1A1A;
          }

          > :nth-child(4) {
            color: #E83E5D;
          }
        }

        .btn {
          width: 90%;
          //height: 4 *$rem;
          margin-top: 1 *$rem;
        }

        .item_des_money {
          width: 90%;

          > :nth-child(1) {
            font-size: 1.7 *$rem;
            color: #E83E5D;
            font-weight: bold;
          }

          > :nth-child(2) {
            color: #E83E5D;
            font-size: 1.3 *$rem;
            font-weight: bold;
          }
        }

        .item_des {
          margin-top: 1 *$rem;
          color: #2E63F6;
          font-size: 1.1 *$rem;

          &.right {
            color: #E83E5D;
          }
        }
      }
    }
  }
}

.exchange_shop {
  width: 94%;
  height: 38 *$rem;
  margin: 5.5 *$rem auto 0;
  border: 2px solid #FFFFFF;
  border-radius: 30px;
  background-color: rgba(160, 203, 255, 0.76);
  padding-bottom: 3 *$rem;
  position: relative;
  //background-color: red;

  .exchange_shop_bg {
    width: 103%;
    height: 103%;
    margin-left: -2 *$rem;
    margin-top: -2.5 *$rem;
    position: absolute;
  }

  .exchange_shop_container {
    width: 100%;
    height: 100%;
    //background-color: purple;
    position: relative;
    z-index: 1;
    border: 0.1px solid transparent;
  }

  .ex_top {
    width: 64%;
    height: 3 *$rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 0;
    top: 3.1 *$rem;

    .ex_top_0 {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8px;

      > img {
        width: 1.9 *$rem;
        height: 1.9 *$rem;
        background-color: white;
        border-radius: 2 *$rem;
        padding: 2px;
      }

      > span {
        font-family: PingFangSC-Regular;
        font-size: 1.1 *$rem;
        font-weight: normal;
        line-height: 30px;
        color: #2E63F6;
        margin-left: 2px;

      }
    }

    .more_p {
      font-family: PingFangSC-Regular;
      font-size: 1.2 *$rem;
      font-weight: normal;
      line-height: 44.8px;
      color: #2E63F6;
      position: absolute;
      right: 20px;
    }
  }

  .item_exchange_container {
    width: 86%;
    margin: 7.5 *$rem auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //background-color: red;

    .item {
      width: 48%;
      height: 21 *$rem;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      border: 0.1px solid transparent;

      .item_img {
        width: 90%;
        height: 63%;
        background-color: #EDEDED;
        border-radius: 10px;
        margin-top: 0.5 *$rem;
        //background-color: red;
        padding: 0.5 *$rem 1 *$rem 0.5 *$rem;
        box-sizing: border-box;
        object-fit: contain;
      }

      .item_title {
        width: 90%;
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        height: 3.3 *$rem;
        font-weight: normal;
        color: #1A1A1A;
        margin-top: 0.5 *$rem;
      }

      .item_bottom {
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.7 *$rem;
      }

      .item_huo{
        margin-left: 5px;
      }

      .item_zs {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.right {
          margin-left: 5px;
        }

        > img {
          width: 1.35 *$rem;
          height: 1.7 *$rem;
          display: inline-block;
        }

        > span {
          font-size: 1.7 *$rem;
          color: #E83E5D;
          margin-left: 3px;
        }
      }
    }
  }

  .item_new_fuli {
    width: 86%;
    height: 8 *$rem;
    margin: 1 *$rem auto 0;
    background-color: white;
    position: relative;
    border-radius: 17px;
    border: 0.1px solid transparent;
    display: flex;
    flex-direction: row;

    .fuli_logo {
      position: absolute;
      left: 0;
      top: 0;
      width: 6.6 *$rem;
      height: 2 *$rem;
      font-size: 1 *$rem;
      color: white;
      border-radius: 17px 0px 30px 0px;
      background: #E83E5D;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fuli_logo_img {
      width: 10 *$rem;
      height: 100%;
      margin-left: 1.5 *$rem;
    }

    .fuli_right {
      margin-left: 5px;

      > :nth-child(1) {
        font-family: PangMenZhengDao;
        font-size: 1.6 *$rem;
        font-weight: normal;
        color: #2E63F6;
        margin-top: 1 *$rem;
      }

      > :nth-child(2) {
        font-family: PingFangSC-Regular;
        font-size: 1.1 *$rem;
        line-height: 20px;
        color: #1A1A1A;
      }

      > :nth-child(3) {
        font-family: PingFangSC-Regular;
        font-size: 1.1 *$rem;
        font-weight: normal;
        color: #2E63F6;
        margin-top: 0.6 *$rem;
      }
    }
  }
}

.go_buy_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 1.5 *$rem;
  position: relative;

  .go_buy_img {
    width: 94%;
  }

  .buy_btn {
    position: absolute;
    bottom: 4 *$rem;
    width: 70%;

  }

  > p {
    position: absolute;
    bottom: 1.5 *$rem;
    width: 70%;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-size: 1.2 *$rem;
    color: #2E63F6;
  }
}

.home_logo {
  width: 40%;
  margin: 2 *$rem auto;

}
