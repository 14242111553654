@import '@/assets/scss/variable.scss';

.container {
  width: px2rem(750);
  margin: 0 auto;
  .top {
    display: flex;
    padding: px2rem(30);
    .label {
      display: flex;
      font-size: px2rem(24);
      margin-right: px2rem(36);
      align-items: center;
      img {
        width: px2rem(38);
        height: px2rem(38);
      }
    }
  }

  .list {
    display: flex;
    padding-left: px2rem(30);
    flex-wrap: wrap;
    .item {
      width: px2rem(336);
      height: px2rem(336 + 222);
      border-radius: px2rem(10);
      margin-bottom: px2rem(30);
      background-color: #fff;
      &:nth-of-type(2n) {
        margin-left: px2rem(20);
      }
      > img {
        width: px2rem(336);
        height: px2rem(336);
        border-radius: 6px;
      }
      .name {
        font-size: px2rem(26);
        font-weight: 700;
        padding: px2rem(12) px2rem(24);
        color: #333333;
      }
      .btn {
        width: px2rem(290);
        height: px2rem(64);
        line-height: px2rem(64);
        display: block;
        margin: 0 auto;
        border: none;
        border-radius: px2rem(182);
        background: linear-gradient(108deg, #46FBF9 -4%, #1254FE 101%);
        font-size: px2rem(28);
        color: #FFFFFF;

        &.disabled {
          opacity: 0.5;
        }
      }

      .row {
        display: flex;
        color: #9E9E9E;
        font-size: px2rem(24);
        align-items: center;
        padding-left: px2rem(16);
        margin-bottom: px2rem(25);
        .material {
          display: flex;
          align-items: center;
          margin-right: px2rem(18);
          font-size: px2rem(36);
          font-weight: 700;
          color: #333333;
          .item_huo{
            margin-left: 5px;
          }
          img {
            width: px2rem(38);
            height: px2rem(38);
          }
        }
        .price {
          align-self: flex-end;
          color: #333333;
          font-size: px2rem(24);
          text-decoration: line-through;
          opacity: 0.7;
        }
      }
    }
  }
}
