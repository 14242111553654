@import '@/assets/scss/variable.scss';

.outer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; // 增加弹性滚动效果
    overscroll-behavior: contain; // 防止滚动穿透
    scroll-behavior: smooth; // 平滑滚动

    background: linear-gradient(180deg, rgba(167, 185, 224, 0) 1%, #AEBBE2 13%);

    .home-top {
        position: relative;
        width: 100%;
        height: 60*$rem;
        background-image: url("~@/assets/img/blindbox/bg.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100% 100%;
        overflow: hidden;

        .back {
            position: absolute;
            top: 2.5*$rem;

            @media screen and (min-height: 780px) {
                top: 5.5*$rem;
            }

            left: 1.4*$rem;
            width: 1*$rem;
            height: 1.8*$rem;
            background: url("~@/assets/img/icon/back.png") no-repeat center center / 100% 100%;
        }

        .right_btns {
            position: absolute;
            top: 8.5*$rem;
            right: 0;
            display: flex;
            flex-direction: column;

            @mixin common_Btn {
                width: 2.1*$rem;
                height: 4.1*$rem;
                border-radius: 0.5*$rem 0px 0px 0.5*$rem;
                background: rgba(81, 53, 219, 0.8);
                color: #FFFFFF;
                font-size: 1.2*$rem;
                font-weight: bold;
                writing-mode: vertical-lr;
                text-align: center;
                line-height: 2.2*$rem;

                &:active {
                    background: rgba(0, 38, 191, 0.8);
                }
            }

            .activity_rules,
            .real_name {
                margin-bottom: 1.2*$rem;
                @include common_Btn();
            }
        }

        .wraper {
            display: flex;
            flex-direction: column;
            padding-top: 7*$rem;
            padding-bottom: 1.5*$rem;
            .buy_btn{
                width: 30*$rem;
                height: 5.6*$rem;
                // border:1px solid red;
                position: absolute;
                bottom: 0;
                left:50%;
                transform: translate(-50%,0);
                .buy {
                    font-family: PingFang SC;
                    font-size:2*$rem;
                    font-weight: 600;
                    font-variation-settings: "opsz"auto;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    background-image: url("~@/assets/img/blindbox/buy_btn.png");
                    background-repeat: no-repeat;
                    background-position: center top;
                    background-size: 100%;
                }
            }

        }

    }

    .home-bottom {
        width: 100vw;
        height: 64.1*$rem;
        background-image: url("~@/assets/img/blindbox/bg-2.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100%;
        overflow: hidden;

        .record {
            margin-top: 1.9*$rem;
            font-family: 思源黑体;
            font-size: 1.2*$rem;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0px;
            background: linear-gradient(180deg, #8557F9 0%, #160EB9 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        .info {
            width: 100vw;
            height: 13.75*$rem;
            padding: 0 3.3*$rem;
            display: flex;
            gap: 2.5*$rem;
            margin-top: 3*$rem;
            margin-bottom: 3*$rem;

            .infoItem {
                flex: 1;

                img {
                    width: 100%;
                }
            }
        }

        .info2 {
            width: 33.35*$rem;
            height: 29*$rem;
            padding: 0 3.3*$rem;
            padding: 2.75*$rem 4.85*$rem 2*$rem 4.85*$rem;
            background-image: url("~@/assets/img/blindbox/bg-4.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
            margin: 0 auto;

            .title {
                width: 100%;
                height: 2.5*$rem;
                background-image: url("~@/assets/img/blindbox/title.png");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100%;
            }

            .infoBox {
                display: flex;
                justify-content: space-between;
                margin-top: 2.25*$rem;
                margin-bottom: 1.7*$rem;


                .infoOut {
                    width: 6.5*$rem;
                    position: relative;
                    text-align: center;
                    .infoItem {
                        width: 6.5*$rem;
                        height: 6.5*$rem;
                        border-radius: 0.5*$rem;
                        opacity: 1;
                        background: #FFFFFF;
                        font-family: PingFang SC;
                        font-weight: 400;
                        font-size: 1.2*$rem;
                        font-variation-settings: "opsz"auto;
                        padding-top: 1.15*$rem;

                        img {
                            height: 4.15*$rem;
                            margin: 0 auto;
                        }


                    }

                    .text1 {
                        margin-top: 0.6*$rem;
                        color: #000000
                    }

                    .text2 {
                        color: #FF5D75
                    }
                }

            }
        }

        .btn {
            .new {
                font-family: PingFang SC;
                font-size:2*$rem;
                font-weight: 600;
                font-variation-settings: "opsz"auto;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 23.6*$rem;
                height: 4.35*$rem;
                background-image: url("~@/assets/img/blindbox/buy_btn.png");
                background-repeat: no-repeat;
                background-position: center top;
                background-size: 100%;
            }
            .record{
                margin-top: 1.4*$rem;
                font-family: 思源黑体;
                font-size: 1.2*$rem;
                font-weight: bold;
                text-align: center;
                letter-spacing: 0px;
                background: linear-gradient(180deg, #8557F9 0%, #160EB9 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }
    .bottom_logo {
        margin-top: 5*$rem;
        margin-bottom: 3.5*$rem;
        img {
            height: 3.5*$rem;
            margin: 0 auto;
        }
    }
}
.shareInvite {
    width: 31.5*$rem;
    height: 45*$rem;
}