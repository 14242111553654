@function to($px) {
  @return ($px / 1624) * 100vw;
}

.common_view {
  display: flex;
  flex-direction: column;
  width: to(1133);
  height: to(570);
  background: url('../../imgs/common-bg.png') no-repeat;
  background-size: 100% 100%;
  border-radius: to(8);
  box-sizing: border-box;

  .view_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: to(15) to(53);

    > img {
      width: to(50);
      height: to(50);
    }

    > span {
      width: to(50);
      height: to(50);
    }

    > h3 {
      font-size: to(36);
      color: #FFFFFF;
      font-weight: 600;
    }
  }
}