@function to($px) {
  @return ($px / 2558) * 100vw;
}

.exchange_goods_detail {
  position: relative;
  padding: to(40) to(120);

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: to(96);
    height: to(96);
  }

  .goods_container {
    display: flex;
    padding: to(50) to(47) to(50) to(40);
    width: to(1850);
    height: to(960);
    border-radius: to(29.6);
    background: #F5F7FA;
    box-sizing: border-box;
  }

  .goods_img_box {
    display: flex;
    height: 100%;

    .img_tabs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: to(88);
      height: 100%;
      background: #FFFFFF;
      box-shadow: 0px 5.92px 14.8px 0px rgba(0, 0, 0, 0.302);
      font-size: to(36);
      color: #9E9E9E;
      border-radius: to(15);
      
      .tab_item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        writing-mode: vertical-rl;
        text-orientation: upright;
        letter-spacing: 0.5em;
        transition: transform 0.3s;
        transform: scale(1);
        &:active {
          transform: scale(0.88);
        }

        &.tab_active {
          color: #FF5203;
          font-weight: 500;
        }

        &:nth-child(1) {
          border-bottom: to(1.48) solid #D8D8D8;
        }
      }
    }

    .tab_content {
      margin: 0 to(26) 0 to(36);
      width: to(860);
      height: to(860);
      border-radius: to(30);
      overflow: hidden;
      
      .main_img {
        width: 100%;
        height: 100%;
        background: #FFFFFF;

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .detail_img {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: #FFFFFF;
      }

      .swiper_item {
        width: to(860);
        height: to(860);

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

  }

  .goods_info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .goods_price {
      padding: to(25);
      box-sizing: border-box;
      width: 100%;
      height: to(227);
      border-radius: to(12);
      background: #FFFFFF;

      > div {
        &:nth-child(1) {
          margin-bottom: to(12);
          color: #FF5203;
          > span {
            &:nth-child(1) {
              padding-right: to(10);
              font-size: to(68);
              font-weight: 600;
            }
            &:nth-child(2) {
              font-size: to(38);
              font-weight: 500;
            }
          }
        }
        &:nth-child(2) {
          font-size: to(44);
          font-weight: 500;
          color: #262727;
          overflow: hidden;
          text-overflow: ellipsis;
          display:-webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; 
        }
      }
    }

    .goods_spec {
      padding: to(20) to(34) to(24) to(24);
      margin-top: to(30);
      margin-bottom: to(20);
      box-sizing: border-box;
      width: 100%;
      height: to(227);
      border-radius: to(12);
      background: #FFFFFF;

      > div {
        &:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: to(25);
          border-bottom: to(1.48) solid #F3F3F3;

          > div {
            &:nth-child(1) {
              > span {
                font-size: to(36);
                &:nth-child(1) {
                  padding-right: to(29);
                  color: #696969;
                }
                &:nth-child(2) {
                  color: #262727;
                }
              }
            }

            &:nth-child(2) {
              display: flex;
              > span {
                margin-right: to(5.5);
                width: to(8.88);
                height: to(8.88);
                border-radius: 50%;
                background: #D8D8D8;
              }
            }
          }
        }

        &:nth-child(2) {
          padding-top: to(22);
          > span {
            font-size: to(36);
            &:nth-child(1) {
              padding-right: to(29);
              color: #696969;
            }
            &:nth-child(2) {
              color: #262727;
            }
          }
        }
      }
    }

    .user_points {
      padding: to(24) to(28);
      display: flex;
      justify-content: space-between;
      border-radius: to(11);
      background: #FCF0BE;

      > div {
        &:nth-child(1) {
          color: #262727;
          font-size: to(27);
        }
        &:nth-child(2) {
          color: #FF5203;
          font-size: to(30);
          font-weight: 500;
        }
      }
    }

    .exchange_btn {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: to(600);
      height: to(90);
      border-radius: to(45);
      background: linear-gradient(90deg, #FE7F02 0%, #FF5203 100%);
      color: #FFFFFF;
      font-size: to(35);
      font-weight: 500;

      &.disable {
        background: #B9B5B2;
      }
    }
  }
}