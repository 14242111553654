$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.orderpay {
  background-color: #f8f8f8;
  width: 100%;
  height: 100vh;
  padding-bottom: 90 * $vw;
  box-sizing: border-box;
  overflow: scroll;
}

.nft_sale {
  width: calc(100% - 30*$rem/10 - 8vw);
  margin: 4vw;
  padding: 15*$rem/10;
  border-radius: 5*$rem/10;
  background-color: #fff;
  .nft_sale_top {
    display: flex;
  }
  .nft_sale_bottom {
    padding-top: 15*$rem/10;
    .content_text {
      text-align: left;
      font-size: 13*$rem/10;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      line-height: 18*$rem/10;

      span {
        color: #777;
        font-family: PingFang SC-Medium, PingFang SC;
        font-size: 12*$rem/10;
        display: inline-block;
      }

      .label {
        font-family: PingFang SC-Medium, PingFang SC;
        font-size: 13*$rem/10;
        word-break: keep-all;
        word-wrap: nowrap;
        margin-right: 6*$rem/10;
        color: #333;
      }
    }
  }

  .nft_sale_display {
    width: 90*$rem/10;
    height: 90*$rem/10;
    margin-right: 15*$rem/10;
    background-color: #ccc;
    border-radius: 6*$rem/10;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .nft_sale_content {
    flex: 1;
    height: 85*$rem/10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-size: 15*$rem/10;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .nft_sale_price {
      display: flex;
      justify-content: space-between;
      height: 20*$rem/10;

      span {
        font-size: 16*$rem/10;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
}

.nft_saleprice {
  width: calc(100% - 30*$rem/10 - 8vw);
  margin: 4vw;
  padding: 15*$rem/10;
  border-radius: 5*$rem/10;
  background-color: #fff;
  padding-bottom: 50*$rem/10;

  .poinsintroduce {
    width: 100%;
    font-size: 12*$rem/10;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #a1a1a1;
    margin-top: 5*$rem/10;
    padding-bottom: 19*$rem/10;
    border-bottom: 1*$rem/10 solid #f2f2f2;
  }

  .nft_pricetotal {
    margin-top: 15*$rem/10;
    height: 18*$rem/10;
    display: flex;
    float: right;

    .nft_total {
      font-family: PingFang SC-Regular;
      color: #333;
      font-size: 14*$rem/10;
      line-height: 18*$rem/10;
    }

    .nft_deduction {
      font-family: PingFang SC-Regular, PingFang SC;
      color: #a1a1a1;
      font-size: 12*$rem/10;
      line-height: 18*$rem/10;
    }

    .nft_price {
      font-family: PingFang SC-Regular, PingFang SC;
      color: #333;
      font-weight: 600;
      font-size: 14*$rem/10;
      line-height: 18*$rem/10;
    }
    .price_left {
      padding-left: 6.4*$rem;
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.nft_spacebt {
  width: 100%;

  span {
    font-size: 15*$rem/10;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #333333;
  }

  .pay_way {
    font-size: 15*$rem/10;
    color: #333333;
    padding-bottom: 12*$rem/10;
    border-bottom: 1*$rem/10 solid #f2f2f2;
  }

  .nft_spacebt_right {
    font-size: 15*$rem/10;
    font-family: PingFang SC-Semibold, PingFang SC;
    // font-weight: 600;
    color: #333333;

    .right {
      float: right;
      width: 20*$rem/10;
      height: 20*$rem/10;

      .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
        border-color: #ff6b6b;
        background-color: #ff6b6b;
      }
    }
  }
}

.orderpay_buttom {
  width: 100vw;
  height: 90 * $vw;
  padding: 0 4vw;
  box-sizing: border-box;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .total {
      flex: 0 0 35*$rem/10;
      font-size: 12*$rem/10;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #333333;
    }

    .price-detail {
      .price {
        font-size: 24*$rem/10;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28*$rem/10;
      }

      .original-price {
        font-size: 12*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #9d9d9d;
        line-height: 14*$rem/10;
      }
    }
  }

  .pay {
    .adm-button {
      font-size: 16*$rem/10;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #614d1a;
      background: linear-gradient(90deg, #ccbd7b 0%, #f9ecb9 100%);
      border-radius: 27*$rem/10;
      padding: 12*$rem/10 46*$rem/10;
    }
  }
}
.pay-type {
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15 * $vw;

    .adm-radio {
      flex: 0 0 20*$rem/10;
    }
    .apy_way_info {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 24*$rem/10;
        height: 24*$rem/10;
        padding: 3*$rem/10;
      }
      .type {
        font-size: 15*$rem/10;
        margin-left: 8*$rem/10;
        word-break: keep-all;
        white-space: nowrap;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #333333;
      }
    }

    .adm-radio.adm-radio-checked .adm-radio-icon {
      border-color: #c1af68;
      background-color: #c1af68;
    }
  }
}
//.adm-stepper-minus,
//.adm-stepper-plus {
  //background-color: #c0ae66;
//}
//.adm-stepper-minus,
//.adm-stepper-plus {
//  color: #fff;
//}
//.adm-stepper-minus:disabled,
//.adm-stepper-plus:disabled {
//  color: #fff;
//}
.adm-checkbox.adm-checkbox-disabled .adm-checkbox-icon.adm-checkbox-icon {
  color: #FFFFFF;
}
