$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.introduceCard {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 2*$rem;
    margin-bottom: 2*$rem;
    overflow: hidden;
    padding: 2*$rem 0 1.6*$rem;
    
    .content_title {
        font-size: 25*$rem/10;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding: 0 1.6*$rem 0.8*$rem;
        white-space: pre-wrap;
    }

    .content_desc {
        padding: 0 1.6*$rem 0.8*$rem;
        font-size: 13*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #777777;
    }

    .desc-img {
        margin: 3.5*$rem 1*$rem 0;
        width: 315/10*$rem;
        object-fit: contain;
    }
}