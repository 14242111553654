$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.donation-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 34*$rem/10;
  img {
    margin-top: 68*$rem/10;
    height: 84.5*$rem/10;
    width: 132.5*$rem/10;
  }
  .title {
    margin: 15*$rem/10 0 5*$rem/10;
    color: #333333;
    font-size: 18*$rem/10;
    font-weight: bold;
  }
  .tip {
    color: #666666;
    font-size: 14*$rem/10;
    line-height: 23*$rem/10;
  }
  .btn {
    margin-top: 68*$rem/10;
    height: 44*$rem/10;
    width: 280*$rem/10;
    text-align: center;
    line-height: 44*$rem/10;
    background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
    font-size: 18*$rem/10;
    color: #624C16;
    border-radius: 36*$rem/10;
  }
}