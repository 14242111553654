$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.collection-message {
    padding: 15*$rem/10;
    background: #ffffff;
    border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    opacity: 1;
    margin-bottom: 15*$rem/10;

    .title-bar {
      font-size: 15*$rem/10;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
    }

    .message-detail {
      padding-top: 10*$rem/10;
      font-size: 13*$rem/10;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #707070;
      line-height: 15*$rem/10;
    }

    .circulation {
      padding-top: 20*$rem/10;
      font-size: 15*$rem/10;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }