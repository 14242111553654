$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@mixin commonBtnStyle {
  width: setRem(243);
  height: setRem(90);
  border-radius: setRem(45);
  font-family: PingFang SC;
  font-size: setRem(32);
  font-weight: 500;
  line-height: setRem(45);
  text-align: center;
  letter-spacing: 0;
  color: #614D1A;
}
.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    min-width: setRem(580);
    min-height: setRem(450);
    border-radius: setRem(20);
    background: #FFFFFF;
    padding: setRem(44) setRem(32);
    .title {
      font-family: PingFang SC;
      font-size: setRem(32);
      font-weight: 600;
      line-height: setRem(38);
      text-align: center;
      letter-spacing: 0;
      color: #333333;
    }
    .content {
      margin-top: setRem(68);
      margin-bottom: setRem(99);
      word-break: break-word;
      text-align: center;
      & > p {
        font-family: PingFang SC;
        font-size: setRem(28);
        font-weight: normal;
        line-height: setRem(34);
        letter-spacing: 0;
        color: #333333;
      }
    }
    .btnBox {
      display: flex;
      gap: setRem(27);
      .cancelBtn {
        @include commonBtnStyle;
        border: 0.1*$rem solid #000000;
      }
      .confirmBtn {
        @include commonBtnStyle;
        background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
      }
    }
  }
}