@function to($px) {
  @return ($px / 2558) * 100vw;
}

.jewel_land {
  display: flex;
  padding-top: to(45);
  height: 100vh;
  width: 100vw;
  background: url('~@/assets/img/landfi/jewelLand-bg-3.png') no-repeat;
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  box-sizing: border-box;

  :global {
    .adm-center-popup-wrap {
      max-width: 100vw;
    }

    .adm-modal-wrap {
      max-width: 100vw;
    }

    .adm-input {
      max-width: 100vw;
    }
  }

  .jeweland_left {
    margin-right: to(91);

    .back_title {
      display: flex;
      align-items: center;

      .back {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: to(43);
        margin-right: to(39);
        width: to(240);
        height: to(95);
        border-radius: 0 to(57) to(57) 0;
        background: #FDEA9A;
        box-sizing: border-box;

        .back_img_box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: to(85);
          height: to(66);
          background: url('~@/assets/img/landfi/back-bg-3.png') no-repeat;
          background-size: 100% 100%;

          &>img {
            width: to(64);
            height: to(49);
          }
        }
      }

      .title {
        color: #FFFFFF;
        font-size: to(72);
        font-family: PangMenZhengDao;
      }
    }

    .blindbox_title {
      position: relative;
      margin-top: to(80);
      margin-left: to(204);
      padding-top: to(56);
      width: to(304);
      height: to(808);
      background: rgba(182, 109, 31, 0.34);
      border-radius: to(38);
      box-sizing: border-box;

      .title_bg {
        margin-left: to(-46);
        margin-bottom: to(20);
        width: to(402);
        height: to(157);
        background: url('~@/assets/img/landfi/gemstone-tab-active.png') no-repeat;
        background-size: 100% 100%;
        color: #FFFFFF;
        font-size: to(45);
        font-weight: 600;
        text-align: center;
        line-height: to(120);

        &:nth-child(1) {
          letter-spacing: 0.5em;
        }

        &.title_bg_disabled {
          margin-left: to(-30);
          background: url('~@/assets/img/landfi/gemstone-tab-dis.png') no-repeat;
          background-size: 100% 100%;
          width: to(370);
          height: to(132);
          line-height: to(115);
        }
      }

      .gemstore_transfer {
        position: absolute;
        left: 50%;
        bottom: to(28);
        transform: translateX(-50%);
        width: 100%;
        color: #712FFF;
        font-size: to(36);
        text-align: center;
      }
    }
  }

  .gem_store_out {
    display: flex;
    flex-direction: column;
    padding-top: to(24);
    padding-left: to(50);
    padding-right: to(50);
    width: to(1840);
    height: to(1219);
    box-sizing: border-box;
    border: to(10) solid #FFFFFF;
    border-radius: to(46);
    background-color: #FCFFC7;
    background-image: url('~@/assets/img/landfi/landgy/gem_store.png');
    background-size: 140% 170%;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0px to(4) to(10) 0px rgba(0, 0, 0, 0.3), inset 0px to(12) to(51) 0px rgba(255, 228, 177, 0.67);
    

    &>.gem_store {
      flex:1;
    }

    &>.gem_store_top {
      display: flex;

      &>.top_title {
        flex: 1;
        display: flex;
        align-items: center;
        gap: to(20);

        &>.title {
          font-family: YueGenYouShangYueBoHei;
          font-size: to(64);
          font-weight: bold;
          color: #000000;

        }

        &>.sub_title {
          font-family: PingFang SC;
          font-size: to(45);
          font-weight: 600;
          color: #9E9E9E;
          position: relative;
          top: to(2);
        }
      }

      &>.top_btns {
        flex: 1;
        display: flex;
        gap: to(24);
      }
    }
  }

  .jeweland_right {
    display: flex;
    padding-top: to(24);
    padding-left: to(64);
    width: to(1663);
    height: to(1219);
    box-sizing: border-box;
    border: to(6) solid #FFFFFF;
    background: linear-gradient(124deg, #F8FF7C 0%, #FCE2B5 97%);
    border-radius: to(46);

    .gem_store {
      .gem_store_top {
        .top_btns {
          display: flex;
        }
      }
    }

    .blindbox_area {
      display: flex;
      flex-direction: column;

      .prize_banner {
        width: to(1085);
        height: to(404);
        background: url('~@/assets/img/landfi/blindbox-banner-3.png') no-repeat;
        background-size: 100% 100%;
      }

      .blindbox_list_container {
        flex: 1;
        margin-top: to(20);
        padding: to(15) to(15) 0 to(15);
        width: to(1085);
        // height: to(735);
        border: to(7.5) solid #000000;
        border-radius: to(60);
        box-sizing: border-box;
        background: #E2A8ED;

        .light_list_bg {
          position: relative;
          padding: to(50) to(50) 0 to(50);
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background: #FFFCDB;
          border-radius: to(40);

          &.light_animate {
            .light {
              &:nth-child(3n + 1) {
                background: #5AAFFF;
              }

              &:nth-child(3n + 2) {
                background: #FF9E37;
              }

              &:nth-child(3n + 3) {
                background: #F35F5F;
              }
            }
          }

          .light {
            position: absolute;
            width: to(24);
            height: to(24);
            border-radius: 50%;
            $lightGap: 62;

            @for $n from 0 through 34 {
              &._#{($n * 3 + 1)} {
                background: #FF9E37;
              }

              &._#{($n * 3 + 2)} {
                background: #F35F5F;
              }

              &._#{($n * 3 + 3)} {
                background: #5AAFFF;
              }
            }

            @for $var from 1 through 35 {
              @if $var <=9 {
                &._#{$var} {
                  top: #{to(588 - ($var - 1) * $lightGap)};
                  left: to(13);
                }
              }

              @if $var==10 {
                &._#{$var} {
                  top: to(35);
                  left: to(23);
                }
              }

              @if $var>=11 {
                &._#{$var} {
                  top: to(12);
                  left: #{to(73 + ($var - 11) * $lightGap)};
                }
              }

              @if $var==26 {
                &._#{$var} {
                  top: to(35);
                  left: to(995);
                }
              }

              @if $var>=27 {
                &._#{$var} {
                  top: #{to(90 + ($var - 27) * $lightGap)};
                  left: to(1002);
                }
              }
            }
          }

          .blindbox_list_bg {
            padding: to(25) to(22) to(100);
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: to(236);
            column-gap: to(10);
            row-gap: to(43);
            width: 100%;
            height: 100%;
            background: #FFF1DB;
            border-radius: to(28);
            box-sizing: border-box;
            overflow-y: hidden;

            .blindbox_list_item {
              position: relative;
              display: flex;
              justify-content: center;
              width: to(170);
              height: to(236);
              box-sizing: border-box;

              .lottery_img {
                width: to(170);
                height: to(236);
              }

              .lottery_gif {
                position: absolute;
                top: -5%;
                left: -38%;
                bottom: 0;
                right: 0;
                width: to(300);
                height: to(1000);
                z-index: 9;
              }

              .select_blindbox_btn {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 50%);
                display: flex;
                align-items: center;
                justify-content: center;
                width: to(150);
                height: to(47);
                background: url('~@/assets/img/landfi/blindbox-btn-bg-3.png') no-repeat;
                background-size: 100% 100%;
                color: #FFFFFF;
                font-size: to(26);
                font-weight: 600;
                transition: 0.35s;

                &.hidden_btn {
                  opacity: 0;
                }

                &.show_btn {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    .lottery_area {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;

      .up_jackpot_out {
        margin-top: to(30);

        :global {
          .adm-progress-circle-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            height: 75%;
            width: 75%;
            border-radius: 50%;
          }

          .adm-progress-circle-svg>.adm-progress-circle-fill {
            stroke: url(#write)
          }
        }

        .jackpot_img {
          margin: 0 auto;
          width: to(116);
          height: to(123);
        }

        .jackpot_num {
          margin-top: to(4);
          color: #000000;
          font-size: to(30);
          font-weight: 600;
        }
      }

      .gem_img {
        position: absolute;
        top: 18%;
        left: 22%;
        width: to(107);
        height: to(105);
      }

      .single_lottery_btn {
        position: relative;
        margin-top: to(50);
        margin-bottom: to(10);
        width: to(304);
        height: to(253);
        background: url('~@/assets/img/landfi/lottery-single-3.png') no-repeat;
        background-size: 100% 100%;

        .lottery_discount {
          position: absolute;
          top: 7%;
          left: 0;
          width: to(81);
          height: to(98);
          background: url('~@/assets/img/landfi/horn-tip-3.png') no-repeat;
          background-size: 100% 100%;
          color: #FFFFFF;
          font-size: to(29);
          font-weight: 600;

          >p {
            letter-spacing: 0.31em;
            transform: rotateZ(-45deg);
          }
        }

        .single_consume_gem {
          position: absolute;
          top: 69%;
          left: 24%;
          width: to(92);
          height: to(44);
          border-radius: to(10);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FBBD94;
          font-size: to(28);
          font-weight: 600;
        }

        .single_text_tip {
          position: absolute;
          top: 22%;
          left: 71%;
          writing-mode: vertical-rl;
          text-orientation: upright;
          color: #231214;
          font-size: to(28);
          font-weight: 600;
          letter-spacing: to(8);
        }
      }

      .mutiple_lottery_btn {
        position: relative;
        width: to(304);
        height: to(253);
        background: url('~@/assets/img/landfi/lottery-mang-3.png') no-repeat;
        background-size: 100% 100%;

        .lottery_discount {
          position: absolute;
          top: 7%;
          left: 0;
          width: to(81);
          height: to(98);
          background: url('~@/assets/img/landfi/horn-tip-3.png') no-repeat;
          background-size: 100% 100%;
          color: #FFFFFF;
          font-size: to(29);
          font-weight: 600;

          >p {
            letter-spacing: 0.31em;
            transform: rotateZ(-45deg);
          }
        }

        .mutiple_consume_gem {
          position: absolute;
          top: 69%;
          left: 24%;
          width: to(92);
          height: to(44);
          border-radius: to(10);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #B662DB;
          font-size: to(28);
          font-weight: 600;
        }

        .mutiple_text_tip {
          position: absolute;
          top: 22%;
          left: 71%;
          color: #FFFFFF;
          font-size: to(28);
          font-weight: 600;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .look_prizes_btn {
        font-size: t0(26);
        color: #DE8E23;
      }
    }
  }

  .gemstore_ranking {
    width: to(1663);
    height: to(1219);
    box-sizing: border-box;
    border: to(10) solid #FFFFFF;
    background: #FCFFC7;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3), inset 0px 12px 51px 0px rgba(255, 228, 177, 0.67);
    border-radius: to(46);

    .gemstore_ranking_inner {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      background: url('~@/assets/img/landfi/gemstore-ranking-bg.png') no-repeat;
      background-size: 140% 170%;
      background-position: center;

      .ranking_title {
        margin-top: to(58);
        margin-bottom: to(47);
        font-size: to(64);
        font-weight: bold;
        color: #000000;
        font-family: YueGenYouShangYueBoHei;
        text-align: center;
      }

      .ranking_content {
        margin-bottom: to(230);
        padding-bottom: to(70);
        flex: 1;
        overflow-y: auto;

        .ranking_item {
          margin-bottom: to(20);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 to(97) 0 to(38);
          width: to(1200);
          height: to(120);
          border-radius: to(30);
          background: linear-gradient(180deg, #FFFCF8 0%, #FFEBB6 100%);
          border: to(1) solid #FFFFFF;
          box-sizing: border-box;
          color: #903F07;
          font-size: to(40);
          font-weight: bold;

          .item_left {
            display: flex;
            align-items: center;

            .ranking_num {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: to(12);
              width: to(130);
              height: to(128);
              color: #FFFFFF;
              font-size: to(60);
              font-weight: bold;
              font-family: YueGenYouShangYueBoHei;
              text-shadow: to(1.5) to(2) to(1) #000;

              >img {
                width: to(130);
                height: to(128);
              }
            }
          }

          .item_right {
            display: flex;
            align-items: center;
            text-align: left;
            width: to(300);

            >img {
              width: to(100);
              height: to(100);
            }
          }
        }
      }

      .my_ranking {
        position: absolute;
        left: 50%;
        bottom: to(130);
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 to(146) to(10) to(97);
        color: #FFFFFF;
        font-size: to(46);
        font-weight: bold;
        background: url('~@/assets/img/landfi/my-ranking-bg.png') no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        width: to(1290);
        height: to(170);

        &_right {
          display: flex;
          align-items: center;

          >img {
            width: to(100);
            height: to(100);
          }
        }
      }
    }
  }

  .jeweland_btc {
    display: flex;
    flex-direction: column;
    padding-right: to(100);
    padding-bottom: to(70);
    flex: 1;
    position: relative;

    .btc_area_out {
      width: 100%;
      height: 100%;
      flex: 1;
      background: url('~@/assets/img/landfi/btc-bg-1.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      .btc_inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: to(5) to(20) to(40) to(20);
        display: flex;
        justify-content: space-between;

        .btc_inner_left {
          box-sizing: border-box;
          padding-top: to(70);
          // padding-left:to(60);
          width: 43%;
          background: url('~@/assets/img/landfi/btc-logo.png') no-repeat;
          background-size: 94%;
          background-position: center;

          .btc_inner_info {
            height: to(108);
            background: url('~@/assets/img/landfi/btc-text.png') no-repeat;
            background-size: 89%;
            background-position: center;
          }
        }

        .btc_inner_right {
          width: 53%;
          flex: 1;
          box-sizing: border-box;
          padding: to(70) to(70);
          display: flex;
          flex-direction: column;

          .top {
            width: 100%;
            height: to(108);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 3%;
            box-sizing: border-box;
            margin-bottom: to(47);

            .top_left {
              width: to(582);
              height: to(108);
              background: url('~@/assets/img/landfi/btc-3.png') no-repeat;
              background-size: contain;
              padding-top: to(14);
              padding-left: to(30);
              box-sizing: border-box;
              color: #FEF815;
              font-family: PangMenZhengDao;
              font-weight: 400;
              font-variation-settings: "opsz"auto;

              span:first-child {
                font-size: to(40);
                margin-right: to(10);
              }

              span:not(:first-child) {
                font-size: to(56);
              }
            }

            .top_right {
              font-family: PingFang SC;
              font-size: to(32);
              font-weight: 600;
              color: #FFFFFF;
            }
          }

          .bottom {
            flex: 1;
            background: url('~@/assets/img/landfi/btc-4.png') no-repeat;
            background-size: 100% 100%;
            background-position: center;
            box-sizing: border-box;
            position: relative;

            .title {
              height: 16%;
              padding: to(0) to(41);
              position: relative;

              .title_left {
                position: absolute;
                top: 50%;
                left: to(41);
                transform: translate(0, -50%);
                font-family: PangMenZhengDao;
                font-size: to(42);
                color: #000000;
                box-sizing: border-box;

              }

              .title_right {
                position: absolute;
                top: 50%;
                right: to(41);
                transform: translate(0, -50%);
                font-family: PingFang SC;
                font-size: to(36);
                font-weight: normal;
                letter-spacing: 0em;
                color: #000000;
              }
            }

            .info {
              width: 100%;
              position: absolute;
              top: 55%;
              left: 50%;
              transform: translate(-50%, -50%);
              padding: to(0) to(60);
              box-sizing: border-box;

              .way_hotArea {
                position: absolute;
                height: to(110);
                top: to(83);
                right: to(60);
                left: to(60);

                display: flex;

                div {
                  flex: 1;
                }
              }

              .way_bg {
                // padding: 0 to(40);


                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .gemCount {
                width: 100%;
                position: relative;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;
                margin-bottom: to(5);
                padding: 0 to(50);

                .record {
                  width: to(180);
                  box-sizing: border-box;
                  font-family: PingFang SC;
                  font-size: to(28);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                  background: #D8D8D8;
                  padding: to(5) to(18);
                  background: linear-gradient(90deg, #F2F6FF 0%, #FFFFFF 100%);

                  span {}

                  height: to(74);

                  span:not(:first-child) {
                    position: relative;
                    top: to(3);
                    font-size: to(42);
                    font-weight: 500;
                    font-variation-settings: "opsz"auto;
                    color: #3D3D3D;

                  }
                }

                .record:first-child {
                  text-align: left;
                  box-shadow: to(-4) 0px 0px 0px rgba(34, 144, 254, 0.5), inset 0px 0px 0px 0px rgba(138, 138, 138, 0.2);

                }

                .record:nth-child(2) {
                  // text-align: center;
                  box-shadow: to(-4) 0px 0px 0px rgba(38, 197, 8, 0.5), inset 0px 0px 0px 0px rgba(138, 138, 138, 0.3);
                }

                .record:nth-child(3) {
                  // text-align: right;
                  box-shadow: to(-4) 0px 0px 0px rgba(247, 98, 120, 0.5), inset 0px 0px 0px 0px rgba(138, 138, 138, 0.3);
                }
              }

              .way {
                padding: 0 to(40);
                width: 100%;
                position: relative;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;

                .record {
                  flex: 1;
                  font-family: PingFang SC;
                  font-size: to(32);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  span {}

                  span:not(:first-child) {
                    position: relative;
                    top: to(3);
                    font-size: to(46);
                    font-weight: 500;
                    font-variation-settings: "opsz"auto;
                    color: #3D3D3D;

                  }
                }

                .record:first-child {
                  text-align: left;
                }

                .record:nth-child(2) {
                  text-align: center;
                }

                .record:nth-child(3) {
                  text-align: right;
                }
              }
            }

            .tips {
              font-family: PingFang SC;
              font-size: to(36);
              font-weight: 500;
              letter-spacing: 0px;
              text-decoration: underline;
              color: #3AA700;
              text-align: center;
            }
          }
        }
      }
    }

    .btc_area_gem {
      margin-bottom: to(50);
      display: flex;
      width: 100%;
      // justify-content: right;

    }

    .btc_area_gem_left {
      flex: 1;
    }
  }
}

.jwel_land1 {
  display: flex;
  padding-top: to(45);
  height: 100vh;
  width: 100vw;
  background: url('~@/assets/img/landfi/btc-bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  box-sizing: border-box;

  .blindbox_title {
    position: relative;
    margin-top: to(80);
    margin-left: to(204);
    padding-top: to(56);
    width: to(304);
    height: to(808);
    border-radius: to(38);
    box-sizing: border-box;
    background: rgba(255, 252, 242, 0.6) !important;
    box-sizing: border-box;
    border: to(1) solid #FFFFFF;
    backdrop-filter: blur(20px);
  }
}

.win_lottery_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: to(26) 0 to(100);
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;

  .win_title {
    width: to(912);
    height: to(198);
    background: url('~@/assets/img/landfi/win-title-3.png') no-repeat;
    background-size: 100% 100%;
  }

  .win_prizes {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: to(27);
    place-items: center;
    width: 72%;

    .win_prizes_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #FFFFFF;
      font-size: to(36);

      >img {
        margin-bottom: to(27);
        border-radius: to(10);
        width: to(249);
        height: to(254);
      }

      >span {
        width: to(249);
        text-align: center;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

  }

  .win_prizes_1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: to(42);

    >img {
      margin-bottom: to(27);
      border-radius: to(15);
      width: to(462);
      height: to(471);
    }
  }

  .btn_box {
    margin-top: to(40);
    display: flex;
    justify-content: space-between;
    width: 85%;

    .btn_box_left {
      display: flex;
    }

    .address_btn_box {
      display: flex;
    }

    .btn_style {
      margin-right: to(120);
      display: flex;
      justify-content: center;
      align-items: center;
      width: to(282);
      height: to(84);
      border-radius: to(42);
      color: #000000;
      font-size: to(40);
      font-weight: 600;
      background: linear-gradient(270deg, #D6F38C 0%, #9EDCC2 100%);

      &.btn_style_single {
        margin-left: to(304);
        background: linear-gradient(90deg, #FCCFAD 0%, #F5915A 100%);
      }

      &.btn_style_mutiple {
        margin-right: 0;
        background: linear-gradient(90deg, #CA84E8 0%, #9652B5 100%);
      }
    }
  }
}

.reward_overview {
  position: relative;
  padding: to(20) to(75);
  display: flex;
  flex-direction: column;
  width: to(1850);
  height: to(960);
  border-radius: to(40);
  box-sizing: border-box;
  border: to(8) solid #FFFFFF;
  background: linear-gradient(180deg, #FEEED5 0%, #F4BFAB 100%);

  .close {
    position: absolute;
    top: to(20);
    right: to(20);
    width: to(62);
    height: to(62);
  }

  .reward_title {
    margin-bottom: to(30);
    text-align: center;
    color: #3D3D3D;
    font-weight: 600;
    font-size: to(50);
  }

  .tabs {
    :global {
      --content-padding: 0;

      .adm-tabs-tab-line {
        --active-line-color: #346AFF;
        height: to(8);
      }

      .adm-tabs-tab {
        color: #3D3D3D;
        font-size: to(45);
      }

      .adm-tabs-header {
        border-bottom: none;
      }

      .adm-tabs-tab-list {
        margin: 0 to(200);
      }
    }

    .tab_title {
      font-size: to(45);
      color: #3D3D3D;

      &.tab_select {
        background: linear-gradient(102deg, #6BBDFE 1%, #AA9FFE 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-weight: 500;
      }
    }
  }

  .tabs_content {
    margin-top: to(60);
    flex: 1;
    width: 100%;
    overflow: hidden;

    .prize_list {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      width: 100%;

      &_item {
        margin-right: to(36);
        margin-bottom: to(40);
        width: to(250);
        height: to(250);
        border-radius: to(15);

        &:nth-child(6n) {
          margin-right: 0;
        }

        >img {
          width: 100%;
          height: 100%;
          border-radius: to(15);
        }
      }

      .list_empty {
        display: flex;
        justify-content: center;
        padding-top: to(200);
        width: 100%;
        height: 100%;
        color: #676767;
        box-sizing: border-box;
      }
    }

    .lottery_rules {
      overflow: auto;
      width: 100%;
      font-size: to(40);
      color: #676767;
      word-break: break-all;
      text-overflow: ellipsis;
      white-space: pre-line;
    }
  }
}

.reward_record_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}

.reward_record {
  padding: to(20) to(75);
  display: flex;
  flex-direction: column;
  width: to(1850);
  height: to(960);
  border-radius: to(40);
  box-sizing: border-box;
  border: to(8) solid #FFFFFF;
  background: linear-gradient(180deg, #FEEED5 0%, #F4BFAB 100%);

  .close {
    position: absolute;
    top: to(20);
    right: to(20);
    width: to(62);
    height: to(62);
  }

  .reward_title {
    margin-bottom: to(30);
    text-align: center;
    color: #3D3D3D;
    font-weight: 600;
    font-size: to(50);
  }

  .record_table_title {
    display: grid;
    grid-template-columns: 1.6fr 2.5fr 4fr;
    grid-template-rows: to(110);
    border-top: to(2) solid #FFFFFF;

    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-right: to(2) solid #FFFFFF;
      border-bottom: to(2) solid #FFFFFF;
      box-sizing: border-box;
      color: #3D3D3D;
      font-size: to(45);

      &:nth-child(1) {
        border-left: to(2) solid #FFFFFF;
      }
    }
  }

  .record_content {
    flex: 1;
    overflow-y: auto;

    .record_table {
      .record_table_item {
        display: grid;
        grid-template-columns: 1.6fr 2.5fr 4fr;
        grid-template-rows: minmax(to(110), auto);

        .fillin_address {
          color: #346AFF;
        }

        >div {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: to(15);
          width: 100%;
          height: 100%;
          border-right: to(2) solid #FFFFFF;
          border-bottom: to(2) solid #FFFFFF;
          box-sizing: border-box;
          font-size: to(36);
          color: #3D3D3D;

          &:nth-child(1) {
            border-left: to(2) solid #FFFFFF;
          }
        }
      }
    }
  }
}