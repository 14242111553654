$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.exchange-guess-bond-mask-succ {
  display: flex;
  align-items: center;
  justify-content: center;

  .exchange-modal-common-succ {
    background: url(../../imgs/pop_1.png) no-repeat;
    background-size: 100% 100%;
    width: 88vw;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;


    .exchange-modal-container-succ {
      width: 75%;
      height: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;

      .exchange-guess-title-succ {
        font-family: PangMenZhengDao;
        font-size: 2.5 *$rem;
        font-weight: normal;
        line-height: 45px;
        text-align: center;
        //margin-top: 1 *$rem;
        background: linear-gradient(108deg, #FF9BAD -14%, #FF4567 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .exchange-guess-title-container-succ {
        width: 97%;
        position: relative;
        font-family: PingFangSC-Regular;
        font-size: 1.3 *$rem;
        font-weight: normal;
        color: #1A1A1A;
        text-align: center;
        line-height: 50px;

        > :nth-child(1) {
          color: #E83B5C;
        }
      }

      .exchange_guess_btn_container_img_succ {
        width: 90%;
        margin: 2 *$rem 0 4 *$rem 0;
      }

      .exchange_guess_btn_container_succ {
        width: 90%;
        height: 3.6 *$rem;
        display: flex;
        align-items: center;
        justify-content: space-between;


        > p {
          width: 9 *$rem;
          height: 3 *$rem;
          display: flex;
          font-size: 1.6 *$rem;
          align-items: center;
          justify-content: center;
          font-family: PingFangSC-Medium;
        }

        > :nth-child(1) {
          color: #2E63F6;
          border-radius: 119px;
          background: linear-gradient(180deg, #C9EEFF 0%, #86D9FF 100%);
        }

        > :nth-child(2) {
          border-radius: 119px;
          color: #FFFFFF;
          background: linear-gradient(180deg, #FF9CAE 0%, #F26881 100%);
        }
      }
    }
  }
}
