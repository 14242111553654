$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.cartoon-item {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #FFF;
    border-radius: 1 * $rem;
    padding: 1.2 *$rem;
    margin-bottom: 1.5 *$rem;
    min-width: 30*$rem;
    .left-img {
        width: 10 *$rem;
        height: 10 * $rem;
        background-color: #D8D8D8;
        >img {
            width: 100%;
        }
    }
    .right-layout {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 0.9 *$rem;
        justify-content: space-between;
        &--top {
            > span {
                font-family: PingFangSC-Semibold;
                font-size: 1.3 *$rem;
                color: #333333;
            }
            > div {
                width: 4.2 *$rem;
                line-height: 1.6 *$rem;
                background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
                font-family: PingFangSC-Semibold;
                font-size: 1.2 *$rem;
                text-align: center;
                border-radius: 0.8 *$rem;
                color: #614D1A;
                margin-top: 1 *$rem;
            }

        }

        &--bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            > span {
                font-size: 1.2 *$rem;
                color: #9E9E9E;
            }
            > div {
                line-height: 1.5 *$rem;
                height: 1.5 *$rem;
                border-radius: 0.75 *$rem;
                font-family: PingFangSC-Semibold;
                font-size: 0.85 *$rem;
                padding: 0 0.8 *$rem;
                margin:  0 0.5 *$rem;

                color: #A1A1A1;
                background: linear-gradient(270deg, #F6F6F6 0%, rgba(212,212,212,0.98) 100%);
                &.enable {
                    color: #FFFFFF;
                    background: linear-gradient(270deg, #E8C591 0%, rgba(244,162,97,0.98) 100%);
                }
            }
        }

    }
}
