$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.shop_stage_share_container {
  width: 100%;
  height: 200%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .shop_share_body {
    width: 80vw;
    border-radius: 2 *$rem;
    margin-top: 6 *$rem;
    overflow: hidden;
    position: relative;

    .shop_share_content {
      width: 80vw;
      // position: relative;
      background-color: #054F2D;
      //background-color: black;

      .shop_share_avatar {
        overflow: hidden;

        img {
          display: block;
          //width: 100%;
          width: 80vw;
          //height: (80vw / (618/962));
        }
      }

      .shop_share_detail {
        width: 66 * $vw;
        height: 66 * $vw;
        position: absolute;
        right: 3%;
        bottom: 7%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 3px;
        border: 1px solid #fff;

        svg {
          //float: right;
          width: 60 * $vw;
          height: 60 * $vw;
          //width: 6.2 *$rem;
          //height: 6.2 *$rem;
        }
      }
    }
  }

  .opt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw;
    margin-top: 3.4 *$rem;
    //margin-bottom: 4vh;

    img {
      width: 4.6 *$rem;
      height: 4.6 *$rem;
    }


    .wechat,
    .share,
    .copy,
    .download {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.2 *$rem;
      color: #fff;

      span {
        margin-top: 0.8 *$rem;
      }
    }
  }


  .cancel_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw;
    height: 5 *$rem;
    overflow: hidden;
    margin-top: 5 *$rem;
    border-radius: 2.5 *$rem;
    //margin-bottom: 5vh;
    background-color: #262626;;
    font-size: 1.6 *$rem;
    font-weight: bold;
    color: #C1C1C1;
  }
}

