$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.session-item-component {
  width: 100%;

  .session-item_container {
    width: 92%;
    //min-height: 80px;
    margin: 1.5 *$rem auto;
    position: relative;

    .session-item-info {
      width: 100%;
      border-radius: 20px;
      border: 0.1px solid transparent;
      background: linear-gradient(121deg, #38A7FF -18%, #2B61F5 81%);

      .session-item-info-container {
        margin: 7px;
        padding-bottom: 1 *$rem;
        border-radius: 20px;
        border: 2px solid #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .session-item-info-container-header {
          width: 92%;
          height: 3 *$rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 1 *$rem;

          > p {
            padding: 3px 10px;
            font-family: PingFangSC-Medium;
            font-size: 1.1 *$rem;
            //line-height: 27px;
            text-align: center;
            color: #2E63F6;
            border-radius: 109px;
            background: #FFFFFF;
          }
        }

        .session-item-title {
          width: 90%;
          font-family: PingFangSC-Medium;
          font-size: 1.8 *$rem;
          font-weight: normal;
          //line-height: 44.44px;
          text-align: center;
          color: #FFFFFF;
          margin-top: 1 *$rem;
          margin-bottom: 0.5 *$rem;
          &.title1 {
            margin-top: 0;
          }
        }

        .question_container {
          width: 100%;
          height: 5 *$rem;
          //background-color: white;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          margin-top: 0.5 *$rem;

          .pk_logo {
            width: 3.2 *$rem;
            height: 3.2 *$rem;
            position: absolute;
            z-index: 1;
          }

          .question_a {
            width: 46%;
            height: 5.5 *$rem;
            position: relative;

            &.left {
              margin-left: 10px;
            }

            &.right {
              margin-right: 10px;
            }

            .pk_a_img {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }

            &.bg_div_red {
              width: 40%;
              height: 90%;
              background: linear-gradient(0deg, #F98399 2%, #FF385D 100%);
              border-radius: 146px;
            }

            &.bg_div_white {
              width: 40%;
              height: 90%;
              border-radius: 146px;
              border: 1px solid white;
            }

            &.bg_div_gray {
              width: 40%;
              height: 90%;
              background-color: #D0D0D0;
              border-radius: 146px;

            }

            .question_ab_container {
              width: 100%;
              height: 100%;
              position: relative;
              z-index: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;


              .p1 {
                font-family: PingFangSC-Medium;
                font-size: 1.5 *$rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 80%;
                text-align: center;


                &.blue0 {
                  color: #2E63F6;
                }

                &.white0 {
                  color: #fff;
                }
              }

              .p2 {
                font-family: PingFangSC-Medium;
                font-size: 1 *$rem;
                color: #E83E5D;
                background: #FFFFFF;
                padding: 1px 6px;
                border-radius: 167px;
              }
            }
          }
        }

        .proportion_container {
          width: 85%;
          //height: 3 *$rem;
          //background-color: red;
          margin-top: 0.3 *$rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          img {
            width: 2.5 *$rem;
            height: 2.5 *$rem;
          }
        }

        .proportion_press {
          width: 85%;
          height: 1.6 *$rem;
          border-radius: 20px;
          margin-top: 5px;
          background: linear-gradient(180deg, #F98399 2%, #E73E5D 100%);

          .proportion_press_left {
            width: 60%;
            height: 100%;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(180deg, #C1F9FF 2%, #60A7FF 100%);

            &.all_round{
              border-radius: 20px;
            }
          }
        }

        .proportion_press_count {
          width: 85%;
          margin-top: 0.5 *$rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          > p {
            font-family: PingFangSC-Medium;
            font-size: 1.1 *$rem;
            font-weight: normal;
            color: #FFFFFF;

          }
        }
      }
    }
  }
}
