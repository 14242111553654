$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.variation-ape {
  position: relative;
  padding: 0 0 4.8*$rem;
  background: #14141F;
  height: 100vh;
  overflow-y: auto;
  .back-share {
    position: fixed;
    left: 0;
    right: 0;
    top: 4.7*$rem;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5*$rem;
    .back-img {
      width: 0.95*$rem;
      height: 1.75*$rem;
    }
    .share-img {
      width: 1.7*$rem;
      height: 1.9*$rem;
    }
  }
  .rules-authen {
    position: absolute;
    left: 0;
    right: 0;
    top: 7.9*$rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2*$rem;
    color: #FFFFFF;
    font-weight: bold;
    .activity-rules {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.75*$rem;
      height: 2.5*$rem;
      border-radius: 0 1.25*$rem 1.25*$rem 0;
      background: #24FAAF;
    }
    .name-authen {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.95*$rem;
      height: 2.5*$rem;
      border-radius: 1.25*$rem 0 0 1.25*$rem;
      background: #24FAAF;
    }
  }
  .legend-ticket {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -1.2*$rem 1.3*$rem 0;
    height: 8*$rem;
    align-items: center;
    font-size: 1.6*$rem;
    color: #FFFFFF;
    font-weight: bold;
    border-radius: 0.85*$rem;
    backdrop-filter: blur(61px);
    box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
    background: linear-gradient(270deg, rgba(52,51,65,0.88) 0%, rgba(52,51,65,0.83) 100%);
    p {
      &:nth-child(3) {
        color: #24FAAF;
        font-size: 1.2*$rem;
      }
    }
  }
  .purchase-ranking-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -1.2*$rem 1.3*$rem 0;
    padding: 0 1.25*$rem;
    height: 8*$rem;
    border-radius: 0.85*$rem;
    backdrop-filter: blur(61px);
    box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
    background: linear-gradient(270deg, rgba(52,51,65,0.88) 0%, rgba(52,51,65,0.83) 100%);
    .ranking-left {
      font-size: 1.6*$rem;
      color: #FFFFFF;
      font-weight: bold;
    }
    .ranking-right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8.5*$rem;
      height: 3.5*$rem;
      font-size: 1.5*$rem;
      font-weight: bold;
      background: #D8D8D8;
      color: #767586;
      border-radius: 1.75*$rem;
    }
  }
  .main-img {
    img {
      width: 100%;
    }
  }
  .main-gl {
    padding: 2.7*$rem 1.3*$rem 0;
    img {
      width: 100%;
    }
  }
  .tab-box {
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    margin-top: 3.8*$rem;
    padding: 0 3.8*$rem;

    .content-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        margin-top: 0.65*$rem;
        width: 100%;
      }
    }
    .tab-common {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.3*$rem;
      color: #8B8B8B;
      font-weight: bold;
      line-height: 1.75*$rem;
      &.active-style {
        color: #FFFFFF;
      }
    }
    .time {
      font-weight: normal;
    }
  }
  .invite-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.65*$rem;
    padding: 0 1.3*$rem;
    border-radius: 0.85*$rem;
    width: 100%;
    box-sizing: border-box;
    height: 8*$rem;
    background: linear-gradient(270deg, rgba(52,51,65,0.88) 0%, rgba(52,51,65,0.83) 100%);
    img {
      width: 4*$rem;
      height: 4*$rem;
    }
    .text-box {
      margin-left: -2*$rem;
      p {
        font-size: 1.5*$rem;
        color: #FFFFFF;
        line-height: 2.24*4$rem;
        font-weight: bold;
      }
    }
    .invite-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10*$rem;
      height: 3.5*$rem;
      background: linear-gradient(117deg, #24FAAF -5%, #D200EE 104%);
      border-radius: 1.75*$rem;
      color: #FFFFFF;
      font-weight: bold;
      &.disabled {
        background: #D8D8D8;
        color: #767586;
      }
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    margin-top: 3.4*$rem;
    img {
      width: 15*$rem;
      height: 2.5*$rem;
    }
  }
  .left-tab-content {
    position: relative;
    margin-top: -0.86*$rem;
    padding: 0 1.3*$rem;
    .compose-medicine {
      width: 100%;
      height: 40*$rem;
      background: url('~@/assets/img/variationApe/pic_hecheng.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      .medicine-count {
        padding-top: 2.5*$rem;
        color: #FFFFFF;
        font-size: 1.75*$rem;
        line-height: 2.24*$rem;
        font-weight: bold;
      }
      .exchange-time {
        color: #24FAAF;
        font-size: 1.2*$rem;
        line-height: 2.24*$rem;
        font-weight: bold;
      }
      .compose-rule {
        position: absolute;
        left: 0;
        top: 23.4*$rem;
        display: flex;
        justify-content: space-between;
        padding: 0 5.7*$rem 0 6.3*$rem;
        color: #24FAAF;
        font-size: 1*$rem;
        line-height: 2.24*$rem;
        font-weight: bold;
        width: 100%;
        box-sizing: border-box;
      }
      .collection-info {
        display: flex;
        justify-content: space-between;
        margin-top: 20.8*$rem;
        padding: 0 3.5*$rem;
        color: #FFFFFF;
        font-size: 1*$rem;
        .count {
          color: #24FAAF;
        }
      }
      .compose-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 1*$rem;
        width: 30*$rem;
        height: 4.5*$rem;
        border-radius: 2.25*$rem;
        background: linear-gradient(102deg, #24FAAF -3%, #D200EE 100%);
        font-size: 1.5*$rem;
        color: #FFFFFF;
        font-weight: bold;
        &.disabled {
          background: #D8D8D8;
          color: #767586;
        }
      }
      .compose-record {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.3*$rem;
        font-size: 1.3*$rem;
        color: #FFFFFF;
        line-height: 2.2*$rem;
        img {
          margin-top: -0.25*$rem;
          width: 1*$rem;
          height: 1*$rem;
        }
      }
    }
  }
  .right-tab-content {
    margin-top: -0.86*$rem;
    padding: 0 1.3*$rem;
    .btn-common-style {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 30*$rem;
      height: 4.5*$rem;
      font-size: 1.5*$rem;
      color: #FFFFFF;
      font-weight: bold;
      border-radius: 2.25*$rem;
      background: linear-gradient(102deg, #24FAAF -3%, #D200EE 100%);
    }
    .right-invite-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 19*$rem;
      background: url('~@/assets/img/variationApe/frame_yx.png') no-repeat;
      background-size: 100% 100%;
      p {
        font-size: 1.5*$rem;
        font-weight: bold;
        line-height: 2.24*$rem;
        &:nth-child(1) {
          margin-top: 3*$rem;
          color: #FFFFFF;
        }
        &:nth-child(2) {
          color: #24FAAF;
        }
      }
      .invite-btn {
        margin-top: 1.37*$rem;
        &.unavailable {
          background: #D8D8D8;
          color: #767586;
        }
      }
      .invite-record-box {
        display: flex;
        align-items: center;
        margin-top: 1.65*$rem;
        span {
          font-size: 1.3*$rem;
          color: #FFFFFF;
          line-height: 2.24*$rem;
        }
        img {
          margin-top: -0.25*$rem;
          width: 1*$rem;
          height: 1*$rem;
        }
      }
    }
    .blind-box {
      position: relative;
      margin-top: 1.5*$rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 40*$rem;
      background: url('~@/assets/img/variationApe/frame_goumai.png') no-repeat;
      background-size: 100% 100%;
      p {
        font-weight: bold;
        line-height: 2.24*$rem;
        &:nth-child(1) {
          margin-top: 3*$rem;
          font-size: 1.75*$rem;
          color: #FFFFFF;
        }
        &:nth-child(2) {
          font-size: 1.2*$rem;
          color: #24FAAF;
        }
      }
      .blind-info {
        position: absolute;
        top: 14.5*$rem;
        left: 23*$rem;
        display: flex;
        flex-direction: column;
        .blind-text {
          display: flex;
          flex-direction: column;
          font-size: 1.5*$rem;
          color: #FFFFFF;
          font-weight: bold;
        }
        .blind-bottom {
          margin-top: 3*$rem;
          color: #FAB324;
          font-size: 2.2*$rem;
          font-weight: bold;
          span {
            &:nth-child(2) {
              font-size: 4*$rem;
            }
          }
        }
      }
      .purthase-blind {
        margin-top: 23.5*$rem;
        &.unavailable {
          background: #D8D8D8;
          color: #767586;
        }
      }
    }
    .compose-variation-ape {
      margin-top: 1.5*$rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 40*$rem;
      background: url('~@/assets/img/variationApe/frame_hecheng.png') no-repeat;
      background-size: 100% 100%;
      p {
        margin-top: 3*$rem;
        font-size: 1.75*$rem;
        color: #FFFFFF;
        font-weight: bold;
      }
      .compose-variation-ape-btn {
        margin-top: 0.8*$rem;
        &.unavailable {
          background: #D8D8D8;
          color: #767586;
        }
      }
      .compose-info {
        display: flex;
        align-items: center;
        margin-top: 21.5*$rem;
        color: #FFFFFF;
        font-size: 1*$rem;
        .compose-info-item {
          span {
            &:nth-child(2) {
              margin-left: 0.3*$rem;
              color: #24FAAF;
            }
          }
        }
        .line {
          margin: 0 0.8*$rem;
          background: #A4A1C2;
          height: 0.85*$rem;
          width: 0.05*$rem;
        }
      }
      .compose-record-box {
        display: flex;
        align-items: center;
        margin-top: 1.45*$rem;
        span {
          font-size: 1.3*$rem;
          color: #FFFFFF;
          line-height: 2.24*$rem;
        }
        img {
          margin-top: -0.25*$rem;
          width: 1*$rem;
          height: 1*$rem;
        }
      }
    }
  }
}
