$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.order_content {
  background: #ffffff;
  border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
  margin-bottom: 15*$rem/10;
  padding: 15*$rem/10;
  display: flex;
  width: 100%;

  .order-img {
    flex: 0 0 74*$rem/10;

    img {
      width: 74*$rem/10;
      height: 74*$rem/10;
      border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    }
  }

  .order-message {
    flex: 1;
    margin-left: 14*$rem/10;

    .title {
      height: 36*$rem/10;
      // margin-left: -6*$rem/10;
      font-size: 15*$rem/10;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 18*$rem/10;
    }

    .price {
      font-size: 16*$rem/10;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 19*$rem/10;
    }

    .pay-detail {
      display: flex;

      .num {
        flex: 1;
        font-size: 12*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #757575;
        line-height: 14*$rem/10;
      }

      .status {
        flex: 0 0 40*$rem/10;
        float: right;
        font-size: 12*$rem/10;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #a89555;
        line-height: 14*$rem/10;
      }
    }
  }
}
