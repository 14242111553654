$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.invite-popup-container {
  display: flex;
  flex-direction: column;
  height: 36*$rem;
  .invite-title {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 1.8*$rem;
    text-align: center;
    span {
      flex: 1;
    }
    img {
      width: 1.6*$rem;
      height: 1.6*$rem;
    }
  }
  .variation-invite-list {
    flex: 1;
    overflow-y: auto;
    .variation-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2.1*$rem;
      .item-left {
        display: flex;
        flex-direction: column;
        span {
          color: #FFFFFF;
          font-size: 1.6*$rem;
          &:nth-child(2) {
            font-size: 1.1*$rem;
            margin-top: 0.4*$rem;
            opacity: 0.8;
          }
        }
      }
      .item-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: #24FAAF;
        font-size: 1.6*$rem;
        span {
          &:nth-child(2) {
            font-size: 1*$rem;
            margin-top: 0.4*$rem;
            opacity: 0.8;
          }
        }
      }
    }
  }
}