@function to($px) {
  @return ($px / 1624) * 100vw;
}

.recycling_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.list_container {
  margin: to(10) to(96);
  overflow: auto;
}

.list_item {
  margin-bottom: to(25);
  min-height: to(156);
  border-radius: to(7);
  background: linear-gradient(279deg, #FCD786 0%, #FFE9A0 99%);
  box-sizing: border-box;

  .time {
    display: flex;
    justify-content: space-between;
    padding: to(18) to(10) to(14);
    margin: 0 to(47);
    color: #000000;
    font-size: to(20);
    border-bottom: to(1) solid #000000;
    box-sizing: border-box;
  }

  .recyc_ele {
    margin-top: to(23);
    padding: 0 to(57);
    color: #000000;
    font-weight: 600;
    font-size: to(21);
    box-sizing: border-box;

    .recyc_ele_item {
      margin-bottom: to(18);

      > span {
        margin-right: to(10);
      }
    }
  }

  .ele_num {
    padding: 0 to(57) to(8);
    box-sizing: border-box;
    text-align: right;
    color: #BF0808;
    font-weight: 600;
    font-size: to(24);
  }
}