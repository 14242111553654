@function to($px) {
  @return ($px / 2558) * 100vw;
}

.list_out {
  width: to(1117);
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  margin-top: to(20);

  :global {
    .list-view-body {
      padding-top: to(46);
      padding-bottom: to(20);
      padding-left: to(20);
    }
  }

  .list_item:nth-child(4n+4) {
    margin-right: 0;

  }

  .list_item {
    display: inline-block;
    margin-bottom: to(96);
    margin-right: to(63);
    width: to(220);
    height: to(220);
    box-sizing: border-box;
    border-radius: to(27);
    position: relative;
    background: linear-gradient(180deg, #F3CEAC 0%, #E7B17F 100%);
    box-sizing: border-box;
    border: to(1.57) dashed #B5641E;
    box-shadow: 0px to(3.15) 0px 0px rgba(181, 100, 30, 0.8), 0px to(6.29) to(15.73) 0px #FFE8D3, inset 0px to(6.29) 15.73px 0px #FFC9A9;
    transition: all 100ms;

    .item_nftNo {
      width: 100%;
      position: absolute;
      bottom: to(-60);
      font-family: PingFang SC;
      font-size: to(24);
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      font-variation-settings: "opsz"auto;
      color: #763900;
      text-align: center;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;

    }

    .item_img {
      width: to(215);
      height: to(215);
      font-size: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
        height: 100%;
        border-radius: to(24);
      }
    }
  }

  .actived {
    border: none;
    position: relative;
    background-image: url("~@/assets/img/landfi/check-bg-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: 0px to(6.29) to(15) 0px rgba(181, 100, 30, 0.8) !important;
  }

  .actived::after {
    content: '';
    position: absolute;
    background-image: url("~@/assets/img/landfi/check-icon.png");
    background-size: 100% 100%;
    background-position: center;
    width: to(48);
    height: to(48);
    right: to(-14);
    top: to(-14);

  }
}