@import '@/assets/scss/variable.scss';

.pay_success {
    width: 100vw;
    height: 100%;
    background-color: white;
    position: relative;
    :global {
        .left {
            display: none;
        }
        .right {
            display: none;
        }
    }
    .pay_success_bg {
        width: 100%;
        height: 100vh;
        background: url(~@/assets/img/ape/buy_bg.png) no-repeat;
        background-size: 100%;
        overflow: hidden;

        .banner {
            margin: 17vh 11vw 0;
            width: 80vw;
            display: flex;
            flex-direction: column;
            align-items: center;

            .stage {
                margin-top: 2vh;
                width: 20*$rem;
                height: 20*$rem;

                transform-style: preserve-3d;
                /* 为了方便观察让3D舞台转动角度 */
                // transform: rotateX(330deg) rotateY(-30deg) rotateZ(180deg);
                transform: rotateX(-30deg) rotateY(60deg);
                animation: play0 3s forwards;

                .face {
                    position: absolute;
                    width: 16*$rem;
                    height: 16*$rem;

                    img {
                        width: 100%;
                    }

                    &.front {
                        /* 第一个面往前走 */
                        transform: translateZ(8*$rem);
                        animation: play2 3s forwards;
                    }

                    &.rear {
                        /* 第二个面往后走 */
                        transform: translateZ(-8*$rem) rotateY(180deg);
                        animation: play1 3s forwards;
                    }

                    &.up {
                        /* 先往上位移150*$rem/10  再绕着X轴转动90deg */

                        transform: translateY(-8*$rem) rotateX(90deg);
                        animation: play3 3s forwards;
                    }

                    &.down {
                        /* 先往下位移150*$rem/10,再绕着X轴转动90deg */
                        animation: play6 3s forwards;
                        transform: translateY(8*$rem) rotateX(-90deg);
                    }

                    &.left {
                        /* 先往左位移150*$rem/10 , 再绕着Y轴转动90deg */
                        transform: translateX(-8*$rem) rotateY(-90deg);
                        animation: play5 3s forwards;
                    }

                    &.right {
                        /* 先往右侧位移150*$rem/10,再绕着Y轴转动90deg */
                        transform: translateX(8*$rem) rotateY(-270deg);
                        animation: play4 3s forwards;
                    }
                }

                @keyframes play0 {
                    0% {
                        transform: rotateX(330deg) rotateY(-30deg) rotateZ(180deg);
                    }

                    50% {
                        transform: rotateX(-30deg) rotateY(60deg);
                    }

                    100% {
                        transform: rotateX(-30deg) rotateY(60deg);
                    }
                }

                @keyframes play1 {
                    0% {
                        opacity: 1;
                        transform: translateZ(-8*$rem);
                        transform-origin: bottom;
                    }

                    50% {
                        opacity: 1;
                        transform: translateZ(-8*$rem);
                        transform-origin: bottom;
                    }

                    100% {
                        opacity: 0;
                        transform-origin: bottom;
                        transform: translateZ(-8*$rem) rotatex(180deg);
                    }
                }

                @keyframes play2 {
                    0% {
                        opacity: 1;
                        transform: translateZ(8*$rem);
                        transform-origin: bottom;
                    }

                    50% {
                        opacity: 1;
                        transform: translateZ(8*$rem);
                        transform-origin: bottom;
                    }

                    100% {
                        opacity: 0;
                        transform-origin: bottom;
                        transform: translateZ(8*$rem) rotatex(-180deg);
                    }
                }

                @keyframes play3 {
                    0% {
                        opacity: 1;
                        transform: rotateX(-90deg) translateZ(-8*$rem);
                    }

                    50% {
                        opacity: 1;
                        transform: rotateX(-90deg) translateZ(-8*$rem);
                    }

                    100% {
                        opacity: 0;

                        transform: rotateX(-90deg) translateZ(-32*$rem);
                    }
                }

                @keyframes play4 {
                    0% {
                        opacity: 1;
                        transform-origin: bottom;
                        transform: translateX(8*$rem) rotateY(-270deg);
                    }

                    50% {
                        opacity: 1;
                        transform-origin: bottom;
                        transform: translateX(8*$rem) rotateY(-270deg);
                    }

                    100% {
                        opacity: 0;
                        transform-origin: bottom;
                        transform: rotateZ(180deg) rotateY(90deg) translateZ(-8*$rem);
                    }
                }

                @keyframes play5 {
                    0% {
                        opacity: 1;
                        transform-origin: bottom;
                        transform: translateX(-8*$rem) rotateY(-90deg);
                    }

                    50% {
                        opacity: 1;

                        transform-origin: bottom;
                        transform: translateX(-8*$rem) rotateY(-90deg);
                    }

                    100% {
                        opacity: 0;
                        transform-origin: bottom;
                        transform: rotateZ(-180deg) rotateY(-90deg) translateZ(-8*$rem);
                    }
                }

                @keyframes play6 {
                    0% {
                        opacity: 1;
                        transform: translateY(8*$rem) rotateX(-90deg);
                    }

                    50% {
                        opacity: 1;
                        transform: translateY(8*$rem) rotateX(-90deg);
                    }

                    100% {
                        opacity: 0;
                        transform: translateY(8*$rem) rotateX(-90deg);
                    }
                }
            }

            .collectionList {
                position: absolute;
                display: flex;
                flex-direction: column;
                &.fade_in {
                    opacity: 0;
                    animation: play8 3s forwards;

                    @keyframes play8 {
                        0% {
                            opacity: 0;
                        }

                        80% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                }

                .blinBox_Swiper {
                    img {
                        width: 200 * $vw;
                        height: 200 * $vw;
                        border-radius: 20*$rem/10;
                        overflow: hidden;
                        background: #D8D8D8;

                        @media screen and (min-height: 736px) {
                            img {
                                margin-top: -2*$rem;
                            }
                        }

                        @media screen and (min-height: 780px) {
                            img {
                                margin-top: -3*$rem;
                            }
                        }
                    }

                    .show_text {
                        margin-top: 14 * $vw;
                        font-weight: normal;
                        font-size: 18px;
                        color: #000000;
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        &.hidden_text {
                            color: rgba(255, 255, 255, 0);
                            display: none;
                          }
                    }
                }
                .btnGroup {
                    display: flex;
                    flex-direction: row;
                    margin-top: 18*$rem;
                    justify-content: center;

                    >button {
                        &:nth-child(1) {
                            height: 4.6 *$rem;
                            border-radius: 2.3 *$rem;
                            color: #614D1A;
                            font-size: 1.6 *$rem;
                            padding: 0 4*$rem;
                            background: #B8B8B8;
                        }
                        &:nth-child(2) {
                            height: 4.6 *$rem;
                            margin-left: 10%;
                            border-radius: 2.3 *$rem;
                            padding: 0 3*$rem;
                            color: #614D1A;
                            font-size: 1.6 *$rem;
                            background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
                        }
                        
                    }
                }
            }
        }
    }

}