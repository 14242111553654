@import '@/assets/scss/variable.scss';

@function to($px) {
  @return ($px / 1624) * 100vw;
}

.huiContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    > p {
      height: 100%;
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;
      overflow-y: auto;
      font-family: PingFang SC;
      font-size: 1 *$rem;
      font-weight: 600;
      color: #FFFFFF;
      white-space: pre-line
    }
  }
}
