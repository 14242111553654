$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.compose-medicine-page {
  display: flex;
  flex-direction: column;
  background: #14141F;
  height: 100vh;
  overflow: hidden;
  .compose-material-container {
    padding: 4.75*$rem 1.25*$rem 2.7*$rem;
    .material-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img-box {
        display: flex;
        align-items: center;
      }
      .text-box {
        display: flex;
        width: 100%;
        .material-item {
          height: 3.5*$rem;
        }
      }
      .equal-sign-left {
        display: flex;
        align-items: center;
        .material-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 0.7*$rem;
          width: 7*$rem;
          .num-img-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .material-collection-img {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.05*$rem solid #24FAAF;
            box-sizing: border-box;
            background: #353443;
            width: 100%;
            height: 7*$rem;
            .material-img {
              width: 100%;
              height: 100%;
            }
            .add-sign {
              width: 1.6*$rem;
              height: 1.6*$rem;
            }
          }
          .collection-num {
            color: #FFFFFF;
            font-size: 1.2*$rem;
            line-height: 2.24*$rem;
            font-weight: bold;
          }
          .delete-img {
            width: 1.75*$rem;
            height: 1.75*$rem;
          }
        }
      }
      .equal-sign-middle {
        margin-right: 0.7*$rem;
        width: 2.55*$rem;
        img {
          width: 2.55*$rem;
          height: 1.65*$rem;
        }
      }
      .equal-sign-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8.5*$rem;
        img {
          width: 8.5*$rem;
          height: 8.5*$rem;
          border: 0.05*$rem solid #24FAAF;
        }
        span {
          color: #24FAAF;
          font-size: 1.2*$rem;
          font-weight: bold;
          line-height: 2.24*$rem;
        }
      }
    }
    .compose-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2.9*$rem auto 0;
      color: #767586;
      font-size: 1.5*$rem;
      width: 17.6*$rem;
      height: 4*$rem;
      border-radius: 2*$rem;
      background: #D8D8D8;
      font-weight: bold;
      &.available {
        background: linear-gradient(108deg, #24FAAF -4%, #D200EE 101%);
        color: #FFFFFF;
      }
    }
  }
  .epic-funds-collection {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1.3*$rem 1.95*$rem 0;
    border-radius: 1.5*$rem 1.5*$rem 0 0;
    background: linear-gradient(270deg, rgba(52,51,65,0.88) 0%, rgba(52,51,65,0.83) 100%);
    backdrop-filter: blur(61px);
    box-shadow: inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42);
    overflow-y: auto;
    .choose-tip {
      color: #FFFFFF;
      font-size: 1.4*$rem;
      line-height: 2.24*$rem;
    }
    .epic-funds-list {
      flex: 1;
      margin-top: 1.5*$rem;
      overflow-y: auto;
      .epic-funds-item {
        display: inline-block;
        position: relative;
        margin-bottom: 1.1*$rem;
        margin-left: 1.75*$rem;
        width: 10*$rem;
        height: 10*$rem;
        border: 0.4*$rem solid transparent;
        box-sizing: border-box;
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
        &.active {
          border-color: #FA6124;
        }
        &.disabled {
          &::after {
            content: '';
            display: block;
            background: rgba(0, 0, 0, .7);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
        .epic-num {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 1.5*$rem;
          background: rgba(0, 0, 0, .5);
          color: #FFFFFF;
          font-size: 1.2*$rem;
          line-height: 1.5*$rem;
          font-weight: bold;
        }
        img {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .adm-modal {
    .adm-modal-wrap {
      width: 100vw;
      max-width: initial;
      >div {
        .adm-modal-body {
          padding: 0;
          .adm-modal-content {
            padding: 0;
            .alert-body {
              .alert-content {
                margin: 0;
                line-height: 0;
              }
            }
          }
          .adm-modal-footer-empty {
            height: 0;
          }
        }
      }
    }
  }
}