$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.adm-modal {
    
    .adm-center-popup-wrap,
    .adm-modal-wrap {
        .adm-modal-body {
            .adm-modal-content {
                padding-bottom: 0;
            }
            //adm-modal-footer下布局修改需谨慎，防止影响alert.show的布局
            .adm-modal-footer {
                &:not(.adm-modal-footer-empty) {
                    padding: 0.9*$rem 0;
                    margin: 1.5*$rem 1.5*$rem 0;
                    border-top: #D8D8D8 solid 0.5px;
                }
                
                &.adm-space-vertical {
                    flex-direction: row;
                    .adm-space-item {
                        flex: 1;
                        margin-bottom: 0;
                        padding: 2.5px 0;
                        border-right: #D8D8D8 solid 0.5px;
                        &:last-child {
                            border-right: initial;
                        }
                    }
                }
                .adm-modal-button {
                    font-size: 1.5*$rem;
                }
            }
            &.no-style {
                padding: 0;
                .adm-modal-content {
                    padding: 0;
                    .alert-body {
                        .alert-content {
                            margin: 0;
                            line-height: 0;
                        }
                    }
                }
                .adm-modal-footer-empty {
                    height: 0;
                }
            }
        }
    }
    .alert-body{
        display: flex;
        flex-direction: column;
    
        .alert-title {
            font-family: PingFangSC-Semibold;
            color: #333;
            font-size: 1.6*$rem;
            text-align: center;
            text-overflow: ellipsis;
            margin-bottom: 2*$rem;
        }
    
        .alert-content {
            margin-bottom: 1*$rem;
        }
    
        .alert-buttons {
            display: flex;
            flex-direction: row;
            border-top: #D8D8D8 solid 0.05*$rem;
            margin-top: 2*$rem;
            >div {
                flex: 1;
                margin-top: 0.5*$rem;
                text-align: center;
                font-size: 1.5*$rem;
                color: #0256FF;
                min-height: 3*$rem;
                line-height: 3*$rem;
                border-right: #D8D8D8 solid 1px;
    
                &:active {
                    opacity: 0.8;
                }
                &:nth-child(1) {
                    color: #999999;
                }
                &:nth-child(2) {
                    color: #CAA846;
                }
                &:last-child {
                    border-right: initial;
                }
    
            }
    
        }
    }
    
}

