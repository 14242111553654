$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.nft-header {
  .container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    color: #333333;
    padding-bottom: 1*$rem;
    z-index: 999;
    .left {
      flex: 1;
      padding-left: 1.7*$rem;
      img {
        width: 0.75*$rem;
        height: 1.55*$rem;
      }
    }
    .middle {
      flex: 2;
      text-align: center;
      font-size: 1.8*$rem;
      font-weight: 500;
      white-space: nowrap;
    }
    .right {
      padding-right: 1.7*$rem;
      flex: 1;
      text-align: right;
      font-size: 1.4*$rem;
    }
  }
}
