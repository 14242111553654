$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.empty-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .empty-img {
    width: 18.5*$rem;
    height: 18.5*$rem;
  }
  .empty-desc {
    color: #8C8C8C;
    font-size: 1.5*$rem;
    font-weight: 500;
  }
}