@function to($px) {
  @return ($px / 2558) * 100vw;
}

.speed_card_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .my_asselerator_card {
    position: relative;
    padding: to(140) to(290) to(16) to(286);
    width: to(2377);
    height: to(1120);
    background: url('~@/assets/img/landfi/land-detail-bg.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    &.purchase_card {
      padding: to(160) to(355) to(16) to(395);
      width: 100vw;
      height: 100vh;
      background: url('~@/assets/img/landfi/land-detail-bg.png') no-repeat;
      background-size: 98% 100%;
      background-position: center;

      .title {
        top: to(-70);
        left: to(170);
      }

      .purchase_more {
        right: to(350);
      }
    }

    .title {
      top: to(-85);
      left: to(55);
      padding-top: to(124);
      padding-left: to(85);
      font-size: to(62);
      width: to(485);
      height: to(234);
    }

    .purchase_more {
      position: absolute;
      top: to(50);
      right: to(280);
      color: #792D16;
      font-size: to(44);
      font-weight: 500;
    }

    .points_use_tip {
      position: absolute;
      top: to(50);
      left: to(660);
      color: #A05D20;
      font-size: to(44);
      font-weight: 500;
    }

    .close_btn {
      position: absolute;
      top: to(40);
      right: to(120);
      width: to(92);
      height: to(92);
    }

    .card_list {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
    }
  }

  .my_prop_card {
    position: relative;
    padding: to(175) to(170) to(52) to(590);
    width: to(2377);
    height: to(1120);
    background: url('~@/assets/img/landfi/landgy/land-detail-bg.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    &.prop_card {
      padding: to(175) to(170) to(52) to(590);
      width: 100vw;
      height: 100vh;
      background: url('~@/assets/img/landfi/landgy/land-detail-bg.png') no-repeat;
      background-size: 104% 108%;
      background-position: center;

      &>.title_box {
        position: absolute;
        top: to(0);
        left: to(154);
        width: to(368);
        height: to(647);

        &>.title {
          top: to(50);
          left: 0;
          width: to(368);
          height: to(143);
          background: url('~@/assets/img/landfi/landgy/buy_prop_title.png') no-repeat;
          background-size: 100% 100%;
          background-position: center;
          z-index: 1;
          padding: 0;
        }

        &>.title_ele {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: to(647);
        }

        &>.title_ele::before {
          position: absolute;
          top: 0;
          left: to(50);
          content: '';
          width: to(27);
          height: to(647);
          background: #776E6F;
          border: to(6) solid #211E1E;
        }

        &>.title_ele::after {
          position: absolute;
          right: to(50);
          content: '';
          width: to(27);
          height: to(647);
          background: #776E6F;
          border: to(6) solid #211E1E;
        }
        &>.sub_title_box{
          top: to(250);
          position: absolute;
          &>.sub_title {
            width: to(363);
            height: to(98);
            background: url('~@/assets/img/landfi/landgy/buy_prop.png') no-repeat;
            background-size: 100% 100%;
            background-position: center;
            font-family: YueGenYouShangYueBoHei;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: to(40);
            font-weight: bold;
            color: #FFFFFF;
            text-shadow:
            -1*to(1) -1*to(1) 0 #000,  
            to(1) -1*to(1) 0 #000,
            -1*to(1) to(1) 0 #000,
            to(1) to(1) 0 #000; /* 文字描边色 */
          }
          &>.actived{
            color: #FEE766;
          }
        }
       
      }

      &>.purchase_more {
        right: to(350);
      }



      &>.purchase_more {
        position: absolute;
        top: to(80);
        right: to(280);
        width: to(243);
        height: to(73);
        background: url('~@/assets/img/landfi/landgy/buy_record_btn.png') no-repeat;
        background-size: 100% 108%;
        background-position: center;
      }

      &>.points_use_tip {
        position: absolute;
        top: to(80);
        left: to(660);
        color: #FFFFFF;
        font-size: to(44);
        font-weight: 500;

      }

      &>.close_btn {
        position: absolute;
        top: to(40);
        right: to(40);
        width: to(88);
        height: to(88);
      }

      &>.card_list {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
      }
    }
  }

  .my_package_box {
    position: relative;
    width: to(2377);
    height: to(1120);
    background: url('~@/assets/img/landfi/landgy/land-detail-bg.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    &.my_package {
      padding: to(175) to(120) to(52) to(450);
      background: url('~@/assets/img/landfi/landgy/land-detail-bg.png') no-repeat;
      background-size: 100% 100%;
      background-position: center;

      &>.title_box {
        position: absolute;
        top: to(0);
        left: to(80);
        width: to(368);
        height: to(647);

        &>.title {
          top: to(50);
          left: 0;
          width: to(368);
          height: to(143);
          background: url('~@/assets/img/landfi/landgy/my_pack_title.png') no-repeat;
          background-size: 100% 100%;
          background-position: center;
          z-index: 1;
          padding: 0;
        }

        &>.title_ele {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: to(647);
        }

        &>.title_ele::before {
          position: absolute;
          top: to(70);
          left: to(50);
          content: '';
          width: to(27);
          height: to(492);
          background: #776E6F;
          border: to(6) solid #211E1E;
        }

        &>.title_ele::after {
          position: absolute;
          top: to(70);
          right: to(50);
          content: '';
          width: to(27);
          height: to(492);
          background: #776E6F;
          border: to(6) solid #211E1E;
        }

        &>.sub_title_box{
          top: to(210);
          position: absolute;
          &>.sub_title {
            width: to(363);
            height: to(98);
            background: url('~@/assets/img/landfi/landgy/buy_prop.png') no-repeat;
            background-size: 100% 100%;
            background-position: center;
            font-family: YueGenYouShangYueBoHei;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: to(40);
            font-weight: bold;
            color: #FFFFFF;
            text-shadow:
            -1*to(1) -1*to(1) 0 #000,  
            to(1) -1*to(1) 0 #000,
            -1*to(1) to(1) 0 #000,
            to(1) to(1) 0 #000; /* 文字描边色 */
          }
          &>.actived{
            color: #FEE766;
          }
        }
      }

      &>.purchase_more {
        right: to(350);
      }



      &>.purchase_more {
        font-size: to(44);
        font-weight: 500;
        position: absolute;
        top: to(80);
        right: to(280);
        width: to(243);
        height: to(73);
        color: #FFFFFF;
        text-decoration:underline ;
        text-underline-offset: to(10);

      }
      

      &>.points_use_tip {
        position: absolute;
        top: to(80);
        left: to(660);
        color: #FFFFFF;
        font-size: to(44);
        font-weight: 500;

      }

      &>.close_btn {
        position: absolute;
        top: to(40);
        right: to(40);
        width: to(88);
        height: to(88);
      }

      &>.card_list {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
      }
    }
  }
}

.no_card_purchase {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #A05D20;
  font-size: to(50);
  font-weight: bold;
  width: 100%;
  height: 100%;
}

.no_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .empty_box {
    width: to(345);
    height: to(310);
    background: url('~@/assets/img/landfi/icon_k.png') no-repeat;
    background-size: 100% 100%;
  }

  >p {
    margin: to(42) 0 to(74);
    color: #A05D20;
    font-size: to(32);
    font-weight: 500;
  }

  .purchase_btn {
    width: to(372);
    height: to(107);
    background: url('~@/assets/img/landfi/677.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: to(107);
    color: #FFFFFF;
    font-size: to(50);
    font-weight: bold;
    font-family: YueGenYouShangYueBoHei;
  }
}

.speed_card_item {
  position: relative;
  display: inline-block;
  margin-right: to(91);
  margin-bottom: to(10);
  width: to(369);
  height: to(469);
  background: url('~@/assets/img/landfi/speed-card-bg.png') no-repeat;
  background-size: 100% 100%;

  &:nth-child(4n) {
    margin-right: 0;
  }

  .card_count {
    transform: translate(35%, 45%);
    width: to(82);
    height: to(82);
    background: linear-gradient(140deg, #E9EDAD 15%, #EA7C1C 84%);
    border-radius: 50%;
    text-align: center;
    line-height: to(82);
    color: #FFFFFF;
    font-size: to(44);
    font-weight: 600;
    box-sizing: border-box;
  }

  .multiple {
    position: absolute;
    top: to(-23);
    right: to(-50);
    padding-left: to(70);
    box-sizing: border-box;
    line-height: to(150);
    width: to(285);
    height: to(150);
    background: url('~@/assets/img/landfi/speedup-bg.png') no-repeat;
    background-size: 100% 100%;
    font-family: YueGenYouShangYueBoHei;
    color: #BF5B00;
    text-shadow: 0px to(1.57) 0px #000000;
    font-size: to(50);
    font-weight: bold;

    >span {
      display: inline-block;
      transform: rotate(-6deg);
    }
  }

  .middle_img {
    margin: to(50) auto 0;
    width: to(200);
    height: to(167);
    background: url('~@/assets/img/landfi/776.png') no-repeat;
    background-size: 100% 100%;

    &.middle_img_purchase {
      margin-top: to(130);
    }
  }

  .day_exp {
    position: absolute;
    left: 50%;
    bottom: to(120);
    transform: translateX(-50%);
    z-index: 1;
    color: #602401;
    font-size: to(38);
    font-weight: 500;
    text-align: center;
    width: to(260);
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .use_btn {
    position: absolute;
    bottom: 0;
    left: to(-2);
    width: to(372);
    height: to(107);
    background: url('~@/assets/img/landfi/677.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: to(107);
    color: #FFFFFF;
    font-size: to(50);
    font-weight: bold;

    &.use_btn_family {
      font-family: YueGenYouShangYueBoHei;
    }
  }
}

.speed_card_confirm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: to(65) to(120) to(0);
  width: to(966);
  height: to(743);
  background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
  background-size: 100% 100%;
  font-family: YueGenYouShangYueBoHei;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    >span {
      &:nth-child(1) {
        width: to(90);
        height: to(90);
      }

      &:nth-child(2) {
        color: #371F00;
        font-size: to(60);
        font-weight: bold;
      }
    }

    >img {
      width: to(90);
      height: to(90);
    }
  }

  >p {
    margin: to(17) 0 to(54);
    width: to(700);
    text-align: center;
    color: #792D16;
    font-size: to(31);
  }

  .used_land_img {
    position: absolute;
    top: 21%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: to(611);
    height: to(531);
    background: url('~@/assets/img/landfi/confirm-img-bg.png') no-repeat;
    background-size: 100% 100%;

    >img {
      width: to(307);
      height: to(314);
    }

    &::after {
      position: absolute;
      top: to(150);
      right: to(130);
      content: "";
      display: block;
      width: to(44);
      height: to(50);
      background: url('~@/assets/img/landfi/speed-sign.png') no-repeat;
      background-size: 100% 100%;
    }

  }

  .btn_box {
    position: absolute;
    bottom: to(70);
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 to(160);
    margin-top: to(340);
    box-sizing: border-box;

    .cancel_btn {
      background: url('~@/assets/img/landfi/icon_zh.png') no-repeat;
      background-size: 100% 100%;
    }

    .confirm_btn {
      background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .common_btn {
    width: to(238);
    height: to(81);
    border-radius: to(40);
    text-align: center;
    line-height: to(81);
    color: #FFFFFF;
    font-size: to(37);
    font-family: YueGenYouShangYueBoHei;
  }
}

.purchase_records_container {
  position: relative;
  padding: to(206) to(120) to(50) to(100);
  width: to(1763);
  height: 90vh;
  background: url('~@/assets/img/landfi/order_bg.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;

  >h1 {
    position: absolute;
    top: to(50);
    left: 50%;
    transform: translateX(-50%);
    font-size: to(52);
    font-weight: bold;
    color: #371f00;
  }

  .records_list {
    padding: 0 to(20);
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .records_item {
    margin-bottom: to(32);
    padding: to(27) to(60);
    width: 100%;
    background: #F1D7AE;
    box-sizing: border-box;
    border-radius: to(17);
    color: #A05D20;

    .item_top {
      display: flex;
      flex-direction: column;
      padding-bottom: to(6);
      border-bottom: to(1.57) solid rgba(170, 103, 39, 0.41);

      .order_info {
        margin-bottom: to(10);
        font-size: to(38);
      }

      .order_time {
        margin-bottom: to(10);
        font-size: to(38);
      }
    }

    .item_bottom {
      display: flex;
      justify-content: space-between;
      margin-top: to(33);

      >div {
        font-size: to(35);

        >span {
          font-size: to(40);
          font-weight: 600;
        }
      }
    }
  }

}

.no_data {
  display: flex;
  justify-content: center;
  padding-top: to(40);
  margin: to(280) auto 0;
  background: url('~@/assets/img/landfi/img_brand.png') no-repeat;
  background-size: 100% 100%;
  width: to(268);
  height: to(215);
  box-sizing: border-box;
  font-family: YueGenYouShangYueBoHei;
  color: #792B06;
}