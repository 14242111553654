@function to($px) {
  @return ($px / 2558) * 100vw;
}

.modal_box {
  width: to(1070);
  height: to(724);
  background-image: url("~@/assets/img/landfi/pop_2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.info_out {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input_out {
    height:to(429);
    padding-top: to(77);
    padding-bottom: to(77);
    box-sizing: border-box;
    .select_box {
      display: flex;
      align-items: center;
      gap: to(10);
     
      .label {
        font-family: PingFang SC;
        font-size: to(50);
        font-weight: 500;
        color: #763900;
      }

      .select {
        width: to(637);
        height: to(99);
        background: #FEFAF2;
        border: to(1) solid #FFFFFF;
        border-radius: to(12);
        padding: to(18) to(0);
        box-sizing: border-box;
        .forever {
          font-family: PingFang SC;
          font-size: to(44);
          font-weight: 500;
          color: #A5A5A5;
          margin-left: to(52);
        }
      }
    }

    .input_box {
      display: flex;
      align-items: center;
      gap: to(10);
      margin-bottom: to(77);

      .label {
        font-family: PingFang SC;
        font-size: to(50);
        font-weight: 500;
        color: #763900;
      }

      .input {
        color: #C77F3D;
        font-size: to(44);
        width: to(637);
        height: to(99);
        padding: to(18) to(52);
        border-radius: to(12);
        opacity: 1;
        background: #FEFAF2;
        box-sizing: border-box;
        border: to(1) solid #FFFFFF;
        position: relative;

        .icon {
          position: absolute;
          width: to(88);
          height: to(88);
          top: to(0);
          right: to(44);
          background-image: url("~@/assets/img/landfi/inputicon.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
        }

        :global {
          .adm-input {
            min-height: 0;
            width: calc(100% - to(88));
          }

          .adm-input-element {
            color: #C77F3D;
            font-size: to(44);
            font-weight: 500;
            --placeholder-color: #C77F3D;
          }
        }
      }
    }
  }


  .checked_box {
    flex:1;
    font-family: PingFang SC;
    font-size: to(37);
    font-weight: normal;
    color: #9C766B;
    padding:to(37) to(0);
    box-sizing: border-box;
    div {
      margin-bottom: to(10);
      word-spacing: normal;
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  .btn_box {
    height: to(135);
    font-family: YueGenYouShangYueBoHei;
    color: #FFFFFF;
    font-size: to(50);
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: space-between;

    .btn_publish {
      width: to(500);
      height: to(135);
      background-image: url("~@/assets/img/landfi/check-publish.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }

    .btn_cancle {
      width: to(370);
      height: to(135);
      background-image: url("~@/assets/img/landfi/check-cancel.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }


}