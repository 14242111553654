$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.egg_hc_big_img_container {
  .adm-mask-content {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .egg_hc_big_img_container0 {
    display: flex;
    width: 100%;
    height: 100vh;
    //background-color: yellow;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .egg_hc_big_img {
    width: 100%;
    height: 45vh;
    object-fit: cover;
    top: 20vh;
    left: 0;
    position: absolute;
    //display: inline-block;

  }
}




