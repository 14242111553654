@font-face {
    font-family: RuiZiZhenYan;
    src: url('RuiZiZhenYan.ttf') format('truetype');
}

@font-face {
    font-family: PangMenZhengDao;
    src: url('PangMenZhengDao.ttf') format('truetype');
}

@font-face {
  font-family: YueGenYouShangYueBoHei;
  src: url('YueGenYouShangYueBoHei.ttf') format('truetype');
}

@font-face {
    font-family: RuiZiZhenYanTiMianFeiShangYong;
    src: url('RuiZiZhenYanTiMianFeiShangYong-2.ttf') format('truetype');
}

@font-face {
    font-family: AlimamaShuHeiTi;
    src: url('AlimamaShuHeiTi.ttf') format('truetype');
}

