$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.synthesis {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .synthesis-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        background: #04234F;

        .select-preview {
            display: flex;
            flex-direction: column;
            margin-top: 5.45*$rem;
            .preview-list {
                display: flex;
                flex-direction: row;
                justify-content: center;

                .preview-after {
                    position: relative;
                }

                .preview-after::after {
                    content: '';
                    position: absolute;
                    width: 6*$rem;
                    height: 6*$rem;
                    right: -4.25*$rem;
                    top: 50%;
                    transform: translate(0, -50%);
                    background: url("~@/assets/img/industrialApe/add2.png") no-repeat center center / 100% 100%;
                }

                .preview-item {
                    margin: 0 2.5*$rem 7.9*$rem 0;
                    width: 6.1*$rem;
                    height: 6.4*$rem;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid #34D3EF;
                    box-shadow: inset 0px 0px 8.74px 0px rgba(107, 33, 66, 0.42);

                    .preview-img {
                        width: 100%;
                        height: 100%;
                        background: #FFFFFF;
                    }

                    .close-icon {
                        position: absolute;
                        right: 0.4*$rem;
                        top: 9*$rem;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 1.5*$rem;
                        height: 1.5*$rem;
                    }

                    span {
                        margin-top: 0.5*$rem;
                        width: 6.1*$rem;
                        color: #FFFFFF;
                        font-size: 1.2*$rem;
                        font-weight: 500;
                        line-height: 2*$rem;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .preview-emty {
                    margin: 0 2.5*$rem 7.9*$rem 0;
                    width: 6.1*$rem;
                    height: 6.4*$rem;
                    background: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid #34D3EF;
                    // box-shadow: inset 0px 0px 8.74px 0px rgba(107, 33, 66, 0.42);

                    img {
                        width: 100%;
                        object-fit: contain;
                    }
                }

                .preview-emty:last-child {
                    margin-right: 0;
                }

                .preview-item:last-child {
                    margin-right: 0;
                }
            }

            .synthesis_opt {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap: 2.1*$rem;
                margin-bottom: 2.15*$rem;

                .synthesis-btn {
                    width: 14.1*$rem;
                    padding: 0.9*$rem 0;
                    height: 3.6*$rem;
                    border-radius: 1.8*$rem;
                    background: linear-gradient(112deg, #24ECFA -4%, #0047EE 102%);
                    font-size: 1.5*$rem;
                    font-weight: bold;
                    color: #FFFFFF;

                    &:active {
                        opacity: 0.8;
                    }

                    &.disable {
                        background: #EEEEEE;
                        color: #8A8A8A;

                        &:active {
                            opacity: 1.0;
                        }
                    }
                }
            }
        }

        .collection-list {
            display: flex;
            flex-direction: column;
            flex: 1;
            border-radius: 1.5*$rem 1.5*$rem 0 0;
            background: #0C4390;
            padding: 1*$rem 2*$rem;
            overflow: hidden;

            .empty {
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                .tips {
                    font-family: PingFang SC;
                    font-size: 1.25*$rem;
                    font-weight: 500;
                    color: #5A8ED6;
                    text-align: center;
                    margin-bottom: 1.5*$rem;
                }

                .buy {
                    width: 8*$rem;
                    padding:0.5*$rem 1.75*$rem;
                    border-radius:9.7*$rem;
                    font-family: 思源黑体;
                    font-size: 1.5*$rem;
                    font-weight: bold;
                    color: #844000;
                    background: linear-gradient(180deg, #FFECAE 0%, #FAA424 100%);
                }
            }

            .tabs {
                width: 100%;
                display: flex;
                flex-direction: row;
                text-align: center;

                &>.tab:first-child::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1px;
                    height: 1.75*$rem;
                    background-color: #5A8ED6;

                }

                &>.tab {
                    position: relative;
                    flex: 1;
                    height: 4.45*$rem;
                    color: #5A8ED6;
                    font-size: 1.4*$rem;
                    font-weight: 500;

                    &.tab_active {
                        color: #FBB03A;
                    }

                }
            }

            .tabs2 {
                width: 100%;
                display: flex;
                flex-direction: row;
                text-align: center;
                column-gap: 4.4*$rem;
                padding-left: 0.4*$rem;

                &>.tab {
                    position: relative;
                    height: 2.3*$rem;
                    color: #5A8ED6;
                    font-size: 1.2*$rem;
                    font-weight: 500;

                    &.tab_active {
                        color: #FBB03A;
                    }

                }
            }

            .list-tip {
                font-family: PingFang SC;
                font-size: 1.4*$rem;
                font-weight: 500;
                color: #FFFFFF;
                padding: 0.85*$rem 0;
            }

            .list_out {
                position: relative;
                width: 100%;
                flex: 1;
                overflow-y: scroll;
                box-sizing: border-box;


            }

            .list-view-body {
                display: flex;
                flex-wrap: wrap;
                gap: 1.35*$rem
            }

            .ant-list-view {
                padding: 0.4*$rem;
            }

            .collection-item {
                display: inline-block;
                // margin-right: 1.45*$rem;
                // margin-bottom: 1.45*$rem;
                width: 10*$rem;
                height: 10.4*$rem;
                position: relative;

                .collection-item-content {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    &.active {
                        position: relative;
                    }

                    &.active::before {
                        content: '';
                        position: absolute;
                        top: -0.4*$rem;
                        left: -0.4*$rem;
                        bottom: -0.4*$rem;
                        right: -0.4*$rem;
                        border: 0.4*$rem solid #FA6124;
                        box-sizing: content-box;

                    }

                    &.disabled {
                        &::after {
                            content: '';
                            display: block;
                            background: rgba(0, 0, 0, .4);
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            // border-radius: 0.5*$rem;
                            // margin: 0.2*$rem;
                            z-index: 3;
                        }
                    }

                    .item-img {
                        position: relative;
                        background: transparent;
                        overflow: hidden;
                        width: 10*$rem;
                        height: 10.4*$rem;
                        box-sizing: border-box;
                        overflow: visible;

                        &>.nftNo {
                            z-index: 2;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            font-family: PingFang SC;
                            font-size: 1.2*$rem;
                            text-align: center;
                            font-weight: 500;
                            /* 纯白 */
                            color: #FFFFFF;
                            background-color: rgba(0, 0, 0, 0.5);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }




                        &.disabled {
                            &::after {
                                content: '';
                                display: block;
                                background: rgba(0, 0, 0, .4);
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                // border-radius: 0.5*$rem;
                                // margin: 0.2*$rem;
                            }
                        }

                        & img {
                            z-index: 1;
                            background: #FFFFFF;
                            // border-radius: 0.5*$rem;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }

                    .select-icon {
                        position: absolute;
                        width: 1.25*$rem;
                        height: 1.25*$rem;
                        top: 0.5*$rem;
                        left: 0.75*$rem;
                    }

                    span {
                        width: 7.7*$rem;
                        line-height: 2*$rem;
                        color: #FFFFFF;
                        font-size: 1.2*$rem;
                        font-weight: 500;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .adm-modal {

        .adm-center-popup-wrap,
        .adm-modal-wrap {
            width: auto;
            max-width: initial;

            >div {
                .adm-modal-body {
                    padding: 0;

                    .adm-modal-content {
                        padding: 0;

                        .alert-body {
                            .alert-content {
                                margin: 0;
                                line-height: 0;
                            }
                        }
                    }

                    .adm-modal-footer-empty {
                        height: 0;
                    }
                }
            }
        }
    }


}