$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.lanhu_page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 100vw;
  height: 253.74vw;
  overflow: hidden;
  .group_1 {
    position: relative;
    width: 100vw;
    height: 216.67vw;
    background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG0c11b708b63a66c41502e198a1342944.png)
      100% no-repeat;
    background-size: 100% 100%;
    .section_1 {
      height: 25.6vw;
      background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNGd1785a2722060a91011af8c4fde2469a.png)
        100% no-repeat;
      background-size: 100% 100%;
      width: 75.87vw;
      position: relative;
      margin: 48.66vw 0 0 12.13vw;
      .text_1 {
        width: 55.6vw;
        height: 5.07vw;
        overflow-wrap: break-word;
        color: rgba(61, 61, 61, 1);
        font-size: 6.66vw;
        font-family: PingFangSC-Semibold-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 5.07vw;
        margin: 10.26vw 0 0 10.53vw;
      }
      .text-wrapper_1 {
        height: 37.2vw;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG1692ed650ded10e6f3299e7534cbfdb8.png)
          100% no-repeat;
        background-size: 100% 100%;
        width: 33.87vw;
        position: absolute;
        left: 47.6vw;
        top: 15.2vw;
        .paragraph_1 {
          width: 18.54vw;
          height: 2.67vw;
          overflow-wrap: break-word;
          color: rgba(255, 253, 113, 1);
          font-size: 5.33vw;
          font-family: Alimama ShuHeiTi-Bold;
          font-weight: 700;
          text-align: center;
          line-height: 1.34vw;
          margin: 17.2vw 0 0 8.26vw;
        }
      }
    }
    .section_2 {
      height: 25.2vw;
      background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG7514af5aba5a215c822561f44465906e.png)
        100% no-repeat;
      background-size: 100% 100%;
      width: 27.34vw;
      margin: 33.33vw 0 83.86vw 69.6vw;
      .text-wrapper_2 {
        width: 18.67vw;
        height: 10.14vw;
        overflow-wrap: break-word;
        font-size: 0;
        font-family: Alimama ShuHeiTi-Bold;
        font-weight: 700;
        text-align: center;
        line-height: 5.07vw;
        margin: 8vw 0 0 4.4vw;
        .text_2 {
          width: 18.67vw;
          height: 10.14vw;
          overflow-wrap: break-word;
          color: rgba(61, 61, 61, 1);
          font-size: 4.66vw;
          font-family: Alimama ShuHeiTi-Bold;
          font-weight: 700;
          text-align: center;
          line-height: 5.07vw;
        }
        .paragraph_2 {
          width: 18.67vw;
          height: 10.14vw;
          overflow-wrap: break-word;
          color: rgba(250, 18, 0, 1);
          font-size: 4.66vw;
          font-family: Alimama ShuHeiTi-Bold;
          font-weight: 700;
          text-align: center;
          line-height: 5.07vw;
        }
      }
    }
    .section_3 {
      position: absolute;
      left: 0;
      top: 147.34vw;
      width: 100vw;
      height: 163.2vw;
      background: #DCFFF2;
    }
    .section_4 {
      position: absolute;
      left: 0;
      top: 145.74vw;
      width: 100vw;
      height: 12vw;
    }
  }
  .group_2 {
    height: 160vw;
    width: 100vw;
    position: absolute;
    left: 0;
    top: -0.53vw;
    .group_3 {
      width: 90.8vw;
      height: 4.94vw;
      margin: 4.53vw 0 0 5.86vw;
      .text_3 {
        width: 11.34vw;
        height: 4.94vw;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 4vw;
        font-family: SFCompactDisplay-Semibold-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 4vw;
      }
      .label_1 {
        width: 4.54vw;
        height: 2.8vw;
        margin: 1.33vw 0 0 61.2vw;
      }
      .text_4 {
        width: 5.07vw;
        height: 4.27vw;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 3.46vw;
        font-family: SFCompactDisplay-Semibold-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 3.47vw;
        margin: 0.53vw 0 0 1.33vw;
      }
      .label_2 {
        width: 6.54vw;
        height: 2.8vw;
        margin: 1.33vw 0 0 0.8vw;
      }
    }
    .group_4 {
      width: 100vw;
      height: 10.67vw;
      margin-top: 2.67vw;
      .nav-bar_1 {
        height: 10.67vw;
        padding: 0;
        width: 100vw;
        background: none;
        .label_3 {
          width: 3.2vw;
          height: 3.2vw;
          margin-top: 5.47vw;
        }
        .adm-nav-bar-back {
          margin: 0;
        }
        .adm-nav-bar-left {
          align-items: start;
          height: 100%;
          padding: 0 0 0 5.2vw;
        }
        .adm-nav-bar-title {
          padding: 0;
          width: 100%;
        }
        .adm-nav-bar-right {
          flex: 0 0 auto;
          width: 3.2vw;
          height: 100%;
          padding: 0 5.2vw 0 0;
        }
      }
    }
    .group_5 {
      width: 5.47vw;
      height: 10.8vw;
      margin: 0.4vw 0 0 94.53vw;
      .button_1 {
        background-color: rgba(78, 221, 165, 0.8);
        border-radius: 10px 0px 10px 0px;
        height: 10.8vw;
        width: 5.47vw;
        .paragraph_3 {
          width: 3.2vw;
          height: 6.67vw;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 3.2vw;
          font-family: Source Han Sans-Bold;
          font-weight: 700;
          text-align: center;
          line-height: 3.34vw;
          margin: 2vw 0 0 1.0vw;
        }
      }
    }
    .group_6 {
      width: 5.47vw;
      height: 10.8vw;
      margin: 3.2vw 0 0 94.53vw;
      .button_2 {
        background-color: rgba(78, 221, 165, 0.8);
        border-radius: 10px 0px 10px 0px;
        height: 10.8vw;
        width: 5.47vw;
        .paragraph_4 {
          width: 3.2vw;
          height: 6.67vw;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 3.2vw;
          font-family: Source Han Sans-Bold;
          font-weight: 700;
          text-align: center;
          line-height: 3.34vw;
          margin: 1.73vw 0 0 1.0vw;
        }
      }
    }
    .group_7 {
      width: 1153.07vw;
      height: 25.2vw;
      margin: 3.33vw 0 0 94.53vw;
      .text-wrapper_3 {
        background-color: rgba(78, 221, 165, 0.8);
        border-radius: 10px 0px 10px 0px;
        height: 25.2vw;
        width: 5.47vw;
        .paragraph_5 {
          width: 3.2vw;
          height: 20vw;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 3.2vw;
          font-family: Source Han Sans-Bold;
          font-weight: 700;
          text-align: center;
          line-height: 3.34vw;
          margin: 2.53vw 0 0 1.2vw;
        }
      }
      .text_5 {
        width: 17.07vw;
        height: 5.07vw;
        overflow-wrap: break-word;
        color: rgba(158, 158, 158, 1);
        font-size: 4.26vw;
        font-family: PingFangSC-Semibold-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 5.07vw;
        margin-top: 0.54vw;
      }
    }
    .group_8 {
      width: 73.34vw;
      height: 16.27vw;
      margin: 68.93vw 0 0 13.33vw;
      .button_3 {
        height: 16.27vw;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNGe44fa7972ebb2939f2512a326df971ae.png)
          100% no-repeat;
        background-size: 100% 100%;
        width: 73.34vw;
        .text_6 {
          width: 26.67vw;
          height: 5.07vw;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 6.66vw;
          font-family: Alimama ShuHeiTi-Bold;
          font-weight: 700;
          text-align: center;
          white-space: nowrap;
          line-height: 5.07vw;
          margin: 5.6vw 0 0 23.33vw;
        }
      }
    }
    .group_9 {
      width: 93.74vw;
      height: 28.14vw;
      margin: 2.4vw 0 0 3.06vw;
      .box_1 {
        width: 93.74vw;
        height: 28.14vw;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNGffcc332e5e7a8fdf4fe6c1d674f1a370.png)
          100% no-repeat;
        background-size: 100% 100%;
        .text-group_1 {
          width: 36.54vw;
          height: 13.47vw;
          margin: 7.46vw 0 0 6.26vw;
          .text_7 {
            width: 36.54vw;
            height: 5.87vw;
            overflow-wrap: break-word;
            color: rgba(8, 135, 86, 1);
            font-size: 4.8vw;
            font-family: Alimama ShuHeiTi-Bold;
            font-weight: 700;
            text-align: left;
            white-space: nowrap;
            line-height: 5.87vw;
          }
          .text-wrapper_4 {
            width: 24.4vw;
            height: 5.6vw;
            overflow-wrap: break-word;
            font-size: 0;
            font-family: PingFang SC-粗体;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 5.6vw;
            margin-top: 2vw;
            .text_8 {
              width: 24.4vw;
              height: 5.6vw;
              overflow-wrap: break-word;
              color: rgba(8, 135, 86, 1);
              font-size: 4vw;
              font-family: PingFang SC-粗体;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 5.6vw;
            }
            .text_9 {
              width: 24.4vw;
              height: 5.6vw;
              overflow-wrap: break-word;
              color: rgba(255, 123, 0, 1);
              font-size: 4vw;
              font-family: PingFang SC-粗体;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 5.6vw;
            }
          }
        }
        .button_4 {
          background: linear-gradient(180deg, #F1B100 0%, #FF8B0F 100%);
          border-radius: 133px;
          height: 10.27vw;
          width: 29.87vw;
          margin: 8.8vw 5.73vw 0 15.33vw;
          .text_10 {
            width: 9.6vw;
            height: 6.94vw;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 4.8vw;
            font-family: Source Han Sans-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 6.94vw;
            margin: 1.2vw 0 0 10.13vw;
          }
        }
        .wrapper_4 {
          background: #D8D8D8;
          border-radius: 133px;
          height: 10.27vw;
          width: 29.87vw;
          margin: 8.8vw 5.73vw 0 15.33vw;
          .text_10 {
            width: 9.6vw;
            height: 6.94vw;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 4.8vw;
            font-family: Source Han Sans-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 6.94vw;
            margin: 1.2vw 0 0 10.13vw;
          }
        }
      }
    }
    .group_10 {
      width: 100%;
      height: 24.14vw;
      margin: 5.2vw 0 0 0;
      justify-content: center;
      .text-group_2 {
        height: 24.14vw;
        .text-wrapper_5 {
          height: 10.14vw;
          overflow-wrap: break-word;
          font-size: 0;
          font-family: Alimama ShuHeiTi-Bold;
          font-weight: 700;
          text-align: center;
          line-height: 5.07vw;
          margin-left: 1.74vw;
          .text_11 {
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(8, 135, 86, 1);
            font-size: 4vw;
            font-family: Alimama ShuHeiTi-Bold;
            font-weight: 700;
            text-align: center;
            line-height: 5.07vw;
          }
          .text_12 {
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(255, 123, 0, 1);
            font-size: 4vw;
            font-family: Alimama ShuHeiTi-Bold;
            font-weight: 700;
            text-align: center;
            line-height: 5.07vw;
          }
          .text_13 {
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(8, 135, 86, 1);
            font-size: 4vw;
            font-family: Alimama ShuHeiTi-Bold;
            font-weight: 700;
            text-align: center;
            line-height: 5.07vw;
          }
          .paragraph_6 {
            width: 45.74vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(255, 123, 0, 1);
            font-size: 3.46vw;
            font-family: PingFangSC-Semibold-Regular;
            font-weight: normal;
            text-align: center;
            line-height: 5.07vw;
          }
          .text_14 {
            width: 45.74vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(8, 135, 86, 1);
            font-size: 3.46vw;
            font-family: PingFangSC-Semibold-Regular;
            font-weight: normal;
            text-align: center;
            line-height: 5.07vw;
          }
          .text_15 {
            width: 45.74vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(255, 123, 0, 1);
            font-size: 3.46vw;
            font-family: PingFangSC-Semibold-Regular;
            font-weight: normal;
            text-align: center;
            line-height: 5.07vw;
          }
        }
        .text-wrapper_6 {
          width: 49.2vw;
          height: 10.14vw;
          overflow-wrap: break-word;
          font-size: 0;
          font-family: Alimama ShuHeiTi-Bold;
          font-weight: 700;
          text-align: center;
          line-height: 5.07vw;
          margin-top: 3.87vw;
          .text_16 {
            width: 49.2vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(8, 135, 86, 1);
            font-size: 4vw;
            font-family: Alimama ShuHeiTi-Bold;
            font-weight: 700;
            text-align: center;
            line-height: 5.07vw;
          }
          .text_17 {
            width: 49.2vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(255, 123, 0, 1);
            font-size: 4vw;
            font-family: Alimama ShuHeiTi-Bold;
            font-weight: 700;
            text-align: center;
            line-height: 5.07vw;
          }
          .text_18 {
            width: 49.2vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(8, 135, 86, 1);
            font-size: 4vw;
            font-family: Alimama ShuHeiTi-Bold;
            font-weight: 700;
            text-align: center;
            line-height: 5.07vw;
          }
          .paragraph_7 {
            width: 49.2vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(255, 123, 0, 1);
            font-size: 3.46vw;
            font-family: PingFangSC-Semibold-Regular;
            font-weight: normal;
            text-align: center;
            line-height: 5.07vw;
          }
          .text_19 {
            width: 49.2vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(8, 135, 86, 1);
            font-size: 3.46vw;
            font-family: PingFangSC-Semibold-Regular;
            font-weight: normal;
            text-align: center;
            line-height: 5.07vw;
          }
          .text_20 {
            width: 49.2vw;
            height: 10.14vw;
            overflow-wrap: break-word;
            color: rgba(255, 123, 0, 1);
            font-size: 3.46vw;
            font-family: PingFangSC-Semibold-Regular;
            font-weight: normal;
            text-align: center;
            line-height: 5.07vw;
          }
        }
      }
    }
    .text-wrapper_7 {
      width: 34.54vw;
      height: 5.07vw;
      margin: 10.93vw 0 0 32.66vw;
      .text_21 {
        width: 34.54vw;
        height: 5.07vw;
        overflow-wrap: break-word;
        color: rgba(8, 135, 86, 1);
        font-size: 2.93vw;
        font-family: PingFang SC-粗体;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        line-height: 5.07vw;
      }
    }
    .image-wrapper_1 {
      width: 52.27vw;
      height: 6.27vw;
      margin: 2.13vw 0 0 23.86vw;
      .image_1 {
        width: 38.67vw;
        height: 6vw;
      }
      .image_2 {
        width: 11.2vw;
        height: 5.07vw;
        margin-top: 1.2vw;
      }
    }
    .image-wrapper_2 {
      width: 52.27vw;
      height: 2.67vw;
      margin: 0.53vw 0 0 23.86vw;
      .thumbnail_1 {
        width: 2.67vw;
        height: 2.54vw;
        margin-top: 0.14vw;
      }
      .thumbnail_2 {
        width: 2.67vw;
        height: 2.54vw;
        margin-left: 2.8vw;
      }
      .thumbnail_3 {
        width: 1.6vw;
        height: 2.14vw;
        margin: 0.4vw 0 0 2.66vw;
      }
      .image_3 {
        width: 5.47vw;
        height: 2.54vw;
        margin: 0.13vw 0 0 2.66vw;
      }
      .thumbnail_4 {
        width: 2.54vw;
        height: 2.54vw;
        margin: 0.13vw 0 0 2.8vw;
      }
      .thumbnail_5 {
        width: 2.54vw;
        height: 2.54vw;
        margin: 0.13vw 0 0 2.66vw;
      }
      .thumbnail_6 {
        width: 2.54vw;
        height: 2.54vw;
        margin: 0.13vw 0 0 2.53vw;
      }
      .thumbnail_7 {
        width: 2.67vw;
        height: 2.4vw;
        margin: 0.13vw 0 0 2.8vw;
      }
      .thumbnail_8 {
        width: 2.67vw;
        height: 2.54vw;
        margin-left: 2.54vw;
      }
      .thumbnail_9 {
        width: 2.67vw;
        height: 2.54vw;
        margin-left: 2.8vw;
      }
    }
    .group_11 {
      width: 35.74vw;
      height: 1.34vw;
      margin: 1.73vw 0 92.26vw 32vw;
      .home-indicator_1 {
        background-color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        width: 35.74vw;
        height: 1.34vw;
      }
    }
  }
}
