@import '@/assets/scss/variable.scss';
.buyHistory {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #F1F0F5;
    .historyList {
        display: flex;
        flex-direction: column;
        flex: 1;
        .record_item {
            background: #FFF;
            display: flex;
            flex-direction: column;
            border-radius: 1*$rem;
            padding: 1.2*$rem 1.3*$rem;
            margin: 1.3*$rem;
            .title_info {
                display: flex;
                flex-direction: row;
                color: #333333;
                font-weight: 600;
                border-bottom: 0.5px solid #DFDFDF;
                padding-bottom: 1*$rem;
                .img {
                    width: 5*$rem;
                    height: 5*$rem;
                    border-radius: 0.5*$rem;
                    background: #f8f8f8;
                }
                > div {
                    display: flex;
                    flex-direction: column;
                    margin: 0 0.8*$rem;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 0.5*$rem;
                    p {
                        color: #333333;
                        font-weight: 600;
                        font-size: 1.6*$rem;
                    }
                    span {
                        background: #D8D8D8;
                        height: 1.6*$rem;
                        line-height: 1.6*$rem;
                        border-radius: 0.8*$rem;
                        color: #9E9E9E;
                        font-weight: 600;
                        font-size: 1.2*$rem;
                        padding: 0 1*$rem;
                    }
                }
            }
            .bottom {
                margin: 1.2*$rem 0;
                display: flex;
                flex-direction: column;
                >div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-size: 1.2*$rem;
                    &:not(:last-child) {
                        margin-bottom: 1*$rem;
                    }
                    p {
                        color: #999999;
                    }
                    >div {
                        color: #333333;
                        font-weight: 600;
                        display: flex;
                        flex-direction: column;
                        text-align: end;
                        >div {
                            margin-top: 0.5*$rem;
                            display: flex;
                            justify-content: flex-end;
                            >span {
                                &:nth-child(1) {
                                    color: #333333;
                                    font-weight: 600;
                                    font-size: 1.2*$rem;
                                    margin-right: 1*$rem;
                                }
                                &:nth-child(2) {
                                    background: #D8D8D8;
                                    border-radius: 0.8*$rem;
                                    color: #9E9E9E;
                                    font-size: 1.1*$rem;
                                    padding: 0.1*$rem 0.6*$rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}