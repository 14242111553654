$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.collection_page {
  padding-top: 32.5*$rem;
  background: linear-gradient(180deg, #FFFFFF 0%, #F1F0F5 100%);
  box-sizing: border-box;

  &.no_address {
    padding-top: 30.5*$rem;
  }

  .top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: url('~@/assets/img/collection/collection_bg.png') no-repeat;
    background-size: 100% 100%;
    height: 31.5 *$rem;
    z-index: 9;
    box-sizing: border-box;
    // background: red;
    overflow: hidden;

    .cards-jump {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      padding: 0.2 *$rem 1.5 *$rem;
      //background: red;
      //margin: 0 0.1*$rem;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > img {
          width: 50%;
          height: 50%;
        }

        > p {
          font-size: 1.2 *$rem;
          color: #000000;
          margin-top: 0.5 *$rem;
        }
      }
    }

    .cards-jump_banner {
      width: 96vw;
      height: 6 *$rem;
      margin: 2 *$rem 2vw;
      border-radius: 10px;
      border: 0.1px solid transparent;
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1*$rem 0 2*$rem;

      .user-info {
        display: flex;

        .avatar {

          img {
            border-radius: 50%;
            //width: 4.8*$rem;
            //height: 4.8*$rem;
            margin: 0 3vw 0;
            width: 12vw;
            height: 12vw;
          }
        }

        .right {
          color: #333333;
          font-size: 1.8*$rem;
          display: flex;
          flex-direction: column;
          width: 82vw;

          .name {
            width: 15*$rem;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .count {
            color: #999999;
            font-size: 1.2*$rem;
            flex: 1;
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            margin-top: 0.85*$rem;

            &.beautiful_num {
              color: #EBAD40;
              font-size: 1.2*$rem;
            }

            > span {
              display: inline-block;
              width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            > img {
              margin-left: 5px;
              width: 14px;
              height: 15.9px;
            }
          }
        }
      }
    }

    .rightHeader {
      flex-direction: row;
      display: flex;

      .search_img {
        width: 20px;
        height: 20px;
        margin-right: 1.9*$rem;
      }

      .setting_img {
        width: 20px;
        height: 20px;
        //margin-right: 1.7*$rem;
        //flex: 1;
      }
    }
  }

  .collection-header {
    position: sticky;
    left: 0;
    padding-top: 2.5*$rem;
    //background: linear-gradient(180deg, #FFFFFF 0%, #F1F0F5 83%);
    background-color: #fff;
    border-radius: 2*$rem 2*$rem 0 0;
    z-index: 9;
    width: 100%;
    top: 17 *$rem;
    padding-bottom: 1.3 *$rem;
    display: flex;
    flex-direction: column;

    &.no_address {
      top: 14.5*$rem;
    }

    .titles {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 22px;
      padding-bottom: 1 *$rem;

      .my_col_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          color: #9E9E9E;

          &.isChoiceTab {
            color: #BE8A08;
          }
        }
      }

      .my_col_title {
        font-family: PingFang SC;
        font-size: 1.6 *$rem;
        font-weight: 600;
      }

      .my_col_count {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 1 *$rem;
        margin-top: 0.5 *$rem;
      }
    }


    .adm-tabs-header {
      border-bottom: 0;
    }

    .adm-tabs-tab-active {
      color: #B89969;
    }

    .adm-tabs {
      --active-line-height: 0;
    }

    .adm-tabs-tab-wrapper {
      margin-left: 1*$rem;
      border-radius: 2*$rem;
      background: #F1F0F5;
      color: #999999;
    }

    .adm-tabs-tab {
      font-size: 1.2*$rem;
      font-weight: 500;
    }
  }

  .tabs {
    //padding-bottom: 0.5*$rem;
  }

  .child-tabs {
    padding-top: 1*$rem;

    .adm-tabs-tab-wrapper {
      background: #FFFFFF;
    }

    .adm-tabs-tab {
      font-size: 1.2*$rem;
      font-weight: 500;
    }

    .adm-tabs-tab-active {
      color: #333333;
    }
  }

  .list-view-body {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1*$rem;
  }

  .adm-tabs-tab-wrapper-stretch {
    flex: none;
  }

  .enter-meta-com {
    position: fixed;
    width: 5 *$rem;
    height: 5 *$rem;
    top: 25*$rem;
    right: 1 *$rem;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.shiming_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding-bottom: 5 *$rem;

  > img {
    width: 8.8 *$rem;
    height: 8.8 *$rem;
    margin-top: 4 *$rem;
  }

  > p {
    font-family: PingFangSC-Regular;
    font-size: 1.4 *$rem;
    line-height: 2 *$rem;
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    font-weight: bold;
    white-space: pre-line;
  }

  .shiming_btn {
    width: 90%;
    height: 4 *$rem;
    border-radius: 39px;
    margin-top: 5 *$rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #624C16;
    justify-content: center;
    background: linear-gradient(94deg, #D4BA77 4%, #FFEAB4 99%);
  }
}
