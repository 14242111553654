@import "@/assets/scss/variable.scss";
@mixin common-buy-button-style {
  height: setRem(332);
  width: setRem(332);
  background-position: center;
  background-size: 100%;
  display: flex;
  justify-content: center;
}
.woodGrainBadgeHome {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(255, 190, 138, 0) 0%, #FF9861 21%);
  .back {
    position: absolute;
    top: 2.5*$rem;
    z-index: 9;
    @media screen and (min-height: 780px) {
      top: 5.5*$rem;
    }
    left: 1.5*$rem;
    width: 1*$rem;
    height: 1.8*$rem;
    background: url("~@/assets/img/icon/icon_back_black.png") no-repeat center center / 100% 100%;
  }
  .top_bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100vw;
    height: 66.5*$rem;
    background-image: url("~@/assets/img/woodGrainBadge/home.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    .right_btns {
      position: absolute;
      top: 8.5*$rem;
      right: 0;
      display: flex;
      flex-direction: column;
      z-index: 5;
      .rightBtn {
        width: 2.1*$rem;
        padding: 0.6*$rem 0;
        border-radius: 0.5*$rem 0px 0px 0.5*$rem;
        background: #FF9961;
        color: #FFFFFF;
        font-size: 1.2*$rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.4*$rem;
        margin-bottom: 1.2*$rem;
        &:active {
          opacity: 0.8;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      background-image: url("~@/assets/img/purchaseRebate/sunshine.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 43.5*$rem;
      width: 100vw;
      top: 11.9*$rem;
      @media screen and (min-height: 780px) {
        top: 11.9*$rem;
      }
      mix-blend-mode: plus-lighter;
    }
  }
  .headIcon {
    position: absolute;
    background-image: url("~@/assets/img/woodGrainBadge/head.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 37.7*$rem;
    width: 100vw;
    top: 16.5*$rem;
  }
  .headText {
    position: absolute;
    background-image: url("~@/assets/img/woodGrainBadge/text.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 7.9*$rem;
    width: 29*$rem;
    top: 9.7*$rem;
  }
  .amountCard {
    position: absolute;
    background-image: url("~@/assets/img/woodGrainBadge/amountCard.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 4.9*$rem;
    width: 8.3*$rem;
    top: 24.5*$rem;
    left: 24.7*$rem;
  }
  .shadowImg {
    width: 88%;
    height: 2.51%;
    position: absolute;
    background: url("~@/assets/img/woodGrainBadge/shadow.png") no-repeat center center / 100%;
    transform: rotate(-8.85deg);
    top: 44*$rem;
    left: -0.45*$rem;
    filter: blur(10px);
    opacity: 0.6;
  }
  .contentBox {
    margin-top: -23.2*$rem;
    background: linear-gradient(180deg, rgba(255, 190, 138, 0) 0%, #FF9861 21%);
    display: flex;
    align-items: center;
    flex-direction: column;
    .counts {
      background: #FFFFFF;
      box-shadow: inset 0 0.2*$rem 0.5*$rem 0 rgba(0, 0, 0, 0.3);
      padding: 0.6*$rem 3.1*$rem;
      margin-top: 9*$rem;
      border-radius: 16*$rem;
      .label {
        font-family: PingFang SC;
        font-size: setRem(26);
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0;
        .value {
          color: #EEA72D
        }
      }
    }
    .buttonBox {
      display: flex;
      gap: 0.6*$rem;
      margin-top: -2.3*$rem;
      .buyButton {
        @include common-buy-button-style;
        background-image: url("~@/assets/img/woodGrainBadge/buyButton.png");
      }
      .sellButton {
        @include common-buy-button-style;
        background-image: url("~@/assets/img/woodGrainBadge/sellButton.png");
      }
    }
    .buy {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: setRem(90);
      width: setRem(314);
      height: setRem(94);
      font-family: Alimama ShuHeiTi;
      font-size: setRem(36);
      font-weight: bold;
      line-height: 1.5*$rem;
      text-align: center;
      letter-spacing: 0;
      color: #FFFFFF;
      z-index: 1000;
    }
  }
  .sellListButton {
    margin-top: -2.8*$rem;
    margin-bottom: 3*$rem;
    font-family: 思源黑体;
    font-size: setRem(24);
    font-weight: bold;
    line-height: setRem(29);
    text-align: center;
    letter-spacing: 0;
    color: #FFFFFF;
  }
  .descContainer {
    border-radius: setRem(56);
    margin-top: 1.5*$rem;
    width: setRem(670);
    background: linear-gradient(180deg, #FFF7EF 0%, #FFEAD9 100%);
    .descContent {
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/img/woodGrainBadge/descBg.png");
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        font-family: Alimama ShuHeiTi;
        font-size: setRem(36);
        font-weight: bold;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0;
        color: #AB5300;
        margin-top: setRem(58);
      }
      .goldCard {
        margin: 1.8*$rem 2.1*$rem 0 2.1*$rem;
        padding-top: setRem(52);
        padding-bottom: setRem(30);
        background: #FFFFFF;
        box-shadow: inset 0 0.2*$rem 0.5*$rem 0 rgba(0, 0, 0, 0.3);
        border-radius: 1.8*$rem;
        .text1 {
          text-align: center;
          font-family: Alimama ShuHeiTi;
          font-size: setRem(30);
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0;
          color: #000000;
          .equalIcon {
            color: #EE972D;
          }
        }
        .goldIconBox {
          margin-top: -1.5*$rem;
          display: flex;
          align-items: center;
          width: 100%;
          .goldImg {
            width: setRem(253);
            height: setRem(247);
            background: url("~@/assets/img/woodGrainBadge/goldIcon.png") center center / 100%;
          }
          .text2 {
            margin-left: -2*$rem;
            font-family: PingFang SC;
            font-size: setRem(24);
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0;
            color: #1A1A1A;
            .numberText {
              color: #EEA72D;
            }
          }
        }
        .compoundBox {
          margin-top: -4*$rem;
          display: flex;
          justify-content: center;
          gap: 1.2*$rem;
          .compoundBg {
            height: setRem(214);
            width: setRem(214);
            background: url("~@/assets/img/woodGrainBadge/compoundBtnBg.png") center center / 100%;
            display: flex;
            justify-content: center;
          }
          .compoundBtn {
            font-family: PingFang SC;
            font-size: setRem(30);
            font-weight: 600;
            line-height: setRem(29);
            text-align: center;
            letter-spacing: 0;
            color: #FFFFFF;
            margin-top: setRem(58);
            width: setRem(196);
            height: setRem(58);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .seeCompoundListBtn {
          margin-top: -2.3*$rem;
          text-align: center;
          font-family: 思源黑体;
          font-size: setRem(24);
          font-weight: bold;
          line-height: setRem(29);
          letter-spacing: 0;
          color: #AB5300;
        }
      }
      .quanyiText {
        margin-top: 2.2*$rem;
        line-height: setRem(46);
        text-align: center;
        letter-spacing: 0;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1A1A1A;
        .text3 {
          font-size: setRem(32);
        }
        .text4 {
          font-size: setRem(24);
        }
        .jifenText {
          color: #EA0000;
        }
      }
      .totalJifen {
        font-family: PingFang SC;
        font-size: setRem(32);
        font-weight: 600;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0;
        color: #EA0000;
        margin-top: 1*$rem;
        margin-bottom: 3.6*$rem;
      }
      .seeBackJifenListBtn {
        font-family: 思源黑体;
        font-size: setRem(24);
        font-weight: bold;
        line-height: setRem(29);
        text-align: center;
        letter-spacing: 0;
        color: #AB5300;
        margin-bottom: setRem(60);
      }
    }
  }
  .bottomLogo {
    margin-top: 5*$rem;
    margin-bottom: 3.5*$rem;
    img {
      height: 3.5*$rem;
      margin: 0 auto;
    }
  }
}