$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.recycling__modal-body {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0.75*$rem;
    padding: 0.7*$rem;
    .content {
        position: relative;
        width: 29.8*$rem;
        // height: 30*$rem;
        background: linear-gradient(136deg, #FFE6D9 -2%, #FF8352 37%, #FFDFA3 100%);
        border-radius: 0.75*$rem;
        padding: 2.5*$rem;
        box-sizing: border-box;
        >p {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 1.75*$rem;
            margin: 0 auto 1.8*$rem auto;
            line-height: normal;
            text-align: center;
        }
        >span {
            color: #FFFFFF;
            font-size: 1.2*$rem;
            line-height: 1.7*$rem;
        }
        >img {
            position: absolute;
            top: 1.2*$rem;
            right: 1.0*$rem;
            width: 1.3*$rem;
            height: 1.3*$rem;
        }
        .img-group {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 1.7*$rem 0;
            .preview-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0 1.25*$rem;
                .preview-img {
                    background: #FFFFFF;
                    border-radius: 0.25*$rem;
                    width: 6.4*$rem;
                    height: 6.4*$rem;
                }
                span {
                    width: 6.4*$rem;
                    color: #FFFFFF; 
                    font-size: 1.2*$rem;
                    font-weight: 500;
                    line-height: 2*$rem;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        .btn-group {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1.7*$rem;
            .cancel {
                background: #FFF6D3;
                width: 8.8*$rem;
                line-height: 4*$rem;
                border-radius: 2*$rem;
                text-align: center;
                color: #643904;
                font-weight: 500;
                font-size: 1.5*$rem;
                &:active {
                    opacity: 0.8;
                }
            }
            .confirm {
                background: #FF8655;
                width: 8.8*$rem;
                line-height: 4*$rem;
                border-radius: 2*$rem;
                text-align: center;
                color: #FFFFFF;
                font-weight: 500;
                font-size: 1.5*$rem;
                &:active {
                    opacity: 0.8;
                }
            }

        }

    }
    
}