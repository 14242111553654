$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.synthesis-success-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .mask-content {
    width: 33*$rem;
    height: 37.5*$rem;
    background: url("~@/assets/img/industrialApe/frame_l@1x.png") no-repeat center center / 100% 100%;

    >div {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
      padding: 4.7*$rem 3*$rem;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      >.title {
        text-align: center;
        font-family: AlimamaShuHeiTi;
        font-size: 1.75*$rem;
        font-weight: bold;
        text-align: center;
        color: #FFFFFF;
      }

      >img {
        position: absolute;
        right: 1*$rem;
        top: 2.2*$rem;
        width: 1.1*$rem;
        height: 1.1*$rem;
      }

      .synthesisList {
        // position: absolute;
        width: 17.3*$rem;
        height: 17.3*$rem;
        border-radius: 0.8*$rem;
        margin: 1*$rem auto;

        &.fade-in {
          opacity: 0;
          animation: play8 3s forwards;

          @keyframes play8 {
            0% {
              opacity: 0;
            }

            80% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }

        }

        .swiper-cards .swiper-slide {
          border-radius: 2*$rem;
        }

        .synthesisList_Swiper {
          @media screen and (min-height: 736px) {
            img {
              margin-top: -2*$rem;
            }
          }

          @media screen and (min-height: 780px) {
            img {
              margin-top: -3*$rem;
            }
          }

          .show_text {
            margin-top: 14 * $vw;
            font-weight: normal;
            font-size: 12px;
            color: #431179;
            display: inline-block;
            width: 100%;
            text-align: center;
            // word-break: keep-all;

            .blod_text {
              white-space: nowrap;
              font-size: 18px;
              font-family: PingFang SC-Semibold, PingFang SC;
            }
          }
        }

        .blinbox_count {
          text-align: center;
          color: #431179;
          font-size: 1.2*$rem;
          margin-top: 5*$rem;
        }

        &>.swiper {
          width: 100%;
          height: 100%;
          img {
            width: 100% !important;
            height: 100% !important;
            margin-top: 0 !important;

          }
          .swiper_item {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
  
            img {
              width: 100% !important;
              height: 100% !important;
              overflow: hidden;
              background: #D8D8D8;
              margin-top: 0 !important;
  
              @media screen and (min-height: 736px) {
                &.img_19 {
                  margin-top:0 !important;
                }
              }
  
              @media screen and (min-height: 780px) {
                &.img_19 {
                  margin-top:0 !important;
                }
              }
            }
  
            .text_style {
              margin-top: 14 * $vw;
              font-size: 1.6*$rem;
              font-family: PingFang SC;
              color: #FFFFFF;
              display: inline-block;
              width: 100%;
              text-align: center;
              font-weight: 600;
  
              &__dark {
                color: #000000;
              }
            }
  
            .show_text_19 {
              margin-top: 14 * $vw;
              font-weight: normal;
              font-size: 12px;
              color: #431179;
              display: inline-block;
              width: 100%;
              text-align: center;
  
              .blod_text {
                white-space: nowrap;
                font-size: 18px;
                font-family: PingFang SC-Semibold, PingFang SC;
              }
            }
  
            .hidden_text {
              font-size: 1.6*$rem;
              color: rgba(255, 255, 255, 0);
              display: none;
            }
          }
  
        }


      }

      .btn-group {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 3.1*$rem;

        .cancel {
          text-align: center;
          width: 9.7*$rem;
          height: 3.35*$rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url("~@/assets/img/industrialApe/no@1x.png") no-repeat center center / 100% 100%;
          color: #0C4390;
          font-size: 1.5*$rem;
          font-weight: bold;

          &:active {
            opacity: 0.8;
          }
        }

        .confirm {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 9.7*$rem;
          height: 3.35*$rem;
          background: url("~@/assets/img/industrialApe/yes@1x.png") no-repeat center center / 100% 100%;
          color: #844000;
          font-size: 1.5*$rem;
          font-weight: bold;

          &:active {
            opacity: 0.8;
          }

        }

      }

    }

  }
}