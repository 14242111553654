@import '@/assets/scss/variable.scss';
.addressBox {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    margin: 1*$rem;
    border-radius: 0.5*$rem;
    .headerImg {
        height: 0.5*$rem;
        width: 100%;
    }
    .addressContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1*$rem 1.5*$rem;
        .addressInfo {
            flex: 1;
            display: flex;
            flex-direction: column;
            .addressName {
                font-size: 1.6*$rem;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-bottom: 0.8*$rem;
            }
            .userInfo {
                font-weight: 400;
                color: #999;
                font-size: 1.4*$rem;
            }
            .remindTip {
                width: 11.2*$rem;
                height: 2*$rem;
                background: rgba(255, 243, 236, 1);
                border-radius: 1*$rem;
                margin-top: 0.3*$rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            
                .remindIcon {
                  width: 1.4*$rem;
                  height: 1.4*$rem;
                }
            
                .remindText {
                  font-size: 1.2*$rem;
                  transform: scale(0.833);
                  color: rgba(255, 102, 0, 1);
                  line-height: 1.2*$rem;
                }
              }
        }
        .address_arrow {
            width: 1.4*$rem;
            height: 1.4*$rem;
        }
    }
}