@import '@/assets/scss/variable.scss';

.box {
  margin: px2rem(50) auto 0;
  width: px2rem(670);
  padding: px2rem(30) px2rem(35);
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFFFFF;
  display: flex;
  > img {
    width: px2rem(180);
    height: px2rem(180);
    margin-right: px2rem(28);
  }

  .righter {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

    }
  }

  .countWrp {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .tip {
      color: #ff0000;
      font-size: pxrem(18);
      margin-bottom: px2rem(6);
    }
  }

  .countBox {
    display: flex;
    align-items: center;
    > span {
      margin: 0 px2rem(25);
    }
    > img {
      width: px2rem(40);
      height: px2rem(43);
    }
  }

  .title {
    color: #333333;
    font-size: px2rem(30);
  }
}


.labelWrap {
  margin-top: px2rem(8);
  .label {
    font-size: px2rem(24);
    margin-right: px2rem(16);
    > span {
      transform: scale(0.8333); // 10px
    }
    padding: px2rem(4) px2rem(12);
    border-radius: px2rem(18);
    background-color: #FF6B6B;
    color: #fff;
    &:nth-of-type(2n) {
      background-color: #ffffff;
      color: #FF6B6B;
      border: 0.5px solid #FF6B6B;
    }	
  }
}