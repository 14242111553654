$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}

.land-detail {
    &-page {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000000b3;
        z-index: 99;

        .adm-tabs-header {
            display: none;
        }

        .adm-tabs {
            height: 100%;
        }

        .adm-tabs-content {
            height: 100%;
        }
    }

    &-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding-right: to(200);
        padding-left: to(161);
        background: url("~@/assets/img/landfi/land-detail-bg.png") no-repeat;
        background-size: 98% 100%;
        background-position: center;
        box-sizing: border-box;
        &.hide {
            display: none;
        }
        &.mode2 {
            background: url("~@/assets/img/landfi/landgy/land-detail-bg.png") no-repeat;
            background-size: 98% 100%;
            background-position: center;
        }
    }

    &-view {
        display: flex;
        flex: 1;
        padding-bottom: to(142);
        box-sizing: border-box;
    }

    &-btns {
        margin-right: to(50);
        margin-top: to(20);
        .mt-24 {
            margin-top: to(24);
        }
        &.mode2{
            margin-right: 0;
            .land-btn-basebtn {
                transform: scale(1);
                color: #FFFFFF;
                padding-right: to(50);
                box-sizing: content-box;
                &.active {
                    color: #200334;
                    background: #B0A3C4;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    transform: scale(1);
                }
            }
        }
    }

    &-landupbox {
        display: flex;
        height: 100%;
        background: #f1d7ae;
        // overflow: hidden;
        &.mode2{
            background: #B0A3C4;
        }
    }

    &-landup {
        width: to(400);
        height: 100%;

        >div {
            height: 33.33%;
            display: flex;
            justify-content: center;
            align-items: center;

            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .land-up-tab {
        position: relative;

        &.active {
            background: #e1c292;
            &.mode2{
                background: #9B84BF;
            }
        }

        &__leveltext {
            color: #3d3d3d;
            font-size: to(40);
            font-weight: 600;
            text-align: center;
        }

        &__bardge {
            position: absolute;
            right: to(16);
            top: to(16);
            display: flex;
            justify-content: center;
            align-items: center;
            width: to(104);
            height: to(34);
            border-radius: to(4);
            font-size: to(22);
            font-weight: bold;
            color: white;

            &.up {
                background: linear-gradient(180deg,
                        rgba(71, 199, 110, 0.82) 0%,
                        #5ab34e 96%);
            }

            &.current {
                background: #a05d20;
                white-space: nowrap;
            }
        }
    }

    .land-up-middle {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        padding: to(30) to(45);
        box-sizing: border-box;

        .land-up-no {
            font-size: to(28);
            font-weight: 500;
            color: #9e7b36;
            &.mode2 {
                color: #FFFFFF;
            }
        }
    }

    .land-up-right {
        width: to(707);
        height: 100%;
        padding: to(60) to(70);
        text-align: center;
        box-sizing: border-box;

        &>div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &>div:nth-child(1) {
                >p {
                    font-family: YueGenYouShangYueBoHei;
                    font-size: to(50);
                    font-weight: bold;
                    color: #27140d;
                    margin-bottom: to(32);
                }

                .land-up-right__info {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: to(563);
                    height: to(300);
                    border-radius: to(17);
                    background: #dcbd8d;

                    &.mode2 {
                        background: #9B84BF;
                    }

                    .info-item {
                        // width: to(400);
                        font-size: to(32);
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        p {
                            margin-right: to(36);
                            margin-left: to(24);
                        }
                        .icon {
                            width: to(95);
                            height: to(64);
                            background: url("~@/assets/img/landfi/icon_zs.png");
                            background-size: 100% 100%;
                        }
                        .land-flex-vmiddle {
                            display: flex;
                            align-items: center;
                            span {
                                font-size: to(46);
                                font-weight: 600;
                                color: #7aae08;
                            }
                            .land-up-icon {
                                width: to(44);
                                height: to(44);
                                background: url('"~@/assets/img/landfi/icon-level-obtain.png');
                                background-size: 100% 100%;
                            }
                            .land-up-icon-none {
                                background-image: none;
                            }
                        }


                        &:nth-child(2) {
                            margin-top: to(40);

                            &>div:nth-child(1) {
                                width: to(95);
                                height: to(64);
                                background: url("~@/assets/img/landfi/icon_cp.png");
                                background-size: 100% 100%;
                            }
                        }
                    }
                }
            }

            &>div:nth-child(2) {
                >p {
                    font-size: to(28);
                    color: #3d3d3d;
                    margin-bottom: to(30);
                }
            }
        }
    }
}