@function to($px) {
  @return ($px / 2558) * 100vw;
}

.gy_land_confirm_order {
  padding: to(75) to(120);
  width: to(2310);
  height: to(1129);
  background: url("~@/assets/img/landfi/landgy/order_bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .land_order__title {
    font-family: YueGenYouShangYueBoHei;
    font-size: to(60);
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: to(40);
  }

  .order_content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    color: #200334;

    &_left {
      width: to(1180);
      background: #B0A3C4;
      border-radius: to(17);
      padding: to(48) to(47) to(32) to(50);
      box-sizing: border-box;

      .order_info {
        display: flex;
        h1 {
          font-size: to(40);
          font-weight: 600;
          color: #200334;
                }
        h2 {
          font-size: to(64);
          font-weight: 600;
        }
        & > div {
          margin-bottom: to(28);
          &:nth-child(1) {
            width: to(300);
            height: to(300);
            margin-right: to(52);
            background: url("~@/assets/img/landfi/landgy/img_thu_bg.png");
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            > img {
              width: to(261);
              height: auto;
              border-radius: to(17);
            }
          }
          &:nth-child(2) {
            padding: to(26) 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
          }
        }
      }

      .purchase_num_box {
        display: flex;
        justify-content: space-between;
        margin-bottom: to(35);

        .purchase_num {
          display: flex;
          align-items: center;
          color: #200334;
          font-size: to(36);
        }

        .purchase_stepper {
          display: flex;
          align-items: center;
          width: to(253);
          height: to(66);
          border-radius: to(33);
          background: #BF6B1F;
          box-sizing: border-box;
          border: to(1.57) solid #E9E9E9;
          color: #FFFFFF;
          font-size: to(44);

          .decrease {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .increase {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .input_num {
            width: to(113);
            height: 100%;
            background: #D57B2B;

            .input :global(.adm-input-element) {
              height: 100%;
              text-align: center;
              color: #FFFFFF;
              font-size: #{to(44)};
            }
          }
        }
      }

      .use_points {
        padding-top: to(20);
        border-top: to(1.71) solid #D1AA6B;
        
        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          &:not(:last-child) {
            margin-bottom: to(20);
          }
          span {
            font-weight: 500;
            font-size: to(41);
            > span {
              font-size: to(30);
            }
          }
          &:nth-child(2) {
            font-size: to(36);
          }
          &:nth-child(3) {
            width: to(260);
            height: to(60);
            background: #d48741;
            border-radius: to(7);

            .input_point :global(.adm-input-element) {
              --placeholder-color: #fff;
              --font-size: #{to(30)};
              --color: #fff;
              padding: 0 to(20);
            }
          }
        }
      }
    }
    /* right */
    &_right {
      width: to(858);
      background: #B0A3C4;
      border-radius: to(17);
      padding: to(32) to(50);
      box-sizing: border-box;

      .price_info {
        margin-bottom: to(70);

        & > div {
          display: flex;
          justify-content: space-between;
          font-size: to(41);
          font-weight: 500;
          margin-bottom: to(32);
          color: #200334;
          span {
            font-size: to(30);
          }
        }
      }

      .pay_type {
        h1 {
          font-size: to(48);
          font-weight: 500;
          color: #200334;
          padding-bottom: to(20);
          margin-bottom: to(70);
          border-bottom: to(1) solid #F0D3FF;
          box-sizing: border-box;
        }
        .speed_card_order__selectpay {
          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: to(40);

            & > label {
              &:nth-child(1) {
                display: flex;
                align-items: center;
                span {
                  margin-left: to(20);
                }
              }
            }
          }
        }
      }
    }
  }

  .order_foot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color:#FFFFFF;
    p {
      margin: to(40);
      span {
        &:nth-child(1) {
          font-size: to(41);
          font-weight: 500;
        }
        &:nth-child(2) {
          margin-left: to(10);
          font-size: to(30);
          font-weight: 500;
          color: #ff7800;
        }
        &:nth-child(3) {
          font-size: to(58);
          font-weight: 600;
          color: #ff7800;
        }
      }
    }
  }
}

:global {
  .adm-center-popup-wrap {
    max-width: 100vw;
  }
  .adm-modal-wrap {
    max-width: 100vw;
  }
  .adm-input {
    max-width: 100vw;
  }
}