@function to($px) {
  @return ($px / 2558) * 100vw;
}

.detail_out_mask {
  width: 100vw;
  height: 100vh;

  :global {
    .adm-mask-content {
      width: 100vw;
      height: 100vh;
      display: block;
      position: relative;
    }
  }

  .detail_out {
    position: absolute;
    width: to(788);
    height: to(837);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: to(54);
    opacity: 1;
    opacity: 1;
    background: url('~@/assets/img/landfi/btc-bg-3.png') no-repeat;
    background-size: 100%;
    background-position: center;
    font-family: PingFang SC;
    box-sizing: border-box;
    padding-top: to(80);
    padding-left: to(65);
    padding-right: to(65);
    padding-bottom: to(80);

    .detail_out_bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: rotate(8.26deg);
      border-radius: to(54);
      opacity: 1;
      background: rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border: to(2.7) solid #FFFFFF;
      backdrop-filter: blur(to(27));
    }

    .title_box {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1001;
      .close_box {
        width: to(52);

        .close {
          width: to(52);
          height: to(52);
        }
      }

      .title {
        flex: 1;

        font-size: to(60);
        font-weight: 600;
        line-height: normal;
        text-align: center;

        font-variation-settings: "opsz"auto;
        background: linear-gradient(109deg, #FF9BAD -14%, #FF4567 71%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }



    .box {
      position: absolute;
      width: 100%;
      left: 50%;
      transform: translate(-50%,0);
      bottom:to(110);
      padding-left: to(65);
      padding-right: to(65);
      box-sizing: border-box;
      .top {
        font-size: to(64);
        font-weight: 500;
        letter-spacing: 0px;
        text-align: center;
        color: #000000;
        margin-bottom: to(90);
      }

      .bottom {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: to(90);

        .bottom_num {
          flex: 1;
          font-family: PingFang HK;
          font-size: to(96);
          font-weight: 600;
          color: #E13F3F;
          text-align: center;
        }

        .bottom_sub {
          width: to(95);
          height: to(95);
          background: url('~@/assets/img/landfi/btc-btn-3.png') no-repeat;
          background-size: 94%;
          background-position: center;
          margin-left: to(30);
          margin-right: to(20);

        }

        .bottom_add {
          width: to(95);
          height: to(95);
          background: url('~@/assets/img/landfi/btc-btn-4.png') no-repeat;
          background-size: 94%;
          background-position: center;
          margin-left: to(30);
          margin-right: to(20);
        }

        .bottom_text {
          font-size: to(48);
          font-weight: normal;
          letter-spacing: 0px;
          font-variation-settings: "opsz"auto;
          color: #1A1A1A;
        }
      }

      .opt{
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        .cancle{
          width: to(320);
          height: to(132);
          background: url('~@/assets/img/landfi/btc-btn-1.png') no-repeat;
          background-size: 94%;
          background-position: center;
        }
        .confirm{
          width: to(320);
          height: to(132);
          background: url('~@/assets/img/landfi/btc-btn-2.png') no-repeat;
          background-size: 94%;
          background-position: center;
        }
      }

    }
  }
}