@function to($px) {
  @return ($px / 2558) * 100vw;
}

.dropdown_select {
  margin-right: to(110);
  color: #763900;
  width: 100%;

  .dropdown_title {
    align-items: center;

    .title {
      margin-right: to(12);
      font-size: to(44);
    }

    .content_box {
      position: relative;
    }

    .content_top {
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      padding: 0 to(51);
      font-size: to(38);
      min-width: to(200);
      height: to(70);
      border-radius: to(35);
      border: to(1.57) solid #FFFFFF;
      background: #FEFAF2;
      box-sizing: border-box;

      .content {
        flex: 1;
        color: #C77F3D;
        font-size: to(44);
        font-weight: 500;
      }

      .icon {
        width: to(44);
        height: to(23);
        transition: 0.3s;

        img {
          width: to(44);
          height: to(23);
        }
      }

      .rotate {
        width: to(44);
        height: to(23);
        transition: 0.3s;
        transform: rotate(180deg);
        transform-origin: 50% 50%;

        img {
          width: to(44);
          height: to(23);
        }
      }
    }
  }

  .dropdown_content {
    position: absolute;
    left: 50%;
    top: to(90);
    transform: translateX(-50%);
    padding-top: to(20);
    font-size: to(38);
    border: to(1.57) solid #FFFFFF;
    background: #FEFAF2;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    border-radius: to(20);
    opacity: 0;
    transition: 0.3s;

    &.show {
      opacity: 1;
    }

    &.hidden {
      display: none;
    }

    &_item {
      margin-bottom: to(20);
      text-align: center;
      opacity: 1;
      transition: transform 0.3s;

      &:active {
        opacity: 0.5;
      }
    }
  }
}