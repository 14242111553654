$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.shop_stage_conatner0 {
  .adm-mask-content {
    height: 100%;
  }
  .shop_stage_rule_container {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: red;
    margin-top: 0;

    .shop_stage_share_container_content {
      width: 80vw;
      height: 36 *$rem;
      background: linear-gradient(180deg, #05512E 0%, #09170A 100%);
      border-radius: 12px;
      border: 1px solid #5AF8AB;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .shop_stage_title {
        margin-top: 1.8 *$rem;
        font-size: 2.2 *$rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }

      .shop_stage_rule_content1 {
        width: 90%;
        height:19 *$rem;
        font-size: 1.1 *$rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8EF4B1;
        line-height: 1.6 *$rem;
        letter-spacing: 1px;
        white-space: pre-line;
        //background-color: red;
        overflow-y: scroll;
        padding-bottom: 6*$rem;
      }

      //.content1 {
      //  width: 82%;
      //  height: 23 *$rem;
      //  font-size: 1.1 *$rem;
      //  overflow: auto;
      //
      //  &::-webkit-scrollbar {
      //    display: flex;
      //  }
      //
      //  font-family: PingFangSC-Regular, PingFang SC;
      //  font-weight: 400;
      //  color: #8EF4B1;
      //  line-height: 1.6 *$rem;
      //  letter-spacing: 1px;
      //  margin-top: 10px;
      //  white-space: pre-line;
      //}

      .shop_stage_rule_sure_btn_father {
        width: 80%;
        height: 4 *$rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 1.5 *$rem;

        img {
          width: 100%;
          height: 100%;
        }


        .sure_btn_txt {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          //background-color: red;
          margin-top: -0.1 *$rem;

          span {
            font-size: 2.2 *$rem;
            font-weight: 400;
            color: #000000;
            font-family: RuiZiZhenYan;
          }
        }
      }
    }
  }
}




