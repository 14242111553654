$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.egg_paysuccess {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: url(~@/assets//img/ape/green_bg.png) no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
}


.egg_pay_content {
  width: 80vw;
  min-height: 30vh;
  //background-color: red;
  margin-top: 15 *$rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .egg_pay_img {
    width: 13 *$rem;
    height: 13 *$rem;
    object-fit: cover;
    background-color: white;
  }

  .egg_pay_title {
    font-family: PingFangSC-Semibold;
    font-size: 1.6 *$rem;
    font-weight: normal;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
  }

  .egg_pay_number {
    font-family: PingFangSC-Regular;
    font-size: 1.4 *$rem;
    font-weight: normal;
    line-height: 40px;
    letter-spacing: 0px;
    color: #000000;
  }
}

.video_player_father {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.img_dh {
  width: 100%;
  height: 45vh;
  object-fit: cover;
}

.egg_view_btn {
  position: absolute;
  width: 80vw;
  height: 4.6 *$rem;
  left: 10vw;
  bottom: 10 *$rem;
  background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
  font-family: PingFangSC-Medium;
  font-size: 1.6 *$rem;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  color: #614D1A;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.3 *$rem;
}
