@function to($px) {
  @return ($px / 2558) * 100vw;
}

.daily_task {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: to(217);
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/img/landfi/land-detail-bg.png") no-repeat;
  background-size: 98% 100%;
  background-position: center;
  box-sizing: border-box;
  font-family: YueGenYouShangYueBoHei;

  .task_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 to(62);
    width: to(2045);
    height: to(252);
    box-sizing: border-box;
    border-radius: to(17);
    background: #F1D7AE;

    .item_left {
      margin-right: to(180);
      >p {
        color: #8A462C;
        font-size: to(50);
        font-weight: 600;
      }

      >span {
        display: block;
        margin-top: to(29);
        width: to(138);
        height: to(62);
        line-height: to(62);
        text-align: center;
        border-radius: to(31);
        background: rgba(189, 118, 56, 0.3);
        color: #8A462C;
        font-size: to(37);
        font-weight: 600;
      }
    }

    .item_middle {
      display: flex;
      flex-direction: column;
      align-items: center;

      >img {
        margin-bottom: to(20);
        width: to(108);
        height: to(86);
      }

      >span {
        color: #792D16;
        font-size: to(57);
        font-weight: bold;
      }
    }

    .item_right {
      width: to(400);
      height: to(104);
      text-align: center;
      line-height: to(104);
      background: url('~@/assets/img/landfi/task-btn.png') no-repeat;
      background-size: 100% 100%;
      font-size: to(38);
      font-weight: bold;
      color: #FFFFFF;

      &.disabled {
        background: url('~@/assets/img/landfi/task-btn-disabled.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

.choose_land {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: to(1205);
  height: 100%;
  background: url('~@/assets/img/landfi/pop_left.png') no-repeat;
  background-size: 99% 93%;
  background-position: left center;
  font-family: YueGenYouShangYueBoHei;
  padding: to(189) to(120) to(142) to(116);
  overflow: hidden;

  .title {
    top: to(-75)
  }

  .close_popup {
    position: absolute;
    top: to(95);
    right: 0;
    >img {
      width: to(100);
      height: to(100);
    }
  }

  >p {
    color: #8A462C;
    font-size: to(38);
    font-weight: bold;
  }

  .land_list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: to(27);
    width: 100%;
    background: #E3C49C;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0 to(30) to(30);

    .list_item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: to(80);
      margin-right: to(44);

      &:nth-child(3n) {
        margin-right: 0;
      }

      .lease_tag {
        position: absolute;
        top: 0;
        left: 0;
        width: to(61);
        height: to(72);
        background: url('~@/assets/img/landfi/lease-tag.png') no-repeat;
        background-size: 100% 100%;
      }

      .land_img_box {
        display: flex;
        justify-content: center;
        width: to(233);
        height: to(213);
        background: url('~@/assets/img/landfi/land-img-bg.png') no-repeat;
        background-size: 100% 100%;
        .land_c {
          margin-top: to(15);
          width: to(192);
          height: to(151);
        }
        .land_c5 {
          margin-top: to(15);
          width: to(225);
          height: to(155);
        }
        .land_a {
          margin-top: to(-35);
          width: to(194);
          height: to(199);
        }
        .land_b {
          margin-top: to(-10);
          width: to(196);
          height: to(177);
        }
      }

      .land_no {
        margin-top: to(-15);
        margin-bottom: to(8);
        width: to(200);
        text-align: center;
        font-size: to(25);
        color: #4C4C4C;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display:-webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .land_level {
        padding: 0 to(15);
        width: to(271);
        height: to(50);
        line-height: to(50);
        text-align: center;
        border-radius: to(25);
        background: linear-gradient(180deg, #FFD95D 0%, #EDB451 100%);
        box-shadow: 0px to(3) 0px 0px #FFCE6B;
        font-size: to(28);
        color: #8A462C;
        font-weight: 600;
        box-sizing: border-box;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display:-webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .select_img {
        position: absolute;
        top: to(10);
        right: 0;
        width: to(50);
        height: to(50);
      }
    }
  }

  .no_data {
    display: flex;
    justify-content: center;
    padding-top: to(40);
    margin: to(220) auto 0;
    background: url('~@/assets/img/landfi/img_brand.png') no-repeat;
    background-size: 100% 100%;
    width: to(268);
    height: to(215);
    box-sizing: border-box;
    font-family: YueGenYouShangYueBoHei;
    color: #792B06;
  }

  .choose_btn_box {
    display: flex;
    justify-content: flex-end;

    .confirm_btn {
      margin-top: to(23);
      width: to(285);
      height:  to(83);
      border-radius: to(50);
      text-align: center;
      line-height: to(83);
      color: #FFFFFF;
      font-size: to(41);

      &.active {
        background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
        background-size: 100% 100%;
      }

      &.disabled {
        background: url('~@/assets/img/landfi/icon_zh.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}