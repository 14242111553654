@import '@/assets/scss/variable.scss';

.container {
  margin: 1 *$rem auto 0;
  width: px2rem(670);
  padding: px2rem(30) px2rem(35) px2rem(8);
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFFFFF;
  font-size: px2rem(30);
  color: #333333;
  .header {
    border-bottom: 1px solid #F2F2F2;
    padding: px2rem(24) 0;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: px2rem(15) 0;
  }
  .payWay {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #333333;
    font-size: px2rem(28);

    img {
      width: px2rem(48);
      height: px2rem(48);
      margin-right: px2rem(16);
    }
  }
  .radio {
    --adm-color-primary: #C1AF68;
    --icon-size: 0.75rem; // px2rem(30);
  }
}
