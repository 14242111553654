$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.collection_raffle_page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #F6D3B7;
  //padding-bottom: 3*$rem;
  overflow: hidden;
  display: flex;
  .body-content {
    flex: 1;
    overflow:scroll;
  }
  .navigate_bar {
    position: absolute;
    top: setRem(72);
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .back_father {
      margin-left: 0.5*$rem;
      padding: 0 10px;

      .back_btn {
        width: 0.95*$rem;
        height: 1.75*$rem;
        background: url(~@/assets//img/icon_left.png) no-repeat;
        background-size: 100%;
      }
    }

    .share_father {
      margin-right: 0.8*$rem;
      padding: 0 10px;

      .share {
        width: 1.7*$rem;
        height: 1.85*$rem;
        background: url(../img/icon_share.png) no-repeat;
        background-size: 100%;
      }
    }
  }
  .part1 {
    position: relative;

    .part1_bg {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .rule_shiming_bar1 {
      position: absolute;
      right: 0;
      top: 9 *$rem;
      width: setRem(41);
      height: setRem(81);
      display: flex;
      //width: 100%;
      background: #F8BCA2;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 6px 0px 0px 6px;
      opacity: 0.8;
      font-size: 1.2 *$rem;
      font-weight: bold;
      color: #FFFFFF;
      padding-right: 1.5px;
    }

    .prize_title_container {
      position: absolute;
      width: 100%;
      top: setRem(382);
      display: flex;

      .prize_title {
        flex: 1;
        justify-content: center;
        text-align: center;
        font-family: PangMenZhengDao;
        font-size: setRem(52);
        font-weight: normal;
        letter-spacing: 0em;
        background: linear-gradient(282deg, #E41B1B 0%, rgba(216, 40, 40, 0.77) 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
      }
    }

    .traffic_signals {
      display: flex;
      position: absolute;
      top: setRem(446);
      justify-content: center;
      width: 100%;

      .img {
        width: setRem(337);
        height: setRem(122);
      }
    }

    .big_prize_pic {
      position: absolute;
      display: flex;
      width: 100%;
      top: setRem(648);

      .big_prize_pic_container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        .btn_left {
          width: setRem(28);
          height: setRem(150);
        }
        .btn_center_container {
          margin-left: setRem(62);
          margin-right: setRem(62);
          width: setRem(305);
          height: setRem(240);
          .btn_center {
            width: setRem(308);
            height: setRem(240);
            //padding-left: setRem(20);
            //padding-right: setRem(20);
          }
        }
        .btn_right {
          width: setRem(28);
          height: setRem(150);
        }
      }
    }

    .btn_container {
      display: flex;
      width: 100%;
      position: absolute;
      justify-content: center;
      align-content: center;
      top: setRem(1011);

      .btn_bg_grey {
        width: setRem(366);
        height: setRem(80);
      }
    }

    .btn_container_title {
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: setRem(70);
      text-align: center;
      top: setRem(1010);

      .btn_name {
        padding-top: setRem(20);
        padding-bottom: setRem(20);
        padding-left: setRem(50);
        padding-right: setRem(50);
        font-family: PangMenZhengDao;
        font-size: setRem(38);
        font-weight: normal;
        letter-spacing: 0em;
        color: #FFFDFD;
      }
    }

    .integration_container_title {
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      top: setRem(1107);

      .integration_title {
        font-family: PingFangSC-Regular;
        font-size: setRem(24);
        font-weight: normal;
        line-height: setRem(34);
        text-align: center;
        letter-spacing: 0px;
        color: #BC6400;

        > span {
          color: #E11515;
        }
      }
    }

    .list_prize_container {
      margin-top: setRem(24);
      margin-bottom: setRem(30);
      margin-right: setRem(30);
      margin-left: setRem(30);
      display: flex;
      //width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow-x: scroll;

      .list_prize {
        margin-left: setRem(11);
        margin-right: setRem(11);
        width: setRem(131);
        height: setRem(131);
        border-radius: setRem(12);
        border: 1px solid #F7A167;
        background: rgba(255, 255, 255, 0.5);
      }

      .list_prize_big {
        margin-left: setRem(11);
        margin-right: setRem(11);
        width: setRem(153);
        height: setRem(153);
        border-radius: setRem(12);
        border: 1px solid #F7A167;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .list_prize_container_2 {
      margin-top: setRem(24);
      margin-bottom: setRem(30);
      margin-left: setRem(30);
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      overflow-x: scroll;
      overflow: auto;

      .list_prize {
        margin-left: setRem(11);
        margin-right: setRem(11);
        width: setRem(131);
        height: setRem(131);
        border-radius: setRem(12);
        border: 1px solid #F7A167;
        background: rgba(255, 255, 255, 0.5);
      }

      .list_prize_big {
        margin-left: setRem(11);
        margin-right: setRem(11);
        width: setRem(153);
        height: setRem(153);
        border-radius: setRem(12);
        border: 1px solid #F7A167;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .prize_result_container {
    margin-top: setRem(28);
    margin-right: setRem(30);
    margin-left: setRem(30);
    border-radius: setRem(16);
    height: setRem(292);
    opacity: 1;
    background: linear-gradient(113deg, rgba(229,152,111,0.47) -4%, #E5986F 100%);

    .prize_result_head_title {
      display: flex;
      padding-top: setRem(20);
      padding-left: setRem(30);
      font-family: PingFangSC-Semibold;
      font-size: setRem(30);
      font-weight: normal;
      line-height: setRem(32);
      letter-spacing: 0em;
      color: #FFFFFF;
    }

    .prize_result_main_title {
      margin-top: setRem(38);
      font-family: PingFangSC-Semibold;
      font-size: setRem(40);
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #000000;
    }

    .prize_result_sub_title {
      //width: setRem(432);
      margin-left: setRem(130);
      margin-right: setRem(130);
      font-family: PingFangSC-Regular;
      font-size: setRem(30);
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      opacity: 0.5;
      color: #000000;
    }

    .btn_receive_package {
      display: flex;
      margin-right: setRem(138);
      margin-left: setRem(138);
      border-radius: setRem(40);
      justify-content: center;
      align-items: center;
      height: setRem(80);
      opacity: 1;
      background: linear-gradient(97deg, #D4BA77 4%, #FFEAB4 99%);
      margin-top: setRem(38);

      .btn_receive_package_title {
        font-family: PingFangSC-Regular;
        font-size: setRem(32);
        font-weight: normal;
        line-height: setRem(42);
        text-align: center;
        letter-spacing: setRem(1);
        color: #624C16;
      }
    }
  }
  .prize_result2_container {
    margin-top: setRem(28);
    margin-right: setRem(30);
    margin-left: setRem(30);
    border-radius: setRem(16);
    height: setRem(403);
    opacity: 1;
    background: linear-gradient(113deg, rgba(229,152,111,0.47) -4%, #E5986F 100%);

    &.content-height{
      min-height: 100px;
      padding-bottom: 2 *$rem;
    }

    .prize_result_head_title {
      display: flex;
      padding-top: setRem(20);
      padding-left: setRem(30);
      font-family: PingFangSC-Semibold;
      font-size: setRem(30);
      font-weight: normal;
      line-height: setRem(32);
      letter-spacing: 0em;
      color: #FFFFFF;
    }

    .prize_result_main_title {
      margin-top: setRem(38);
      font-family: PingFangSC-Semibold;
      font-size: setRem(40);
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #000000;
    }

    .prize_result_sub_title {
      //width: setRem(432);
      margin-left: setRem(130);
      margin-right: setRem(130);
      font-family: PingFangSC-Regular;
      font-size: setRem(30);
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      opacity: 0.5;
      color: #000000;
    }

    .btn_receive_package {
      display: flex;
      margin-right: setRem(138);
      margin-left: setRem(138);
      border-radius: setRem(40);
      justify-content: center;
      align-items: center;
      height: setRem(80);
      opacity: 1;
      background: linear-gradient(97deg, #D4BA77 4%, #FFEAB4 99%);
      margin-top: setRem(38);

      .btn_receive_package_title {
        font-family: PingFangSC-Regular;
        font-size: setRem(32);
        font-weight: normal;
        line-height: setRem(42);
        text-align: center;
        letter-spacing: setRem(1);
        color: #624C16;
      }
    }
  }
  .activity_process_container {
    display: flex;
    flex-direction: column;
    margin-top: setRem(28);
    margin-left: setRem(30);
    margin-right: setRem(30);
    border-radius: setRem(16);
    height: setRem(382);
    opacity: 1;
    background: linear-gradient(113deg, rgba(229,152,111,0.47) -4%, #E5986F 100%);
    .prize_result_head_title {
      display: flex;
      padding-top: setRem(20);
      padding-left: setRem(30);
      font-family: PingFangSC-Semibold;
      font-size: setRem(30);
      font-weight: normal;
      line-height: setRem(32);
      letter-spacing: 0em;
      color: #FFFFFF;
    }
    .activity_process {
      margin-right: setRem(37);
      margin-left: setRem(37);
      margin-top: setRem(32);
      display: flex;
      flex-direction: row;
      .icon_style_container {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .icon_style {
          width: setRem(82);
          height: setRem(82);
        }
        .text_style {
          margin-top: setRem(8);
          font-family: PingFangSC-Semibold;
          font-size: setRem(24);
          font-weight: normal;
          line-height: setRem(32);
          letter-spacing: 0em;
          color: #FFFFFF;
        }
      }

    }
    .progress_bar_container {
      position: relative;
      margin-right: setRem(37);
      margin-left: setRem(37);
      margin-top: setRem(27);
      justify-content: space-between;
      align-items: center;
      background: #FFFFFF;
      border-radius: setRem(307);
      .progress-bar {
        height: 100%;
        //border-top-left-radius: setRem(307);
        //border-bottom-left-radius: setRem(307);
        border-radius: setRem(307);
        background: linear-gradient(90deg, #F73C3C 5%, #FF8C4E 99%);

        //display: flex;
        height: setRem(15);
        //width: 100%;
        //border-radius: setRem(307);
        //opacity: 1;
        //background: #FFFFFF;
        //box-shadow: inset 0px 4px 10px 0px #CB865E;
      }
      .time_label_container {
        position: absolute;
        top: setRem(-13);
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: setRem(42);
        .label_begin {
          height: setRem(42);
          width: setRem(192);
          border-radius: setRem(121);
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          background: linear-gradient(270deg, #FFA252 0%, #FFECA1 100%);

          font-family: PingFangSC-Medium;
          font-size: setRem(20);
          font-weight: normal;
          letter-spacing: 0em;
          color: #BC6400;
        }
        .label_end {
          height: setRem(42);
          width: setRem(192);
          border-radius: setRem(121);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          opacity: 1;
          background: #FFF3C5;

          font-family: PingFangSC-Medium;
          font-size: setRem(20);
          font-weight: normal;
          letter-spacing: 0em;
          color: #BC6400;
        }
      }
    }
    .tips {
      margin-top: setRem(40);
      margin-right: setRem(73);
      margin-left: setRem(73);
      height: setRem(73);
      border-radius: setRem(30.01);
      opacity: 1;
      background: linear-gradient(98deg, #EFB11A 0%, #EF643F 100%);
      box-shadow: inset 0px 4.74px 24.49px 0px #FFFFFF;

      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: setRem(28.43);
      font-weight: normal;
      line-height: setRem(18.96);
      letter-spacing: 0px;
      text-align: center;
      color: #FFFFFF;
    }
  }
  .bottom_empty {
    height: setRem(60);
  }

}
