$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.pwd_setting_container {
  width: 100%;
  height: 100%;
  //background: purple;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pwd_setting_content {
    width: 90vw;
    min-height: 120px;
    margin-top: 2 *$rem;
    background: white;
    border-radius: 6px;

    .item_0 {
      width: 100%;
      height: 4 *$rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      img {
        width: 13px;
        height: 13px;
        padding: 6px 12px;
      }
    }

    .pwd_setting_line {
      width: 94%;
      height: 1px;
      opacity: 0.5;
      background: #D8D8D8;
      margin-left: 3%;
      margin-top: 1 *$rem;
    }
  }
}

.pwd_des {
  width: 85%;
  opacity: 0.5;
  font-family: PingFang SC;
  font-size: 1.2 *$rem;
  font-weight: normal;
  line-height: 50px;
  letter-spacing: 0px;
  color: #000000;
  text-align: left;
}
.forget_pwd{
  width: 85%;
  opacity: 0.5;
  font-family: PingFang SC;
  font-size: 1.2 *$rem;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #000000;
  text-align: right;
  margin-top: 2 *$rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.done_btn {
  width: 90vw;
  height: 4.5 *$rem;
  border-radius: 45px;
  opacity: 0.6;
  background: linear-gradient(95deg, #D4BA77 4%, #FFEAB4 99%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #624C16;
  font-size: 1.8 *$rem;
  margin-top: 4 *$rem;

  &.btn_opacity{
    opacity: 1;
  }
}

