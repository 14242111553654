$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.synthesis__modal-body {
    width: 33*$rem;
    height: 24.15*$rem;
    background: url("~@/assets/img/industrialApe/frame_s@1x.png") no-repeat center center / 100% 100%;

    .content {
        width: 33*$rem;
        height: 24.15*$rem;
        position: relative;
        padding: 4.7*$rem;
        box-sizing: border-box;
        line-height: normal;

        >.title {
            font-family: AlimamaShuHeiTi;
            display: block;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 1.75*$rem;
            margin-bottom: 2.75*$rem;
            text-align: center;
        }

        >span {
            display: block;
            padding: 0 2*$rem;
            text-align: center;
            color: #FFFFFF;
            font-size: 1.2*$rem;
            line-height: 1.7*$rem;

        }

        >img {
            position: absolute;
            right: 1*$rem;
            top: 2.2*$rem;
            width: 1.1*$rem;
            height: 1.1*$rem;
        }

        .btn-group {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 3.1*$rem;

            .cancel {
                text-align: center;
                width: 9.7*$rem;
                height: 3.35*$rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: url("~@/assets/img/industrialApe/no@1x.png") no-repeat center center / 100% 100%;
                color: #0C4390;
                font-size: 1.5*$rem;
                font-weight: bold;

                &:active {
                    opacity: 0.8;
                }
            }

            .confirm {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 9.7*$rem;
                height: 3.35*$rem;   
                background: url("~@/assets/img/industrialApe/yes@1x.png") no-repeat center center / 100% 100%;
                color: #844000;
                font-size: 1.5*$rem;
                font-weight: bold;

                &:active {
                    opacity: 0.8;
                }
            }

        }

    }

}