$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.prize_record_page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;

    .container_record_parts {
        .record_itemContainer {
            margin: 1*$rem 1.5*$rem;
            background-color: #FFFFFF;
            border-radius: 16 / 20*$rem;
            padding: 30 / 20*$rem;
            min-height: 130 / 20*$rem;
            display: flex;
            flex-direction: column;

            .itemLeft {
              display: flex;
              flex-direction: column;

              .itemTop {
                display: flex;
                flex-direction: column;

                .itemTopTitle {
                  color: #333333;
                  font-size: 28 / 20*$rem;
                  line-height: 48 / 20*$rem;
                  font-weight: bold;
                }

                .itemTopSubTitle {
                  color: #5D5D5D;
                  font-size: 20 / 20*$rem;
                  line-height: 40 / 20*$rem;
                }
              }

              .itemTime {
                color: #333333;
                font-size: 20 / 20*$rem;
                line-height: 40 / 20*$rem;
              }
            }

            .itemRight {
              color: #C70003;
              font-size: 50 / 20*$rem;
              line-height: 70 / 20*$rem;
              font-weight: 500;
            }

            .itemAddressBtn {
              margin-top: 12 / 20*$rem;
              padding-top: 24 / 20*$rem;
              height: 90 / 20*$rem;
              width: 100%;
              border-top: 1px solid #D8D8D888;
              display: flex;
              justify-content: center;
              align-items: center;

              .itemAddressBtnTitle {
                font-size: 26 / 20*$rem;
                color: #004FC4;
                text-align: center;
                line-height: 50 / 20*$rem;
                border-bottom: 1px solid #004FC4;
              }
            }
        }
    }
}
