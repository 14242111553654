@function to($px) {
  @return ($px / 2558) * 100vw;
}

.select_ape {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: to(1020);
  height: 100%;
  background: url('~@/assets/img/landfi/779.png') no-repeat;
  background-size: 100% 100%;
  background-position-y: center;
  font-family: YueGenYouShangYueBoHei;
  padding: to(158) to(130) to(142) to(94);
  
  .ape_auth {
    position: fixed;
    width: to(327);
    height: to(107);
    line-height: to(107);
    text-align: center;
    right: to(80);
    bottom: to(100);
    border-radius: to(106);
    background: #D8D8D8;
    z-index: 10;
  }

  :global {
    .adm-swiper{
      flex:1;
    }
  }
  &.mode2 {
    background: url('~@/assets/img/landfi/landgy/pop_left_none.png') no-repeat;
    background-size: 100% 100%;

    .nftno {
      color: #FFFFFF;
    }
   
  }

  .title_mode2 {
    width: to(368);
    height: to(144);
    background: url('~@/assets/img/landfi/landgy/title_bg.png') no-repeat;
    background-size: 100% 100%;
    color: #200334;
    font-size: to(62);
    text-shadow: none;
    text-align: center;
    padding: 0;
    line-height: to(144);
  }

  .title {
    top: to(-120);
    padding-left: to(50);
  }

  .close_popup {
    position: absolute;
    top: to(50);
    right: to(10);
    width: to(115);
    height: to(115);
    background: url('~@/assets/img/landfi/icon_del.png') no-repeat;
    background-size: 100% 100%;

    &.mode2 {
      right: to(40);
      background: url('~@/assets/img/landfi/landgy/icon_del.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .tabs {
    :global {
      --content-padding: 0;

      .adm-tabs-tab {
        padding-bottom: 0;
        margin: 0;
      }

      .adm-tabs-header {
        border-bottom: none;
      }

      .adm-tabs-tab-line {
        display: none;
      }

      .adm-tabs-header-mask-right,
      .adm-tabs-header-mask-left {
        background: none;
      }

      .adm-tabs-tab-wrapper-stretch {
        flex: none;
      }

      .adm-tabs-tab-wrapper {
        padding: 0;
      }
      
    }

    .tab_title {
      color: rgba(129, 76, 25, 0.8);
      font-family: YueGenYouShangYueBoHei;
      font-size: to(38);
      box-sizing: border-box;
      text-align: center;

      &.tab_select {
        color: #A05D20;
        font-size: to(44);
        font-weight: 500;
      }

      &.mode2 {
        color: #FFFFFF;
        font-size: to(44);

        &.tab_select {
          color: #DDC1F9;
        }
      }

      .tab_text {
        padding: 0 to(30);
      }

      .tab_active {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: to(65);

        >img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        >span {
          width: 100%;
          height: to(3.15);
          background: rgba(133, 91, 49, 0.76);
        }

        &.mode2 {
          >span {
            background: #DDC1F9;
          }
        }
      }
    }
  }

}

.ape_list {
  padding-left: to(30);
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  position: relative;



  .no_data {
    display: flex;
    justify-content: center;
    padding-top: to(40);
    margin: to(180) auto 0;
    background: url('~@/assets/img/landfi/img_brand.png') no-repeat;
    background-size: 100% 100%;
    width: to(268);
    height: to(215);
    box-sizing: border-box;
    font-family: YueGenYouShangYueBoHei;
    color: #792B06;
  }
}

.list_item {
  display: inline-block;
  width: fit-content;
  margin-top: to(42);
  margin-right: to(70);
  box-sizing: border-box;

  &:nth-child(3n) {
    margin-right: 0;
  }

  .list_item_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .list_item_mark {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PangMenZhengDao;
      font-size: to(28);
      color: #200334;
      top: to(6);
      left: to(6);
      width: to(101);
      height: to(40);
      background: url('~@/assets/img/landfi/landgy/gy_mark.png') no-repeat;
      background-size: 100% 100%;
      z-index: 0;
    }
    .list_item_dis{
      position: absolute;
      width: 100%;
      height: to(198);
      background: rgba(0,0,0,0.5);
      z-index: 1;
    }

    .lease_tag {
      position: absolute;
      top: to(0);
      left: to(0);
      width: to(61);
      height: to(72);
      background: url('~@/assets/img/landfi/lease-tag.png') no-repeat;
      background-size: 100% 100%;
    }

    .select_img {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(40%, -40%);
      width: to(50);
      height: to(50);
    }

    .land_img {
      margin-bottom: to(19);
      width: to(198);
      height: to(198);
      box-sizing: border-box;
      border: to(6.29) solid transparent;
      border-radius: to(3.15);

      &.active {
        box-shadow: 0px to(6.29) to(15.73) 0px rgba(129, 76, 25, 0.4);
        border-image: linear-gradient(135deg, #FFFFFF 0%, #FFF8BB 47%, #FFFFFF 100%) 6.29;
      }

      >img {
        width: 100%;
        height: 100%;
      }
    }

    >p {
      color: #725341;
      font-size: to(38);
      font-weight: 500;
      width: to(198);
      text-align: center;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}