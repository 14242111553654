$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.recovery-record {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    .recovery-record-warper {
        background: #F7F7F7;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        .recovery-record-item {
            background: #FFFFFF;
            border-radius: 1*$rem;
            margin: 0 1.3*$rem 1*$rem 1.3*$rem;
            padding: 1.4*$rem;
            &:first-child {
                margin-top: 1*$rem;
            }
            .card-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-bottom: 0.5px solid #D8D8D8;
                padding: 1.5*$rem 0;
                margin-bottom: 1*$rem;
                >span{
                    font-size: 1.4*$rem;
                    line-height: 1.3*$rem;
                    &:first-child {
                        color: #999999;
                    }
                    &:last-child {
                        color: #333333;
                        font-weight: 600;
                    }
                }
            }
            .card-body {
                color: #333333;
                font-weight: 600;
                font-size: 1.4*$rem;
                padding: 0.76*$rem 0;
            }
        }
    }
}