$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.collector_info {
    padding: 15*$rem/10;
    background: #ffffff;
    border-radius: 6*$rem/10 6*$rem/10 6*$rem/10 6*$rem/10;
    opacity: 1;
    margin-bottom: setRem(30);

    .collector-name-container {
        display: flex;
        align-items: center;

        >img {
            width: setRem(100);
            height: setRem(100);
            border-radius: 50%;
        }

        .collector-name {
            font-family: PingFangSC-Medium;
            font-size: setRem(36);
            margin-left: setRem(13);
            font-weight: normal;
            line-height: setRem(42);
            letter-spacing: 0px;
            color: #333333;
        }
    }

    .collector-time-container {
        margin-top: setRem(60);
        margin-bottom: setRem(36);
        display: flex;
        height: setRem(42);
        justify-content: space-between;

        .collector-time-name {
            font-family: PingFangSC-Regular;
            font-size: setRem(24);
            font-weight: normal;
            line-height: setRem(42);
            letter-spacing: 0px;
            color: #9E9E9E;
        }

        .collector-time {
            font-family: PingFangSC-Regular;
            font-size: setRem(26);
            font-weight: normal;
            line-height: setRem(42);
            letter-spacing: 0px;
            color: #000000;
        }
    }

    .collector-hash-container {
        display: flex;
        justify-content: space-between;

        .collector-hash-name {
            font-family: PingFangSC-Regular;
            font-size: setRem(24);
            font-weight: normal;
            line-height: setRem(42);
            letter-spacing: 0px;
            color: #9E9E9E;
        }

        .collector-hash {
            word-break: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 169*$rem/10;
            font-family: PingFangSC-Regular;
            font-size: setRem(26);
            font-weight: normal;
            line-height: setRem(36.4);
            text-align: right;
            letter-spacing: 0px;
            color: #165DFF;
        }
    }
}