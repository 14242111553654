@import '@/assets/scss/variable.scss';
.recycling_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  .container {
    width: 40vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 3 *$rem;
    background: url('../../imgs/common-bg.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 6px;

    .title {
      width: 100%;
      font-family: PingFang SC;
      margin-top: 4 *$rem;
      font-size: 1.6 *$rem;
      text-align: center;
      color: #FFFFFF;
    }

    .btns {
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-top: 6 *$rem;

      > p {
        width: 10 *$rem;
        height: 3 *$rem;
        border-radius: 1.4 *$rem;
        box-sizing: border-box;
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(1) {
          border: 1px solid #FCD888;
          color: #FCD888;
        }

        &:nth-child(2) {
          background: linear-gradient(286deg, #FCD786 0%, #FFE9A0 99%);
          color: #BF0808;
        }
      }
    }

    .btn {
      width: 10 *$rem;
      height: 2.8 *$rem;
      border-radius: 1.4 *$rem;
      box-sizing: border-box;
      font-family: PingFang SC;
      font-size: 1.2 *$rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(286deg, #FCD786 0%, #FFE9A0 99%);
      color: #BF0808;
    }

  }
}

