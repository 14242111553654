@import '@/assets/scss/variable.scss';


.recycling_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .container {
    width: 90vw;
    height: 90vh;
    background: #F5F7FA;
    margin: 0 auto;
    border-radius: 6px;
    overflow: hidden;
  }

  .view_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5 *$rem 1 *$rem;

    > img {
      width: 0.8 *$rem;
      padding: 0 6px;
    }

    > span {

    }

    > h3 {
      font-family: PingFang SC;
      font-size: 1.2 *$rem;
      font-weight: 500;
      color: #262727;
    }
  }

  .content {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;

    .left {
      flex: 0.6;
      height: 100%;
      background: #fff;
      border-radius: 4px;
      overflow: hidden;

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2.5 *$rem 2 *$rem 2 *$rem;
        padding-bottom: 2 *$rem;
        border-bottom: 0.5px solid #F2F2F2;

        > img {
          width: 25%;
          padding: 6px 4px;
          background: #EDEDED;
          border-radius: 6px;
        }

        .titlePrice {
          height: 7 *$rem;
          margin-left: 1 *$rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          > p {
            &:nth-child(1) {
              font-family: PingFang SC;
              font-size: 1.2 *$rem;
              font-weight: 600;
              color: #333333;
            }

            &:nth-child(2) {
              font-family: PingFang SC;
              font-size: 1.6 *$rem;
              font-weight: 600;
              color: #333333;
            }
          }
        }
      }

      .stepContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        justify-content: space-between;

        .step {
          display: flex;
          flex-direction: row;
          align-items: center;

          > span {
            font-family: PingFang SC;
            font-size: 1.2 *$rem;
            font-weight: 500;
            color: #262727;
            margin: 0 10px;
          }
        }
      }
    }

    .right {
      flex: 0.39;
      height: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 16px;
      border-radius: 6px;
      padding: 10px;
      box-sizing: border-box;

      .des {
        font-family: PingFang SC;
        font-size: 1.4 *$rem;
        font-weight: normal;
        line-height: 30px;
        color: #333333;
        width: 100%;
        margin-left: 4%;
      }

      .item {
        width: 96%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.5 *$rem;

        > img {
          &:nth-child(1) {
            width: 13%;
          }
        }

        .btn {
          width: 2.5 *$rem;
        }

        > p {
          font-family: PingFang SC;
          font-size: 1.4 *$rem;
          font-weight: normal;
          line-height: 39.2px;
          color: #333333;
          margin-left: -12 *$rem;
        }
      }
    }
  }

  .bottom {
    width: 40%;
    margin-left: 60%;
    margin-top: 3 *$rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    box-sizing: border-box;

    .price {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        &:nth-child(1) {
          font-family: PingFang SC;
          font-size: 1.2 *$rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          color: #000000;
        }

        &:nth-child(2) {
          font-family: PingFang SC;
          font-size: 1.7 *$rem;
          font-weight: 600;
          line-height: normal;
          display: flex;
          align-items: center;
          color: #FF5203;
          margin-left: 5px;
          &::before{
            content: '¥';
            font-size: 1.2 *$rem;
          }
        }
      }
    }

    .submit {
      height: 3 *$rem;
      padding: 0 20px;
      background: linear-gradient(90deg, #FE7F02 0%, #FF5203 100%);
      font-family: PingFang SC;
      font-size: 1.2 *$rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      border-radius: 1.5 *$rem;
    }
  }
}

