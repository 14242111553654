@function to($px) {
  @return ($px / 2558) * 100vw;
}

.land_lease_center {
  position: relative;
  display: flex;
  background: url('~@/assets/img/landfi/lease-center-bg.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  
  .close {
    position: absolute;
    top: to(25);
    right: to(70);
    width: to(88);
    height: to(88);
  }

  .left_tabs {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: to(154);
    width: to(270);
    height: 100%;
    box-shadow: 0px 6.29px 15.73px 0px #6F3900;
    font-family: YueGenYouShangYueBoHei;
    box-sizing: border-box;

    .tabs_title {
      position: absolute;
      top: to(-40);
      left: 50%;
      transform: translateX(-50%);
      padding-top: to(110);
      padding-left: to(74);
      width: to(392);
      height: to(220);
      background: url('~@/assets/img/landfi/title-bg.png') no-repeat;
      background-size: 100% 100%;
      color: #FFE9D3;
      font-size: to(62);
      font-weight: bold;
      text-shadow: 0px 1px 2px #000000;
      box-sizing: border-box;
    }

    .tabs_top {
      padding-top: to(200);
      background: #AD5A18;
      box-sizing: border-box;
    }

    .tabs_bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: to(35);
      padding-bottom: to(50);
      background: #BB6410;
      box-sizing: border-box;
    }

    .tabs_item {
      width: 100%;
      height: to(122);
      color: #FFFFFF;
      font-size: to(57);
      font-weight: bold;
      text-align: center;
      line-height: to(122);

      &.active {
        background: url('~@/assets/img/landfi/lease-tab-active.png') no-repeat;
        background-size: 128% 120%;
        background-position: center;
      }
    }

    .tabs_item_last {
      margin: 0 auto;
      width: to(236);
      height: to(236);
      background: url('~@/assets/img/landfi/woyaochuzu.png') no-repeat;
      background-size: 100% 100%;
      transition: transform 0.3s;
      transform: scale(1);
      &:active {
        transform: scale(0.88);
      }
    }
  }

  .tabs_content {
    flex: 1;
    padding: to(132) to(195) 0 to(76);
    height: 100%;
    box-sizing: border-box;
  }
}

.land_lease {
  display: flex;
  flex-direction: column;
  height: 100%;

  .select_box {
    display: flex;
  }
  
  &_list {
    flex: 1;
    margin-top: to(95);
    overflow-y: auto;
  }
}

.confirm_lease_land {
  .lease_land_top {
    display: flex;
    padding-bottom: to(63);
    border-bottom: to(1.57) solid rgba(168, 102, 72, 0.34);

    .land_img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: to(362);
      height: to(362);
      background: url('~@/assets/img/landfi/lease-land-bg.png') no-repeat;
      background-size: 100% 100%;

      > img {
        width: to(280);
        height: to(286);
        object-fit: contain;
        border-radius: to(20);
      }
    }

    .common_item {
      margin-top: to(12);
      color: #763900;
      font-size: to(32);
      font-weight: 500;
    }

    .land_info {
      flex: 1;
      .info_title {
        display: flex;
        justify-content: space-between;
        color: #8D3218;
        font-size: to(57);
        font-weight: 600;

        > span {
          &:nth-child(1) {
            flex: 1;
            margin-right: to(20);
            overflow: hidden;
            text-overflow: ellipsis;
            display:-webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
      
      .lease_no {
        margin-top: to(34);
      }
      .lease_land_no {
        margin-top: to(8);
      }

      .lease_person {
        margin-top: to(12);
        word-break: break-all;
      }
      .lease_ape_person {
        margin-top: to(15);
        word-break: break-all;
      }
      
      .lease_land_manage {
        margin-top: to(23);
      }
      .info_speed {
        display: flex;
        justify-content: space-between;
        margin-top: to(25);
        color: #AD5A18;
        font-size: to(32);
      }
    }
  }

  .lease_land_bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: to(10);
    font-family: YueGenYouShangYueBoHei;
    color: #FFFFFF;
    font-size: to(50);
    font-weight: bold;

    .gemstore_counts {
      padding-right: to(100);
      font-size: to(38);
      color: #A86648;
    }

    .btn_box {
      display: flex;
      margin-top: to(18);

      .cancel {
        margin-right: to(86);
        box-sizing: border-box;
        position: relative;
        width: to(208);
        height: to(92);
        background: linear-gradient(134deg, #A7A7A7 12%, #9A9A9A 65%);
        box-shadow: inset 0px to(6.29) to(6.29) 0px #FFFFFF;
        border: to(1.57) solid #878787;
        border-radius: to(46);
        .inner {
          position: absolute;
          top: to(0);
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          line-height: to(87);
          width: to(205);
          height: to(87);
          background: linear-gradient(137deg, #CDCDCD 29%, #B6B6B6 65%);
          box-shadow: 0px to(6.29) to(15.73) 0px rgba(0, 0, 0, 0.3),inset 0px 0px to(12.59) 0px rgba(255, 255, 255, 0.3),inset 0px to(-4.72) to(4.72) 0px #C8C8C8;
          border-radius: to(46);
        }
      }

      .confirm {
        position: relative;
        min-width: to(468);
        height: to(92);
        border-radius: to(128);
        border: to(1.57) solid #68AE2E;
        box-shadow: inset 0px to(6.29) to(6.29) 0px #CDFF5E;
        background: linear-gradient(113deg, #2BAE47 12%, #68AE2E 53%);
        box-sizing: border-box;

        &.disabled {
          background: linear-gradient(134deg, #A7A7A7 12%, #9A9A9A 65%);
          box-shadow: inset 0px to(6.29) to(6.29) 0px #FFFFFF;
          border: to(1.57) solid #878787;

          .inner {
            background: linear-gradient(137deg, #CDCDCD 29%, #B6B6B6 65%);
            box-shadow: 0px to(6.29) to(15.73) 0px rgba(0, 0, 0, 0.3),inset 0px 0px to(12.59) 0px rgba(255, 255, 255, 0.3),inset 0px to(-4.72) to(4.72) 0px #C8C8C8;
          }
        }

        .inner {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          padding-right: 0 to(20);
          box-sizing: border-box;
          min-width: to(462);
          height: to(87);
          border-radius: to(128);
          background: linear-gradient(0deg, #6FBF19 5%, #AEF172 110%);
          box-shadow: 0px to(6.29) to(15.73) 0px rgba(0, 0, 0, 0.3),inset 0px to(6.29) to(12.59) 0px #BCFF26,inset 0px to(-4.72) to(22.03) 0px #FFFFFF;

          > img {
            margin-top: to(10);
            margin-left: to(-15);
            width: to(146);
            height: to(146);
          }
        }

      }
    }
  }
}

.my_lease_out {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabs_box {
    width: fit-content;

    :global {
      .adm-tabs-tab-wrapper {
        padding-left: 0;
        padding-right: to(83);
      }

      .adm-tabs-tab {
        font-size: to(62);
      }

      .adm-tabs-header {
        border: none;
      }
    }
  }

  .select_box {
    display: flex;
    justify-content: space-between;
    margin-top: to(47);

    .selects {
      display: flex;
    }

    .batch_manage_box {
      display: flex;
      margin-top: to(-20);
      margin-right: to(-20);

      &.show {
        display: flex;
      }

      &.hide {
        display: none;
      }

      .cancel_batch_manage {
        width: to(252);
        height: to(146);
        text-align: center;
        line-height: to(134);
        background: url('~@/assets/img/landfi/lease-cancel-bg.png') no-repeat;
        background-size: 100% 100%;
        color: #FFFFFF;
        font-size: to(50);
        font-weight: bold;
        font-family: YueGenYouShangYueBoHei;
      }

      .batch_manage {
        width: to(318);
        height: to(146);
        background: url('~@/assets/img/landfi/batch-manage-btn.png') no-repeat;
        background-size: 100% 100%;

        &.cancel_lease {
          background: url('~@/assets/img/landfi/cancel-lease.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .lease_list {
    flex: 1;
    margin-top: to(50);
    overflow-y: auto;
  }
}