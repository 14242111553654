$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.land_ape_countdown {
  display: flex;
  align-items: cener;
  font-size: 1.1*$rem;
  color: #AA0000;
  font-weight: 500;

  >span {
    &:nth-child(2n) {
      margin: 0 0.3*$rem;
      width: 1.75*$rem;
      height: 1.75*$rem;
      border-radius: 0.2*$rem;
      background: #FFFFFF;
      text-align: center;
      line-height: 1.75*$rem;
      font-size: 1.2*$rem;
      color: #D10073;
    }
  }
}