$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.share_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .share_container_content {
    width: 80vw;
    //height: 36 *$rem;
    padding-bottom: 2 *$rem;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title {
      margin-top: 1.8 *$rem;
      font-size: 1.8 *$rem;
      font-family: SourceHanSansCN-Bold;
      font-weight: bold;
      color: #333333;
      width: 82%;
    }

    .line{
      width: 82%;
      height: 0.6px;
      background: #D8D8D8;
      margin-top: 1.5 *$rem;
      opacity: 0.5;
    }

    .content {
      width: 82%;
      //height: 10 *$rem;
      margin-top: 2 *$rem;
      font-size: 1.4 *$rem;
      line-height: 2 *$rem;
      letter-spacing: 1px;
      white-space: pre-line;
      overflow: auto;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      color: #333;

      &::-webkit-scrollbar {
        display: flex;
      }
    }

    .btn_container {
      width: 82%;
      //background: yellow;
      margin-top: 4 *$rem;
      height: 5 *$rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .sure_btn {
        width: 36%;
        height: 4 *$rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2 *$rem;
        opacity: 1;
        font-family: PingFangSC-Medium;
        font-size: 1.6 *$rem;
        font-weight: normal;
        color: #FFF;

        &.cancel {
          border-radius: 40px;
          border: 2px solid #DFCFAB;
          color: #CDAC5B;
        }
      ;

        &.sure {
          background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
          color: #624C16;
        }
      }
    }

  }
}
