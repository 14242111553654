$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.block_chain_info_container {
  //width: 100%;
  height: 100%;
  background: #F1F0F5;
  padding: 4vw;
}

.account_msg_container {
  width: 92vw;
  min-height: 100px;
  background: white;
  border: 0.1px solid #fff;
  padding-bottom: 2 *$rem;

  .account_msg_title {
    width: 100%;
    color: #000000;
    font-size: 1.9 *$rem;
    text-align: center;
    font-weight: bold;
    margin-top: 3 *$rem;
  }

  .account_msg_title1 {
    color: #000000;
    font-family: PingFang SC;
    font-size: 1.4 *$rem;
    font-weight: bold;
    line-height: 38px;
    margin-left: 3vw;
    margin-top: 3 *$rem;
  }

  .account_msg_content {
    width: 80vw;
    margin-left: 3vw;
    padding: 1 *$rem;
    border-radius: 10px;
    border: 0.1px solid #fff;
    background: #EEF3FF;

    .account_msg_content_title {
      color: #000;
      font-size: 1.2 *$rem;
      opacity: 0.6;
    }

    .account_address_container {
      margin-top: 10px;
      width: 100%;
      padding-bottom: 1.2 *$rem;

      .account_address {
        color: #000;
        font-size: 1.4 *$rem;
        width: 80%;
        word-break: break-all;
        word-wrap: break-word;
      }

      > img {
        width: 16px;
        height: 18px;
        margin-left: 5px;
        display: inline-block;
      }
    }
  }
}

.account_del_container {
  width: 92vw;
  min-height: 100px;
  background: white;
  margin-top: 1.5 *$rem;
  border: 0.1px solid #fff;
  padding-bottom: 1 *$rem;

}

.account_del_title {
  margin-left: 3vw;
  display: inline-block;
  font-size: 1.4 *$rem;
  font-weight: bold;
  line-height: 2 *$rem;
  color: #000000;
  margin-top: 1 *$rem;
  //background: yellow;
}

.account_del_count {
  line-height: 2 *$rem;
  border-radius: 10px;
  display: inline-block;
  padding: 0 2 *$rem;
  font-size: 1.4 *$rem;
  text-align: center;
  opacity: 1;
  background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
  color: #624C16;
  margin-top: 1 *$rem;
  margin-left: 1 *$rem;
  font-weight: bold;
}

.tab_container {
  width: 88vw;
  min-height: 100px;
  overflow-x: auto;
  margin-left: 2vw;
  margin-top: 1.5 *$rem;
  border-radius: 10px;
  border: 0.1px solid #fff;
}

.del_tab {
  width: 200%;
  background: #EEF3FF;
  padding: 10px 10px 10px;
  border-radius: 10px;
  border: 0.1px solid #fff;
  table-layout: fixed;
  //border-collapse: collapse;

  > tbody {
    > tr {
      > th {
        width: 16%;
        text-align: left;
        height: 20px;
        color: #9E9E9E;
        font-size: 1.2 *$rem;
        font-family: PingFang SC;
      }

      > td {
        width: 16%;
        text-align: left;
        height: 35px;
        color: #000000;
        font-size: 1.2 *$rem;
        font-family: PingFang SC;
        font-weight: normal;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }

}


.tabIndex_container {
  width: 86vw;
  margin-left: 3vw;
  margin-top: 1 *$rem;
  //background: red;

  .tabIndex_container_total {
    font-family: PingFang SC;
    font-size: 1.2 *$rem;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #3D3D3D;
    display: inline-block;

  }
}

.tabIndex {
  width: 100%;
  height: 30px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .tabIndex_left {
    min-width: 40%;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //background: yellow;

    > img {
      width: 6px;
      height: 12px;
      padding: 5px;
      //background: red;
    }

    > p {
      font-family:PingFang SC;
      font-size: 1.3 *$rem;
      line-height: 26.1px;
      letter-spacing: 0px;
      color: #000000;
      font-weight: 500;
      //background: purple;
      padding: 5px;
      width: 10%;
      text-align: center;

    }
  }

  .tabIndex_right {
    position: absolute;
    width: 30%;
    right: 0;
    top: 0;
    height: 100%;
    //background: purple;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
