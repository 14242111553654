$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

html,body,#root {
  height: 100%;
}
.transfer-record {
  background: #F1F0F5;
  height: 100%;
  .tabs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #F1F0F5;
    padding-top: 4*$rem;
    .adm-tabs-tab {
      font-size: 1.6*$rem;
    }
    .adm-tabs {
      --active-line-color: #B89969;
      --active-title-color: #B89969;
    }
    .adm-tabs-header {
      border-bottom: none;
    }
    .adm-tabs-tab-line {
      width: 4.65*$rem;
    }
  }
  .record-list {
    padding-top: 5.5*$rem;
  }
}