@import '@/assets/scss/variable.scss';

.confirmOrder {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #F1F0F5;
    padding-top: 1*$rem;
    .container {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .goodsBox {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            margin: 1*$rem;
            border-radius: 0.5*$rem;
            padding: 1*$rem 0.5*$rem;
            .goodsStore {
                display: flex;
                flex-direction: row;
                align-items: center;
                &>div {
                    &:nth-child(1) {
                        background: linear-gradient(90deg, #FE7F02 0%, #FF5203 100%);
                        border-radius: 0.5*$rem;
                        color: #FFFFFF;
                        font-size: 1.2*$rem;
                        padding: 0 0.5*$rem;
                    }
                    &:nth-child(2) {
                        font-size: 1.5*$rem;
                        font-weight: bold;
                        margin: 0 0.5*$rem;
                    }
                }
            }
            .goodsInfo {
                display: flex;
                flex-direction: row;
                padding: 1*$rem 0;
                .goodsImg {
                    width: 7.5*$rem;
                    height: 7.5*$rem;
                    background: #F1F0F5;
                }
                .goodsDetail {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 0 1*$rem;
                    .goodsName {
                        font-size: 1.3*$rem;
                        font-weight: bold;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .priceCount {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-right: 2*$rem;
                        .goodsPrice {
                            font-size: 1.5*$rem;
                            font-weight: bold;
                            color: #FE7F02;
                            span {
                                font-size: 1.2*$rem;
                            }
                        }
                        :global {
                            .stepper-nft {
                                img {
                                    width: 3*$rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .deliveryInfo {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: #FFFFFF;
            margin: 1*$rem;
            border-radius: 0.5*$rem;
            padding: 1*$rem;
            font-size: 1.4*$rem;
        }
        .orderInfo {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            margin: 1*$rem;
            border-radius: 0.5*$rem;
            padding: 1*$rem;
            font-size: 1.4*$rem;
            .betweenStyle {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                &:not(:last-child) {
                    margin-bottom: 1*$rem;
                }
                >span {
                    &.organer {
                        color: #FE7F02;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        padding: 2*$rem;

        @media screen and (min-height: 780px) {
            padding-bottom: 3.5 * $rem;
        }

        .bottomLeft {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 1.6*$rem;
            font-weight: bold;
            >div {
                color: #FE7F02;
                span {
                    font-size: 1.3*$rem;
                }
            }
        }
        .bottomRight {
            background: linear-gradient(90deg, #FE7F02 0%, #FF5203 100%);
            height: 4.4*$rem;
            width: 12*$rem;
            border-radius: 2.3*$rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8*$rem;
            color: #FFFFFF;

            &:active {
                opacity: 0.8;
            }
        }

    }
}