@function to($px) {
  @return ($px / 2558) * 100vw;
}

.dropdown_select {
  margin-right: to(110);
  color: #763900;
  width: fit-content;

  .dropdown_title {
    display: flex;
    align-items: center;

    .title {
      margin-right: to(12);
      font-size: to(44);
    }

    .content_box {
      position: relative;
    }
    .content_top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 to(23);
      font-size: to(38);
      min-width: to(200);
      height: to(70);
      border-radius: to(35);
      border: to(1.57) solid #FFFFFF;
      background: #FEFAF2;
      box-sizing: border-box;

      > img {
        margin-left: to(23);
        width: to(26);
        height: to(14);
        transition: 0.3s;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
  .dropdown_content {
    position: absolute;
    left: 50%;
    top: to(80);
    z-index: 999;
    transform: translateX(-50%);
    padding-top: to(20);
    font-size: to(38);
    border: to(1.57) solid #FFFFFF;
    background: #FEFAF2;
    box-sizing: border-box;
    width: 100%;
    max-height: 40vh;
    overflow: auto;
    border-radius: to(20);
    display: none;
    transition: 0.3s;

    &.show {
      display: block;
    }

    &.hidden {
      display: none;
    }

    &_item {
      margin-bottom: to(35);
      text-align: center;
      opacity: 1;
      transition: transform 0.3s;
      &:active {
        opacity: 0.5;
      }
    }
  }
  .sub_dropdown_content {
    position: absolute;
    left: 105%;
    top: to(80);
    z-index: 999;
    //transform: translateX(-80%);
    padding-top: to(20);
    font-size: to(38);
    border: to(1.57) solid #FFFFFF;
    background: #FEFAF2;
    box-sizing: border-box;
    width: 100%;
    max-height: 40vh;
    overflow: auto;
    border-radius: to(20);
    display: none;
    transition: 0.3s;

    &.show {
      display: block;
    }

    &.hidden {
      display: none;
    }

    &_item {
      margin-bottom: to(35);
      text-align: center;
      opacity: 1;
      transition: transform 0.3s;
      &:active {
        opacity: 0.5;
      }
    }
  }
}