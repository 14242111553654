$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.return-points {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1 *$rem 2*$rem 0*$rem;
  padding: 1.5*$rem ;
  border-radius: 12px;
  background-color: #fff;
  span {
    color: #333333;
    font-size: 1.5*$rem;
    &:nth-child(2) {
      font-weight: bold;
    }
  }
}
