$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.collection_item {
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 4vw;

  .avatar {
    .picture {
      position: relative;

      img {
        border-radius: 16px;
        width: 92vw;
        height: 92vw;
      }
    }

    .sale-before {
      position: absolute;
      top: 17px;
      left: 0;
      padding: 3px 7px;
      display: flex;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 13px;
      opacity: 1;
      margin-left: 15px;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ddcb90;
      line-height: 17px;

      .icon {
        width: 15px;
        height: 15px;
        background: url(~@/assets//img/home/salebefore.png);
        background-size: 15px 15px;
        margin-right: 3px;
        line-height: 17px;
      }
    }

    .sale-out {
      position: absolute;
      top: 17px;
      left: 0;
      padding: 3px 7px;
      display: flex;
      margin-left: 15px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 13px;
      opacity: 1;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #aeaeae;
      line-height: 17px;
      .icon {
        width: 15px;
        height: 15px;
        background: url(~@/assets//img/home/saleout.png);
        background-size: 15px 15px;
        margin-right: 3px;
        line-height: 17px;
      }
    }
    .saleing {
      position: absolute;
      top: 17px;
      left: 0;
      padding: 3px 7px;
      display: flex;
      margin-left: 15px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 13px;
      opacity: 1;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #e8675f;
      line-height: 17px;
      .icon {
        width: 15px;
        height: 15px;
        background: url(~@/assets//img/home/fire.png);
        background-size: 15px 15px;
        margin-right: 3px;
        line-height: 17px;
      }
    }
  }

  .detail-text {
    padding: 15px 4vw 17px;
    position: relative;
    overflow: hidden;
    background: url("~@/assets/img/home/bgline.png") no-repeat right 0px bottom 0px / 148px 89px;

    .caption {
      padding-bottom: 17px;
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
    }

    .bt {
      .num {
        float: left;
        padding: 3px 12px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #614d1a;
        background: linear-gradient(90deg, #c7b270 0%, #f8ebb7 100%);
        border-radius: 13px 13px 13px 13px;
      }

      .price {
        float: right;
        font-size: 20px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
}
