$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.session-detail-acty-container {
  background: linear-gradient(180deg, #DFFAFD 0%, #A9D2FF 100%);
  min-height: 100%;
  padding-bottom: 2 *$rem;

  .session-detail-header {
    width: 100%;
    height: 5 *$rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > img {
      width: 1.6 *$rem;
      height: 1.6 *$rem;
      padding: 6px;
    }

    > p {
      font-family: PingFangSC-Medium;
      font-size: 2 *$rem;
      font-weight: normal;
      background: linear-gradient(113deg, #38A7FF -17%, #2B61F5 81%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin: 0 6px;
    }


  }

  .session-detail-title-container {
    width: 90%;
    height: 5 *$rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > :nth-child(1) {
      font-family: 思源黑体;
      font-size: 1.2 *$rem;
      color: #2E63F6;
    }

    > :nth-child(2) {
      width: 8.4 *$rem;
      height: 3 *$rem;
      border-radius: 46px;
      background: #2E63F6;
      font-size: 1.2 *$rem;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .session-detail-con-title {
    width: 80%;
    padding: 5px 2 *$rem;
    margin: 0 auto;
    border-radius: 100px;
    background: #EC5470;
    font-family: 思源黑体;
    font-size: 1.1 *$rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 0.7 *$rem;
  }

  .session-detail-acty-no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10 *$rem;

    > img {
      width: 30%;
      height: 30%;
    }
    >p {
      font-family: PingFangSC-Semibold;
      font-size: 1.2 *$rem;
      font-weight: normal;
      margin-top: 1 *$rem;
      color: #9E9E9E;
    }
  }
}
