$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.total-info-box {
    display: flex;
    flex-direction: column;
    margin: 0 1.5*$rem 1.5*$rem;
    padding: 1.5*$rem;
    border-radius: 0.5*$rem;
    background-color: #fff;

    .price-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        >span {
            font-size: 1.5*$rem;
            color: #333;
        }
        .nft_spacebt_right {
            font-size: 1.5*$rem;
            color: #333;
            font-family: PingFangSC-Semibold;
        }
    }

    .nft_pricetotal {
        margin-top: 1.5*$rem;
        padding-top: 1*$rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 0.5px solid #f2f2f2;
        .nft_total {
            font-size: 1.3*$rem;
            color: #333;
        }
        .nft_price_left {
            font-size: 1.5*$rem;
            color: #333;
            font-family: PingFangSC-Semibold;
        }
    }
}