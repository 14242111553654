@import '@/assets/scss/variable.scss';

.container {
  max-width: px2rem(750);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: px2rem(30);
  display: flex;
  align-items: center;
  &.disabled {
    opacity: 0.5;
  }
  .ticket {
    width: px2rem(650);
    height: px2rem(170);
    background: url(./icon-bg.png) no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    font-size: px2rem(32);
    .amountWrap {
      width: px2rem(190);
      box-sizing: border-box;
      padding-left: px2rem(40);
      white-space: 0;
    }
    .amount {
      font-size: px2rem(64);
      // margin-left: px2rem(4);
    }
    .name {
      padding-left: px2rem(24);
    }
    .seal {
      width: px2rem(118);
      height: px2rem(118);
      position: absolute;
      bottom: px2rem(12);
      right: px2rem(21);
    }
  }

  .radio {
    width: px2rem(36);
    height: px2rem(36);
    margin-left: px2rem(17);
  }
}