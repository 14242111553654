$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.parts-recycling-rule-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  .rules-modal-common {
    .modal-container {
      padding: 1.4*$rem 2.4*$rem 1.9*$rem;
      width: 33*$rem;
      height: 41*$rem;
      background: #FFFFFF;
      border-radius: 1*$rem;
      display: flex;
      flex-direction: column;
      background: linear-gradient(141deg, #E6C2FF 0%, #5194FF 25%, #B9FFFF 100%);
      box-sizing: border-box;
      .modal-title {
        padding-bottom: 1.1*$rem;
        color: #FFFFFF;
        font-size: 1.8*$rem;
        font-weight: bold;
        text-align: center;
      }
      .modal-content {
        flex: 1;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow-y: auto;
        white-space: pre-line;
        line-height: 1.7*$rem;
        color: #FFFFFF;
        font-size: 1.2*$rem;
      }
      .rules-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1*$rem auto 0;
        width: 15.6*$rem;
        height: 4.1*$rem;
        border-radius: 4.1*$rem;
        background: linear-gradient(90deg, #FFAFD6 1%, #D4209E 102%);
        color: #3D3D3D;
        font-size: 1.8*$rem;
      }
    }
  }
}