@function to($px) {
  @return ($px / 2558) * 100vw;
}

.exchange_land {
  position: relative;
  padding: to(170) to(140) to(143) to(122);
  width: to(1197);
  height: 100%;
  background: url('~@/assets/img/landfi/pop_left.png') no-repeat;
  background-size: 100% 95%;
  background-position: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;

  .close_popup {
    position: absolute;
    top: to(60);
    right: 0;
    >img {
      width: to(98);
      height: to(95);
    }
  }

  .title {
    top: to(-80);
    left: to(80);
  }

  .exchange_tip {
    margin-bottom: to(34);
    color: #8A462C;
    font-weight: bold;
    font-size: to(39);
  }
  .tabs {
    :global {
      --content-padding: 0;
      .adm-tabs-header {
        border-bottom: none;
        box-sizing: content-box;
      }
      .adm-tabs-tab-wrapper {
        margin-right: to(50);
        padding: 0;
        width: fit-content;
      }
      .adm-tabs-tab {
        width: fit-content;
        padding-bottom: 0;
        margin: 0;
      }
      .adm-tabs-tab-line {
        display: none;
      }
      .adm-tabs-tab-wrapper-stretch {
        flex: none;
      }
    }
    .tab_title {
      color: #DC7C00;
      font-family: YueGenYouShangYueBoHei;
      font-size: to(50);
      width: to(184);
      height: to(107);
      text-align: center;
      line-height: to(107);
      border-radius: to(30) to(30) 0 0;
      &.tab_select {
        background: #E3C49C;
        color: #371F00;
      }
    }
  }

  .tabs_content {
    flex: 1;
    display: flex;
    width: 100%;
    overflow: hidden;
    background: #E3C49C;
  }

  .confirm_btn_box {
    display: flex;
    justify-content: flex-end;

    .confirm_btn {
      margin-top: to(33);
      margin-left: to(80);
      width: to(298);
      height:  to(102);
      border-radius: to(40);
      text-align: center;
      line-height: to(102);
      color: #FFFFFF;
      font-size: to(40);
      font-family: YueGenYouShangYueBoHei;
      &.active {
        background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
        background-size: 100% 100%;
      }
      &.disabled {
        background: url('~@/assets/img/landfi/icon_zh.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}


.land_list {
  flex: 1;
  padding: 0 to(60);
  width: 100%;
  overflow: auto;

  .no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: to(120);
    > img {
      width: to(268);
      height: to(215);
    }
  }
}

.list_item {
  display: inline-block;
  width: fit-content;
  margin-top: to(50);
  margin-right: to(55);
  box-sizing: border-box;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .list_item_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    .select_img {
      position: absolute;
      top: 0;
      right: 0;
      width: to(49);
      height: to(49);
    }
    .lease_tag {
      position: absolute;
      top: 0;
      left: 0;
      width: to(61);
      height: to(72);
      background: url('~@/assets/img/landfi/lease-tag.png') no-repeat;
      background-size: 100% 100%;
    }
    .land_img_box {
      display: flex;
      justify-content: center;
      width: to(233);
      height: to(213);
      background: url('~@/assets/img/landfi/land-img-bg.png') no-repeat;
      background-size: 100% 100%;
      .land_c {
        margin-top: to(15);
        width: to(192);
        height: to(151);
      }
      .land_c5 {
        margin-top: to(15);
        width: to(225);
        height: to(155);
      }
      .land_a {
        margin-top: to(-35);
        width: to(194);
        height: to(199);
      }
      .land_b {
        margin-top: to(-10);
        width: to(196);
        height: to(177);
      }
    }
    > p {
      margin-top: to(-25);
      color: #2C2C2C;
      font-size: to(26);
      font-weight: 500;
      width: to(225);
      text-align: center;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}

.confirm_mask {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: to(60) to(120);
  width: to(996);
  height: to(743);
  background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
  background-size: 100% 100%;
  font-family: YueGenYouShangYueBoHei;
  box-sizing: border-box;

  .close_confirm_mask {
    width: to(76);
    height: to(76);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .title {
    color: #602401;
    font-size: to(53);
    font-weight: bold;
  }

  .confirm_tip {
    margin-top: to(118);
    margin-bottom: to(208);
    font-size: to(38);
    font-weight: bold;
    text-align: center;
    color: #BD7638;
  }

  .btn_box {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .common_btn {
    width: to(220);
    height:  to(63);
    border-radius: to(40);
    text-align: center;
    line-height: to(63);
    color: #FFFFFF;
    font-size: to(38);
    font-family: YueGenYouShangYueBoHei;
  }

  .cancel_btn {
    @extend .common_btn;
    background: url('~@/assets/img/landfi/icon_zh.png') no-repeat;
    background-size: 100% 100%;
    
  }

  .confirm_btn {
    @extend .common_btn;
    background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
    background-size: 100% 100%;
  }
}