@function to($px) {
  @return ($px / 2558) * 100vw;
}

.my_lands_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .my_lands_container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: to(59) to(153) to(91) to(200);
    width: to(2318);
    height: to(1092);
    background: url('~@/assets/img/landfi/land-detail-bg.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: to(44);
      right: to(98);
      width: to(90);
      height: to(90);
    }

    .my_lands_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: to(98);
      height: to(108);
      font-family: YueGenYouShangYueBoHei;
      font-weight: bold;
      box-sizing: border-box;

      .header_left {
        color: #371F00;
        font-size: to(60);
      }

      .header_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #792B06;
        background: url('~@/assets/img/landfi/icon_receive2.png') no-repeat;
        background-size: 100% 100%;
        width: to(291);
        height: to(108);

        >span {
          &:nth-child(1) {
            font-size: to(48);
            font-weight: bold;
          }
          &:nth-child(2) {
            font-size: to(24);
          }
        }
      }
    }

    .my_lands_content {
      flex: 1;
      display: flex;
      margin-top: to(37);

      .lands_list_box {
        flex: 1;
        height: to(800);
        background: #E3C49C;
        overflow: auto;

        .land_list {
          padding: 0 to(25);
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          overflow-y: auto;
          overflow-x: hidden;
        
          .no_data {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: to(255);
            box-sizing: border-box;
            > img {
              width: to(268);
              height: to(215);
            }
          }
        }

        .list_item {
          display: inline-block;
          width: fit-content;
          margin-top: to(68);

          .list_item_inner {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: to(430);

            .lease_tag {
              position: absolute;
              top: to(40);
              left: to(18);
              width: to(61);
              height: to(72);
              background: url('~@/assets/img/landfi/lease-tag.png') no-repeat;
              background-size: 100% 100%;
            }

            .img_bg {
              display: flex;
              align-items: center;
              justify-content: center;
              background: url('~@/assets/img/landfi/item-bg.png') no-repeat;
              background-size: 100% 100%;
              width: to(378);
              height: to(237);

              .land_img {
                margin-bottom: to(20);
                width: to(244);
                height: to(193);
              }

            }

            .used_mark {
              position: absolute;
              top: to(25);
              right: 0;
              width: to(130);
              width: to(104);
            }

            > p {
              margin-top: to(9);
              color: #2C2C2C;
              font-size: to(24);
              font-weight: 500;
              width: to(400);
              text-align: center;
              word-break: break-all;
              overflow: hidden;
              text-overflow: ellipsis;
              display:-webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .up_land_btn {
              margin-top: to(9);
              width: to(132);
              height: to(48);
              &.up_active {
                background: url('~@/assets/img/landfi/up-active.png') no-repeat;
                background-size: 100% 100%;
              }
              &.up_disabled {
                background: url('~@/assets/img/landfi/up-disabled.png') no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }

    .tabs {
      :global {
        --content-padding: 0;
        .adm-tabs-header {
          border-bottom: none;
          box-sizing: content-box;
        }
        .adm-tabs-tab-list {
          display: flex;
          flex-direction: column;
        }
        .adm-tabs-tab-wrapper {
          padding: 0;
          width: fit-content;
        }
        .adm-tabs-tab {
          width: fit-content;
          padding-bottom: 0;
          margin: 0;
        }
        .adm-tabs-tab-line {
          display: none;
        }
        .adm-tabs-tab-wrapper-stretch {
          flex: none;
        }
      }
      .tab_title {
        color: #DC7C00;
        font-family: YueGenYouShangYueBoHei;
        font-size: to(60);
        width: to(182);
        height: to(135);
        text-align: center;
        line-height: to(135);
        border-radius: to(30) 0 0 to(30);
        &.tab_select {
          background: #E3C49C;
          color: #371F00;
        }
      }
    }
  }
}
