$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}

.transteToGyApeModel {
    position: relative;
    padding: to(70) to(120);
    width: to(1290);
    // height: to(730);
    background: url("~@/assets/img/landfi/landgy/land-detail-bg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .model_close {
        position: absolute;
        top: to(30);
        right: to(30);
        width: to(88);
        height: to(88);
    }

    .model_title {
        font-family: YueGenYouShangYueBoHei;
        color: #FFFFFF;
        font-weight: bold;
        font-size: to(56);
        text-shadow: 0 0 0 #FFFFFF;
    }

    .count_show {
        border-radius: to(10);
        background: #B0A3C4;
        width: to(454);
        height: to(82);
        margin-top: 5px;
        font-size: to(30);
        color: #200334;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .model_conent {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        >img {
            margin-top: to(20);
            width: to(250);
            height: to(200);
        }

        >span {
            color: #FEE766;
            font-family: YueGenYouShangYueBoHei;
            text-shadow: 0 0 0 #FEE766;
            font-size: to(44);
            font-weight: bold;
            margin-top: to(20);
        }
    }

    .model_btns {
        display: flex;
        flex-direction: row;
        margin-top: to(10);

        .btn {
            transition: transform 0.3s;
            transform: scale(1);
            width: to(328);
            height: to(134);
            margin: 0 to(30);

            &:active {
                transform: scale(0.88);
            }

            &.cancel {
                background: url("~@/assets/img/landfi/landgy/cancel_btn.png");
                background-size: 100% 100%;
            }

            &.confirm1 {
                background: url("~@/assets/img/landfi/landgy/ok_btn.png");
                background-size: 100% 100%;
            }

            &.confirm2 {
                background: url("~@/assets/img/landfi/landgy/confirm_btn.png");
                background-size: 100% 100%;
            }
        }
    }
}