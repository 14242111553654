body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  background: #f8f8f8;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  android: clipToPadding="true";

  android: fitsSystemWindows="true";
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
form,
input,
fieldset,
legend,
img,
select {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;

}

img {
  display: block;
  border: 0;
}

section,
nav,
header,
footer,
aside {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

strong,
b {
  font-weight: normal;
}

i,
em {
  font-style: normal;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clear:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.clear {
  zoom: 1;
}
html {
  font-size: 20px;
}
