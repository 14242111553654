$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.recovery-success-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .mask-content {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0.75*$rem;
    padding: 0.7*$rem;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 29.8*$rem;
      background: linear-gradient(116deg, #FFE6D9 2%, #FF8352 37%, #FFDFA3 100%);
      border-radius: 0.75*$rem;
      padding: 1*$rem 2.5*$rem 1.5*$rem 2.5*$rem;
      box-sizing: border-box;
      >p {
        color: #FFFFFF;
        font-size: 3.6*$rem;
        letter-spacing: 0.07em;
        font-family: PangMenZhengDao;
        margin: 0 auto 1*$rem auto;
        line-height: normal;
        text-align: center;
      }
      >span {
          color: #FFFFFF;
          font-size: 1.2*$rem;
          line-height: 1.7*$rem;
      }
      .confirm-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8.8*$rem;
        height: 3*$rem;
        border-radius: 1.5*$rem;
        color: #671F1F;
        font-size: 1.5*$rem;
        margin-top: 1.8*$rem;
        background: #FFF6D3;
        font-weight: bold;
      }

    }
    
  }
}