$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.shop-edit-box {
    display: flex;
    flex-direction: row;
    margin: 0 1.5*$rem 1.5*$rem;
    padding: 1.5*$rem;
    border-radius: 0.5*$rem;
    background-color: #fff;
    .sale_display_img {
        width: 9*$rem;
        height: 9*$rem;
        overflow: hidden;
        background: #f9f9f9;
        > img {
            width: 100%;
            object-fit: cover;
        }
    }
    .shop_sale_content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        margin-left: 1.48*$rem;
        >p {
            color: #333;
            font-size: 1.5*$rem;
            font-weight: 500;
        }
        .shop_sale_price {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            > span {
                font-size: 1.6*$rem;
                font-family: PingFangSC-Semibold;
                color: #333;
            }
            .stepper-content {
                display: flex;
                flex-direction: column;
                >span {
                    margin-left: 0.5*$rem;
                    color: #ff0000;
                    font-size: 0.9*$rem;
                    margin-bottom: 1.1*$rem;
                }
                .stepper-nft {
                    > span {
                        font-size: 1.2*$rem;
                        color: #333;
                        padding: 0;
                        width: 3*$rem;
                    }
                }
            }
        }
    }

}