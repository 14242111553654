@function to($px) {
  @return ($px / 2558) * 100vw;
}

.recallape_mask {
  display: flex;
  justify-content: center;
  align-items: center;

  .recallape_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: to(60) to(110);
    width: to(995);
    height: to(742);
    background: url('~@/assets/img/landfi/pop_2.png') no-repeat;
    background-size: 100% 100%;
    font-family: YueGenYouShangYueBoHei;
    box-sizing: border-box;
  
    .close_recallape_mask {
      width: to(75);
      height: to(75);
    }
  
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  
    .title {
      color: #371F00;
      font-size: to(53);
      font-weight: bold;
    }
  
    .recallape_tip {
      margin-top: to(100);
      margin-bottom: to(170);
      color: #000000;
      font-size: to(46);
      font-weight: bold;
      text-align: center;
      color: #BD7638;
      width: to(660);
    }
  
    .btn_box {
      display: flex;
      justify-content: space-between;
      width: to(680);
  
      .cancel_btn {
        background: url('~@/assets/img/landfi/icon_zh.png') no-repeat;
        background-size: 100% 100%;
      }
  
      .confirm_btn {
        background: url('~@/assets/img/landfi/btn_def.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  
    .common_btn {
      width: to(298);
      height:  to(102);
      border-radius: to(40);
      text-align: center;
      line-height: to(102);
      color: #FFFFFF;
      font-size: to(40);
      font-family: YueGenYouShangYueBoHei;
    }
  }
}
