$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.shop_paysuccess {
  width: 100vw;
  height: 100%;
  background-color: white;
  position: relative;

  .shop_content {
    width: 100%;
    height: 40 *$rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .shop_img {
      width: 7 *$rem;
      height: 7 *$rem;
      object-fit: cover;
    }
  }

  .shop_pay_success_des0 {
    font-family: PingFangSC-Medium;
    font-size: 1.9 *$rem;
    font-weight: normal;
    letter-spacing: 0em;
    color: #3D3D3D;
    margin-top: 3.5 *$rem;
  }

  .shop_pay_success_des1 {
    font-family: PingFangSC-Light;
    font-size: 1.4 *$rem;
    font-weight: normal;
    letter-spacing: 0em;
    color: #3D3D3D;
  }

  .shop_go_look_father {
    width: 80%;
    height: 4.6 *$rem;
    margin-left: 10%;
    position: absolute;
    bottom: 10 *$rem;
    opacity: 1;
    border-radius: 2.3 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #614D1A;
    font-size: 1.6 *$rem;
    background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
  }
}

