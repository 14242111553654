$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.guess_count-down-component {

  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 0 0.3 *$rem;
  background: linear-gradient(90deg, rgba(94,115,255,0.00) 0%, #5C3EBC 11%, #9244E5 51%, #5E3EBD 88%, rgba(94,115,255,0.00) 100%);

  .count-unity {
    display: inline-block;
    font-size: 8px;
    text-align: center;
    width: 1.4 *$rem;
    height: 1.4 *$rem;
    line-height: 1.4 *$rem;
    border-radius: 0.2*$rem;
    background: #FFFFFF;
    color: #8902D8;
    margin: 0 2px;


    &.long {
      //width: 2.3*$rem;
    }
  }

  .text {
    color: #fff;
    font-weight: bold;
    font-size: 0.9*$rem;
  }
}
