@import '@/assets/scss/variable.scss';
.container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #F1F0F5;
  .list {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2*$rem 1.5*$rem 0 1.5*$rem;

    .itemBox {
      margin-bottom: 1*$rem;;
      border-radius: 1*$rem;
      padding: setRem(38) setRem(28) setRem(54) setRem(28);
      background: #FFFFFF;

      .topBox {
        display: flex;
        align-items: end;

        .imgBox {
          width: setRem(100);
          height: setRem(100);
          border-radius: setRem(10);
          border: 1px solid #979797;
        }

        .nameBox {
          margin-left: setRem(20);
        }

        .nameBox :first-child {
          font-family: PingFang SC;
          font-size: setRem(32);
          font-weight: 600;
          line-height: setRem(32);
          letter-spacing: 0;
          color: #333333;
        }

        .tagBox {
          width: fit-content;
          margin-top: 1.1*$rem;
          border-radius: 200px;
          background: #D8D8D8;
          font-family: PingFang SC;
          font-size: setRem(24);
          font-weight: 600;
          line-height: setRem(38);
          letter-spacing: 0;
          color: #9E9E9E;
          padding: setRem(2) setRem(27);
        }
      }

      .divider {
        margin-top: setRem(20);
        margin-bottom: setRem(28);
        border-bottom: setRem(1) solid #DFDFDF;
      }
      .detailBox {
        display: flex;
        flex-direction: column;
        gap: setRem(40);
        & > div {
          display: flex;
          justify-content: space-between;
        }
        .leftText {
          font-family: PingFang SC;
          font-size: setRem(24);
          font-weight: normal;
          line-height: setRem(26);
          letter-spacing: 0;
          color: #999999;
        }
        .rightText {
          font-family: PingFang SC;
          font-size: setRem(24);
          font-weight: 600;
          line-height: setRem(26);
          text-align: right;
          letter-spacing: 0;
          color: #333333;
        }
      }
    }
  }
}