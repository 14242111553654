@import '@/assets/scss/variable.scss';

.epic_boring_ape_container {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  .item {
    width: px2rem(690);
    margin: 0 auto;
    padding-left: px2rem(30);
    padding-right: px2rem(28);
    border-radius: px2rem(20);
    background-color: #ffffff;
    box-sizing: border-box;
    font-size: px2rem(26);
    color: #999999;
    margin-bottom: px2rem(52);
    .strong {
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .red {
      color: #E83E5D;
    }
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: px2rem(15) 0;
    }
    .rowSub {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: px2rem(94);
      border-top: 1px solid #D8D8D8;
      margin-top: px2rem(15);
    }
    .rowSup {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: px2rem(92);
      border-bottom: 1px solid #D8D8D8;
      margin-bottom: px2rem(15);
    }
  }


}
