@function to($px) {
  @return ($px / 2558) * 100vw;
}

.space_extend {
  width: to(1395);
  height: 100%;
  background: rgba(0, 0, 0, 0.52);
  backdrop-filter: blur(154.55px);
  box-sizing: border-box;
  overflow: hidden;

  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: to(63) to(188);
    color: #FFFFFF;
    font-size: to(60);
    font-weight: bold;
    font-family: YueGenYouShangYueBoHei;

    >span {
      &:nth-child(1) {
        width: to(85);
      }
    }

    >img {
      width: to(88);
      height: to(88);
    }
  }

  .swiper_list {
    margin: 0 auto;
    width: to(261);
    height: to(500);

    :global {
      .adm-swiper {
        overflow: visible;
      }
    }

    .space_item {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      height: to(500);
      color: #FFFFFF;
    }

    .space_item_big {
      position: relative;
      height: to(300);

      .received_tag_big {
        position: absolute;
        left: 50%;
        bottom: to(0);
        transform: translateX(-50%);
        width: to(104);
        height: to(104);
        background: url('~@/assets/img/landfi/big-received.png') no-repeat;
        background-size: 100% 100%;
      }

      .space_item_out {
        display: flex;
        align-items: center;
        height: 100%;

        .space_item_container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: to(206);
          height: to(206);
          border-radius: 50%;
          box-sizing: border-box;
          border: to(3.03) solid #FFDE92;
          &.mode2 {
            border: to(3.03) solid #DDC1F9;
          }

          .space_item_inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: to(180);
            height: to(180);
            border-radius: 50%;
            background: radial-gradient(50% 50% at 50% 50%, #FFD063 0%, #E7B17F 100%);
            box-sizing: border-box;
            border: to(1.52) solid;
            border-image: linear-gradient(155deg, #EBAD40 12%, #FFEDCC 87%);
            box-shadow: 0px to(27.27) to(42.42) 0px rgba(255, 222, 146, 0.33), inset 0px to(1.52) to(54.55) 0px rgba(255, 255, 255, 0.83);
            color: #BB6410;
            text-shadow: 0px to(6.06) to(43.94) #FFFFFF;
            font-size: to(85);
            font-weight: 600;
            &.mode2 {
              background: radial-gradient(50% 50% at 50% 50%, #F0D3FF 0%, #D1ADF6 100%);
              box-shadow: 0 to(27.27) to(42.42) 0 rgba(255, 222, 146, 0.33), inset 0 to(1.52) to(54.55) 0 rgba(255, 255, 255, 0.83);
              color: #200334;
            }
          }
        }
      }
    }

    .space_item_litter {
      position: relative;
      height: to(300);

      .received_tag_litter {
        position: absolute;
        left: 50%;
        bottom: to(25);
        transform: translateX(-50%);
        width: to(88);
        height: to(88);
        background: url('~@/assets/img/landfi/big-received.png') no-repeat;
        background-size: 100% 100%;
      }

      .space_item_out {
        display: flex;
        align-items: center;
        height: 100%;

        .space_item_container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: to(139);
          height: to(139);
          border-radius: 50%;
          box-sizing: border-box;
          border: to(3.03) solid #E1E1E1;

          &.mode2 {
            border: to(3.03) solid #B0A3C4;
          }
        }

        .space_item_inner {
          display: flex;
          align-items: center;
          justify-content: center;
          width: to(121);
          height: to(121);
          border-radius: 50%;
          background: rgba(225, 225, 225, 0.28);
          box-sizing: border-box;
          border: to(1.52) solid rgba(255, 255, 255, 0.49);
          box-shadow: 0px to(27.27) to(42.42) 0px rgba(255, 222, 146, 0.33), inset 0px to(1.52) to(54.55) 0px #E1E1E1;
          color: #522700;
          font-size: to(55);
          font-weight: 600;

          &.mode2 {
            background: rgba(225, 225, 225, 0.28);
            box-shadow: 0 to(27.27) to(42.42) 0 rgba(204, 146, 255, 0.33),inset 0 to(1.52) to(54.55) 0 #B0A3C4;
            color: #200334;
          }
        }
      }
    }
  }

  .connect_line {
    width: to(60);
    border: to(4.55) solid;
    background: #614D2E;
    border-image: linear-gradient(270deg, #614D2E 0%, rgba(207, 157, 94, 0.21) 100%);

    &.mode2 {
      border: none;
      height: to(2);
      background: #D1ADF6;
    }
  }

  .space_land_max {
    color: #EBAD40;
    font-size: to(42);
    font-weight: 500;
    text-align: center;
    margin-top: to(120);
    &.mode2 {
      color: #FFFFFF;
    }
  }

  .btn_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: to(40);
    font-family: YueGenYouShangYueBoHei;

    .received_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: to(606);
      height: to(88);
      border-radius: to(44);
      border: to(1.52) solid rgba(235, 173, 64, 0.35);
      color: #CBFD5D;
      font-size: to(48);
      font-weight: bold;

      >img {
        margin-right: to(36);
        width: to(42);
        height: to(32);
      }
    }

    .pay_btn {
      width: to(887);
      height: to(163);
      background: url('~@/assets/img/landfi/pay-btn-bg.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: to(163);
      color: #FFFFFF;
      font-size: to(59);
      font-weight: bold;
    }
  }
}