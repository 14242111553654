@import '@/assets/scss/variable.scss';

.container {
  width: px2rem(750);
  margin: 0 auto;
  background-color: #f8f8f8;
  padding-bottom: px2rem(200);
  .rowTip {
    background-color: #fff;
    width: px2rem(670);
    box-sizing: border-box;
    padding: px2rem(24) px2rem(35);
    border-radius: px2rem(12);
    margin: px2rem(18) auto px2rem(-20);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: px2rem(30);
    color: #333333;
    :last-child {
      font-weight: 500;
    }
  }
}