@import '@/assets/scss/variable.scss';

.recycle_record {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.2);


  .container {
    width: 60%;
    height: 80%;
    border-radius: 8px;
    background: linear-gradient(121deg, #E6C2FF -5%, #B9FFFF 99%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;

    .header {
      width: 98%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;

      > img {
        width: 2.3 *$rem;
      }

      > p {
        font-family: PingFangSC-Medium;
        font-size: 1.4 *$rem;
        color: #000000;
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: #000;
        opacity: 0.1;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .list {
      flex: 1;
      overflow-y: auto;
      padding-top: 5px;
      box-sizing: border-box;

      .item {
        width: 100%;
        min-height: 100px;
        border-radius: 4px;
        background: linear-gradient(270deg, #C895F4 0%, #7286EB 100%);
        margin-top: 1.2 *$rem;
        box-sizing: border-box;
        padding: 0 20px;

        .top {
          height: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #D8D8D8;
          font-family: PingFangSC-Regular;
          font-size: 1 *$rem;
          color: #FFFFFF;
        }

        .middle {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 1 *$rem;
          padding-bottom: 10px;

          > p {
            font-family: PingFangSC-Semibold;
            font-size: 1.1 *$rem;
            font-weight: normal;
            line-height: 1.6 *$rem;
            color: #FFFFFF;
          }
        }

        .bottom {
          height: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-family: PingFangSC-Semibold;
          font-size: 1.2 *$rem;
          color: #FFFFFF;
          >img{
            width: 1.4 *$rem;
            padding: 5px;
          }
        }
      }
    }
  }
}





