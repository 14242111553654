$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.cartoon-collection-page {
    height: 100vh;
    background: linear-gradient(180deg, #FFFFFF 0%, #F1F0F5 83%);
    .page-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        .tip-view {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1.2*$rem 1.2*$rem 1.2*$rem 1.5*$rem;
            align-items: center;
            margin-top: 1 *$rem;
            .tip-left {
                display: flex;
                flex-direction: row;
                align-items: center;
                > div {
                    line-height: 1.75 *$rem;
                    height: 1.75 *$rem;
                    background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
                    font-size: 1.2 *$rem;
                    text-align: center;
                    border-radius: 0.8 *$rem;
                    padding: 0 0.6*$rem;
                    color: #614D1A;
                    margin-right: 1 *$rem;
                }
                > span {
                    color: #9E9E9E;
                    font-size: 1.4*$rem;
                }

            }
            .tip-right {
                color: #9E9E9E;
                font-size: 1.4*$rem;
            }
        }
        .list-view-body {
            display: flex;
            flex-direction: row;
            flex: 1;
            flex-wrap: wrap;
            padding: 0 0.6*$rem;
            margin-bottom: 4.4*$rem;
        }

    }
    .fixed-button {
        position: fixed;
        height: 4.4*$rem;
        bottom: 1.5 * $rem;
        left: 2.5*$rem;
        right: 2.5*$rem;
        border-radius: 2.2*$rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #C7C7C7;
        
        .title {
            font-family: PingFangSC-Semibold;
            color: #676767;
            font-size: 1.6*$rem;
        }
        .subTitle {
            color: #676767;
            font-size: 1.2*$rem;
        }

        &.enable {
            background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
            &:active {
                background: linear-gradient(90deg, #D9CB88 0%, #FAF2CE 100%);
            }
            .title {
                font-family: PingFangSC-Semibold;
                color: #614D1A;
                font-size: 1.75*$rem;
            }
        }

    }
    @media screen and (min-height: 780px) {
        .fixed-button {
            bottom: 3 * $rem;
        }
    }
    .adm-modal {
        .adm-modal-wrap {
            width: 100vw;
            max-width: initial;
            >div {
                .adm-modal-body {
                    padding: 0;
                    .adm-modal-content {
                        padding: 0;
                        .alert-body {
                            .alert-content {
                                margin: 0;
                                line-height: 0;
                            }
                        }
                    }
                    .adm-modal-footer-empty {
                        height: 0;
                    }
                }
            }
        }
        .adm-modal-body {
            .alert-body {
                .alert-title {
                    margin-bottom: 0.9*$rem
                }
                .alert-content {
                    margin-bottom: 0;
                }
            }
        
        }
    }
    
}


.CartoonItem {
    display: flex;
    flex-direction: column;
    border-radius: 0.6 *$rem;
    overflow: hidden;
    background-color: #FFFFFF;
    margin: 0.5*$rem 0.6*$rem;
    
    padding: 0;
    .img-box {
        position: relative;
        width: 16.8*$rem;
        height: 16.8*$rem;
        img {
            width: 100%;
            height: 100%;
        }
        .mask {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.4);
            padding: 0.6*$rem;
            >span {
                color: #FFFFFF;
                font-size: 1.1*$rem;
                font-family: PingFangSC-Medium;
                background-color: rgba(0,0,0,0.6);
                padding: 0 0.9*$rem;
                height: 2.4*$rem;
                line-height: 2.4*$rem;
                border-radius: 1.2*$rem;
                display: inline-block;
                text-align: center;
            }
        }
    }
    .info-box {
        display: flex;
        flex-direction: column;
        padding: 0.5*$rem 0.7*$rem;
        margin-bottom: 1 * $rem;
        width: 16.8*$rem;
        box-sizing: border-box;
        .name {
            color: #333333;
            font-size: 1.3 *$rem;
            font-weight: 500;
            font-family: PingFangSC-Semibold;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
        }

        .date {
            margin-top: 0.25*$rem;
            color: #777777;
            font-size: 1.1 *$rem;
        }

    }

}