$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.variation-record-page {
  padding: 0 1.7*$rem;
  .common-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reocrd-item {
    margin-top: 1.7*$rem;
    padding: 1.4*$rem 1.5*$rem 2.65*$rem;
    background: #FFFFFF;
    border-radius: 1*$rem;
    .medicine-info {
      display: flex;
      padding-bottom: 0.95*$rem;
      height: 5*$rem;
      border-bottom: 0.1*$rem solid #f1f1f1;
      img {
        margin-right: 1*$rem;
        border-radius: 0.5*$rem;
        height: 5*$rem;
        width: 5*$rem;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.1*$rem 0;
        span {
          &:nth-child(1) {
            color: #333333;
            font-size: 1.6*$rem;
            font-weight: bold;
          }
          &:nth-child(2) {
            color: #999999;
            font-size: 1.2*$rem;
            font-weight: bold;
          }
        }
      }
    }
    .left {
      color: #999999;
      font-size: 1.4*$rem;
    }
    .right {
      padding-top: 1.4*$rem;
      color: #333333;
      font-size: 1.4*$rem;
      width: 100%;
      font-weight: bold;
    }
    .right-material {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .compose-material {
      padding-top: 1.4*$rem;
      .right-material {
        span {
          padding-top: 1.4*$rem;
          &:nth-child(1) {
            padding-top: 0;
          }
        }
      }
    }
    .compose-time {
      margin-top: 2.85*$rem;
    }
    .business-hash {
      margin-top: 2.85*$rem;
      .right-hash {
        color: #165DFF;
      }
    }
  }
}