@import '@/assets/scss/variable.scss';

.huiContainer {
  width: 100%;
  height: 100%;
  //background: orange;
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    //background: red;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;

    .left {
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > img {
        width: 43%;
      }

      > span {
        font-family: PingFang SC;
        font-weight: 600;
        line-height: 25.44px;
        color: #FEE69C;
        font-size: 2.2 *$rem;

        &::before {
          content: '¥';
          font-size: 1.5 *$rem;
        }
      }
    }

    .right {
      width: 40%;
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      > img {
        width: 88%;
      }

      .des {
        font-family: PingFang SC;
        font-size: 1.1 *$rem;
        font-weight: 500;
        text-align: center;
        color: #FFFFFF;
      }

      .btn {
        width: 88%;
        height: 2.7 *$rem;
        border-radius: 356px;
        background: linear-gradient(279deg, #FCD786 0%, #FFE9A0 99%);
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        font-weight: 600;
        color: #BF0808;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
