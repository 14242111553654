$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.sessionCpt-component {
  width: 100%;

  .session_container {
    width: 96%;
    min-height: 80px;
    margin: 2 *$rem auto;
    position: relative;

    .pk_msg_container {
      background: url(../../imgs/pic_time.png) no-repeat;
      background-size: 100% 100%;
      width: 16.9 *$rem;
      height: 6 *$rem;
      object-fit: cover;
      position: relative;
      z-index: 1;

      &.long {
        width: 20 *$rem;
      }

      .session_count {
        display: inline-block;
        font-size: 2.5 *$rem;
        color: white;
        margin-left: 1 *$rem;
        margin-top: 0.3 *$rem;
        font-family: PangMenZhengDao;
      }

      .session_count_0 {
        display: inline-block;
        font-family: 思源黑体;
        font-size: 1.2 *$rem;
        font-weight: bold;
        color: #FFFFFF;
        margin-left: 1.0 *$rem;
        font-weight: bold;
      }

      .countDown {
        width: 82%;
        margin-top: 0.3 *$rem;
      }
    }

    .more_session {
      position: absolute;
      right: 2 *$rem;
      top: -0.3  *$rem;
      font-size: 1.3 *$rem;
      line-height: 44px;
      color: #2E63F6;
      font-family: PingFangSC-Medium;
    }

    .session_info {
      width: 100%;
      //min-height: 15 *$rem;
      border-radius: 20px;
      border: 0.1px solid transparent;
      margin-top: -3 *$rem;
      background: linear-gradient(121deg, #38A7FF -18%, #2B61F5 81%);

      .session_info_container {
        margin: 5px;
        padding-bottom: 1 *$rem;
        border-radius: 20px;
        border: 2px solid #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .juan_count {
          position: absolute;
          right: 1 *$rem;
          top: 0.6 *$rem;
          font-size: 1.2 *$rem;
          color: #fff;
        }

        .congratulation_container {
         position: relative;
          width: 86%;
          height: 4.5 *$rem;
          object-fit: cover;
          margin-top: 4 *$rem;
          //background-color: yellow;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          > img {
            width: 1.5 *$rem;
            height: 1.5 *$rem;
            margin-top: -0.3 *$rem;
          }

          .con_title {
            //width: 94%;
            width: 100%;
            height: 3 *$rem;
            background-color: #EC5470;
            font-family: 思源黑体;
            font-size: 1 *$rem;
            font-weight: normal;
            text-align: center;
            border-radius: 100px;
            color: #FFFFFF;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 3px;

          }
        }

        .congratulation_title {
          height: 3 *$rem;
          border-radius: 109px;
          opacity: 1;
          background: #fff;
          padding: 0 10px;
          color: #2E63F6;
          font-size: 1.1 *$rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFangSC-Medium;
        }

        .title {
          width: 90%;
          font-family: PingFangSC-Medium;
          font-size: 1.8 *$rem;
          font-weight: normal;
          //line-height: 44.44px;
          text-align: center;
          color: #FFFFFF;
          margin-top: 4 *$rem;

          &.title1 {
            margin-top: 0px;
          }

          &.title2 {
            margin-top: 10px;
          }
        }

        .question_container {
          width: 100%;
          height: 5 *$rem;
          //background-color: white;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          margin-top: 1.2 *$rem;

          .pk_logo {
            width: 3.2 *$rem;
            height: 3.2 *$rem;
            position: absolute;
            z-index: 1;
          }

          .question_a {
            width: 46%;
            height: 5.5 *$rem;
            position: relative;

            &.left {
              margin-left: 10px;
            }

            &.right {
              margin-right: 10px;
            }

            .pk_a_img {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }

            &.bg_div_red {
              width: 40%;
              height: 90%;
              background: linear-gradient(0deg, #F98399 2%, #FF385D 100%);
              border-radius: 146px;
            }

            &.bg_div_white {
              width: 40%;
              height: 90%;
              border-radius: 146px;
              border: 1px solid white;
            }

            &.bg_div_gray {
              width: 40%;
              height: 90%;
              background-color: #D0D0D0;
              border-radius: 146px;

            }

            .question_ab_container {
              width: 100%;
              height: 100%;
              position: relative;
              z-index: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;


              .p1 {
                font-family: PingFangSC-Medium;
                font-size: 1.5 *$rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 80%;
                text-align: center;

                &.blue0 {
                  color: #2E63F6;
                }

                &.white0 {
                  color: #fff;
                }
              }

              .p2 {
                font-family: PingFangSC-Medium;
                font-size: 1 *$rem;
                color: #E83E5D;
                background: #FFFFFF;
                padding: 1px 6px;
                border-radius: 167px;

              }
            }
          }
        }

        .proportion_container {
          width: 85%;
          margin-top: 1 *$rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          img {
            width: 2.5 *$rem;
            height: 2.5 *$rem;
          }
        }

        .proportion_press {
          width: 85%;
          height: 1.6 *$rem;
          border-radius: 20px;
          margin-top: 5px;
          background: linear-gradient(180deg, #F98399 2%, #E73E5D 100%);

          .proportion_press_left {
            width: 60%;
            height: 100%;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(180deg, #C1F9FF 2%, #60A7FF 100%);

            &.all_round{
              border-radius: 20px;
            }
          }
        }

        .proportion_press_count {
          width: 85%;
          margin-top: 0.5 *$rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          > p {
            font-family: PingFangSC-Medium;
            font-size: 1.1 *$rem;
            font-weight: normal;
            color: #FFFFFF;

          }
        }

        .award_record {
          font-family: PingFangSC-Regular;
          font-size: 1.2 *$rem;
          margin-top: 1 *$rem;
          color: #FFFFFF;
          line-height: 2 *$rem;
          //background-color: red;
          padding: 5px 10px 0;
        }
      }
    }
  }
}
