$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.vote_rule_conatner0 {
  .adm-mask-content {
    height: 100%;
  }

  .vote_rule_container {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    .vote_container_content {
      width: 90vw;
      height: 38 *$rem;
      background: #FFFFFF;
      border-radius: 12px;
      border: 1px solid #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .vote_rule_title {
        margin-top: 1.6 *$rem;
        font-size: 1.8 *$rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }

      .vote_rule_content1 {
        width: 90%;
        height: 23 *$rem;
        color: #3D3D3D;
        line-height: 1.8 *$rem;
        letter-spacing: 1px;
        white-space: pre-line;
        overflow-y: scroll;
        //padding-bottom: 6*$rem;
        font-family: PingFangSC-Regular;
        font-size: 1.3 *$rem;
        font-weight: normal;
        margin-top: 1 *$rem;
      }

      .vote_rule_sure_btn_father {
        width: 80%;
        height: 4 *$rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 1.5 *$rem;
        //background-color: red;

        .sure_btn_txt {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55%;
          height: 100%;
          border-radius: 2 *$rem;
          background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);


          span {
            font-size: 1.8*$rem;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
  }
}




