$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.receive-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img-box {
    margin: 30*$rem/10 0 20*$rem/10;
    height: 150*$rem/10;
    width: 150*$rem/10;
    img {
      border-radius: 13*$rem/10;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    color: #333333;
    font-size: 18*$rem/10;
    font-weight: bold;
  }
  .number {
    margin: 2*$rem/10 0 60*$rem/10;
    color: #AB8A36;
    font-size: 14*$rem/10;
  }
  .desc {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30*$rem/10;
    color: #333333;
    font-size: 18*$rem/10;
    font-weight: 500;
    text-align: center;
    &.overtime {
      color: #666666;
      font-size: 14*$rem/10;
    }
    .receive-success {
      height: 31.5*$rem/10;
      width: 40.5*$rem/10;
    }
  }
  .btn {
    margin-top: 99*$rem/10;
    width: 335*$rem/10;
    height: 45*$rem/10;
    line-height: 45*$rem/10;
    text-align: center;
    color: #624C16;
    font-size: 18*$rem/10;
    background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
    border-radius: 36*$rem/10;
  }
}