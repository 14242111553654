$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.buy_30_container {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;

  > img {
    width: 90%;
    margin-top: 7 *$rem;
  }

  > p {
    width: 80%;
    font-family: PingFangSC-Regular;
    font-size: 1.4 *$rem;
    font-weight: normal;
    line-height: 2 *$rem;
    text-align: center;
    color: #9E9E9E;
    margin-top: 7 *$rem;
    white-space: pre-line;
  }


  .buy_30_duihuan_btn {
    position: fixed;
    bottom: 20 *$rem;
    margin: 0 auto;
    width: 19*$rem;
    height: 3.5*$rem;
    background: url(../../../imgs/btn_da.png) no-repeat;
    background-size: 100% 100%;
    font-family: 思源黑体;
    font-size: 1.5 *$rem;
    font-weight: bold;
    text-align: center;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
