@function to($px) {
  @return ($px / 1624) * 100vw;
}

.my_elements_mask {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.ele_list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: to(10) to(59) to(20);
  overflow: hidden;
  
  .tabs {
    :global {
      --content-padding: 0;
      .adm-tabs-header {
        border-bottom: none;
        box-sizing: content-box;
      }
      .adm-tabs-tab-wrapper {
        margin-right: to(57);
        padding: 0;
      }
      .adm-tabs-tab {
        width: fit-content;
        padding-bottom: 0;
        margin: 0;
      }
      .adm-tabs-tab-line {
        display: none;
      }
      .adm-tabs-tab-wrapper-stretch {
        flex: none;
      }
    }
    .tab_title {
      padding: 0 to(17);
      height: to(40);
      line-height: to(40);
      color: #3D3D3D;
      font-size: to(20);
      border-radius: to(8);
      background: linear-gradient(298deg, #FCD786 0%, #FFE9A0 99%);
      opacity: 0.7;
      &.tab_select {
        background: linear-gradient(287deg, #FCD786 0%, #FFE9A0 99%);
        opacity: 1;
      }
    }
  }
}

.list_container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top: to(22);
  overflow-y: auto;  

  .ele_list_item {
    position: relative;
    margin-right: to(35);
    margin-bottom: to(35);
    width: to(140);
    height: to(140);
    border-radius: to(8);
    background: #FFFFFF;

    &:nth-child(6n) {
      margin-right: 0;
    }

    > img {
      width: 100%;
      height: 100%;
      border-radius: to(8);
    }

    > span {
      position: absolute;
      top: 0;
      right: 0;
      width: to(40);
      height: to(40);
      border-radius: to(8);
      border: to(1) solid #E75F4D;
      background: #933225;
      color: #FFFFFF;
      font-size: to(28);
      font-weight: 600;
      text-align: center;
      line-height: to(40);
    }
  }
}