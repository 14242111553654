$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.variation-invite-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: 'rgba(0, 0, 0, 0.7)'
}
.invite_modal_container2 {
  width: 100%;
  min-height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .share_body {
    width: 33*$rem;
    border-radius: setRem(28);
    margin-top: 6*$rem;
    position: relative;
    // border-radius: 2*$rem;
    overflow: hidden;
    .share_content {
      position: relative;
      width: 100%;
      .center_img_container {
        display: flex;
        width: 100%;
        justify-content: center;
        position: absolute;
        top: setRem(255);
        >img {
          width: setRem(520);
          height: setRem(509);
        }
      }
      .center_text_container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: setRem(264);
        >span {
          font-family: PangMenZhengDao;
          font-size: setRem(44);
          font-weight: 400;
          letter-spacing: 0em;
          color: #D82828;
        }

      }
      .center_img_container2 {
        display: flex;
        width: 100%;
        justify-content: center;
        position: absolute;
        top: setRem(481);
        >img {
          width: setRem(257);
          height: setRem(194);
        }
      }
      .share_img_box {
        display: flex;
        justify-content: center;
        align-items: center;
        .share_avatar {
          width: 100%;
          height: 46.2*$rem;
        }
      }
      .share_detail2 {
        position: absolute;
        right: 4%;
        bottom: 1%;
        width: 7*$rem;
        height: 7*$rem;
        border-radius: 0.8*$rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        canvas {
          max-width: 6*$rem;
          max-height: 6*$rem;
        }
      }
    }
  }
  .invite-opt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw;
    margin-top: 3*$rem;
    img {
      width: 4.6*$rem;
      height: 4.6*$rem;
    }
    .wechat,
    .share,
    .copy,
    .download {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.2*$rem;
      color: #fff;
      span {
        margin-top: 0.8*$rem;
      }
    }
  }
}

.cancel_btn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 5*$rem;
  overflow: hidden;
  margin-top: 3*$rem;
  border-radius: 2.5*$rem;
  background-color: #262626;;
  font-size: 1.6*$rem;
  font-weight: bold;
  color: #C1C1C1;
}
