$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.recovery-parts-page {
  display: flex;
  flex-direction: column;
  background: #4F78FF;
  height: 100vh;
  overflow: hidden;
  .recovery-material-container {
    padding: 2.85*$rem 1.25*$rem 0.65*$rem;
    .material-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .text-box {
        .material-item {
          height: 2.24*$rem;
        }
      }
      .equal-sign-left {
        display: flex;
        align-items: center;
        justify-content: center;
        .material-item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.8*$rem;
          &:nth-child(3) {
            margin-right: 0;
          }
          width: 8.95*$rem;
          .material-collection-img {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            background: #FFFFFF;
            width: 8.95*$rem;
            height: 8.95*$rem;
            background: url('../imgs/border-bg.png') no-repeat;
            background-size: 100% 100%;
            .material-img {
              border-radius: 0.5*$rem;
              width: 8.8*$rem;
              height: 8.8*$rem;
            }
            .add-sign {
              width: 3*$rem;
              height: 3*$rem;
            }
            .delete-img {
              position: absolute;
              top: 0.4*$rem;
              right: 0.45*$rem;
              width: 1.6*$rem;
              height: 1.6*$rem;
            }
          }
          .collection-num {
            color: #FFFFFF;
            font-size: 1.2*$rem;
            line-height: 2.24*$rem;
            font-weight: bold;
            width: 8.95*$rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
      .equal-sign-middle {
        margin-right: 0.7*$rem;
        width: 2.55*$rem;
        img {
          width: 2.55*$rem;
          height: 1.65*$rem;
        }
      }
      .equal-sign-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8.5*$rem;
        img {
          width: 8.5*$rem;
          height: 8.5*$rem;
          border: 0.05*$rem solid #24FAAF;
        }
        span {
          color: #24FAAF;
          font-size: 1.2*$rem;
          font-weight: bold;
          line-height: 2.24*$rem;
        }
      }
    }
    .recycling-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1.2*$rem auto 0;
      color: #767586;
      font-size: 1.5*$rem;
      width: 27*$rem;
      height: 3.6*$rem;
      border-radius: 1.8*$rem;
      background: #D8D8D8;
      font-weight: bold;
      &.available {
        background: #D3F1FF;
        color: #004FC4;
      }
    }
  }
  .epic-funds-collection {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 1.45*$rem;
    padding: 1.75*$rem 2*$rem 0;
    border-radius: 2*$rem 2*$rem 0 0;
    background: linear-gradient(144deg, #E6C2FF 0%, #5194FF 37%, #B9FFFF 100%);
    overflow-y: auto;
    .choose-tip {
      color: #000000;
      font-size: 1.6*$rem;
      line-height: 2.24*$rem;
      font-weight: bold;
    }
    .epic-funds-list {
      flex: 1;
      margin-top: 1.5*$rem;
      overflow-y: auto;
      .parts-item {
        display: inline-block;
        margin-bottom: 1.1*$rem;
        margin-left: 0.9*$rem;
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }
      .epic-funds-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7.7*$rem;
        height: 7.7*$rem;
        box-sizing: border-box;
        border-radius: 0.5*$rem;
        &.active {
          &::after {
            content: '';
            display: block;
            background: url('../imgs/kuang_sel.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 7.7*$rem;
            height: 7.7*$rem;
          }
        }
        &.disabled {
          &::after {
            content: '';
            display: block;
            background: rgba(0, 0, 0, .7);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 0.5*$rem;
          }
        }
        img {
          border-radius: 0.5*$rem;
          width: 7.7*$rem;
          height: 7.7*$rem;
        }
      }
      .epic-num {
        text-align: center;
        margin-top: 0.55*$rem;
        width: 7.7*$rem;
        color: #FFFFFF;
        font-size: 1.2*$rem;
        line-height: 2.24*$rem;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .adm-modal {
    .adm-modal-wrap {
      width: 100vw;
      max-width: initial;
      >div {
        .adm-modal-body {
          padding: 0;
          .adm-modal-content {
            padding: 0;
            .alert-body {
              .alert-content {
                margin: 0;
                line-height: 0;
              }
            }
          }
          .adm-modal-footer-empty {
            height: 0;
          }
        }
      }
    }
  }
}