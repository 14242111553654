@import '@/assets/scss/variable.scss';

.upgrade_s {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  
  &_content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1.75*$rem 1.5*$rem 0;
    width: 100%;
    background: linear-gradient(153deg, #C2F8E8 0%, #559BFE 100%);
    box-sizing: border-box;
    overflow: hidden;

    > h1 {
      color: #000000;
      font-size: 1.6*$rem;
      font-weight: 500;
    }

    .workape_list {
      margin-top: 1.2*$rem;
      flex: 1;
      width: 100%;
      overflow-y: auto;
    }

    .upgrade_btn {
      position: absolute;
      left: 50%;
      bottom: 7.3*$rem;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25.4*$rem;
      height: 4*$rem;
      border-radius: 2*$rem;
      background: linear-gradient(270deg, #1150FF 0%, #47FFF9 100%);

      &.disabled {
        background: linear-gradient(270deg, #8D8D8D 0%, #D6D6D6 100%);
        .btn_inner {
          pointer-events: none;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 99%);
        }
      }

      .btn_inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23*$rem;
        height: 3.5*$rem;
        border-radius: 2*$rem;
        color: #FFFFFF;
        font-size: 1.5*$rem;
        font-weight: bold;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 99%);
      }
    }
  }

  .list_item_box {
    display: inline-block;
    margin-right: 0.5*$rem;
    margin-bottom: 0.9*$rem;

    &:nth-child(4n) {
      margin-right: 0;
    }

    .list_item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .img_box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8.2*$rem;
        height: 8.2*$rem;

        &.active {
          background: url('~@/assets/img/collectionRecoveryLand/kuang.png');
          background-size: 100% 100%;
        }

        &.disabled {
          &::after {
            content: '';
            display: block;
            background: rgba(0, 0, 0, .4);
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0.5*$rem;
            margin: 0.2*$rem;
          }
        }

        .nft_img {
          border-radius: 0.5*$rem;
          width: 7.7*$rem;
          height: 7.7*$rem;
        }

        .select_img {
          position: absolute;
          width: 1.25*$rem;
          height: 1.25*$rem;
          top: 0.65*$rem;
          left: 0.65*$rem;
        }
      }

      .nft_no {
        width: 7.7*$rem;
        text-align: center;
        color: #FFFFFF;
        font-size: 1.2*$rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        box-sizing: border-box;
        line-height: 2.24*$rem;
      }
    }
  }
}

.upgrade_confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27.9*$rem;
  height: 21.6*$rem;
  border-radius: 1*$rem;
  background: #FFFFFF;

  .title {
    margin-top: 1.3*$rem;
    margin-bottom: 1*$rem;
    color: #333333;
    font-size: 1.6*$rem;
    font-weight: 600;
  }

  .s_stock {
    color: #737373;
    font-size: 1.2*$rem;
    text-align: center;
  }

  .tip {
    margin-top: 3.5*$rem;
    width: 80%;
    color: #333333;
    font-size: 1.4*$rem;
    text-align: center;
  }

  .btn_box {
    margin-top: 2.8*$rem;
    padding-top: 0.9*$rem;
    display: flex;
    align-items: center;
    width: 90%;
    border-top: 0.05*$rem solid #D8D8D8;
    box-sizing: border-box;

    .btn_cancel {
      flex: 1;
      height: 3*$rem;
      text-align: center;
      line-height: 3*$rem;
      color: #999999;
      font-size: 1.5*$rem;
      border-right: 0.05*$rem solid #D8D8D8;
    }

    .btn_confirm {
      flex: 1;
      height: 3*$rem;
      text-align: center;
      line-height: 3*$rem;
      color: #CAA846;
      font-size: 1.5*$rem;
    }
  }
}

.upgrade_success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27.9*$rem;
  height: 31.4*$rem;
  border-radius: 1*$rem;
  background: #FFFFFF;

  .title {
    margin-top: 1.5*$rem;
    color: #333333;
    font-size: 1.6*$rem;
    font-weight: 600;
  }

  .success_img {
    margin-top: 5*$rem;
    width: 14.4*$rem;
    height: 14.4*$rem;
  }

  .btn_box {
    margin-top: 3*$rem;
    padding-top: 0.9*$rem;
    display: flex;
    align-items: center;
    width: 90%;
    border-top: 0.05*$rem solid #D8D8D8;
    box-sizing: border-box;

    .btn_cancel {
      flex: 1;
      height: 3*$rem;
      text-align: center;
      line-height: 3*$rem;
      color: #999999;
      font-size: 1.5*$rem;
      border-right: 0.05*$rem solid #D8D8D8;
    }

    .btn_confirm {
      flex: 1;
      height: 3*$rem;
      text-align: center;
      line-height: 3*$rem;
      color: #CAA846;
      font-size: 1.5*$rem;
    }
  }
}