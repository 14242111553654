$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.count-down-component {
  
  text-align: center;
  
  .count {
    display: inline-block;
    margin: 0 0.5*$rem;
    width: 1.75*$rem;
    height: 1.75*$rem;
    text-align: center;
    line-height: 1.75*$rem;
    border-radius: 0.2*$rem;
    background: #FFFFFF;
    color: #8902D8;
    font-size: 1.2*$rem;
  }
  .text {
    color: #03009F;
    font-weight: bold;
    font-size: 1.1*$rem;
  }
}