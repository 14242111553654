@import '@/assets/scss/variable.scss';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: #F8F8F8;

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 3 *$rem;

    .top_item {
      width: 19vw;
      height: 19vw;
      position: relative;
      border-radius: 6px;
      overflow: hidden;

      > img {
        width: 100%;
        height: 100%;
        background-color: lightgray;
      }

      .top_item_toast {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #000;
        opacity: 0.4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        > img {
          width: 2*$rem;
          height: 2 *$rem;
        }

        > p {
          font-family: PingFang SC;
          font-size: 1.1 *$rem;
          color: #FFFFFF;
          margin-top: 0.5 *$rem;

        }
      }


    }
  }

  .hc_btn {
    width: 50%;
    height: 4 *$rem;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4 *$rem auto 4 *$rem;
    background: #D7D7D7;
    font-family: 思源黑体;
    font-size: 1.5 *$rem;
    font-weight: bold;
    color: #747474;

    &.can {
      background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
      color: #624C16;
    }
  }

  .bottom {
    flex: 1;
    min-height: 100px;
    background-color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .bottom_title {
      width: 100%;
      text-align: center;
      font-family: PingFang SC;
      font-size: 1.6 *$rem;
      font-weight: 500;
      line-height: 6 *$rem;
      color: #000000;

    }
  }

  .titles {
    width: 80%;
    height: 3 *$rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .title_item {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.borderright {
        border-right: 1px solid #000000;
      }

      > p {
        font-family: PingFang SC;
        font-size: 1.4 *$rem;
        font-weight: 500;
        color: #ADADAD;

        &.isChoice {
          color: #000000;
        }
      }
    }
  }

  .list {
    height: 100%;
    overflow-y: scroll;

    .item {
      display: inline-block;
      width: 28vw;
      height: 28vw;
      margin-left: 4vw;
      margin-top: 2 *$rem;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      box-sizing: border-box;

      &.isChoiceItem {
        border: 2px solid #F0D24F;
      }


      > img {
        width: 100%;
      }

      > p {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        background-color: #000000;
        opacity: 0.7;
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        color: #FFFFFF;
        text-align: center;
        padding: 0 3px;
        box-sizing: border-box;
        line-height: 1.5 *$rem;
        word-wrap: break-word;
      }
    }
  }
}
