@import '@/assets/scss/variable.scss';

.container {
  margin: px2rem(37) auto 0;
  width: px2rem(670);
  padding: px2rem(30) px2rem(35) px2rem(8);
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFFFFF;
  font-size: px2rem(30);
  color: #333333;
  .row {
    display: flex;
    padding: px2rem(12) 0;
    align-items: center;
    justify-content: space-between;
  }
  .greyText {
    color: #A1A1A1;
    font-size: px2rem(24);
  }
  .icon {
    width: px2rem(36);
    height: px2rem(36);
  }
  .discountBox {
    padding-bottom: px2rem(38);
    .smaller {
      font-size: px2rem(24);
    }
  }
  .footer {
    display: flex;
    border-top: 1px solid #F2F2F2;
    padding: px2rem(24) 0;
    align-items: center;
    justify-content: space-between;
    .greyText {
      transform: scale(0.91);
    }
  }
}