@import '@/assets/scss/variable.scss';
.batchTransform {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    .batchContent {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .bgimg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            object-fit: contain;
        }
        .batchList {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 3*$rem 0;
            overflow-y: auto;
            .batch_item {
                margin: 0.6*$rem 1.5*$rem;
                border-radius: 0.8*$rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 1*$rem;
                background: rgba(255, 255, 255, 0.8);
                .check {
                    margin: 1*$rem;
                    
                    :global {
                        .adm-radio {
                            .adm-radio-icon {
                                background: #D8D8D8;
                            }
                        }
                        .adm-radio-checked {
                            .adm-radio-icon {
                                background: #CEBF7D;
                                border-color: #CEBF7D;
                            }
                        }
                    }
                }
                .item_content {
                    display: flex;
                    flex-direction: row;
                    margin-left: 1*$rem;
                    .itemImg {
                        width: 6.4*$rem;
                        height: 6.1*$rem;
                        background: #D8D8D8;
                        border-radius: 0.8*$rem;
                    }
                    >div {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 0.8*$rem;
                        >span {
                            font-weight: 500;
                            &:nth-child(1) {
                                color: #000000;
                                font-weight: 600;
                            }
                            &:nth-child(2) {
                                color: #9E9E9E;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
            .empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 8*$rem;
                >img {
                    width: 30*$rem;
                    height: 30*$rem;
                }
                >span {
                    color: #9E9E9E;
                    font-weight: 500;
                    font-size: 1.5*$rem;
                    margin-top: 2*$rem;
                }
               
            }
        }
        
    }
    .bottom_btn {
        padding: 2*$rem 3*$rem 3.5*$rem;
        position: relative;
        :global {
            .adm-radio {
                .adm-radio-icon {
                    background: #D8D8D8;
                }
            }
            .adm-radio-checked {
                .adm-radio-icon {
                    background: #CEBF7D;
                    border-color: #CEBF7D;
                }
            }
        }
        
        .btn {
            height: 4.5*$rem;
            border-radius: 2.5*$rem;
            // width: 30.8*$rem;
            margin: 3*$rem 0 1*$rem;
            background: linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #614D1A;
            font-weight: 500;
            font-size: 1.6*$rem;
            &:active {
                opacity: 0.8;
            }
        }
        >span {
            color: #9E9E9E;
            font-weight: 500;
            font-size: 1.6*$rem;
            margin: 0 auto;
            display: block;
            text-align: center;
        }
    }
    
}