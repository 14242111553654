$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.egg_hc_container {
  .adm-mask-content {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .egg_hc_container0 {
    width: 100vw;
    //height: 100%;
    //min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: red;

    .egg_hc_container_content {
      width: 80vw;
      //min-height: 60vh;
      background: linear-gradient(180deg, #05512E 0%, #09170A 100%);
      border-radius: 12px;
      border: 1px solid #5AF8AB;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .egg_hc_title {
        margin-top: 1.8 *$rem;
        font-family: PingFangSC-Semibold;
        font-size: 1.5 *$rem;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0px;
        color: #8EF4B1;
      }

      .egg_hc_mid_content {
        width: 86%;
        //max-height: 17 *$rem;
        margin-top: 1 *$rem;
        font-size: 1.1 *$rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8EF4B1;
        line-height: 1.6 *$rem;
        letter-spacing: 1px;
        white-space: pre-line;
        //background-color: red;
        overflow-y: scroll;

        .egg_hc_mid_content_title {
          width: 96px;
          //height: 30px;
          opacity: 1;
          font-family: PingFangSC-Regular;
          font-size: 1.2 *$rem;
          font-weight: normal;
          letter-spacing: 0px;
          line-height: 30px;
          color: #8EF4B1;
          margin-left: 10px;
          //background-color: yellow;
        }

        .egg_hc_mid_content_list {
          //display: flex;
          //flex-direction: row;
          //background-color: yellow;
          //max-height: 12 *$rem;
          //background-color: red;

          img {
            width: 5 *$rem;
            height: 5 *$rem;
            object-fit: contain;
            display: inline-block;
            //border: 6px;
            border-radius: 6px;
            margin-top: 0.5 *$rem;
            margin-left: 10px;
            background-color: whitesmoke;
          }
        }

        .egg_hc_mid_content_title1_father {
          width: 90%;
          margin-top: 2 *$rem;
          margin-left: 2 *$rem;
          opacity: 1;
          font-family: PingFangSC-Regular;
          font-size: 1.2 *$rem;
          font-weight: normal;
          line-height: 1.8 *$rem;
          letter-spacing: 0px;
          //background-color: purple;

          .egg_hc_mid_content_title1_red {
            color: #FF5E28;
          }
        }
      }

      .egg_hc_img_father{
        width: 12 *$rem;
        height: 12 *$rem;
        position: relative;
      }

      .egg_hc_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        //background-color: blue;
        margin-top: 1.5 *$rem;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
      }


      .egg_hc_sure_cancel_btn_father {
        width: 84%;
        height: 4 *$rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2 *$rem;
        padding-bottom: 1 *$rem;


        .egg_hc_sure_btn_txt {
          width: 9 *$rem;
          height: 3 *$rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          span {
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;
            left: 0;
            top: -0.1 *$rem;
            font-size: 1.6 *$rem;
            font-weight: 400;
            color: #000000;
            font-family: RuiZiZhenYan;
            display: flex;
            align-items: center;
            justify-content: center;

            &.cancel {
              color: #8EF4B1;
            }
          }
        }
      }
    }
  }
}




