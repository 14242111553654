$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.receive-donation {
  display: flex;
  flex-direction: column;
  align-items: center;
  .count-down {
    margin: 20*$rem/10 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 16*$rem/10;
    span {
      margin: 0 12*$rem/10;
    }
    .count {
      height: 27*$rem/10;
      width: 27*$rem/10;
      background: #EFEFEF;
      text-align: center;
      line-height: 27*$rem/10;
    }
  }
  .img {
    margin: 20*$rem/10 0;
    height: 150*$rem/10;
    width: 150*$rem/10;
    .swiper {
        height: 150*$rem/10;
        width: 150*$rem/10;
        padding: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: 150*$rem/10;
          width: 150*$rem/10;
          border-radius: 20*$rem/10;
          overflow: hidden;
        }
      }
  }
  .title {
    font-size: 18*$rem/10;
    color: #333333;
    font-weight: bold;
  }
  .number {
    margin-top: 2*$rem/10;
    color: #AB8A36;
    font-size: 14*$rem/10;
  }
  .transferor {
    margin: 20*$rem/10 0 2*$rem/10;
    color: #333333;
    font-size: 14*$rem/10;
    font-weight: 500;
  }
  .time {
    margin-bottom: 2*$rem/10;
    color: #666666;
    font-size: 13*$rem/10;
  }
  .btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 58*$rem/10;
    .btn-common {
      flex: 1;
      padding: 10*$rem/10 39*$rem/10;
      border-radius: 36*$rem/10;
      font-size: 18*$rem/10;
    }
    .cancel-btn {
      margin-right: 15*$rem/10;
      border: 0.5*$rem/10 solid #DFCFAB;
      background: #FFFFFF;
      color: #CDAC5B;
    }
    .confirem-btn {
      background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
      color: #624C16;
    }
  }
}
