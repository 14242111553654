$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.upgradeModal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .subtitle {
        font-size: 1.2*$rem;
        font-weight: 400;
        color: #737373;
    }
    .action-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 3*$rem 0;
        >img {
            width: 2*$rem;
            padding:  0 0.5*$rem;
        }
        > span {
            padding: 0 1*$rem;
            color: #000;
            font-size: 1.6*$rem;
            font-weight: 500;
        }
    }

    .desc-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.4*$rem;
        color: #333;
        margin-bottom: 0.6*$rem;
        span {
            color: #CAA846;
        }
    }

}