@function to($px) {
  @return ($px / 2558) * 100vw;
}

.land_count_down {
  padding-top: to(5);
  box-sizing: border-box;
  width: to(246);
  height: to(63);
  background: url('~@/assets/img/landfi/time-down.png') no-repeat;
  background-size: 100% 100%;
  color: #792D16;
  font-size: to(30);
  font-weight: 500;
  text-align: center;
}