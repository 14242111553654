$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}

.land-btn {
    transition: transform 0.3s;
    transform: scale(1);
    font-family: YueGenYouShangYueBoHei;

    &:active {
        transform: scale(0.88);
    }

    &-floatbtn {
        position: absolute;

        &__img {
            width: to(190);
            height: to(190);
            border-radius: 100%;
            display: block;
            overflow: hidden;
        }

        &__text {
            font-weight: bold;
            font-size: to(40);
            text-align: center;
            color: white;
        }

        &__myland {
            bottom: to(60);
            right: 16.3vw;
        }
        &__landmode {
            bottom: to(60);
            left: 16.5vw;
            >img {
                width: to(243);
                height: to(220);
            }
        }

        &__door {
            display: flex;
            align-items: center;
            top: to(60);
            left: 32vw;

            &>img {
                margin-right: to(19);
                width: to(104);
                height: to(104);
            }
        }

        &__landlease {
            bottom: to(60);
            right: 28vw;
        }

        &__spaceextend {
            bottom: to(60);
            right: 5vw;
        }
        &__text {
            font-family: YueGenYouShangYueBoHei;
            font-size: to(41);
        }
        &__package {
            left:to(123);
            bottom:to(105);
        }
    }

    &-homeBack {
        position: absolute;
        top: to(60);
        left: to(235);
        width: to(120);
        height: to(120);
        background: url("~@/assets/img/landfi/icon_tc.png");
        background-size: 100% 100%;
        z-index: 1;
    }

    &-backIcon {
        width: to(120);
        height: to(120);
        background: url("~@/assets/img/landfi/icon_tc.png");
        background-size: 100% 100%;
        z-index: 1;
    }

    &-speedbtn {
        width: to(297);
        height: to(103);
        background: url("~@/assets/img/landfi/icon_card.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: to(60);
        right: 18vw;
        &.mode2 {
            background: url("~@/assets/img/landfi/landgy/prop-btn.png") no-repeat;
            background-size: 100% 100%;
        }
    } 
    &-propBtn {
        position: absolute;
        top: to(60);
        right: 16.3vw;
        width: to(297);
        height: to(102);
        background: url("~@/assets/img/landfi/landgy/prop-btn.png") no-repeat;
        background-size: 100% 100%;
        z-index: 1;
    }

    &-scorebtn,
    &-scorexbtn {
        position: absolute;
        width: to(325);
        height: to(189);
        background: url("~@/assets/img/landfi/icon_diamond.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        top: to(60);
        right: 2vw;

        &>div {
            position: absolute;
            width: 8vw;
            left: 0.6vw;
            top: 1.3vw;
            font-size: 1.4vw;
            font-weight: bold;
            text-align: center;
            color: white;
        }
    }

    &-scorexbtn {
        width: to(325);
        height: to(107);
        background: url("~@/assets/img/landfi/icon_diamond2.png");
        background-size: 100% 100%;
    }
    
    &-energyxbtn {
        position: absolute;
        width: to(325);
        height: to(107);
        background: url("~@/assets/img/landfi/landgy/icon_energy.png");
        background-size: 100% 100%;
        top: to(60);
        right: 2vw;

        &>div {
            position: absolute;
            width: 8vw;
            left: 0.6vw;
            top: 1.3vw;
            font-size: 1.4vw;
            font-weight: bold;
            text-align: center;
            color: white;
        }
    }

    &-basebtn {
        width: to(245);
        height: to(135);
        border-radius: to(20);
        background: transparent;
        color: #dc7c00;
        font-size: to(50);
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
            background: #e3c49c;
            color: #27140d;
        }
    }

    &-closebtn {
        position: absolute;
        right: to(120);
        top: to(30);
        width: to(90);
        height: to(90);
        background: url("~@/assets/img/landfi/icon_del.png");
        background-size: 100% 100%;
        &.mode2{
            background: url("~@/assets/img/landfi/landgy/icon_del.png");
            background-size: 100% 100%;
        }
    }

    &-backbtn {
        position: absolute;
        top: to(50);
        left: to(50);
        width: to(94);
        height: to(94);
        background: url("~@/assets/img/landfi/icon_return.png");
        background-size: 100% 100%;
    }
    &-backbtn-mode2 {
        position: absolute;
        top: to(50);
        left: to(50);
        width: to(94);
        height: to(94);
        background: url("~@/assets/img/landfi/landgy/back.png");
        background-size: 100% 100%;
    }

    &-greenbtn,
    &-orangebtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: to(366);
        height: to(120);
        background-image: url("~@/assets/img/landfi/btn_def.png");
        background-size: 100% 100%;
        font-weight: bold;
        color: #fff;
        padding-bottom: to(10);
        box-sizing: border-box;

        &__landup {
            width: to(584);
            height: to(102);
            background-image: url("~@/assets/img/landfi/btn_long.png");
        }
    }

    &-greenbtn {
        &__disabled {
            background-image: url("~@/assets/img/landfi/icon_zh.png");
            pointer-events: none;
            transition: none;
        }

        &__large-disabled {
            background-image: url("~@/assets/img/landfi/task-btn-disabled.png");
            pointer-events: none;
            transition: none;
        }
    }

    &-orangebtn {
        background-image: url("~@/assets/img/landfi/btn_sj.png");

        &__disabled {
            background-image: url("~@/assets/img/landfi/icon_zh.png");
            pointer-events: none;
            transition: none;
        }
    }

    &-taskBtn {
        position: absolute;
        top: to(30);
        right: to(780);
        width: to(297);
        height: to(148);
        background: url("~@/assets/img/landfi/tack-icon.png") no-repeat;
        background-size: 100% 100%;
        z-index: 1;
    }
    
    &-exchangeLandBtn {
        position: absolute;
        bottom: to(148);
        left: to(137);
        width: to(241);
        height: to(78);
        background: rgba(12, 12, 12, 0.5);
        border-radius: to(39);
        box-shadow: inset 0px 4px 39px 0px rgba(0, 0, 0, 0.3);
        border: to(6) solid rgba(216, 216, 216, 0.5);
        box-sizing: border-box;
        z-index: 1;
        font-family: YueGenYouShangYueBoHei;
        color: #FFFFFF;
        font-size: to(36);
        display: flex;
        align-items: center;
        padding-left: to(18);
    
        > img {
          width: to(47);
          height: t0(47);
        }
    }
}

.land-text {
    &-spaceNum {
        position: absolute;
        top: to(60);
        left: to(60);
        z-index: 1;
        width: to(121);
        height: to(121);
        text-align: center;
        line-height: to(121);
        border-radius: 50%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.65) 100%);
        box-sizing: border-box;
        backdrop-filter: blur(13px);
        text-shadow: 0px to(3.03) 0px #C77F3D;
        font-size: to(73);
        font-weight: bold;
        font-family: YueGenYouShangYueBoHei;
        color: #FFFFFF;
    }
    &-credit {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: to(20);
        &-bg {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #2D0041;
            height: to(50);
            min-width: to(140);
            border-radius: to(25);
            padding: 0 to(10) 0 to(55);
            margin-top: to(60);
            .img_icon {
                width: to(115);
                height: to(115);
                margin-left: to(-70);
                margin-top: to(-32);
            }
            .text {
                color: #E4B478;
                padding: 0 to(14) 0 to(4);
            }
            .level {
                position: absolute;
                width: to(50);
                height: to(50);
                left: to(14);
                bottom: 0;
                border-radius: to(25);
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.65) 100%);
                font-weight: bold;
                color: #2D0041;
                text-align: center;
                line-height: to(50);
                font-size: to(35);
            }
        }
        
    }

    &-spaceBeautifulId {
        position: absolute;
        top: to(18);
        left: to(338);
        z-index: 1;
        padding-left: to(240);
        color: #FFFFFF;
        font-size: to(32);
        font-weight: 600;
        box-sizing: border-box;
        width: to(500);
        height: to(200);
        line-height: to(195);

        &.spaceBeautifulId-1 {
            background: url('~@/assets/img/spaceBeautifulAccount/1.gif') no-repeat;
            background-size: 100% 100%;
        }

        &.spaceBeautifulId-2 {
            background: url('~@/assets/img/spaceBeautifulAccount/2.gif') no-repeat;
            background-size: 100% 100%;
        }
    }

    &-spaceId {
        position: absolute;
        top: to(80);
        left: to(430);
        z-index: 1;
        display: flex;
        align-items: center;
        padding: 0 to(20);
        height: to(68);
        border-radius: to(34);
        background: rgba(12, 12, 12, 0.5);
        border: to(4) solid rgba(255, 239, 187, 0.6);
        box-shadow: inset 0px to(2.52) to(24.53) 0px rgba(0, 0, 0, 0.3);
        color: #FFEFBB;
        font-size: to(32);
        font-weight: 500;
        box-sizing: border-box;
    }
}