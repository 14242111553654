$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.recovery-success-mask {
  padding-top: 10*$rem;
  .mask-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31.6*$rem;
    height: 37.6*$rem;
    box-sizing: border-box;
    &.type-01 {
      background: url('../../imgs/pop_500.png') no-repeat;
      background-size: 100% 100%;
    }
    &.type-02 {
      background: url('../../imgs/pop_yazhu.png') no-repeat;
      background-size: 100% 100%;
    }
    .confirm-btn {
      margin-top: 30.5*$rem;
      margin-left: 2.8*$rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 11*$rem;
      height: 3*$rem;
      border-radius: 10*$rem;
      color: #671F1F;
      font-size: 1.5*$rem;
      background: linear-gradient(90deg, #FFAA3D -8%, #FFF3CD 102%);
      font-weight: bold;
    }
  }
}