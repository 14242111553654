$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.nft-search-header {

  .container {

    width: 100%;
    align-items: center;
    color: #333333;
    display: flex;
    padding-bottom: 2*$rem;
    .left {
      width: 50px;
      padding-left: 1.7*$rem;
      img {
        width: 0.75*$rem;
        height: 1.55*$rem;
      }
    }
    .middle {
      width: 100%;
      text-align: center;
    }
    .rightButton {
      font-family: PingFangSC-Regular;
      line-height: setRem(38);
      width: setRem(100);
      padding-right: setRem(30);
      text-align: right;
      font-size: setRem(28);
      font-weight: normal;
      letter-spacing: 0px;
      color: #2F2F2F;
    }
  }

  .searchTitle1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 1.5*$rem;
    margin-right: 1.5*$rem;
    padding-right: setRem(30);
    padding-left: setRem(30);
    border-radius: setRem(20);
    height: setRem(160);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(164, 139, 139, 0.1);
    margin-bottom: setRem(60);

    .titleContainer {
      display: flex;
      flex-direction: column;
      .title {
        font-family: PingFangSC-Medium;
        font-size: setRem(36);
        font-weight: normal;
        color: #000000;
        line-height: setRem(38);
        letter-spacing: setRem(0);
      }
      .subTitle {
        padding-top: setRem(25);
        font-family: PingFangSC-Regular;
        font-size: setRem(28);
        font-weight: normal;
        line-height: setRem(38);
        letter-spacing: setRem(0);
        color: #000000;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: setRem(150);
    justify-content: flex-end;
    > img {
      margin-right: setRem(2);
      width: setRem(34);
      height: setRem(30);
    }
    .researchTitle {
      font-family: PingFangSC-Regular;
      font-size: setRem(28);
      font-weight: normal;
      line-height: setRem(38);
      letter-spacing: setRem(0);
      color: #000000;
    }
    .fixed-button {
      position: absolute;
      z-index: 112;
      top: setRem(50);
      left: setRem(-30);
      > Button {
        width: setRem(200);
      }
    }
  }
  .totalCount {
    font-family: PingFangSC-Regular;
    font-size: setRem(30);
    font-weight: normal;
    letter-spacing: 0;
    color: #1A1A1A;
    margin-left: setRem(30);
    > span {
      font-family: PingFangSC-Semibold;
      font-size: setRem(36);
      font-weight: normal;
      letter-spacing: 0em;
      color: #1A1A1A;
    }
  }
  .searchTitle2 {
    display: flex;
    margin-right: setRem(30);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: setRem(40);
  }
}
