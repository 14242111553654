$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.pwd_modal_box {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .pwd_modal_container { 
    width: 30*$rem;
    min-height: 100px;
    background: white;
    border-radius: 6px;
    padding: 2vh 6vw 3vh;
    margin-top: -20px;

    .pwd_modal_top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .pwd_title {
        font-family: PingFangSC-Medium;
        font-size: 1.6 *$rem;
        font-weight: normal;
        color: #333333;
      }

      img {
        width: 16px;
        height: 16px;
        padding: 5px;
      }
    }

    .pwd_line {
      width: 100%;
      height: 1px;
      background: #000000;
      opacity: 0.1;
      margin-top: 1.2 *$rem;
    }

    .div_inputs {
      height: 4 *$rem;
      margin-top: 3 *$rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .input {
        width: 40px;
        height: 40px;
        margin: 2px;
        border: 1px solid #CDAC5B;
        border-radius: 3px;
        .adm-input-element {
          width: 40px;
          min-width: 40px;
        }
      }
      
    }

    .pwd_error {
      width: 50%;
      font-family: PingFangSC-Regular;
      font-size: 1.2 *$rem;
      font-weight: normal;
      color: #FF0000;
      margin: 2 *$rem auto 0;
      text-align: center;
    }
    .pwd_face_shibie{
      max-width: 80%;
      font-family: PingFangSC-Regular;
      font-size: 1.2 *$rem;
      font-weight: normal;
      letter-spacing: 0px;
      color: #9E9E9E;
      margin: 1 *$rem auto 0;
      text-align: center;
    }
  }
}

.key_board_container {
  width: 100%;
  height: 22 *$rem;
  background: #CED2D9;
  position: absolute;
  left: 0;
  bottom: 20px;

  .key_board {
    display: inline-block;
    width: 30vw;
    height: 4.6 *$rem;
    margin-left: 2.3vw;
    text-align: center;
    margin-top: 5px;
    overflow: hidden;
  }

  .key_board_item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-family: "PingFang SC";
    font-weight: bold;
    color: #000000;
    font-size: 22px;
    border-radius: 6px;

    .del_img {
      width: 30px;
      height: 30px;
    }
  }
}
