@import '@/assets/scss/variable.scss';

 .bgImg {
  width: 100%;
}

.midContent {
  width: 53%;
  height: 50%;
  //background-color: purple;
  position: absolute;
  top: 30%;
  left: 24.5%;
  display: flex;
  flex-direction: column;

  .myEle {
    height: 2 *$rem;
    font-family: PingFang SC;
    font-size: 1 *$rem;
    font-weight: 500;
    color: #FFFFFF;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imgContainer {
    margin-top: 2.2 *$rem;
    width: 100%;
    height: 2.6 *$rem;
    background: linear-gradient(274deg, #FCD786 3%, #FFE9A0 99%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    > img {
      width: 70%;
      background-size: 100%;
    }
  }

  .items {
    width: 100%;
    flex: 0.8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.6 *$rem;

    .item {
      width: 48%;
      height: 100%;
      background: linear-gradient(299deg, #FCD786 0%, #FFE9A0 99%);
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 0.5 *$rem;
      box-sizing: border-box;

      .imgs {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 80%;
        margin: 1 *$rem 0;

        > img {
          width: 3.2 *$rem;
          height: 3.2 *$rem;
          border-radius: 4px;
        }
      }

      .mhImg {
        width: 3.5 *$rem;
        height: 3.2 *$rem;
        margin: 1 *$rem 0;
      }

      > p {
        height: 2.0 *$rem;
        border-radius: 71px;
        background: #B7402E;
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        font-weight: 600;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1 *$rem;
      }
      .dis{
        opacity: 0.6;
      }
    }
  }

  .btns {
    width: 100%;
    flex: 0.2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-family: PingFang SC;
    font-size: 1.1 *$rem;
    color: #FFF;
    margin-top: 0.3 *$rem;
  }
}
