@import '@/assets/scss/variable.scss';
.pageBox {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background: linear-gradient(144deg, #E3D3C1 6%, #D4C0AB 100%);
    .back {
        position: absolute;
        top: 2.5*$rem;
        z-index: 9;
        @media screen and (min-height: 780px) {
            top: 5.5*$rem;
        }

        left: 1.5*$rem;
        width: 1*$rem;
        height: 1.8*$rem;
        background: url("~@/assets/img/icon/icon_back_black.png") no-repeat center center / 100% 100%;
    }
    .top_bg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        // padding-bottom: 14.7*$rem;
        width: 100vw;
        height: 60.4*$rem;
        background: url("~@/assets/img/wangXiaoHui/home.png") no-repeat center center / 100% 100%;
        .right_btns {
            position: absolute;
            top: 8.5*$rem;
            right: 0;
            display: flex;
            flex-direction: column;
            .rightBtn {
                width: 2.1*$rem;
                padding: 0.6*$rem 0;
                border-radius: 0.5*$rem 0px 0px 0.5*$rem;
                background: #8D2300;
                color: #FFFFFF;
                font-size: 1.2*$rem;
                font-weight: bold;
                text-align: center;
                line-height: 1.4*$rem;
                margin-bottom: 1.2*$rem;
                &:active {
                    opacity: 0.8;
                }
            }
            
        }
        
    }
    .contentBox {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: -18*$rem auto 0;
        .buy_button {
            width: 30*$rem;
            height: 6.1*$rem;
            margin: 0 auto;
            background: url("~@/assets/img/wangXiaoHui/buy.png") no-repeat center center / 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 2.5*$rem;
            font-weight: 700;
            &:active {
                opacity: 0.8;
            }
        }
        .bottomBox {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 4*$rem auto 0;
            border-radius: 0.9*$rem;
            box-shadow: 0px 5px 5px 0px #987530;
            background: linear-gradient(180deg, #AE9053 0%, rgba(174, 144, 83, 0.51) 100%);
            >img {
                position: absolute;
                height: 3.54*$rem;
                object-fit: contain;
                top: -1.6*$rem;
            }
            .boxIn {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 34*$rem;
                padding: 3.9*$rem 0 2.4*$rem;
                background: #FFFFFF;
                border-radius: 0.9*$rem;
                margin: 1px 1px 1px;
                .grids {
                    display: flex;
                    flex-wrap: wrap;
                    .grid_item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 0.5*$rem;
                        img {
                            width: 6.2*$rem;
                            height: 6.2*$rem
                        }
                        div {
                            margin-top: 0.8*$rem;
                            color: #A04A2C;
                            font-weight: 500;
                            font-size: 1.4*$rem;
                        }
                    }
                }
                .boxBtn {
                    display: flex;
                    flex-direction: row;
                    margin-top: 1.5*$rem;
                    .btn {
                        &:nth-child(1) {
                            height: 3.5*$rem;
                            width: 16.3*$rem;
                        }
                        &:nth-child(2) {
                            height: 3.5*$rem;
                            width: 11.8*$rem;
                            margin-left: 1.8*$rem;
                        }
                        &:active {
                            opacity: 0.8;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    
                }
            }
            .descBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 34*$rem;
                padding: 2.5*$rem 0 2.4*$rem;
                background: #FFFFFF;
                border-radius: 0.9*$rem;
                margin: 1px 1px 1px;
                .text {
                    padding: 1.5*$rem 2*$rem 0;
                    font-size: 1.3*$rem;
                    line-height: 1.6*$rem;
                    color: #4D4D4D;
                    text-indent: 26px;
                }
                .imgBox {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 2*$rem;
                    &>img {
                        &:nth-child(1) {
                            width: 19*$rem;
                            height: 24.85*$rem;
                        }
                        &:nth-child(2) {
                            z-index: 1;
                            width: 12.7*$rem;
                            height: 20.85*$rem;
                            margin-left: -1*$rem;
                        }
                    }
                }
            }
        }
        
    }
    
    .bottom_logo {
        margin-top: 5*$rem;
        margin-bottom: 3.5*$rem;
        img {
            height: 3.5*$rem;
            margin: 0 auto;
        }
    }
}