@import '@/assets/scss/variable.scss';

.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #F7F7F7;
  position: relative;
  display: flex;
  flex-direction: column;

  .bg_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    background-size: 100% 100%;
  }

  .content_container {
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding-bottom: 10vh;

    .img_container {
      width: 70%;
      display: flex;
      flex-direction: row;
      margin: 2 *$rem auto 0;
      justify-content: space-around;

      > img {
        width: 10.5 *$rem;
        height: 12.3 *$rem;
      }
    }

    .des {
      max-width: 96%;
      border-radius: 544px;
      margin: 2.5 *$rem auto 0;
      padding: 5px 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.74);
      font-family: PingFang SC;
      font-size: 1.2 *$rem;
      font-weight: 600;
      color: #FF4A6B;
    }

    .header {
      width: 100%;
      height: 5 *$rem;
      margin-top: 2 *$rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 10px 10px 0 0;
      background: white;

      .header_titles {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 84%;
        height: 90%;
        border-bottom: 0.2px solid #535353;

        > p {
          height: 98%;
          line-height: 4 *$rem;
          font-family: PingFang SC;
          font-weight: normal;
          color: #000000;
          opacity: 0.4;

          &.sel {
            font-weight: 600;
            color: #478BF8;
            border-bottom: 2px solid #478BF8;
            opacity: 1;
          }
        }
      }
    }

    .list_container {
      //flex: 1;
      //background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      .cartoon_header {
        width: 90%;
        box-sizing: border-box;
        background-color: #fff;
        height: 4 *$rem;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;

        span {
          font-family: PingFangSC-Regular;
          font-size: 1.2 *$rem;
          font-weight: normal;
          color: #000000;
          display: inline-block;
          opacity: 0.5;
        }

        .cartoon_header_pm {
          width: 4 *$rem;
          margin-left: 1 *$rem;
        }

        .cartoon_header_yh {
          width: 13 *$rem;
          left: 5 *$rem;
          position: absolute;
        }

        .cartoon_header_yq {
          //width: 7 *$rem;
          position: absolute;
          right: 1 *$rem;
          text-align: center;
        }
      }

      .list {
        flex: 1;
        width: 100%;
        overflow: auto;
      }

      .list_item {
        width: 100%;
        height: 5 *$rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        //justify-content: space-around;
        position: relative;
        background-color: white;

        .list_item_img_pm {
          width: 2.7 *$rem;
          height: 2.4 *$rem;
          object-fit: cover;
          margin-left: 2.6 *$rem;
        }

        .list_item_img_pm_txt {
          width: 2.2 *$rem;
          height: 2 *$rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC;
          font-size: 1.5 *$rem;
          font-weight: 600;
          color: #000000;
          margin-left: 2.8 *$rem;
        }

        .img_tel_com {
          position: absolute;
          display: flex;
          align-items: center;
          flex-direction: row;
          left: 7 *$rem;
          max-width: 15 *$rem;

          .list_item_img {
            width: 3 *$rem;
            height: 3 *$rem;
            object-fit: cover;
            border-radius: 50%;
          }

          > div {
            margin-left: 10px;
          }
        }

        .list_item_invited_num {
          //width: 3 *$rem;
          font-family: SourceHanSansCN-Bold;
          font-size: 1.2 *$rem;
          font-weight: bold;
          text-align: right;
          color: #E83E5D;
          position: absolute;
          right: 3 *$rem;
          //background-color: red;
        }
      }
    }
  }

  .cartoon_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background: #B5DFFA;
    display: flex;
    flex-direction: row;
    //align-items: center;

    .left {
      height: 70%;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 4 *$rem;
        height: 4 *$rem;
        object-fit: cover;
        border-radius: 50%;
        margin-left: 2 *$rem;
        background: grey;
      }

      .cartoon_bottom_tel {
        display: inline-block;
        margin-left: 0.6 *$rem;
        font-size: 1.2 *$rem;
        color: #007CFF;
      }

    }

    .cartoon_bottom_pm {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: center;
      position: absolute;
      right: 13 *$rem;

      span {
        display: inline-block;
        color: #007CFF;
      }

      .cartoon_bottom_pm_count {
        font-family: SourceHanSansCN-Bold;
        font-size: 1.5 *$rem;
        font-weight: bold;
        text-align: right;
        color: #007CFF;
        padding: 4px 0;
      }
    }

    .cartoon_bottom_yq {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: center;
      position: absolute;
      right: 2 *$rem;

      span {
        display: inline-block;
        color: #007CFF;
      }

      .cartoon_bottom_pm_count {
        font-family: SourceHanSansCN-Bold;
        font-size: 1.5 *$rem;
        font-weight: bold;
        text-align: right;
        color: #007CFF;
        padding: 4px 0;
      }
    }
  }

}

