@import '@/assets/scss/variable.scss';

.scratch_card_gift_container{
  display: flex;
  justify-content: space-between;
  height: 100%;
  .prize_list_box {
    flex: 0.8;
    margin-right: 1*$rem;
    padding: 1*$rem 0.75*$rem;
    border-radius: 0.5*$rem;
    border: 0.13*$rem solid #FFFFFF;
    box-sizing: border-box;
    overflow: auto;
    .prize_empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 8.9*$rem;
        height: 8.9*$rem;
      }
      span {
        font-size: 1.19*$rem;
        color: #FFFFFF;
        font-weight: bold;
      }
    }
  }
  .scratch_card_box {
    flex: 1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .card_prize_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.85*$rem 0 0.65*$rem;
    margin-bottom: 0.6*$rem;
    width: 100%;
    height: 2*$rem;
    border-radius: 0.3*$rem;
    background: #FFFFFF;
    box-sizing: border-box;
    span {
      &:nth-child(1) {
        font-size: 0.8*$rem;
        color: #999999;
      }
      &:nth-child(2) {
        font-size: 0.8*$rem;
        color: #0E54B2;
        font-weight: bold;
      }
    }
  }
}


