$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

@function to($px) {
    @return ($px / 2558) * 100vw;
}

.land-game-exp {
    position: absolute;
    width: 100%;
    height: 100%;
    // display: flex;
    // flex-direction: column;

    &__scrollbox {
        position: relative;
        // flex: 1;
        width: 100%;
        height: calc(100% - #{to(80)});

        &-bg {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 99.99%;

            >p {
                width: 100%;
                height: to(72);
                background: #e1c292;
            }

            &>div {
                flex: 1;

                p {
                    width: 100%;
                    height: 33.33%;
                    background: #ffebcc;

                    &.__active {
                        background: #f1d7ae;
                    }
                }
            }

            &.mode2 {
                >p {
                    background: #8F6FC2;
                }

                &>div {
                    p {
                        background: #B0A3C4;

                        &.__active {
                            background: #9B84BF;
                        }
                    }
                }
            }
        }
    }

    &__scroll {
        position: relative;
        // flex: 1;
        width: 100%;
        height: 100%;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
            /* Chrome Safari */
        }

        &>div {
            position: relative;
            display: flex;
            height: 100%;
            width: to(210 * 80);
        }

        .land-game-expscroll-box {
            width: fit-content;
        }
    }

    /* column */
    &__box {
        display: flex;
        flex-direction: column;
        width: to(210);
        height: 100%;

        // background: #f1d7ae;
        &.sticky {
            position: sticky;
            left: 0;
            z-index: 999;
        }

        &.land-game-shadow {
            box-shadow: to(14) to(4) to(44) 0 #dfb778;
        }

        >div {
            position: relative;
            display: flex;
            flex-direction: column;

            /* header */
            &:nth-child(1) {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: YueGenYouShangYueBoHei;

                width: 100%;
                height: to(72);
                background: #e1c292;

                font-size: to(32);
                font-weight: bold;
                color: #000000;
            }

            &:nth-child(2) {
                flex: 1;

                .land-game-box__default {
                    background: #ffebcc;

                    &.mode2 {
                        background: #B0A3C4;
                    }
                }

                .land-game-box__active {
                    background: #f1d7ae;

                    &.mode2 {
                        background: #9B84BF;
                    }
                }

                &>div {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 33.33%;

                    span {
                        position: absolute;
                        bottom: to(10);
                        font-size: to(24);
                        color: #502c16;
                    }
                }
            }
        }

        &-el {
            position: relative;
            width: to(130);
            height: to(130);

            &__score {
                position: relative;
                width: 100%;
                height: 100%;
                background: #fce2b9;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: to(10);
                z-index: 2;

                &.__lock {
                    opacity: 0.68;
                }

                >img {
                    // margin-bottom: to(20);
                    width: 100%;
                    height: 100%;
                }

                >span {
                    font-size: to(24) !important;
                    font-weight: 600;
                    color: #ffffff !important;
                    height: to(38);
                    background: #0000004d;
                    position: absolute;
                    bottom: 0 !important;
                    width: 100%;
                    border-radius: 0 0 to(10) to(10);
                    text-align: center;

                    &.__can__receive {
                        background: rgba(255, 89, 0, 0.4392);
                    }
                }
            }

            &__canget {
                border-radius: to(10);
                box-shadow: 0px to(4) to(27) 0px #cd4e00;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: to(10);
                    background-image: url("~@/assets/img/landfi/icon_guang.png");
                    background-size: 100% 100%;
                    transform: scale(1.4);
                }
            }

            &__isReceived {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(40%, -40%);
                width: to(24);
                height: to(24);
                background: #FF0000;
                border-radius: 50%;
                z-index: 3;
            }

            &__received {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: to(130);
                height: to(130);
                border-radius: to(10);
                background: rgba(0, 0, 0, 0.6);
                z-index: 3;

                &::after {
                    content: "";
                    display: block;
                    width: to(64);
                    height: to(48);
                    background: url('~@/assets/img/landfi/received.png') no-repeat;
                    background-size: 100% 100%;
                }
            }

            &__lock {
                position: absolute;
                right: 0;
                top: 0;
                width: to(24);
                height: to(24);
                background-image: url("~@/assets/img/landfi/icon_s.png");
                background-size: 100% 100%;
                transform: translate(25%, -25%);
                z-index: 3;
            }

            &__star {
                position: relative;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;

                &>img {
                    width: 100%;
                    height: 100%;
                }

                &.__lock {
                    opacity: 0.68;
                }

                &-a {
                    background-image: url("~@/assets/img/landfi/icon_hz_a.png");
                }

                &-b {
                    background-image: url("~@/assets/img/landfi/icon_hz_b.png");
                }

                &-c {
                    background-image: url("~@/assets/img/landfi/icon_hz_c.png");
                }

                >span {
                    font-size: to(24) !important;
                    font-weight: 600;
                    color: #ffffff !important;
                    height: to(38);
                    background: #0000004d;
                    position: absolute;
                    bottom: 0 !important;
                    width: 100%;
                    border-radius: 0 0 to(10) to(10);
                    text-align: center;

                    &.__can__receive {
                        background: rgba(255, 89, 0, 0.4392);
                    }
                }
            }
            &__energy {
                position: relative;
                width: 100%;
                height: 100%;
                background: #fce2b9;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: to(10);
                z-index: 2;

                &.__lock {
                    opacity: 0.68;
                }

                >img {
                    // margin-bottom: to(20);
                    width: 100%;
                    height: 100%;
                }

                >span {
                    font-size: to(24) !important;
                    font-weight: 600;
                    color: #ffffff !important;
                    height: to(38);
                    background: #0000004d;
                    position: absolute;
                    bottom: 0 !important;
                    width: 100%;
                    border-radius: 0 0 to(10) to(10);
                    text-align: center;

                    &.__can__receive {
                        background: rgba(255, 89, 0, 0.4392);
                    }
                }
            }
        }
    }

    &__box.mode2 {
        &.land-game-shadow {
            box-shadow: to(14) to(4) to(44) 0 #8F6FC2;
        }

        >div {

            /* header */
            &:nth-child(1) {
                background: #8F6FC2;
                color: #ffffff;
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: to(10);

        span {
            font-size: to(30);
            font-weight: 500;
            color: #502c16;
        }

        &.mode2 {
            >span {
                color: #ffffff;
            }
        }

        &>div {
            display: flex;
        }

        .land-width-52 {
            width: to(52);
        }
    }

    .receive-mask-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        .receive-mask-bg {
            position: relative;
            margin-top: to(120);
            margin-left: to(-145);
            width: to(1239);
            height: to(646);
            background: url('~@/assets/img/landfi/915.png') no-repeat;
            background-size: 100% 100%;

            .receive-prize-list {
                position: absolute;
                left: 50%;
                top: 60%;
                transform: translateX(-42%);
                display: flex;
                justify-content: center;
                width: to(1239);
                overflow-x: auto;

                &.item-center {
                    justify-content: flex-start;
                }

                .prize-list-item {
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: to(60);

                    >p {
                        margin-top: to(21);
                        color: #FFFFFF;
                        font-size: to(36);
                        font-weight: 600;
                    }

                    .prize-box {
                        width: to(130);
                        height: to(130);
                        border-radius: to(10);
                        overflow: hidden;

                        .gemstone-box {
                            position: relative;
                            background: #FCE2B9;

                            >img {
                                width: to(130);
                                height: to(130);
                            }

                            >div {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: to(38);
                                background: rgba(0, 0, 0, .3);
                                color: #FFFFFF;
                                font-size: to(24);
                                font-weight: 600;
                                text-align: center;
                            }
                        }

                        .ape-box {
                            position: relative;

                            >img {
                                width: to(130);
                                height: to(130);
                            }

                            >div {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: to(38);
                                background: rgba(0, 0, 0, .3);
                                color: #FFFFFF;
                                font-size: to(24);
                                font-weight: 600;
                                text-align: center;
                            }
                        }
                        
                        .energy-box {
                            position: relative;

                            >img {
                                width: to(130);
                                height: to(130);
                            }

                            >div {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: to(38);
                                background: rgba(0, 0, 0, .3);
                                color: #FFFFFF;
                                font-size: to(24);
                                font-weight: 600;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        .receive-mask-close {
            margin: to(80) to(386) 0;
            padding-top: to(122);
            width: 70%;
            text-align: center;
            border-top: to(1) solid rgba(223, 223, 223, 0.56);
            color: #FFFFFF;
            font-size: to(28);
            box-sizing: border-box;
        }
    }
}