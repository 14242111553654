@import '@/assets/scss/variable.scss';

.workape_blindbox_home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20*$rem;
  padding-bottom: 5*$rem;
  width: 100vw;
  min-height: 100vh;
  background: url("~@/assets/img/workapeBlindboxImg/home_bg.png") no-repeat center center / 100% 100%;
  box-sizing: border-box;

  .back_box {
    position: fixed;
    top: 4*$rem;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5*$rem;
    height: 5*$rem;
    > img {
      width: 1*$rem;
      height: 1.8*$rem;
    }
  }

  @mixin common_btn {
    width: 2*$rem;
    height: 4*$rem;
    border-radius: 0.5*$rem 0px 0px 0.5*$rem;
    opacity: 0.8;
    background: #7ABAFF;
    color: #FFFFFF;
    font-size: 1.2*$rem;
    font-weight: bold;
    writing-mode: vertical-lr;
    text-align: center;
    line-height: 2*$rem;
  }

  .rules {
    @include common_btn();
    position: absolute;
    top: 7*$rem;
    right: 0;
  }

  .real_name {
    @include common_btn();
    position: absolute;
    top: 12.5*$rem;
    right: 0;
  }

  .blindbox {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1.5*$rem;
    box-sizing: border-box;
    width: 33*$rem;
    height: 45*$rem;
    background: url("~@/assets/img/workapeBlindboxImg/blindbox-price.png") no-repeat center center / 100% 100%;

    > div {
      width: 23.4*$rem;
      height: 7*$rem;
      background: url("~@/assets/img/workapeBlindboxImg/purchase_btn_bg.png") no-repeat center center / 100% 100%;
      text-align: center;
      line-height: 7*$rem;
      color: #FFFFFF;
      font-size: 1.75*$rem;
      font-weight: bold;
    }
  }

  .blindbox_no {
    margin-top: 0.4*$rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4*$rem 1.25*$rem;
    box-sizing: border-box;
    width: 31.5*$rem;
    height: 10.2*$rem;
    background: url("~@/assets/img/workapeBlindboxImg/img_ck@1x.png") no-repeat center center / 100% 100%;

    &_left {
      color: #FFFFFF;
      > div {
        &:nth-child(1) {
          font-size: 1.3*$rem;
          font-weight: 600;
        }
        &:nth-child(2) {
          margin-top: 0.35*$rem;
          font-size: 1*$rem;
        }
        &:nth-child(3) {
          font-size: 1*$rem;
        }
        &:nth-child(4) {
          margin-top: 0.95*$rem;
          width: 16.25*$rem;
          height: 3*$rem;
          border-radius: 1.5*$rem;
          background: linear-gradient(100deg, #A0FEFF 0%, #FFEF9F 100%);
          text-align: center;
          line-height: 3*$rem;
          color: #1255FE;
          font-size: 1.55*$rem;
          font-weight: 500;
        }
      }
    }

    > img {
      width: 8.5*$rem;
      height: 8.5*$rem;
    }
  }

  .upgrade_s {
    margin-top: 1.85*$rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4*$rem 1.25*$rem;
    box-sizing: border-box;
    width: 31.5*$rem;
    height: 10.2*$rem;
    background: url("~@/assets/img/workapeBlindboxImg/img_sj@1x.png") no-repeat center center / 100% 100%;

    &_left {
      color: #FFFFFF;
      > div {
        &:nth-child(1) {
          font-size: 1.3*$rem;
          font-weight: 600;
        }
        &:nth-child(2) {
          margin-top: 0.35*$rem;
          font-size: 1*$rem;
        }
        &:nth-child(3) {
          font-size: 1.2*$rem;
          color: #FFF200;
          font-weight: 600;
        }
      }
      .upgrade_s_btn {
        margin-top: 0.2*$rem;
        width: 12.05*$rem;
        height: 3*$rem;
        border-radius: 1.5*$rem;
        background: linear-gradient(100deg, #A0FEFF 0%, #FFEF9F 100%);
        text-align: center;
        line-height: 3*$rem;
        color: #1255FE;
        font-size: 1.55*$rem;
        font-weight: 500;
        &.disabled {
          background: #DFDFDF;
          color: #535353;
          // pointer-events: none;
        }
      }
    }

    > img {
      width: 8.5*$rem;
      height: 8.5*$rem;
    }
  }
}

.nft_no_list {
  display: flex;
  flex-direction: column;
  align-items: center;

  .list_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 2.5*$rem);
    row-gap: 2.2*$rem;
    column-gap: 1*$rem;
    padding: 2.5*$rem 1.5*$rem;
    width: 30*$rem;
    height: 31*$rem;
    border-radius: 1.25*$rem;
    background: linear-gradient(136deg, #C5FBE7 0%, #4F95FF 100%);
    box-sizing: border-box;
    overflow-y: auto;

    .nft_no_item {
      padding: 0 0.3*$rem;
      width: 100%;
      height: 1.9*$rem;
      border-radius: 0.5*$rem;
      background: #FFFFFF;
      text-align: center;
      line-height: 1.9*$rem;
      color: #3361D9;
      font-size: 1*$rem;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      white-space: nowrap;
      box-sizing: border-box;
    }
  }

  .close {
    margin-top: 2.5*$rem;
    width: 2.5*$rem;
    height: 2.5*$rem;
    background: url("~@/assets/img/workapeBlindboxImg/close.png") no-repeat center center / 100% 100%;
  }
}

.rules_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5*$rem 2.5*$rem;
  width: 30*$rem;
  height: 36.3*$rem;
  border-radius: 1.25*$rem;
  background: linear-gradient(140deg, #C5FBE7 0%, #4F95FF 100%);
  box-sizing: border-box;

  .title {
    color: #0053B8;
    font-size: 1.75*$rem;
    font-weight: 500;
    text-align: center;
  }

  .content {
    padding-top: 1*$rem;
    box-sizing: border-box;
    flex: 1;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow-y: auto;
    white-space: pre-line;
    line-height: 1.7*$rem;
    color: #0053B8;
    font-size: 1.05*$rem;
  }

  .close {
    display: flex;
    justify-content: center;
    padding-top: 1.5*$rem;
    box-sizing: border-box;
    width: 100%;
    > div {
      width: 9.8*$rem;
      height: 3*$rem;
      text-align: center;
      line-height: 3*$rem;
      color: #1255FE;
      font-size: 1.6*$rem;
      font-weight: 500;
      background: linear-gradient(107deg, #A0FEFF 0%, #FFEF9F 100%);
      border-radius: 1.5*$rem;
    }
  }
}