$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.parts-box-rules {
    display: flex;
    align-items: center;
    justify-content: center;
    .rules-outer {
      padding: 1.4*$rem 0 1.9*$rem;
      width: 30*$rem;
      max-height: 36.3*$rem;
      min-height: 20*$rem;
      background: #FFFFFF;
      border-radius: 1*$rem;
      display: flex;
      flex-direction: column;
      background: linear-gradient(140deg, #EEF7FF 0%, #99C1FA 100%);
      box-sizing: border-box;
      .rules-title {
        padding-bottom: 1.1*$rem;
        color: #0053B8;
        font-size: 1.75*$rem;
        font-weight: bold;
        text-align: center;
      }
      .rules-content {
        flex: 1;
        padding: 0 2.4*$rem;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow-y: auto;
        white-space: pre-line;
        line-height: 1.7*$rem;
        color: #0053B8;
        font-size: 1.1*$rem;
      }
      .rules-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1*$rem auto 0;
        width: 9.8*$rem;
        height: 3*$rem;
        border-radius: 1.5*$rem;
        background: linear-gradient(270deg, #1150FF 0%, #47FFF9 100%);
        color: #FFFFFF;
        font-size: 1.6*$rem;
        font-weight: 500;
        &:active {
            opacity: 0.8;
          }
      }
    }
  }