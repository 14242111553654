@import '@/assets/scss/variable.scss';

.unity_activity_rule {
  padding: 1*$rem 0;
  width: 100%;
  height: 85%;
  font-size: 0.9*$rem;
  color: #FFFFFF;
  font-weight: bold;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow-y: auto;
  white-space: pre-line;
  line-height: 1.7*$rem;
}