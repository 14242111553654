$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}

.list-item {
  padding: 1.4*$rem;
  margin-top: 1*$rem;
  border-radius: 1*$rem;
  background: #FFFFFF;
  width: 100%;
  box-sizing: border-box;
  .header {
    display: flex;
    padding-bottom: 1*$rem;
    margin-bottom: 1.3*$rem;
    align-items: center;
    border-bottom: .05*$rem solid #DFDFDF;
    font-weight: 500;
    img {
      margin-right: 1*$rem;
      background: #666;
      border-radius: .5*$rem;
      width: 5*$rem;
      height: 5*$rem;
    }
    .title {
      p {
        font-size: 1.6*$rem;
        color: #333333;
        font-weight: 500;
        &:nth-child(2) {
          margin-top: .45*$rem;
          color: #999999;
        }
      }
    }
  }
  .content-item {
    display: flex;
    margin-top: 2*$rem;
    font-size: 1.4*$rem;
    .key {
      color: #999999;
    }
    .value {
      flex: 1;
      text-align: right;
      color: #333333;
      font-weight: 500;
      &.success {
        font-size: 1.3*$rem;
        color: #B89969;
      }
      &.failed {
        font-size: 1.3*$rem;
        color: #FF0000;
      }
      &.hash {
        padding-left: 2*$rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #315AFF;
      }
    }
  }
}
