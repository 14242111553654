$device_width: 375;
$vw: (100vw / $device_width);
$dpr: 1;
$rem: 0.5rem/$dpr;


@function setRem($px) {
  @return ($px / 20) * $rem;
}
// 以设计稿 750 为准
@function px2rem($px) {
  @return $px / 40 * 1rem / $dpr;

}


.upgradeHistory-page {
    height: 100vh;
    background-color: #F1F0F5;
    .page-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #F1F0F5;
        padding-top: 1.5*$rem;
    }
}

.upgradeHistory-item {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 1*$rem;
    margin: 0 1.5*$rem 1.5*$rem;
    padding: 1.4*$rem 1.4*$rem 0.9*$rem;
    .item-top {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #DFDFDF;
        padding-bottom: 1*$rem;
        >img {
            width: 5*$rem;
            height: 5*$rem;
            border-radius: 0.5*$rem;
        }
        .item-info {
            display: flex;
            flex-direction: column;
            margin-left: 1*$rem;
            justify-content: space-between;
            span {
                color: #333333;
                font-size: 1.6*$rem;
                font-weight: 500;
            }
            >div {
                display: flex;
                flex-direction: row;
                align-items: center;
                >:nth-child(1) {
                    background: #D8D8D8;
                    width: 4.2*$rem;
                    height: 1.6*$rem;
                    line-height: 1.6*$rem;
                    border-radius: 0.8*$rem;
                    text-align: center;
                    color: #9E9E9E;
                    font-size: 1.2*$rem;
                }
                >:nth-child(2) {
                    margin: 0 0.7*$rem;
                    color: #999999;
                    font-size: 1.2*$rem;
                }
            }
        }
    }
    .item-bottom {
        display: flex;
        flex-direction: column;
        margin-top: 0.5 *$rem;
        .upgrade-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0.5*$rem 0;
            span {
                color: #999999;
                font-size: 1.2*$rem;
            }
            div {
                color: #333333;
                font-size: 1.2*$rem;
                font-weight: 500;
            }
        }
    }

}